import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import {
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  POSITIONTYPE_SERVICE_KEY,
  PROFESSIONALTYPE_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users'
import { emptyUserDTO, UserDTO } from '../../modules/users/models/User'
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { Alert, Box, Grid, MenuItem, Select, Skeleton, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import styles from './Editor.module.css'
import genericStyle from '../../common/utils/generic.module.css'
import professionalSMS from '../../assets/role_icons/ico-rol-profesional.svg'
import patient from '../../assets/role_icons/ico-rol-paciente.svg'
import career from '../../assets/role_icons/ico-rol-cuidador.svg'
import family from '../../assets/role_icons/ico-rol-familiar.svg'
import externProfessional from '../../assets/role_icons/ico-rol-externo.svg'
import { ROUTE_USER_PROFILE } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Header } from '../../components/header/Header'
import { Roles } from '../../modules/users/enums/Roles'
import { Query } from '../../common/api/Query'
import { RolesService } from '../../modules/users/services/RolesServices'
import { ProfessionalTypesService } from '../../modules/users/services/ProfessionalTypeService'
import { ProfessionalType } from '../../modules/users/models/ProfessionalType'
import {
  emptyProfessionalTypeUserDTO,
  ProfessionalTypeUserDTO,
  toModel,
} from '../../modules/users/models/ProfessionalTypeUserDTO'
import { v4 as uuidv4, v4 } from 'uuid'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { IStatusService } from '../../common/status/StatusService'
import { useGlobalContext } from '../../common/utils/GlobalRoleContext'
import { PositionType } from '../../modules/users/models/PositionType'
import { toModel as positionToModel } from '../../modules/users/models/PositionTypeUserDTO'
import { PositionTypesService } from '../../modules/users/services/PositionTypeService'
import {
  emptyProfessionalQueryDTO,
  fromModel,
  ProfessionalQueryDTO,
  toModel as professionalQueryToModel,
} from '../../modules/messenger_consulta/models/ProfessionalQueryDTO'
import { getMessengerConsultaContainer } from '../../container/messenger-consulta-module'
import { PROFESSIONAL_QUERY_SERVICE_KEY } from '../../modules/messenger_consulta'
import { ProfessionalQueryService } from 'modules/messenger_consulta/services/ProfessionalQueryService'
import { ProfessionalQuery } from '../../modules/messenger_consulta/models/ProfessionalQuery'
import { emptyPositionTypeDTO, PositionTypeDTO } from '../../modules/users/models/PositionTypeDTO'
import icoProfile from '../../assets/esfera/profile/profile.svg'
import {
  emptyProfessionalTypeDTO,
  ProfessionalTypeDTO,
} from '../../modules/users/models/ProfessionalTypeDTO'
import { emptyFileDTO } from '../../modules/files/models/FileDTO'
import { getFileContainer } from '../../container/file-module'
import { FILE_SERVICE_KEY, IFileService } from '../../modules/files'
import { useGetProfilePhoto } from '../../hooks/profilePhoto/useGetProfilePhoto'
import { forkJoin } from 'rxjs'

const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const roleService = userContainer.get<RolesService>(ROLES_SERVICE_KEY)
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)
const positionTypeService = userContainer.get<PositionTypesService>(POSITIONTYPE_SERVICE_KEY)
const professionalQueryService = getMessengerConsultaContainer().get<ProfessionalQueryService>(
  PROFESSIONAL_QUERY_SERVICE_KEY
)
const resourcesService = getFileContainer().get<IFileService>(FILE_SERVICE_KEY)

export function Editor() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()

  const [userToEdit, setUserToEdit] = useState<UserDTO>(emptyUserDTO())
  // const [role, setRole] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [roles, setRoles] = useState<Map<string, string>>(new Map())
  const [professionalSpecialities, setProfessionalSpecialities] = useState<ProfessionalType[]>([])
  const [professionalPosition, setProfessionalPosition] = useState<PositionType[]>([])
  const [professionalTypeUser, setProfessionalTypeUser] = useState<ProfessionalTypeUserDTO>(
    emptyProfessionalTypeUserDTO()
  )
  const [professionalType, setProfessionalType] = useState<ProfessionalTypeDTO>(
    emptyProfessionalTypeDTO()
  )
  const [positionTypeUser, setPositionTypeUser] = useState<PositionTypeDTO>(emptyPositionTypeDTO())
  const [positionType, setPositionType] = useState<string>('')
  const [professionalQuery, setProfessionalQuery] = useState<ProfessionalQueryDTO>(
    emptyProfessionalQueryDTO()
  )
  const [pQ, setPq] = useState<ProfessionalQuery | undefined>()
  const { role } = useGlobalContext()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { profilePhotoData, refreshProfilePhoto, setRefreshProfilePhoto, isLoading, setIsloading } =
    useGetProfilePhoto()

  useEffect(() => {
    roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        const tmpMap = new Map<string, string>()
        res.forEach((r) => tmpMap.set(r.id, r.name))
        setRoles(tmpMap)
      })
  }, [])

  useEffect(() => {
    userService.getByID(loggedUser?.id ?? '').subscribe((res) => {
      if (!res) {
        return
      }
      setUserToEdit(res.toDTO())
    })
  }, [roles])

  useEffect(() => {
    if (loggedUser)
      professionalQueryService.getByUserID(loggedUser?.id).subscribe((res) => {
        setPq(res)
        if (res) {
          setProfessionalQuery(fromModel(res))
        }
      })
  }, [])

  useEffect(() => {
    professionalTypeService
      .getUserProfessionalTypeByUserID(loggedUser?.id ?? '')
      .subscribe((res) => {
        if (res) {
          setProfessionalTypeUser(res)
        }
      })
  }, [])

  useEffect(() => {
    professionalTypeService
      .getByID(professionalTypeUser?.professionalTypeID || '')
      .subscribe((res) => {
        if (res) {
          setProfessionalType(res)
        }
      })

    professionalTypeService
      .getUserProfessionalTypeByUserID(loggedUser?.id ?? '')
      .subscribe((res) => {})
  }, [professionalTypeUser])

  useEffect(() => {
    professionalTypeService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        setProfessionalSpecialities(res)
      })
  }, [])

  useEffect(() => {
    positionTypeService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        setProfessionalPosition(res)
      })
  }, [])

  useEffect(() => {
    positionTypeService.getPositionTypeByUserID(loggedUser?.id ?? '').subscribe((res) => {
      if (res) {
        setPositionTypeUser(res)
      }
    })
  }, [])

  useEffect(() => {
    positionTypeService.getByID(positionType).subscribe((res) => {
      if (res) {
        setPositionTypeUser(res)
      }
    })
  }, [positionType])

  useEffect(() => {
    setMessage('')
  }, [userToEdit])

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setUserToEdit(Object.assign({ ...userToEdit }, { [e.target.name]: e.target.value }))

  const getImage = () => {
    if (role.length === 0) return
    switch (role[0]?.name) {
      case Roles.Professional:
        return <img className={styles.roleIcon} src={professionalSMS} alt={t(Roles.Professional)} />

      case Roles.Patient:
        return <img className={styles.roleIcon} src={patient} alt={t(Roles.Patient)} />

      case Roles.CarerOrTeacher:
        return <img className={styles.roleIcon} src={career} alt={t(Roles.CarerOrTeacher)} />

      case Roles.FamilyOrTutor:
        return <img className={styles.roleIcon} src={family} alt={t(Roles.FamilyOrTutor)} />

      case Roles.ProfessionalExtern:
        return (
          <img
            className={styles.roleIcon}
            src={externProfessional}
            alt={t(Roles.ProfessionalExtern)}
          />
        )
    }
  }

  const handleSelect = (e: string) => {
    professionalTypeService.getByName(e).subscribe((res) => {
      if (res) {
        const aux: ProfessionalType = res
        setProfessionalType(aux)
      }
    })
  }

  function formatDate(dateString: Date): string {
    const date = new Date(dateString)
    let month = '' + (date.getMonth() + 1)
    let day = '' + date.getDate()
    const year = date.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [day, month, year].join('-')
  }

  function calculateAge(dateString: Date): number {
    const birthDate = new Date(dateString)
    const today = new Date()
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }

  const handleSelectPosition = (e: string) => {
    setPositionType(e)
  }

  const cancelHandler = () => navigate(ROUTE_USER_PROFILE)

  function validarNumeroTelefono(numero: string): boolean {
    const patronTelefono = /^(?:(?:\+|00)?34)?[\s.]?(\d{9})$/

    const numeroLimpio = numero.replace(/[\s.-]/g, '')

    const esValido = patronTelefono.test(numeroLimpio)

    return esValido
  }

  const handleUploadPhoto = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (file: File) => {
    const selectedFile = emptyFileDTO(loggedUser?.id, '', true)
    if (!loggedUser) return
    selectedFile.id = loggedUser.id
    selectedFile.name = 'profile' + loggedUser.id
    selectedFile.mimeType = file.type
    selectedFile.extension = file.name.split('.').pop() ?? '' // get last text after last dot
    selectedFile.creator = loggedUser.id
    // get data from file
    const reader = new FileReader()

    reader.onload = function (e) {
      const fileData = e.target?.result // Obtiene los datos del archivo en forma de ArrayBuffer

      let base64Data = ''

      if (!fileData) return
      if (fileData instanceof ArrayBuffer) {
        base64Data = btoa(
          new Uint8Array(fileData).reduce((data, byte) => data + String.fromCharCode(byte), '')
        )
      } else {
        // If it's a string (text data), use it directly
        base64Data = btoa(fileData)
      }
      selectedFile.data = base64Data
      // send file to server
      // check if already exist a profile photo
      resourcesService.getByID(loggedUser.id).subscribe((res) => {
        setIsloading(true)
        if (res) {
          resourcesService.update(selectedFile).subscribe((res) => {
            setRefreshProfilePhoto(!refreshProfilePhoto)
          })
        } else {
          resourcesService.add(selectedFile).subscribe((res) => {
            setRefreshProfilePhoto(!refreshProfilePhoto)
          })
        }
      })
    }

    reader.readAsArrayBuffer(file)
  }

  const saveData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (userToEdit.email === '' || userToEdit.email === undefined) {
      setErrorMessage('emailRequired')
      return
    }
    if (!validarNumeroTelefono(userToEdit.phone)) {
      setErrorMessage('phoneInvalid')
      return
    }
    setErrorMessage('')
    professionalQuery.userID = userToEdit.id
    if (professionalQuery.message === '' || professionalQuery.message === undefined) {
      professionalQuery.message = 'Consulta no disponible en este momento.'
    }
    if (pQ) {
      professionalQuery.id = pQ.id
    }
    forkJoin([
      userService.update(userToEdit),
      pQ
        ? professionalQueryService.update(professionalQueryToModel(professionalQuery))
        : professionalQueryService.add(professionalQueryToModel(professionalQuery)),
      professionalTypeService.getUserProfessionalTypeByUserID(userToEdit.id),
      positionTypeService.createPositionTypeUser(
        positionToModel({ id: v4(), positionTypeID: positionType, userID: userToEdit.id })
      ),
    ]).subscribe((array) => {
      const res = array[2]
      if (res) {
        res.professionalTypeID = professionalType.id
        professionalTypeService.updateProfessionalTypeUser(toModel(res)).subscribe((res) => {})
      } else {
        professionalTypeUser.id = uuidv4()
        professionalTypeUser.professionalTypeID = professionalType.id
        professionalTypeUser.userID = userToEdit.id
        professionalTypeService
          .createProfessionalTypeUser(toModel(professionalTypeUser))
          .subscribe((res) => {})
      }

      statusService.sendStatus({ variant: 'success' })
      navigate(ROUTE_USER_PROFILE)
    })
  }

  const hasRelevantRole = [
    'professionalSMS',
    'managerHealthChild',
    'managerActivePlan',
    'managerEpileptic',
  ].some((role) => loggedUser?.roles?.includes(role))

  const onlyCaseManager = ['managerHealthChild', 'managerActivePlan', 'managerEpileptic'].some(
    (role) => loggedUser?.roles?.includes(role)
  )

  return (
    <Box className={genericStyle.pageContainer}>
      <form onSubmit={saveData}>
        <Box bgcolor="white" m="1rem">
          <Box className={styles.headerContainer}>
            <Header
              label={
                loggedUser?.roles?.includes('patient')
                  ? t('patientProfile')
                  : t('professionalProfile')
              }
            />
          </Box>
          <Box className={genericStyle.containerRow}>
            <Box>
              {isLoading ? (
                <Skeleton
                  style={{ marginRight: '40px', marginLeft: '8px' }}
                  variant="circular"
                  width={100}
                  height={100}
                />
              ) : (
                <Box className={styles.imagenRow}>
                  <Box className={styles.containerColumn}>
                    <Box
                      style={{
                        backgroundColor: '#2C80BA',
                        borderRadius: '50%',
                        width: '100px',
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '40px',
                        marginLeft: '8px',
                      }}
                    >
                      {profilePhotoData !== '' ? (
                        <>
                          <img
                            style={{
                              height: '110px',
                              width: '110px',
                              background: '#FFF',
                              borderRadius: 100,
                            }}
                            src={`data:image/jpeg;base64,${profilePhotoData}`}
                            alt={'selectedProfilePhoto'}
                          />
                        </>
                      ) : (
                        <img src={icoProfile} alt={icoProfile} />
                      )}
                    </Box>
                    {
                      // Change for patient when security issue is fixed inside includes
                      loggedUser?.roles?.includes('changeForPatientWhenSecurityIssueFixed') && (
                        <Box sx={{ marginTop: '12px' }}>
                          <AppButton
                            theme={ButtonTheme.NewPrimary}
                            type={'button'}
                            label={t('uploadPhoto')}
                            span={true}
                            handler={handleUploadPhoto}
                          />
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={(e) => {
                              const file = e.target.files![0]
                              if (file) {
                                handleFileChange(file)
                              }
                            }}
                          />
                        </Box>
                      )
                    }
                  </Box>
                </Box>
              )}
            </Box>

            <Grid container className={genericStyle.leftBar}>
              {loggedUser?.roles?.includes('patient') && (
                <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                  <Grid item>
                    <p className={styles.label}>{t('fullName')}</p>
                  </Grid>
                  <Grid item xs={10}>
                    <p className={styles.textResponsive}>{userToEdit.name}</p>
                  </Grid>
                </Box>
              )}

              {loggedUser?.roles?.includes('professionalSMS') && (
                <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                  <Grid item>
                    <p className={styles.label}>{t('fullName')}</p>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      id={'name'}
                      key={'name'}
                      name="name"
                      className={styles.textField}
                      value={userToEdit.name}
                      type="name"
                      required={true}
                      variant={'outlined'}
                      size={'small'}
                      disabled={true}
                    />
                  </Grid>
                </Box>
              )}

              {loggedUser?.roles?.includes('patient') && (
                <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                  <Grid item>
                    <p className={styles.label}>{t('birthdate')}</p>
                  </Grid>
                  <Grid item xs={10}>
                    <p className={styles.textResponsive}>{formatDate(userToEdit.birthDate)}</p>
                  </Grid>
                </Box>
              )}

              {loggedUser?.roles?.includes('patient') && (
                <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                  <Grid item>
                    <p className={styles.label}>{t('age')}</p>
                  </Grid>
                  <Grid item xs={10}>
                    <p className={styles.textResponsive}>{calculateAge(userToEdit.birthDate)}</p>
                  </Grid>
                </Box>
              )}

              {loggedUser?.roles?.includes('patient') && (
                <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                  <Grid item>
                    <p className={styles.label}>{t('dni')}</p>
                  </Grid>
                  <Grid item xs={10}>
                    <p className={styles.textResponsive}>{userToEdit.dni}</p>
                  </Grid>
                </Box>
              )}

              {loggedUser?.roles?.includes('patient') && (
                <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                  <Grid item>
                    <p className={styles.label}>{t('cip')}</p>
                  </Grid>

                  <Grid item xs={10}>
                    <p className={styles.textResponsive}> {userToEdit.cip}</p>
                  </Grid>
                </Box>
              )}

              <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                <Grid item>
                  <p className={styles.label}>{t('email')}</p>
                </Grid>

                <Grid item xs={10}>
                  <TextField
                    id={'email'}
                    key={'email'}
                    name="email"
                    className={styles.textField}
                    value={userToEdit.email}
                    type="email"
                    onChange={handleInput}
                    variant={'outlined'}
                    size={'small'}
                  />
                </Grid>
              </Box>

              <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                <Grid item>
                  <p className={styles.label}>{t('phone')}</p>
                </Grid>

                <Grid item xs={10}>
                  <TextField
                    id={'phone'}
                    key={'phone'}
                    name="phone"
                    type="tel"
                    className={styles.textField}
                    value={userToEdit.phone}
                    onChange={handleInput}
                    variant={'outlined'}
                    size={'small'}
                  />
                </Grid>
              </Box>

              {loggedUser?.roles?.includes('patient') && (
                <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                  <Grid item>
                    <p className={styles.label}>{t('role') + '*'}</p>
                  </Grid>

                  <Grid item xs={10}>
                    <p className={styles.textResponsive}>
                      {t(role && role.length > 1 ? role[1].name : role[0]?.name || '')}
                    </p>
                  </Grid>
                </Box>
              )}

              {hasRelevantRole && (
                <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                  <Grid item>
                    <p className={styles.label}>{t('role') + '*'}</p>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      id="role"
                      key="role"
                      name="role"
                      className={styles.textField}
                      InputProps={{
                        startAdornment: getImage(),
                      }}
                      value={
                        role.length && role.find((r) => r.name === 'professionalSMS')
                          ? t(role && role.length > 1 ? role[1].name : role[0]?.name || '')
                          : t('caseManager')
                      }
                      type="text"
                      required={true}
                      variant={'outlined'}
                      size={'small'}
                      disabled={true}
                    />
                  </Grid>
                </Box>
              )}

              {onlyCaseManager && (
                <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                  <Grid item>
                    <p className={styles.label}>{t('circle') + '*'}</p>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      id="role"
                      key="role"
                      name="role"
                      className={styles.textField}
                      InputProps={{
                        startAdornment: getImage(),
                      }}
                      value={
                        loggedUser?.roles?.includes('managerActivePlan')
                          ? t('activePlan')
                          : loggedUser?.roles?.includes('managerHealthChild')
                            ? t('healthykid')
                            : loggedUser?.roles?.includes('managerEpileptic')
                              ? t('pediatricEpilepsy')
                              : ''
                      }
                      type="text"
                      required={true}
                      variant={'outlined'}
                      size={'small'}
                      disabled={true}
                    />
                  </Grid>
                </Box>
              )}

              <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                {role.length && role.find((r) => r.name === 'professionalSMS') && (
                  <>
                    <Grid item>
                      <p className={styles.label}>{t('professionalType') + '*'}</p>
                    </Grid>

                    <Grid item xs={10}>
                      <Select
                        id="specialty-select"
                        key="specialty-select"
                        className={styles.textField}
                        style={{ height: '40px' }}
                        value={(professionalType && professionalType.name) || ''}
                        name="professionalType"
                        variant={'outlined'}
                        onChange={(e) => handleSelect(e.target.value)}
                        required
                      >
                        {Object.values(professionalSpecialities).map((k) => (
                          <MenuItem key={k.id} value={k.name}>
                            {k.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </>
                )}
              </Box>
              {loggedUser?.roles?.includes('professionalSMS') && (
                <Box mb={3} className={styles.textFieldBox} display={'flex'}>
                  <Grid item>
                    <p className={styles.label}>{t('positionType') + '*'}</p>
                  </Grid>

                  <Grid item xs={10}>
                    <Select
                      id="position-select"
                      key="position-select"
                      className={styles.textField}
                      style={{ height: '40px' }}
                      value={positionTypeUser.id}
                      name="positionType"
                      variant={'outlined'}
                      onChange={(e) => {
                        handleSelectPosition(e.target.value)
                      }}
                      required
                    >
                      {Object.values(professionalPosition).map((k) => (
                        <MenuItem key={k.id} value={k.id}>
                          {k.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Box>
              )}
              {loggedUser?.roles?.includes('professionalSMS') && (
                <Box
                  mb={3}
                  className={styles.checkBox}
                  display={
                    loggedUser?.roles?.find((rol) => rol.includes('manager')) ? 'none' : 'flex'
                  }
                >
                  <Grid item>
                    <p className={styles.label}>{t('acceptChats')}</p>
                  </Grid>
                  <Grid item>
                    <div className={styles.radioContainer}>
                      <label className={styles.radioLabel}>
                        <input
                          type="radio"
                          name="acceptChats"
                          value="yes"
                          checked={userToEdit.acceptChat}
                          onChange={() =>
                            setUserToEdit(Object.assign({ ...userToEdit }, { acceptChat: true }))
                          }
                          className={styles.check}
                          disabled={false}
                        />
                        Sí
                      </label>

                      <label className={styles.radioLabel}>
                        <input
                          type="radio"
                          name="acceptChats"
                          value="no"
                          checked={!userToEdit.acceptChat}
                          onChange={() =>
                            setUserToEdit(Object.assign({ ...userToEdit }, { acceptChat: false }))
                          }
                          className={styles.check}
                          disabled={false}
                        />
                        No
                      </label>
                    </div>
                  </Grid>
                </Box>
              )}
              {loggedUser?.roles?.includes('professionalSMS') && (
                <Box mb={3} className={styles.checkBox} display={'flex'}>
                  <Grid item>
                    <p className={styles.label}>{t('acceptQuerys') + ':'}</p>
                  </Grid>
                  <Grid item>
                    <div className={styles.radioContainer}>
                      <label className={styles.radioLabel}>
                        <input
                          type="radio"
                          name={'acceptQuerys'}
                          value="yes"
                          checked={professionalQuery.acceptConsulta === 1}
                          onChange={() => {
                            setProfessionalQuery(
                              Object.assign({ ...professionalQuery }, { acceptConsulta: 1 })
                            )
                          }}
                          className={styles.check}
                          disabled={false}
                        />
                        Sí
                      </label>

                      <label className={styles.radioLabel}>
                        <input
                          type="radio"
                          name={'acceptQuerys'}
                          value="no"
                          checked={professionalQuery.acceptConsulta === 0}
                          onChange={() => {
                            setProfessionalQuery(
                              Object.assign({ ...professionalQuery }, { acceptConsulta: 0 })
                            )
                          }}
                          className={styles.check}
                          disabled={false}
                        />
                        No
                      </label>
                    </div>
                  </Grid>
                </Box>
              )}
              {loggedUser?.roles?.includes('professionalSMS') && (
                <Box mb={3} className={styles.checkBox} display={'flex'}>
                  <Grid item>
                    <p className={styles.label}>{t('acceptVideoCalls')}</p>
                  </Grid>
                  <Grid item>
                    <div className={styles.radioContainer}>
                      <label className={styles.radioLabel}>
                        <input
                          type="radio"
                          name="acceptVideoCalls"
                          value="yes"
                          checked={userToEdit.acceptVideoCalls}
                          onChange={() =>
                            setUserToEdit(
                              Object.assign({ ...userToEdit }, { acceptVideoCalls: true })
                            )
                          }
                          className={styles.check}
                          disabled={false}
                        />
                        Sí
                      </label>

                      <label className={styles.radioLabel}>
                        <input
                          type="radio"
                          name="acceptVideoCalls"
                          value="no"
                          checked={!userToEdit.acceptVideoCalls}
                          onChange={() =>
                            setUserToEdit(
                              Object.assign({ ...userToEdit }, { acceptVideoCalls: false })
                            )
                          }
                          className={styles.check}
                          disabled={false}
                        />
                        No
                      </label>
                    </div>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
        {message && (
          <Box mb={3}>
            <Alert severity="success" key="message" id="message">
              {t(message)}
            </Alert>
          </Box>
        )}
        {errorMessage && (
          <Box mb={3}>
            <Alert severity="warning" key="errorMessage" id="errorMessage">
              {t(errorMessage)}
            </Alert>
          </Box>
        )}
        <Box className={styles.buttonContainer} display="flex">
          <AppButton
            theme={ButtonTheme.NewSecondary}
            type={'button'}
            label={t('cancel')}
            handler={() => cancelHandler()}
          />
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'submit'}
            label={t('save')}
            handler={() => {}}
          />
        </Box>
      </form>
    </Box>
  )
}
