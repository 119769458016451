// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n\n\n.dashBoardStyle_selectMainRow__2ZHjc{\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n}", "",{"version":3,"sources":["webpack://src/features/dashboard/dashBoardStyle.module.css"],"names":[],"mappings":";;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":["\n\n\n.selectMainRow{\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectMainRow": "dashBoardStyle_selectMainRow__2ZHjc"
};
export default ___CSS_LOADER_EXPORT___;
