import { Box, Popper } from '@mui/material'
import { useSelectedMenuContext } from 'common/utils/menu-selected-context/MenuSelectedContext'
import { styleMenu } from 'pages/layout/stylesFunctions'
import React from 'react'

type ArticleSubMenuProps = {
  children: React.ReactNode
  style: string
  anchorEl: HTMLElement | null
}

export const ArticleSubMenu = ({ children, style, anchorEl }: ArticleSubMenuProps) => {
  const { isArticleOpen, setIsArticleOpen } = useSelectedMenuContext()

  return (
    <div onMouseEnter={() => setIsArticleOpen(true)}>
      <Popper
        anchorEl={anchorEl}
        id={isArticleOpen ? 'simple-popper' : undefined}
        open={isArticleOpen ?? false}
        style={styleMenu}
        transition={true}
      >
        <Box onMouseLeave={() => setIsArticleOpen(false)} className={style}>
          {children}
        </Box>
      </Popper>
    </div>
  )
}
