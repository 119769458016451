import { UserCircle, UserCircleQuery } from '../models/UserCircle'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { IUserCircleApi } from '../api/UserCircleApi'
import { ItemList } from '../../../common/models/ItemList'
import { UserCircleDTO } from '../models/UserCircleDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UserCircleWithCircle } from '../models/UserCircleWithCircle'
import { UserCircleWithRelaters } from '../models/UserCircleWithRelaters'
import { UserCircleWithCircleAndUser } from '../models/UserCircleWithCircleAndUser'
import { UserCircleWithRelatersAndCircle } from '../models/UserCircleWithRelatersAndCircle'

type Props = {
  apiKey: symbol
}

export interface IUserCircleService extends IInit {
  getByID(id: string): Observable<UserCircle | undefined>

  getByUserIDWithRelaters(id: string): Observable<UserCircleWithRelaters[] | undefined>

  getUserCircleByUserIDWithCircle(id: string): Observable<UserCircleWithCircle[] | undefined>

  getUserCircleByUserIDWithCircleForQuery(
    id: string
  ): Observable<UserCircleWithCircle[] | undefined>

  getWichIncludesUserIDWithRelatersAndCircle(
    userID: string
  ): Observable<UserCircleWithRelatersAndCircle[] | undefined>

  getWichIncludesUserIDWithRelatersAndCircleLetter(
    userID: string,
    letter: number
  ): Observable<UserCircleWithRelatersAndCircle[] | undefined>

  getFilteredList(q: Query<UserCircleQuery>): Observable<ItemList<UserCircle>>

  getFilteredListWithCircleAndUser(
    q: Query<UserCircleQuery>
  ): Observable<ItemList<UserCircleWithCircleAndUser>>

  add(e: UserCircleDTO): Observable<UserCircle | undefined>

  update(e: UserCircleDTO): Observable<UserCircle | undefined>

  removeParticipant(id: string, participantId: string): Observable<string>

  delete(id: string): Observable<boolean>

  getFilteredListWithCircleAndUserCustom(
    q: Query<UserCircleQuery>
  ): Observable<ItemList<UserCircleWithCircleAndUser>>
}

export class UserCircleService implements IUserCircleService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserCircleApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserCircleApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: UserCircleDTO): Observable<UserCircle | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<UserCircle | undefined> {
    return this._api.getByID(id)
  }

  getUserCircleByUserIDWithCircle(id: string): Observable<UserCircleWithCircle[] | undefined> {
    return this._api.getUserCircleByUserIDWithCircle(id)
  }

  getUserCirclesByUserId(id: string): Observable<UserCircleWithCircle[] | undefined> {
    return this._api.getUserCirclesByUserId(id)
  }

  getUserCircleByUserIDWithCircleForQuery(
    id: string
  ): Observable<UserCircleWithCircle[] | undefined> {
    return this._api.getUserCircleByUserIDWithCircleForQuery(id)
  }

  getByUserIDWithRelaters(id: string): Observable<UserCircleWithRelaters[] | undefined> {
    return this._api.getByUserIDWithRelaters(id)
  }

  getWichIncludesUserIDWithRelatersAndCircle(
    userID: string
  ): Observable<UserCircleWithRelatersAndCircle[] | undefined> {
    return this._api.getWichIncludesUserIDWithRelatersAndCircle(userID)
  }

  getWichIncludesUserIDWithRelatersAndCircleLetter(
    userID: string,
    letter: number
  ): Observable<UserCircleWithRelatersAndCircle[] | undefined> {
    return this._api.getWichIncludesUserIDWithRelatersAndCircleLetter(userID, letter)
  }

  getFilteredList(q: Query<UserCircleQuery>): Observable<ItemList<UserCircle>> {
    return this._api.getFilteredList(q)
  }

  getFilteredListWithCircleAndUser(
    q: Query<UserCircleQuery>
  ): Observable<ItemList<UserCircleWithCircleAndUser>> {
    return this._api.getFilteredListWithCircleAndUser(q)
  }

  update(e: UserCircleDTO): Observable<UserCircle | undefined> {
    return this._api.update(e)
  }

  removeParticipant(id: string, participantId: string): Observable<string> {
    return this._api.removeParticipant(id, participantId)
  }

  getFilteredListWithCircleAndUserCustom(
    q: Query<UserCircleQuery>
  ): Observable<ItemList<UserCircleWithCircleAndUser>> {
    return this._api.getFilteredListWithCircleAndUserCustom(q)
  }
}
