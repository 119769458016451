
import { Box } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { NotificationsCardPatient } from './notification-card-patient/NotificationsCardPatient'
import { ChatsCard } from './chats-card/ChatsCard'
import { CirclesCard } from './circles-card/CirclesCard'
import { EducationalContentCard } from './educational-content-card/EducationalContentCard'
import { ActiveQueriesCard } from './active-query-card/ActiveQueriesCard'
import { UserCircleWithRelatersAndCircle } from '../../../modules/user-circle/models/UserCircleWithRelatersAndCircle'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { useTranslation } from 'react-i18next'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
type DashboardPatientMobileProps = {
  section: number;
  changeSection: (number: number) => void
  SetSelectedUserCircle: (userCircle: UserCircleWithRelatersAndCircle) => void
  SetNumberPendingChats: (number: number) => void
  SetNumberNewResources: (number: number) => void

  selectedUserCircle: UserCircleWithRelatersAndCircle | undefined
}


export const DashboardPatientMobile = (props: DashboardPatientMobileProps) => {
  const { t } = useTranslation()

  return (
      <>
        {
          props.section === -1 && (
            <Box style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <AppButton
                  label={t('notifications')}
                  theme={ButtonTheme.borderOrangeTextBlack}
                  handler={(e) => props.changeSection(0)}
                  type={'button'}
                  fullWidth
                />
                <AppButton
                  label={t('yourCircles')}
                  theme={ButtonTheme.borderOrangeTextBlack}
                  handler={(e) => props.changeSection(1)}
                  type={'button'}
                  fullWidth
                />
                <AppButton
                  label={t('pendingChats')}
                  theme={ButtonTheme.borderOrangeTextBlack}
                  handler={(e) => props.changeSection(2)}
                  type={'button'}
                  fullWidth
                />
                <AppButton
                  label={t('educationalContent')}
                  theme={ButtonTheme.borderOrangeTextBlack}
                  handler={(e) => props.changeSection(3)}
                  type={'button'}
                  fullWidth
                />
                <AppButton
                  label={t('activeQuerys')}
                  theme={ButtonTheme.borderOrangeTextBlack}
                  handler={(e) => props.changeSection(4)}
                  type={'button'}
                  fullWidth
                />
            </Box>
          )
        }
        {
          props.section === 0 && (
            <NotificationsCardPatient
              ProfessionalNotifications={false}
              SetNumberNewResources={props.SetNumberNewResources}
              selectedUserCircle={props.selectedUserCircle}
            />
          )
        }
        {
          props.section === 1 && (
            <CirclesCard
              selectedUserCircle={props.selectedUserCircle}
              setSelectedUserCircle={props.SetSelectedUserCircle}
            />
          )
        }
        {
          props.section === 2 && (  
            <ChatsCard setNumberPendingChats={props.SetNumberPendingChats} />
          )
        }
        {
          props.section === 3 && (
            <EducationalContentCard />
          )
        }
        {
          props.section === 4 && (
            <ActiveQueriesCard />
          )
        }
      </>
  )
}
