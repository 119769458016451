import React from 'react'
import { Pager } from '../table_type/types'
import { useTranslation } from 'react-i18next'
import { TablePagination } from '@mui/material'
import { ROWS_PER_PAGE_OPTIONS } from 'common/const'

type PaginationProps = Pager

export function Pagination(props: PaginationProps) {
  const { t } = useTranslation()

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    props.rowsPerPage ?? ROWS_PER_PAGE_OPTIONS[0]
  )

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (props.handleChangeRowsPerPage) {
      setRowsPerPage(parseInt(event.target.value))
      props.handleChangeRowsPerPage(event)
    }
  }

  return (
    <TablePagination
      labelRowsPerPage={
        <span style={{ color: '#515151', fontFamily: 'OpenSans', fontSize: '16px' }}>
          {t('Resultados por página')}
        </span>
      }
      count={props.count}
      onPageChange={(event, page) => props.handleChangePage(event, page)}
      page={props.page}
      labelDisplayedRows={() => <></>}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      style={{ border: 'none' }}
      sx={{
        '& .MuiTablePagination-caption': {
          fontFamily: 'Poppins !important',
        },
        '& .MuiTablePagination-toolbar': {
          '& > p:nth-of-type(2)': {
            fontFamily: 'Poppins !important',
          },
        },
        '& .MuiTablePagination-actions': {
          margin: 0,
        },
        '& .MuiTablePagination-selectIcon': {
          color: '#7cb5ec',
        },
      }}
      SelectProps={{
        style: {
          border: '1px solid #7cb5ec',
          borderRadius: '3px',
          fontFamily: 'Poppins',
          margin: 0,
          color: '#7cb5ec',
        },
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom', // Make the options appear below the Select
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              marginTop: '8px', // Add some spacing to separate the Select and options
              borderRadius: '3px',
            },
          },
        },
      }}
      backIconButtonProps={{
        style: {
          color: props.page === 0 ? '#b5b8c4' : '#7cb5ec',
          border: 'none',
          borderRadius: '3px',
          width: '30px',
          height: '30px',
        },
      }}
      nextIconButtonProps={{
        style: {
          color: props.count - (props.page + 1) * rowsPerPage <= 0 ? '#b5b8c4' : '#7cb5ec',
          border: 'none',
          borderRadius: '3px',
          width: '30px',
          height: '30px',
        },
      }}
    />
  )
}
