// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TeleconsultationForm_newConversationContainer__3mcLe {\n  flex-direction: column;\n  min-height: 400px;\n  width: auto;\n  display: flex;\n  background-color: var(--color-white);\n  padding: 1rem;\n  height: 100%;\n  overflow-y: auto;\n\n}", "",{"version":3,"sources":["webpack://src/features/teleconsultation/TeleconsultationForm.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,oCAAoC;EACpC,aAAa;EACb,YAAY;EACZ,gBAAgB;;AAElB","sourcesContent":[".newConversationContainer {\n  flex-direction: column;\n  min-height: 400px;\n  width: auto;\n  display: flex;\n  background-color: var(--color-white);\n  padding: 1rem;\n  height: 100%;\n  overflow-y: auto;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newConversationContainer": "TeleconsultationForm_newConversationContainer__3mcLe"
};
export default ___CSS_LOADER_EXPORT___;
