import { DashboardSectionCard } from '../../../../components/section-card/DashboardSectionCard'
import React, { useEffect, useState } from 'react'
import { getUserCircleContainer } from '../../../../container/user-circle-module'
import { IUserCircleService, USER_CIRCLE_SERVICE_KEY } from '../../../../modules/user-circle'
import { UserCircleWithRelatersAndCircle } from '../../../../modules/user-circle/models/UserCircleWithRelatersAndCircle'
import { getUserContainer } from '../../../../container/user-module'
import { ILoggedUserService } from '../../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../../modules/users'
import { Box, List, ListItem, ListItemText } from '@mui/material'
import style from './CirclesCard.module.css'
import { listItemTextStyle } from '../notification-card-patient/NotificationsCardStyleMui'
import patientIcon from '../../../../assets/role_icons/ico-rol-paciente.svg'
import professionalSMSIcon from '../../../../assets/role_icons/ico-rol-profesional.svg'
import familyIcon from '../../../../assets/role_icons/ico-rol-familiar.svg'
import externProfessionalIcon from '../../../../assets/role_icons/ico-rol-externo.svg'
import career from '../../../../assets/role_icons/ico-rol-cuidador.svg'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CIRCLES } from 'routes/routes-constants'

type CirclesCardProps = {
  selectedUserCircle: UserCircleWithRelatersAndCircle | undefined
  setSelectedUserCircle: (userCircle: UserCircleWithRelatersAndCircle) => void
}

const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const CirclesCard: React.FC<CirclesCardProps> = ({
  setSelectedUserCircle,
  selectedUserCircle,
}) => {
  const [userCircles, setUserCircles] = useState<UserCircleWithRelatersAndCircle[]>([])
  const loggedUser = loggedUserService.get()
  const navigate = useNavigate()

  useEffect(() => {
    const storedUser = localStorage.getItem('selected user')

    const selectedUsr = storedUser ? JSON.parse(storedUser) : null
    const userId = selectedUsr?.id ?? '' // Si es null o undefined, usamos un string vacío
    userCircleService.getWichIncludesUserIDWithRelatersAndCircle(userId).subscribe((res) => {
      if (res && res.length !== 0) {
        setUserCircles(res)
        setSelectedUserCircle(res[0])
      }
    })
  }, [])

  const handleClickCircle = (userCircle: UserCircleWithRelatersAndCircle) => {
    setSelectedUserCircle(userCircle)
  }

  const getRoleIcon = (userCircle: UserCircleWithRelatersAndCircle) => {
    if (userCircle.profExt.some((profExt) => profExt.id === loggedUser?.id)) {
      return externProfessionalIcon
    }
    if (userCircle.profSms.some((prof) => prof.id === loggedUser?.id)) {
      return professionalSMSIcon
    }
    if (userCircle.familiars.some((fam) => fam.id === loggedUser?.id)) {
      return familyIcon
    }
    if (userCircle.careers.some((carer) => carer.id === loggedUser?.id)) {
      return career
    }

    return patientIcon
  }

  const handleShowMore = () => {
    navigate(ROUTE_CIRCLES)
  }

  return (
    <DashboardSectionCard label={'Círculos del paciente'} onClick={handleShowMore}>
      <Box className={style.container}>
        <List className={style.wrapper}>
          {userCircles.map((userCircle) => (
            <ListItem
              key={userCircle.id}
              onClick={() => {
                handleClickCircle(userCircle)
              }}
              className={`${style.chatContainer} ${selectedUserCircle?.id === userCircle.id && style.chatContainerActive}`}
            >
              <ListItemText sx={listItemTextStyle} className={style.circle}>
                {userCircle.circle.name}
              </ListItemText>
              <Box className={style.user}>
                <img src={getRoleIcon(userCircle)} alt={'roleIcon'} className={style.icon} />
                <ListItemText sx={listItemTextStyle} className={style.text}>
                  {userCircle.user.name}
                </ListItemText>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </DashboardSectionCard>
  )
}
