import { User, UserDTO, UserQuery } from '../models/User'
import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { IUserApi } from '../api/UserApi'
import { ItemList } from '../../../common/models/ItemList'
import { Related, RelatedUser } from '../models/RelatedUser'
import { FamiliarData, FamiliarDataQuery } from '../models/FamiliarData'
import { FamiliarDataDTO } from '../models/FamiliarDataDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Role } from '../models/Role'
import { UpdatePassword } from '../models/UpdatePassword'
import { UpdatePasswordDTO } from '../models/UpdatePasswordDTO'
import { CitizenDataDTO } from '../models/CitizenDataDTO'
import { CitizenDataRes } from '../models/CitizenData'
import { RegisterUserDTO } from '../models/RegisterUserDTO'

import { UserPending } from '../models/UserPending'
import { UserWithRelaters } from '../models/UserWithRelaters'
import { Token } from '../models/Token'
import { ExternalProfessionalPending } from '../../userTypeExternalProfessional/models/ExternalProfessionalPending'

type Props = {
  apiKey: symbol
}

export interface IUserService extends IInit {
  getByID(id: string): Observable<User | undefined>

  getByUsername(username: string): Observable<User | undefined>

  getFilteredItems(q: Query<UserQuery>): Observable<User[]>

  getParticipantList(q: Query<UserQuery>): Observable<User[]>

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>>

  add(e: User): Observable<User | undefined>

  update(e: UserDTO): Observable<User | undefined>

  getRelated(id: string): Observable<RelatedUser | undefined>

  getByDoctorID(q: Query<UserQuery>): Observable<ItemList<User>>

  getUsersWithRelaters(userID: string): Observable<UserWithRelaters | undefined>

  getUsersWithRelatersLetter(
    userID: string,
    letter: number
  ): Observable<UserWithRelaters[] | undefined>

  addRelated(ru: Related, userID: string): Observable<Related>

  removeRelated(id: string, rID: string): Observable<boolean>

  getUserRolesByCircle(q: Query<User>): Observable<ItemList<User>>

  getInformationByID(id: string): Observable<FamiliarData | undefined>

  getInformationByUser(q: Query<FamiliarDataQuery>): Observable<ItemList<FamiliarData>>

  addInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined>

  updateInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined>

  deleteInformation(id: string): Observable<boolean>

  getPermissions(): Observable<ItemList<Role>>

  invite(email: string, circleID: string, roleName: string): Observable<boolean>

  passwordRecover(email: string): Observable<boolean>

  changePassword(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined>

  changePasswordLoggedUser(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined>

  getCitizenData(e: CitizenDataDTO): Observable<CitizenDataRes | undefined>

  register(e: RegisterUserDTO): Observable<UserDTO | undefined>

  checkToken(token: string): Observable<UserPending | ExternalProfessionalPending | undefined>

  getUserPending(circleId: string): Observable<UserPending[]>

  deleteUserPending(email: string): Observable<boolean>

  getUsersByDoctor(userID: string): Observable<User[]>
  getUsersByExternal(userID: string): Observable<User[]>
  checkRoleByUsername(username: string): Observable<string[] | undefined>

  checkRecoverToken(token: string): Observable<Token | undefined>

  registerTrainer(e: UserDTO, token: string): Observable<User | undefined>
}

export class UserService implements IUserService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: User): Observable<User | undefined> {
    return this._api.add(e)
  }

  getByID(id: string): Observable<User | undefined> {
    return this._api.getByID(id)
  }

  getByUsername(username: string): Observable<User | undefined> {
    return this._api.getByUsername(username)
  }

  getFilteredItems(q: Query<UserQuery>): Observable<User[]> {
    return this._api.getFilteredItems(q).pipe()
  }

  getParticipantList(q: Query<UserQuery>): Observable<User[]> {
    return this._api.getParticipantList(q).pipe()
  }

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: UserDTO): Observable<User | undefined> {
    return this._api.update(e)
  }

  getRelated(id: string): Observable<RelatedUser | undefined> {
    return this._api.getRelated(id)
  }

  getByDoctorID(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._api.getByDoctorID(q)
  }

  addRelated(ru: Related, userID: string): Observable<Related> {
    return this._api.addRelated(ru, userID)
  }

  removeRelated(id: string, rID: string): Observable<boolean> {
    return this._api.removeRelated(id, rID)
  }

  getUserRolesByCircle(q: Query<User>): Observable<ItemList<User>> {
    return this._api.getUserRolesByCircle(q)
  }

  getInformationByID(id: string): Observable<FamiliarData | undefined> {
    return this._api.getInformationByID(id)
  }

  getInformationByUser(q: Query<FamiliarDataQuery>): Observable<ItemList<FamiliarData>> {
    return this._api.getInformationByUser(q)
  }

  addInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined> {
    return this._api.addInformation(e)
  }

  updateInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined> {
    return this._api.updateInformation(e)
  }

  deleteInformation(id: string): Observable<boolean> {
    return this._api.deleteInformation(id)
  }

  getPermissions(): Observable<ItemList<Role>> {
    return this._api.getPermissions()
  }

  invite(email: string, circleID: string, roleName: string): Observable<boolean> {
    return this._api.invite(email, circleID, roleName)
  }

  passwordRecover(email: string): Observable<boolean> {
    return this._api.passwordRecover(email)
  }

  changePassword(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined> {
    return this._api.changePassword(e)
  }

  changePasswordLoggedUser(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined> {
    return this._api.changePasswordLoggedUser(e)
  }

  getCitizenData(e: CitizenDataDTO): Observable<CitizenDataRes | undefined> {
    return this._api.getCitizenData(e)
  }

  register(e: RegisterUserDTO): Observable<UserDTO | undefined> {
    return this._api.register(e)
  }

  checkToken(token: string): Observable<UserPending | ExternalProfessionalPending | undefined> {
    return this._api.checkToken(token)
  }

  getUserPending(circleId: string): Observable<UserPending[]> {
    return this._api.getUserPending(circleId)
  }

  deleteUserPending(token: string): Observable<boolean> {
    return this._api.deleteUserPending(token)
  }

  getUsersByDoctor(userID: string): Observable<User[]> {
    return this._api.getUsersByDoctor(userID)
  }

  getUsersWithRelaters(userID: string): Observable<UserWithRelaters | undefined> {
    return this._api.getUsersWithRelaters(userID)
  }

  getUsersWithRelatersLetter(
    userID: string,
    letter: number
  ): Observable<UserWithRelaters[] | undefined> {
    return this._api.getUsersWithRelatersLetter(userID, letter)
  }

  getUsersByExternal(userID: string): Observable<User[]> {
    return this._api.getUsersByExternal(userID)
  }

  checkRoleByUsername(username: string): Observable<string[] | undefined> {
    return this._api.checkRoleByUsername(username)
  }

  checkRecoverToken(token: string): Observable<Token | undefined> {
    return this._api.checkRecoverToken(token)
  }

  registerTrainer(e: UserDTO, token: string): Observable<User | undefined> {
    return this._api.registerTrainer(e, token)
  }
}
