import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import style from './EpilepsyCard.module.scss'
import { TypographyStyle } from './EpilepsyCardStyleMui'
import { dividerStyle } from './EpilepsyCardStyleMui'
import { Query, QueryParam } from '../../../../common/api/Query'
import GenericChartDashboard from '../../../../components/dashboard/generic-chart-dashboard/GenericChartDashboard'
import { ChartType } from 'chart.js'
import { LifeQuality } from './LifeQuality'
import { getFormContainer } from '../../../../container/form-module'
import { UserFormService } from '../../../../modules/forms/services/UserFormService'
import { USER_FORM_SERVICE_KEY } from '../../../../modules/forms'
import { UserForm, UserFormQuery } from '../../../../modules/forms/models/UserForm'
import { ReadingStatus } from '../../../../modules/forms/enums/ReadingStatus'
import { getUserCircleContainer } from '../../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../../modules/user-circle'
import { PROMS_PREMS } from '../../../../modules/forms/enums/FormType'
import { SleepQuality } from './SleepQuality'
import { CognitiveFunction } from './CognitiveFunction'
import { PsychologicalHealth } from './PsychologicalHealth'
import { CrisisSeverity } from './CrisisSeverity'
import { UserAppSatisfaction } from './UserAppSatisfaction'
import { UserSatisfaction } from './UserSatisfaction'
import { AdverseEffects } from './AdverseEffects'

type ActivePlanCardProps = {}

interface Dataset {
  label?: string
  data: number[]
  backgroundColor?: string | string[]
  borderColor?: string
}

interface GenericChartDashboardComponentProps {
  datasets: Dataset[]
  labels: string[]
  title: string
  typeChart: ChartType
  legendPosition: 'top' | 'bottom' | 'left' | 'right'
  showXAxis?: boolean
  showYAxis?: boolean
}

interface CardComponentProps {
  title: string
  percent?: string
  children: React.ReactNode
}

const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)

const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
export const EpilepsyCard: React.FC<ActivePlanCardProps> = () => {
  const activeUser = UserCircleActiveService.getActiveFullUserCircle()
  const [userFormsCompleted, setUserFormsCompleted] = useState<UserForm[]>([])
  const isXS = useMediaQuery('(max-width: 599px)');

  useEffect(() => {
    userFormService
      .getFilteredList(
        new Query<UserFormQuery>({
          query: [
            new QueryParam('userCircleID', activeUser?.id ?? ''),
            new QueryParam('readingStatus', ReadingStatus.Completed),
          ],
          sort: [{ field: 'date' }],
        })
      )
      .subscribe((data) => {
        console.log('data', data)
        if (!data) return
        setUserFormsCompleted(data.items)
      })
  }, [activeUser])

  return (
    <Grid container direction={isXS ? "column" : "row"}>
      <Grid container direction={isXS ? "column" : "row"} spacing={2} mt={4}>
        <Grid item xs={12} sm={6} md={3}>
          <LifeQuality
            userForms={userFormsCompleted.filter((uf) => uf.formID === PROMS_PREMS.QOLIE10)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SleepQuality
            userForms={userFormsCompleted.filter((uf) => uf.formID === PROMS_PREMS.PITTSBURGH)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CognitiveFunction
            userForms={userFormsCompleted.filter((uf) => uf.formID === PROMS_PREMS.SEAL)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <PsychologicalHealth
            userForms={userFormsCompleted.filter((uf) => uf.formID === PROMS_PREMS.BECKII)}
          />
        </Grid>
      </Grid>
      <Grid container direction={isXS ? "column" : "row"} spacing={2} mt={4}>
        <Grid item xs={12} sm={6} md={3}>
          <AdverseEffects
            userForms={userFormsCompleted.filter((uf) => uf.formID === PROMS_PREMS.ADVERSE_EFFECTS)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CrisisSeverity
            userForms={userFormsCompleted.filter((uf) => uf.formID === PROMS_PREMS.LSS20)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <UserAppSatisfaction
            userForms={userFormsCompleted.filter(
              (uf) => uf.formID === PROMS_PREMS.APP_USER_SATISFACTION
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <UserSatisfaction
            userForms={userFormsCompleted.filter(
              (uf) => uf.formID === PROMS_PREMS.USER_SATISFACTION
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const CardComponent: React.FC<CardComponentProps> = ({ percent, title, children }) => (
  <Card className={style.card}>
    <CardContent className={style.containerCard}>
      <Box className={style.rowSpaceBetween}>
        <Typography sx={TypographyStyle} variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography sx={TypographyStyle} variant="h5" gutterBottom>
          {percent}
        </Typography>
      </Box>

      <Divider sx={dividerStyle} />
      {children}
    </CardContent>
  </Card>
)

export const GenericChartDashboardComponent: React.FC<GenericChartDashboardComponentProps> = ({
  datasets,
  labels,
  title,
  typeChart,
  legendPosition,
  showXAxis,
  showYAxis,
}) => (
  <GenericChartDashboard
    datasets={datasets}
    labels={labels}
    title={title}
    typeChart={typeChart}
    legendPosition={legendPosition}
    showXAxis={showXAxis}
    showYAxis={showYAxis}
  />
)
