export enum SourceType {
  CalendarEventCreated = 1,
  CalendarEventUpdated = 2,
  CalendarNotification = 10,
  MessengerMessageCreated = 51,
  QueryMessageCreated = 52,
  FormsNewUserForm = 101,
  FormsUserFormFilled = 102,
  FilesNewCreated = 301,
  ContentNewArticle = 401,
  ContentArticleRejected = 402,
  ContentArticleValidated = 403,
  PatientSymptomCreated = 501,
  TreatmentCreated = 601,
  NewRegisteredUser = 702,
  ActivePlanCreated = 801,
  ActivePlanPending = 802,
  ActivePlanRefused = 803,
  ActivePlanActive = 804,
  ActivePlanFinished = 805,
  NewSegmentedNotification = 901,
  NewReport = 902
}

export const sourceTypes = (): Record<SourceType, string> => ({
  [SourceType.CalendarEventCreated]: 'New Event',
  [SourceType.CalendarEventUpdated]: 'Event Updated',
  [SourceType.CalendarNotification]: 'Event Notification',
  [SourceType.MessengerMessageCreated]: 'New Message',
  [SourceType.QueryMessageCreated]: 'New Query Message',
  [SourceType.FormsNewUserForm]: 'Form Pending',
  [SourceType.FormsUserFormFilled]: 'Form Filled',
  [SourceType.FilesNewCreated]: 'A new resource was created',
  [SourceType.ContentNewArticle]: 'A new article was created',
  [SourceType.ContentArticleRejected]: 'The article you published was rejected by the Content Manager',
  [SourceType.ContentArticleValidated]: 'A new article has to be validated',
  [SourceType.PatientSymptomCreated]: 'A new patient symptom was created',
  [SourceType.TreatmentCreated]: 'A new treatment was created',
  [SourceType.NewRegisteredUser]: 'A new user has been registered',
  [SourceType.ActivePlanCreated]: 'New Active Plan',
  [SourceType.ActivePlanPending]: 'Active Plan Pending',
  [SourceType.ActivePlanRefused]: 'Active Plan Refused',
  [SourceType.ActivePlanActive]: 'Active Plan Active',
  [SourceType.ActivePlanFinished]: 'Active Plan Finished',
  [SourceType.NewSegmentedNotification]: 'A new notification from your segmented group was created',
  [SourceType.NewReport]: 'A new report was created',
})
