import React, { useEffect } from 'react'
import { AppRouter } from '../../routes/AppRouter'

import style from './Main.module.css'
import { useSelectedMenuContext } from 'common/utils/menu-selected-context/MenuSelectedContext'

type MainProps = {}

export function Main(props: MainProps) {
  const mainContainer = React.useRef<HTMLDivElement>(null)
  const { sMenu } = useSelectedMenuContext()

  useEffect(() => {
    if (mainContainer.current !== null) {
      mainContainer.current.scrollTop = 0
    }
  }, [sMenu])

  return (
    <div ref={mainContainer} className={style.mainContainer} id="mainContainer">
      {/* @ts-expect-error router error to fix */}
      <AppRouter refMain={mainContainer} />
    </div>
  )
}
