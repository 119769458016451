export const groupColors: string[] = [
  'var(--active-plan-group-orange)',
  'var(--active-plan-group-orange-light)',
  'var(--active-plan-group-blue)',
  'var(--active-plan-group-dark-blue)',
  'var(--active-plan-group-dark-red)',
  'var(--active-plan-group-red)',
  'var(--active-plan-group-dark-green)',
  'var(--active-plan-group-green)',
]
