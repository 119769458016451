/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  MenuItem,
  Modal,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { Header } from '../../../components/header/Header'
import Button from '@mui/material/Button'
import React, { useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import { Actions, Field } from '../../../components/table_type/types'
import editIcon from '../../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../../assets/table_icons/ico-eliminar.svg'
import viewIcon from '../../../assets/table_icons/ico-ver.svg'
import printIcon from '../../../assets/table_icons/ico-download.svg'
import { ReportModal, ReportProps } from './ReportModal'
import { ReportPreview, ReportToPrint } from './ReportPreview'
import { getReportContainer } from '../../../container/report-module'
import { ReportService } from '../../../modules/report/services/ReportService'
import { REPORT_SERVICE_KEY } from '../../../modules/report'
import { useGlobalContext } from '../../../common/utils/GlobalRoleContext'
import close from '../../../assets/table_icons/ico-eliminar.svg'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { dateToYYYYMMDD } from '../../../common/utils/date'
import styles from './ActivePlan.module.css'
import styleModal from './CustomModal.module.css'
import { Body } from '../../../components/table/Body'
import { PrintPDFComponent } from './PrintPDF'

function EmptyReportProps() {
  return {
    generalData: true,
    attendanceMonth: true,
    startDate: new Date(),
    finishDate: new Date(),
    SF12: true,
    specificData: true,
    sessionsAttendance: false,
    intensityMonth: true,
    efdComments: true,
    RCV: true,
    antroData: true,
    totalSessions: true,
    condition: true,
  }
}

type Order = 'asc' | 'desc'

type ReportType = {
  createdAt: string
  createdByUserID: string
  createdByUserString: string
  period?: string
  finishDate: Date
  generalInfoID: string
  id: string
  rcvValoration: string
  sessionsAttendance: string
  specificInfoID: string
  startDate: Date
  userCircleID: string
}

type ReportResponse = {
  items: any[]
  count: number
}

function EmptyReport() {
  return {
    createdAt: '',
    createdByUserID: '',
    createdByUserString: '',
    finishDate: new Date(),
    generalInfoID: '',
    id: '',
    period: '',
    rcvValoration: '',
    sessionsAttendance: '',
    specificInfoID: '',
    startDate: new Date(),
    userCircleID: '',
  }
}

const reportContainer = getReportContainer()
const reportService = reportContainer.get<ReportService>(REPORT_SERVICE_KEY)

export function Reports() {
  const [showNewReport, setShowNewReport] = useState<boolean>(false)
  const [reports, setReports] = useState<[]>([])
  const [initReports, setInitReports] = useState<[]>([])
  const [dataToPrint, setDataToPrint] = useState({})
  const [reportSelected, setReportSelected] = useState<ReportType>(EmptyReport())
  const [reportProps, setReportProps] = useState<ReportProps>(EmptyReportProps())
  const [previewReportModal, setPreviewReportModal] = useState<boolean>(false)
  const [showRemoveReport, setShowRemoveReport] = useState<boolean>(false)
  const [showDeleted, setShowDeleted] = useState<boolean>(false)
  const [generate, setDisabledGenerate] = useState<boolean>(false)
  const [modalLabel, setModalLabel] = useState<string>('')
  const [createdBy, setCreatedBy] = useState<string>('')
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();
  const [selectedReportId, setSelectedReportId] = useState<string>('')
  const [selectedReport, setSelectedReport] = useState<object>({})
  const { t } = useTranslation()
  // const [toDate, setToDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() + 365)))
  // @ts-ignore
  const [toDate, setToDate] = useState<Date>(null)
  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState<string>('createdAt')
  // @ts-ignore
  const [fromDate, setFromDate] = useState<Date>(null)
  // const [fromDate, setFromDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 8)))
  const { role } = useGlobalContext()

  const editReport = (report: any) => {
    setSelectedReportId(report.id)
    setReportProps({
      generalData: report.generalInfoID !== '' && true,
      RCV: report.rcvValoration !== '' && true,
      SF12: report.healthFormulary !== '' && true,
      antroData: report.anthropometric !== '' && true,
      sessionsAttendance: report.sessionsAttendance !== '' && true,
      attendanceMonth: report.averageAttendancePerMonth !== '' && true,
      condition: report.physicalCondition !== '' && true,
      efdComments: report.edfComments !== '' && true,
      intensityMonth: report.averageIntensityPerMonth !== '' && true,
      specificData: report.specificInfoID !== '' && true,
      startDate: report.startDate,
      finishDate: report.finishDate,
      totalSessions: false,
    })
    setShowNewReport(true)
    setModalLabel('editReport')
  }

  const viewReport = (report: any) => {
    setSelectedReportId(report.id)
    setSelectedReport(report)
    setReportProps({
      generalData: report.generalInfoID !== '' && true,
      RCV: report.rcvValoration !== '' && true,
      SF12: report.healthFormulary !== '' && true,
      antroData: report.anthropometric !== '' && true,
      sessionsAttendance: report.sessionsAttendance !== '' && true,
      attendanceMonth: report.averageAttendancePerMonth !== '' && true,
      condition: report.physicalCondition !== '' && true,
      efdComments: report.edfComments !== '' && true,
      intensityMonth: report.averageIntensityPerMonth !== '' && true,
      specificData: report.specificInfoID !== '' && true,
      startDate: report.startDate,
      finishDate: report.finishDate,
      totalSessions: false,
    })
    setPreviewReportModal(true)
  }

  const handleClosePreview = () => {
    setPreviewReportModal(false)
  }

  const printReport = (report: any) => {
    setDisabledGenerate(true);
    const printData = {
      // generalData: {}, specificData: {}, RCV: {}, sessionsAttendance:{}, anthropometric: {}, averageAttendancePerMonth: {}, averageIntensityPerMonth: {}, averageConditionPerMonth:{}, condition:{}
    }
    if (report.generalInfoID) {
      reportService.getGeneralInformationbyId(report.id).subscribe((resp) => {
        if (localStorage.getItem('selected user circle') && resp) {
          const userCircle = JSON.parse(localStorage.getItem('selected user circle') ?? 'null').user
          const setGeneralData = {
            patientName: userCircle.name + ' ' + userCircle.lastname,
            age: '45',
            programType: resp?.programType,
            // @ts-ignore
            period:
              new Date(resp.reportStartDate).toLocaleDateString('es-ES', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }) +
              ' - ' +
              new Date(resp.reportFinishDate).toLocaleDateString('es-ES', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }),
            professional: resp?.prescribingProfessional,
            center: resp?.healthCentre,
            professionalCategory: resp?.professionalCategory,
            // @ts-ignore
            dateStartProgram: new Date(resp.programStartDate).toLocaleDateString('es-ES', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }),
            // @ts-ignore
            dateFinishProgram: new Date(resp.programFinishDate).toLocaleDateString('es-ES', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }),
          }
          // @ts-ignore
          printData.generalData = setGeneralData
        }
      })
    }
    if (report.specificInfoID) {
      reportService.getSpecificInformationbyId(report.id).subscribe((resp) => {
        const setSpecificData = {
          sportCenter: resp?.sportCentre,
          capacity: resp?.capacity,
          address: resp?.address,
          EFDName: resp?.efdName,
          center: resp?.cityHall,
          EFDEmail: resp?.efdEmail,
          period:
            new Date(resp.programStartDate).toLocaleDateString('es-ES', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            }) +
            ' - ' +
            new Date(resp.programFinishDate).toLocaleDateString('es-ES', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            }),
          trainingDays: 'test', // TODO
          group: resp.group,
        }
        // @ts-ignore
        printData.specificData = setSpecificData
      })
    }
    if (report.rcvValoration) {
      reportService.getRcvValorationById(report.id).subscribe((resp) => {
        // @ts-ignore
        printData.RCV = resp
      })
    }
    if (report.sessionsAttendance) {
      reportService.getSessionsAttendancebyId(report.id).subscribe((resp) => {
        // @ts-ignore
        printData.totalSessions = resp
      })
    }
    if (report.averageAttendancePerMonth) {
      reportService.getAvgAttendanceMonthById(report.id).subscribe((resp) => {
        if (resp) {
          // @ts-ignore
          printData.attendance = resp
        }
      })
    }
    if (report.anthropometric) {
      reportService.getAnthropometricById(report.id).subscribe((resp) => {
        // @ts-ignore
        printData.antroData = resp
      })
    }

    if (report.averageIntensityPerMonth) {
      reportService.getAvgIntensityMonthById(report.id).subscribe((resp) => {
        // @ts-ignore
        printData.intensityMonth = resp
      })
    }
    //
    if (report.averageConditionPerMonth) {
      reportService.getConditionById(report.id).subscribe((resp) => {
        // @ts-ignore
        printData.condition = resp
      })
    }
    if (report.healthFormulary) {
      reportService.getHealthReportById(report.id).subscribe((resp) => {
        // @ts-ignore
        printData.sf12 = resp
      })
    }
    if (report.edfComments) {
      reportService.getEFDCommentsById(report.id).subscribe((resp) => {
        // @ts-ignore
        printData.efdComments = resp
      })
    }
    setTimeout(() => {
      PrintPDFComponent(
        <ReportToPrint dataPrint={printData} header={t('planActivaReport')} reportId={report.id} />
      ).then((resp) => setDisabledGenerate(false))
    }, 5000)
  }

  const deleteReport = (report: any) => {
    setReportSelected(report)
    handleShowRemoveReport()
  }

  useEffect(() => {
    if (localStorage.getItem('selected user circle')) {
      const userCircle = JSON.parse(localStorage.getItem('selected user circle') ?? 'null')
      // @ts-ignore
      reportService.getByUserCircleID(userCircle.id).subscribe((resp: ReportResponse) => {
        parseReports(resp.items)
        // @ts-ignore
        setInitReports(resp.items)
      })
    }
  }, [])

  useEffect(() => {
    const userCircle = JSON.parse(localStorage.getItem('selected user circle') ?? 'null')
    // @ts-ignore
    reportService.getByUserCircleID(userCircle.id).subscribe((resp: any) => {
      let result = []
      if (createdBy === 'patient') {
        result = resp.items.filter(
          (item: any) =>
            item.createdByUserID ===
            JSON.parse(localStorage.getItem('selected user circle') ?? 'null').user.id
        )
      } else if (createdBy === 'professional') {
        result = resp.items.filter(
          (item: any) =>
            item.createdByUserID !==
            JSON.parse(localStorage.getItem('selected user circle') ?? 'null').user.id
        )
      } else {
        result = resp.items
      }
      parseReports(result)
    })
  }, [createdBy])

  const parseReports = (reports: any) => {
    const newReports = reports.map((report: ReportType) => {
      // let date = new Date(report.createdAt)
      const startDate = new Date(report.startDate)
      const finishDate = new Date(report.finishDate)

      const selectedUser = localStorage.getItem('selected user')

      // Verificar si selectedUser no es null
      if (selectedUser) {
        const selectedUserID = JSON.parse(selectedUser).id
        report.createdByUserString =
          report.createdByUserID === selectedUserID ? 'Paciente' : 'Profesional SMS'
      } else {
        //  caso en que selectedUser sea null
        console.error('No se encontró ningún usuario seleccionado en localStorage')
      }

      report.period =
        startDate.toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' }) +
        ' - ' +
        finishDate.toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' })

      return report
    })

    // newReports.sort()
    newReports.sort(function (a: any, b: any) {
      return new Date(b[orderBy]).getTime() - new Date(a[orderBy]).getTime()
    })

    setReports(newReports)
  }

  const fields: Field<any>[] = [
    {
      name: 'createdAt',
      label: t('creationDate'),
      renderFunc: (f, i) =>
        new Date(i.createdAt).toLocaleDateString('es-ES', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
    },
    {
      name: 'period',
      label: t('reportPeriod'),
      renderFunc: (f, i) => <span style={{ margin: '0px', cursor: 'pointer' }}>{i.period}</span>,
    },
    {
      name: 'creator',
      label: t('creator'),
      renderFunc: (f, i) => (
        <span style={{ margin: '0px', cursor: 'pointer' }}>{i.createdByUserString}</span>
      ),
    },
  ]

  const fieldsPatient: Field<any>[] = [
    {
      name: 'createdAt',
      label: t('creationDate'),
      renderFunc: (f, i) =>
        new Date(i.createdAt).toLocaleDateString('es-ES', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
    },
    {
      name: 'period',
      label: t('reportPeriod'),
      renderFunc: (f, i) => <span style={{ margin: '0px', cursor: 'pointer' }}>{i.period}</span>,
    },
    {
      name: 'creator',
      label: t('creator'),
      renderFunc: (f, i) => (
        <span style={{ margin: '0px', cursor: 'pointer' }}>{i.createdByUserString}</span>
      ),
    },
  ]

  const actions: Actions<any> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editReport,
        icon: editIcon,
        label: 'edit',
        hidden: (item) => {
          return (
            item.createdByUserString === 'Profesional SMS' &&
            JSON.parse(localStorage.getItem('logged user') ?? 'null').roles.find(
              (obj: any) => obj === 'patient'
            ) === 'patient'
          )
        },
        // hidden: (item) => (item.createdByUserString==="Profesional SMS") && (JSON.parse(localStorage.getItem("logged user") || "null").roles.find((obj:any) => obj === "professionalSMS") !== "professionalSMS")
      },
      {
        handler: viewReport,
        icon: viewIcon,
        label: 'view',
      },
      {
        handler: printReport,
        icon: printIcon,
        label: 'print',
      },
      {
        handler: deleteReport,
        icon: deleteIcon,
        label: 'delete',
        hidden: (item) => {
          return (
            item.createdByUserString === 'Profesional SMS' &&
            JSON.parse(localStorage.getItem('logged user') ?? 'null').roles.find(
              (obj: any) => obj === 'patient'
            ) === 'patient'
          )
        },
      },
    ],
  }

  const deleteSelectedReport = () => {
    if (reportSelected) {
      reportService.delete(reportSelected.id).subscribe((resp) => {
        updateReports()
      })
    }
    handleShowDeleted()
  }

  const updateReports = () => {
    if (localStorage.getItem('selected user circle')) {
      const userCircle = JSON.parse(localStorage.getItem('selected user circle') ?? 'null')
      // @ts-ignore
      reportService.getByUserCircleID(userCircle.id).subscribe((resp: ReportResponse) => {
        let result = []
        if (createdBy === 'patient') {
          result = resp.items.filter(
            (item: any) =>
              item.createdByUserID ===
              JSON.parse(localStorage.getItem('selected user circle') ?? 'null').user.id
          )
        } else if (createdBy === 'professional') {
          result = resp.items.filter(
            (item: any) =>
              item.createdByUserID !==
              JSON.parse(localStorage.getItem('selected user circle') ?? 'null').user.id
          )
        } else {
          result = resp.items
        }
        parseReports(result)
      })
    }
  }

  const handleFromDateChange = (date: any) => {
    let reports
    let result: any[]
    if (date) {
      setMinValue(date.format("MM-DD-YYYY"));
      setFromDate(date.toDate())
    } else {
      // @ts-ignore
      setFromDate(null)
    }
    if (!date && toDate) {
      // @ts-ignore
      reports = initReports.filter((report: any) => new Date(report.finishDate) <= toDate)
    } else if (toDate) {
      reports = initReports.filter(
        (report: any) =>
          new Date(report.startDate) >= date.toDate() && new Date(report.finishDate) <= toDate
      )
    } else if (!date && !toDate) {
      reports = initReports
    } else {
      reports = initReports.filter((report: any) => new Date(report.startDate) >= date.toDate())
    }
    if (createdBy === 'patient') {
      result = reports.filter(
        (item: any) =>
          item.createdByUserID ===
          JSON.parse(localStorage.getItem('selected user circle') ?? 'null').user.id
      )
    } else if (createdBy === 'professional') {
      result = reports.filter(
        (item: any) =>
          item.createdByUserID !==
          JSON.parse(localStorage.getItem('selected user circle') ?? 'null').user.id
      )
    } else {
      result = reports
    }
    parseReports(result)
  }

  const handleToDateChange = (date: any) => {
    let reports
    let result: any[]
    if (date) {
      setMaxValue(date.format("MM-DD-YYYY"));
      setToDate(date.toDate())
    } else {
      // @ts-ignore
      setToDate(null)
    }
    if (!date && fromDate) {
      // @ts-ignore
      reports = initReports.filter((report: any) => new Date(report.startDate) >= fromDate)
    } else if (fromDate) {
      // const [fromDate, setFromDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 8)))
      reports = initReports.filter(
        (report: any) =>
          new Date(report.finishDate) <= date.toDate() && new Date(report.startDate) >= fromDate
      )
    } else if (!date && !fromDate) {
      reports = initReports
    } else {
      debugger;
      reports = initReports.filter((report: any) => new Date(report.finishDate) <= date.toDate())
    }
    if (createdBy === 'patient') {
      result = reports.filter(
        (item: any) =>
          item.createdByUserID ===
          JSON.parse(localStorage.getItem('selected user circle') ?? 'null').user.id
      )
    } else if (createdBy === 'professional') {
      result = reports.filter(
        (item: any) =>
          item.createdByUserID !==
          JSON.parse(localStorage.getItem('selected user circle') ?? 'null').user.id
      )
    } else {
      result = reports
    }
    parseReports(result)
  }

  const handleShowNewReport = (report: any) => {
    setReportProps({
      generalData: true,
      specificData: true,
      startDate: new Date(),
      finishDate: new Date(),
      RCV: false,
      SF12: false,
      antroData: false,
      attendanceMonth: false,
      sessionsAttendance: false,
      condition: false,
      efdComments: false,
      intensityMonth: false,
      totalSessions: false,
    })
    setShowNewReport(true)
    setModalLabel('')
  }

  const handleCloseNewReport = () => {
    setSelectedReportId('')
    setShowNewReport(false)
  }

  const handleCloseRemoveReport = () => {
    setSelectedReportId('')
    setShowRemoveReport(false)
  }

  const handleShowRemoveReport = () => {
    setShowRemoveReport(true)
  }

  const handleCreateNewReport = () => {}

  const handleShowDeleted = () => {
    handleCloseRemoveReport()
    setShowDeleted(true)
  }

  const handleCloseDeleted = () => {
    setShowDeleted(false)
  }

  const handleCreatedBy = (event: any) => {
    setCreatedBy(event.target.value)
  }

  const createSortHandler = (props: any) => (event: React.MouseEvent<unknown>) => {
    const isAsc = orderBy === props && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(props)
  }

  useEffect(() => {
    const aux: any[] = []
    Object.assign(aux, reports)
    aux.sort(function (a: any, b: any) {
      if (order === 'asc') {
        return new Date(a[orderBy]).getTime() - new Date(b[orderBy]).getTime()
      } else {
        return new Date(b[orderBy]).getTime() - new Date(a[orderBy]).getTime()
      }
    })
    // @ts-ignore
    setReports(aux)
  }, [order, orderBy])

  // const handleRequestSort = (
  //   event: React.MouseEvent<unknown>,
  //   property: keyof Data,
  // ) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  return (
    <>
      <Modal open={showRemoveReport} onClose={handleCloseRemoveReport}>
        <div className={styleModal.agoraContainer}>
          <Box
            className={styleModal.containerInside}
            style={{ height: '500px', overflow: 'auto', justifyContent: 'space-between' }}
          >
            <Box style={{ overflow: 'auto' }}>
              <Header label={t('removeReport')} icon={close} onClick={handleCloseRemoveReport} />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <h1 style={{ color: '#f18b24' }}>ELIMINAR INFORME</h1>
              <h4>¿Está seguro de eliminar este informe?</h4>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              gap="20px"
              alignItems="center"
              justifyContent="center"
            >
              <Box style={{ paddingTop: '20px' }}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('no')}
                  handler={handleCloseRemoveReport}
                />
              </Box>
              <Box style={{ paddingTop: '20px' }}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('yes')}
                  handler={deleteSelectedReport}
                />
              </Box>
            </Box>
          </Box>
        </div>
      </Modal>
      <Box className={styles.test1}>
        <Box display={'flex'} flexDirection={'column'} className={styles.notificationContainer}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              flex: 1,
              position: 'sticky',
              top: '0',
              backgroundColor: '#ffffff',
              borderBottom: '3px solid #f18b24',
            }}
          >
            <Header divider={true} label={'Mis informes'} />
            <>
              {
                // @ts-ignore
                !JSON.parse(localStorage.getItem('logged user')).roles.includes(
                  'professionalSMS'
                ) && (
                  <>
                    <p>{t('Creado por')}:</p>
                    <Select
                      style={{ width: '500px' }}
                      key={'createdBy'}
                      id={'createdBy'}
                      placeholder={'Creado por'}
                      name={'createdBy'}
                      value={createdBy}
                      onChange={handleCreatedBy}
                      required={true}
                      size={'small'}
                      variant={'outlined'}
                      fullWidth={true}
                    >
                      <MenuItem value={''}>
                        <div>Todos</div>
                      </MenuItem>
                      <MenuItem value={'professional'}>
                        <div>Profesional SMS</div>
                      </MenuItem>
                      <MenuItem value={'patient'}>
                        <div>Paciente</div>
                      </MenuItem>
                    </Select>
                  </>
                )
              }
              <p>{t('from')}:</p>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                <DatePicker
                  key={'date'}
                  disableFuture={true}
                  maxDate={maxValue}
                  PopperProps={{
                    sx: {
                      '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize',
                      },
                      '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                        fontFamily: 'Poppins',
                      },
                      '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                        fontFamily: 'Poppins',
                      },
                      '& .css-3eghsz-PrivatePickersYear-button': {
                        fontFamily: 'Poppins',
                      },
                    },
                  }}
                  // onError={(reason, value) => {
                  //   switch (reason) {
                  //     case 'invalidDate':
                  //       setDateTimePickerError(t('invalidDateMessage'))
                  //       break
                  //     case 'minDate':
                  //       setDateTimePickerError(t('minDateMessage'))
                  //       break
                  //   }
                  // }}
                  renderInput={(props) => (
                    <TextField
                      style={{ width: '20%' }}
                      sx={{
                        '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                        '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                          fontFamily: 'Poppins',
                        },
                      }}
                      variant={'outlined'}
                      // helperText={props.error && DateTimePickerError}
                      {...props}
                    />
                  )}
                  inputFormat="DD/MM/YYYY"
                  value={fromDate}
                  onChange={handleFromDateChange}
                />
              </LocalizationProvider>
              <p>{t('to')}:</p>

              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                <DatePicker
                  key={'date'}
                  minDate={minValue}
                  disableFuture={true}
                  PopperProps={{
                    sx: {
                      '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize',
                      },
                      '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                        fontFamily: 'Poppins',
                      },
                      '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                        fontFamily: 'Poppins',
                      },
                      '& .css-3eghsz-PrivatePickersYear-button': {
                        fontFamily: 'Poppins',
                      },
                    },
                  }}
                  // onError={(reason, value) => {
                  //   switch (reason) {
                  //     case 'invalidDate':
                  //       setDateTimePickerError(t('invalidDateMessage'))
                  //       break
                  //     case 'minDate':
                  //       setDateTimePickerError(t('minDateMessage'))
                  //       break
                  //   }
                  // }}
                  renderInput={(props) => (
                    <TextField
                      style={{ width: '20%' }}
                      sx={{
                        '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                        '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                          fontFamily: 'Poppins',
                        },
                      }}
                      variant={'outlined'}
                      // helperText={props.error && DateTimePickerError}
                      {...props}
                    />
                  )}
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  onChange={handleToDateChange}
                />
              </LocalizationProvider>

              <Button
                onClick={handleShowNewReport}
                // variant="contained"
                style={{
                  backgroundColor: '#68b3e0',
                  marginLeft: '20px',
                  float: 'right',
                  width: '25%',
                  color: 'white',
                }}
              >
                Generar nuevo informe
              </Button>
            </>
          </Box>
          <Box alignItems={'center'} className={styles.horizontalTimeline}>
            {
              <>
                <Modal open={showNewReport} onClose={handleCloseNewReport}>
                  <ReportModal
                    handleClose={handleCloseNewReport}
                    updateReports={updateReports}
                    label={modalLabel}
                    reportsFromView={reportProps}
                    reportId={selectedReportId}
                  />
                </Modal>
                <Modal open={previewReportModal} onClose={handleClosePreview}>
                  <ReportPreview
                    handleClose={handleClosePreview}
                    reportData={selectedReport}
                    reportId={selectedReportId}
                    dataToPrint={reportProps}
                  />
                </Modal>
                <Table
                  style={{
                    borderCollapse: 'separate',
                    borderSpacing: '0 20px',
                    borderColor: '#DBDADA',
                  }}
                >
                  {/* <Head fields={JSON.parse(localStorage.getItem("logged user") || "null").roles.includes("professionalSMS") ? fields: fieldsPatient} actions={actions} /> */}
                  <TableHead>
                    <TableRow>
                      {fieldsPatient.map((headCell, index) => (
                        <TableCell
                          key={index}
                          align={'left'}
                          padding={'normal'}
                          sortDirection={orderBy === headCell.name ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === headCell.name}
                            direction={orderBy === headCell.name ? order : 'desc'}
                            onClick={createSortHandler(headCell.name)}
                          >
                            {headCell.label}
                            {/* {orderBy === headCell.id ? ( */}
                            {/*  <Box component="span" sx={visuallyHidden}> */}
                            {/*    {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                            {/*  </Box> */}
                            {/* ) : null} */}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell align={'center'} padding={'normal'}>
                        {t('Actions')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <Body
                    actions={actions}
                    fields={
                      JSON.parse(localStorage.getItem('logged user') ?? 'null').roles.includes(
                        'professionalSMS'
                      )
                        ? fields
                        : fieldsPatient
                    }
                    items={reports}
                    rowKeyField={'id'}
                    // specialRowStyle={props.specialsRowStyle}
                    // rowStyleStatus={props.rowStyleFunc}
                  />
                </Table>
              </>
            }
          </Box>
        </Box>
      </Box>
    </>
  )
}
