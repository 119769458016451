import { Header } from '../../../../../components/header/Header'
import { Box, Divider, Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from '../../../add-new-circle/view 4 Forms/Modal.module.css'
import exitIcon from '../../../../../assets/newCircle/exit_icon.svg'
import { FormDTO } from '../../../../../modules/forms/models/FormDTO'
import { CheckboxAssignType } from '../../../../../modules/forms/enums/CheckboxAssignType'
import styles from '../../../../patient-data/symptoms/epilepticalCrisis/ModalNewCrisis.module.css'
import { AppButton, ButtonTheme } from '../../../../../components/app-button/AppButton'
import stylesConfig from '../../../../form-generator/config-form-section/ConfigForm.module.css'
import { CronItemMockup } from '../../../../../components/form-card/CronItemMockup'

interface Props {
  onClose: () => void
  handleNextStep: () => void
  formParent: FormDTO
  setFormParent: (form: FormDTO) => void
}

const ModalAddNewCircleNewFormFirstStep: React.FC<Props> = ({
  onClose,
  handleNextStep,
  formParent,
  setFormParent,
}) => {
  const { t } = useTranslation()
  const [form, setForm] = useState<FormDTO>(formParent)
  const [checkBoxSelected, setCheckBoxSelected] = useState<string>(
    formParent.checkBoxesSelected ?? ''
  )
  const [isRecurrence, setIsRecurrence] = useState<boolean>(formParent.repeating !== '0 0 0 0 0')
  const [cron, setCron] = useState<string>(formParent.repeating)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [monthRecurrence, setMonthRecurrence] = useState<number>(formParent.monthRecurrence)
  const [weekDayRecovered, setWeekDayRecovered] = useState<string[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    setForm(formParent)
    setCheckBoxSelected(formParent.checkBoxesSelected ?? '')
    setCron(formParent.repeating)
    setIsRecurrence(formParent.repeating !== '0 0 0 0 0')
    setMonthRecurrence(formParent.monthRecurrence)
    setWeekDayRecovered([])
  }, [formParent])
  const validateForm = () => {
    if (!form.description.trim() || !form.title.trim()) {
      setErrorMessage('Los campos de título y descripción son obligatorios')
      return false
    }
    if (checkBoxSelected === '') {
      setErrorMessage('Debe seleccionar al menos una asignación')
      return false
    }
    setErrorMessage('')
    return true
  }
  const handleRecurrence = (value: boolean) => {
    setIsRecurrence(value)
  }

  const handleChangeRecurrence = (s: string, sd: Date, fd: Date) => {
    setCron(s)
    setForm(Object.assign({ ...form }, { startCron: sd, finishCron: fd }))
  }
  const handlerMonthRecurrence = (mr: number) => {
    setMonthRecurrence(mr)
  }
  const recoveredCron = (
    c: string,
    mc: number,
    startDate: Date,
    finishDate: Date,
    weekDay: string[]
  ) => {
    form.repeating = c
    form.monthRecurrence = mc
    form.startCron = startDate
    form.finishCron = finishDate
    setWeekDayRecovered(weekDay)
    setCron(c)
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm(Object.assign({ ...form }, { [e.target.name]: e.target.value }))
  }

  useEffect(() => {
    setForm({ ...form, checkBoxesSelected: checkBoxSelected })
  }, [checkBoxSelected])

  const handleCheckBoxAssignation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    let checkboxSelectedAux = checkBoxSelected.split(',')
    if (checkboxSelectedAux.length === 1 && checkboxSelectedAux[0] === '') {
      checkboxSelectedAux = []
    }
    switch (name) {
      case 'allCircles':
        checked
          ? checkboxSelectedAux.push(CheckboxAssignType.AllMyCircles.toFixed())
          : (checkboxSelectedAux = checkboxSelectedAux.filter(
              (item) => item !== CheckboxAssignType.AllMyCircles.toFixed()
            ))
        break
      case 'allCarers':
        checked
          ? checkboxSelectedAux.push(CheckboxAssignType.AllCareers.toFixed())
          : (checkboxSelectedAux = checkboxSelectedAux.filter(
              (item) => item !== CheckboxAssignType.AllCareers.toFixed()
            ))
        break
      case 'allPatients':
        checked
          ? checkboxSelectedAux.push(CheckboxAssignType.AllMyPatients.toFixed())
          : (checkboxSelectedAux = checkboxSelectedAux.filter(
              (item) => item !== CheckboxAssignType.AllMyPatients.toFixed()
            ))
        break
      case 'allProfessionals':
        checked
          ? checkboxSelectedAux.push(CheckboxAssignType.AllExternal.toFixed())
          : (checkboxSelectedAux = checkboxSelectedAux.filter(
              (item) => item !== CheckboxAssignType.AllExternal.toFixed()
            ))
        break
      case 'allFamiliars':
        checked
          ? checkboxSelectedAux.push(CheckboxAssignType.AllFamiliars.toFixed())
          : (checkboxSelectedAux = checkboxSelectedAux.filter(
              (item) => item !== CheckboxAssignType.AllFamiliars.toFixed()
            ))
        break
    }

    setCheckBoxSelected(checkboxSelectedAux.join(','))
  }
  return (
    <Box>
      <Box className={style.modalContainer}>
        <Box style={{ margin: '16px' }}>
          <Box className={style.contentHeaderExitIcon}>
            <img
              style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
              onClick={onClose}
              src={exitIcon}
            />
          </Box>
          <Box className={style.contentHeader}>
            <Header label={t('newForm')} />
          </Box>

          <Box>
            <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.textField}>
              <b className={style.titleLabel} style={{ marginRight: '32px' }}>
                {t('name')}*
              </b>
              <input
                className={style.inputName}
                type="text"
                name="title"
                value={form.title}
                onChange={(e) => handleInput(e)}
                required
              />
            </Box>

            <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.textField}>
              <b className={style.titleLabel}>{t('description')}*</b>
              <textarea
                className={style.inputDescription}
                name="description"
                value={form.description}
                onChange={(e) => handleInput(e)}
                rows={2}
                required
              ></textarea>
            </Box>
          </Box>

          <Box>
            <Box>
              <p
                style={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 'bold',
                  fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                  marginLeft: 0,
                  width: '100%',
                }}
              >
                {t('configuration')}
              </p>
              <Divider light style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
            </Box>
            <Box className={stylesConfig.boxRecurrence}>
              <>
                <Box display="flex">
                  <label className={style.checkboxContainer}>
                    <input
                      type="checkbox"
                      name="recurrence"
                      className={styles.check}
                      disabled={false}
                      onChange={() => handleRecurrence(!isRecurrence)}
                      checked={isRecurrence}
                      style={{ marginRight: '16px' }}
                    />
                    {t('periodicity')}
                  </label>
                </Box>
              </>
            </Box>
            {isRecurrence && (
              <Box mb={3}>
                <CronItemMockup
                  handleChange={handleChangeRecurrence}
                  id={form.id}
                  form={form}
                  isRecurrence={isRecurrence}
                  handlerMonthRecurrence={handlerMonthRecurrence}
                  recoveredCron={recoveredCron}
                  preview={true}
                  cronOldState={cron}
                  weekDayRecovered={weekDayRecovered}
                  isCreator={true}
                />
              </Box>
            )}
          </Box>
          <Box>
            <Box>
              <p
                style={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 'bold',
                  fontSize: window.innerWidth > 1220 ? '1.2rem' : '0.9rem',
                  marginLeft: 0,
                  width: '100%',
                }}
              >
                {t('assign')}
              </p>
              <Divider light style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
            </Box>
            <Box>
              <Box
                display="flex"
                style={{
                  backgroundColor: '#fff',
                  border: '2px solid #68b3e0',
                  borderRadius: '25px',
                  height: '180px',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
                className={style.containerInput}
              >
                <Box className={style.columnContainer} style={{ margin: '20px' }}>
                  <label className={style.checkboxContainer}>
                    <input
                      type="checkbox"
                      name="allCircles"
                      className={styles.check}
                      checked={checkBoxSelected.includes(CheckboxAssignType.AllMyCircles.toFixed())}
                      onChange={handleCheckBoxAssignation}
                    />
                    {t('allCircles')}
                  </label>
                  <label className={style.checkboxContainer}>
                    <input
                      type="checkbox"
                      name="allCarers"
                      className={styles.check}
                      onChange={handleCheckBoxAssignation}
                      checked={checkBoxSelected.includes(CheckboxAssignType.AllCareers.toFixed())}
                    />
                    {t('allCarers')}
                  </label>
                  <label className={style.checkboxContainer}>
                    <input
                      type="checkbox"
                      name="allPatients"
                      onChange={handleCheckBoxAssignation}
                      className={styles.check}
                      checked={checkBoxSelected.includes(
                        CheckboxAssignType.AllMyPatients.toFixed()
                      )}
                    />
                    {t('allPatients')}
                  </label>
                </Box>
                <Box className={style.columnContainer}>
                  <label className={style.checkboxContainer}>
                    <input
                      type="checkbox"
                      name="allProfessionals"
                      onChange={handleCheckBoxAssignation}
                      checked={checkBoxSelected.includes(CheckboxAssignType.AllExternal.toFixed())}
                      className={styles.check}
                    />
                    {t('allProfessionals')}
                  </label>
                  <label className={style.checkboxContainer}>
                    <input
                      type="checkbox"
                      name="allFamiliars"
                      checked={checkBoxSelected.includes(CheckboxAssignType.AllFamiliars.toFixed())}
                      onChange={handleCheckBoxAssignation}
                      className={styles.check}
                    />
                    {t('allFamiliars')}
                  </label>
                </Box>
              </Box>
            </Box>
            {errorMessage && (
              <Box mb={3}>
                <Alert severity="warning" key="errorMessage" id="errorMessage">
                  {t(errorMessage)}
                </Alert>
              </Box>
            )}
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box>
                <AppButton
                  theme={ButtonTheme.newEvent}
                  type={'button'}
                  label={t('saveAndNext')}
                  marginStartIcon={{ marginRight: '10px' }}
                  handler={() => {
                    setFormParent(form)
                    validateForm() && handleNextStep()
                  }}
                  vertical={true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ModalAddNewCircleNewFormFirstStep
