import { FormFieldType } from '../../modules/forms/enums/FormFieldType'
import React, { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { FORM_SERVICE_KEY, USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { FormService } from '../../modules/forms/services/FormService'
import { getFormContainer } from '../../container/form-module'
import { UserFormService } from '../../modules/forms/services/UserFormService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { FormCard } from '../../components/form-card/FormCard'
import genericStyle from '../../common/utils/generic.module.css'
import {
  emptyUserFormDTO,
  fromModel as userFormModel,
  UserFormDTO,
  UserFormValueDTO,
} from '../../modules/forms/models/UserFormDTO'
import { FormFieldDTO, fromModelFormField } from '../../modules/forms/models/FormDTO'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ROUTE_PATIENT_FORMS } from '../../routes/routes-constants'
import { FileItem } from '../../components/form-card/FileItem'
import { File as F } from '../../modules/files/models/File'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import styles from './View.module.css'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { ICircleService } from '../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../modules/users/container'
import { ReadingStatus } from '../../modules/forms/enums/ReadingStatus'
import { CustomModal } from '../../components/modal/CustomModal'
import style from '../generic/GenericTable.module.css'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { useSelectedMenuContext } from 'common/utils/menu-selected-context/MenuSelectedContext'
type PatientFormViewProps = {
  id: string
  edit: boolean
}

const rows = 10
const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const formContainer = getFormContainer()
const userFormService = formContainer.get<UserFormService>(USER_FORM_SERVICE_KEY)
const formService = formContainer.get<FormService>(FORM_SERVICE_KEY)
const fileContainer = getFileContainer()
const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

export function View(props: PatientFormViewProps) {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const circle = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY).getActiveUser()
  const navigate = useNavigate()

  const [userForm, setUserForm] = useState<UserFormDTO>(
    emptyUserFormDTO(loggedUser?.id ?? '', circle?.id ?? '')
  )
  const [formFields, setFormFields] = useState<FormFieldDTO[]>([])
  const [saveAndSend, setSaveAndSend] = useState<boolean>(false)
  const [files, setFiles] = useState<Map<string, F[]>>(new Map())
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [formTitle, setFormTitle] = useState<string>('')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openModalSend, setOpenModalSend] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formFieldNoAnswer, setFormFielNoAnswer] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [date, setDate] = useState<Date>(new Date())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [dateCompleted, setDateCompleted] = useState<Date>(new Date())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pathology, setPathology] = useState<string[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [specialty, setSpecialty] = useState<string>('')
  const isProfessionalSMS = loggedUser?.roles.includes('professionalSMS')
  const [formDescription, setFormDescription] = useState<string>('')
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  const { handleSetMenuSelected } = useSelectedMenuContext()

  useEffect(() => {
    // esfera_564
    handleSetMenuSelected(ROUTE_PATIENT_FORMS)

    if (props.id) {
      userFormService.getByID(props.id).subscribe((uf) => {
        uf?.userFormValues.forEach((ufv) => {
          setDateCompleted(new Date(ufv.date))
        })
        if (uf) {
          setUserForm(userFormModel(uf))
          if (uf.formID) {
            formService.getByID(uf.formID).subscribe((f) => {
              if (f) {
                const circleArray = f.circles.split(',')
                setFormFields(f.formFields.map((ff) => fromModelFormField(ff, f.id)))
                setFormTitle(f.title)
                setFormDescription(f.description)
                setSpecialty(f.specialities)
                setIsLoading(false)
                setPathology(circleArray)
              }
            })
          }
        }
      })
    }
    const interval = setInterval(() => {}, 500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (!userForm) return
    formService.getByID(userForm.formID).subscribe((form) => {
      // const auxArrayPathology: string[] = []
    })

    const interval = setInterval(() => setIsLoading(false), 1000)
    return () => clearInterval(interval)
  }, [userForm])

  useEffect(() => {
    const fileFields = formFields.filter((ff) => ff.type === FormFieldType.File)
    fileFields.forEach((ff) => {
      const ufv = userForm.userFormValues.find((ufv) => ufv.formFieldID === ff.id)
      if (ufv?.values && ufv.values.length > 0) {
        const newMap = new Map()
        fileService.getByID(ufv.values[0]).subscribe((file) => {
          if (file) {
            newMap.set(ff.id, [file])
            setFiles(new Map(newMap))
          }
        })
      }
    })
  }, [formFields])

  const handleChange = (formFieldID: string, values: string[]) => {
    if (Array.isArray(userForm?.userFormValues)) {
      let index = -1
      userForm.userFormValues.some((ufv, i) => {
        if (ufv.formFieldID === formFieldID) {
          index = i
          return true
        }
        return false
      })
      if (index > -1) {
        const ufv = userForm.userFormValues.slice()
        ufv[index] = {
          userFormID: userForm.id ?? '',
          formFieldID,
          values,
          date,
          done: true,
        }
        setUserForm(Object.assign({ ...userForm }, { userFormValues: ufv }))
      }
    }
  }
  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleChange(e.target.name, [e.target.value])
  }

  const handleDate = (e: any, formFieldID: string) => {
    if (e) {
      handleChange(formFieldID, [e.toDate()])
    }
  }

  const handleTime = (e: any, formFieldID: string) => {
    if (e) {
      handleChange(formFieldID, [e.toDate()])
    }
  }

  const handleSelect = (e: SelectChangeEvent<string[]>, formFieldID: string, multiple: boolean) => {
    handleChange(
      formFieldID,
      multiple ? (e.target.value as string[]).filter((o) => o !== '') : [e.target.value as string]
    )
  }

  /*  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.name, [e.target.checked ? '1' : '0'])
  } */

  const handleRadioButton = (formFieldID: string, option: string) => {
    handleChange(formFieldID, [option])
  }

  const handleFiles = (formFieldID: string, value: F[]) => {
    const newMap = new Map(files)
    newMap.set(formFieldID, value)
    setFiles(newMap)
    handleChange(
      formFieldID,
      value.map((f) => f.id ?? '')
    )
  }

  const generateTextField = (
    formField: FormFieldDTO,
    values: string[] | undefined,
    multiline: boolean
  ) => {
    let textValue = ''
    if (values && values.length > 0) values.forEach((v) => (textValue = textValue + v))
    return (
      <Box mb={3} key={formField.id}>
        <p style={{ margin: 0 }}>
          {formField.title}
          {formField.required && '*'}
        </p>
        <TextFieldItem
          key={formField.id}
          field={formField.id}
          type={formField.type === FormFieldType.Number ? 'number' : 'text'}
          label={''}
          rows={multiline ? rows : undefined}
          value={values && values.length > 0 ? textValue : ''}
          fullWidth={formField.type !== FormFieldType.Number}
          handleChange={handleInput}
          required={formField?.required}
          disabled={!props.edit}
        />
      </Box>
    )
  }

  const generateNoResponseField = (formField: FormFieldDTO, values: string[] | undefined) => {
    return (
      <Box mb={3} key={formField.id}>
        <p style={{ margin: 0 }}>
          {formField.title}
          {formField.required && '*'}
        </p>
      </Box>
    )
  }

  const generateDateField = (formField: FormFieldDTO, values: string[] | undefined) => {
    let date = new Date()
    if (values && values.length > 0 && values[0] !== '') {
      date = new Date(values[0])
    }
    return (
      <Box key={formField.id}>
        <p style={{ margin: 0 }}>
          {formField.title}
          {formField.required && '*'}
        </p>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
          <DatePicker
            key={formField.id}
            PopperProps={{
              sx: {
                '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                  fontFamily: 'Poppins',
                  textTransform: 'capitalize',
                },
                '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                  fontFamily: 'Poppins',
                },
                '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                  fontFamily: 'Poppins',
                },
                '& .css-3eghsz-PrivatePickersYear-button': {
                  fontFamily: 'Poppins',
                },
              },
            }}
            onError={(reason, value) => {
              switch (reason) {
                case 'invalidDate':
                  setDateTimePickerError(t('invalidDateMessage'))
                  break
                case 'minDate':
                  setDateTimePickerError(t('minDateMessage'))
                  break
              }
            }}
            renderInput={(props) => (
              <TextField
                sx={{
                  '.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input': {
                    fontFamily: 'Poppins',
                  },
                }}
                style={{ marginBottom: 8, width: '220px' }}
                size={'small'}
                required={formField.required}
                helperText={props.error && DateTimePickerError}
                {...props}
              />
            )}
            inputFormat="DD/MM/YYYY"
            onChange={(e) => handleDate(e, formField.id)}
            value={date}
            // disableFuture={true}
            disabled={!props.edit}
          />
        </LocalizationProvider>
      </Box>
    )
  }

  const generateTimeField = (formField: FormFieldDTO, values: string[] | undefined) => {
    let date = new Date()
    if (values && values.length > 0 && values[0] !== '') {
      date = new Date(values[0])
    }
    return (
      <Box key={formField.id}>
        <p style={{ margin: 0 }}>
          {formField.title}
          {formField.required && '*'}
        </p>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
          <TimePicker
            key={formField.id}
            PaperProps={{
              sx: {
                '& .MuiButtonBase-root': {
                  fontFamily: 'Poppins',
                },
              },
            }}
            className={styles.hourPicker}
            InputProps={{ disableUnderline: true, sx: { fontFamily: 'Poppins' } }}
            onError={(reason, value) => {
              switch (reason) {
                case 'invalidDate':
                  setDateTimePickerError(t('invalidDateMessage'))
                  break
              }
            }}
            renderInput={(props) => (
              <TextField
                sx={{
                  '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                    fontFamily: 'Poppins',
                  },
                }}
                style={{ marginBottom: 8 }}
                variant={'standard'}
                required={formField.required}
                helperText={props.error && DateTimePickerError}
                {...props}
              />
            )}
            inputFormat="HH:mm"
            ampm={false}
            onChange={(e) => handleTime(e, formField.id)}
            value={date}
            disabled={!props.edit}
          />
        </LocalizationProvider>
      </Box>
    )
  }

  const generateDateTimeField = (formField: FormFieldDTO, values: string[] | undefined) => {
    let date = new Date()
    if (values && values.length > 0 && values[0] !== '') {
      date = new Date(values[0])
    }
    return (
      <Box key={formField.id}>
        <p style={{ margin: 0 }}>
          {formField.title}
          {formField.required && '*'}
        </p>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
          <DateTimePicker
            PopperProps={{
              sx: {
                '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                  fontFamily: 'Poppins',
                  textTransform: 'capitalize',
                },
                '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                  fontFamily: 'Poppins',
                },
                '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                  fontFamily: 'Poppins',
                },
                '& .css-3eghsz-PrivatePickersYear-button': {
                  fontFamily: 'Poppins',
                },
              },
            }}
            key={formField.id}
            onError={(reason, value) => {
              switch (reason) {
                case 'invalidDate':
                  setDateTimePickerError(t('invalidDateMessage'))
                  break
                case 'minDate':
                  setDateTimePickerError(t('minDateMessage'))
                  break
              }
            }}
            renderInput={(props) => (
              <TextField
                sx={{
                  '.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input': {
                    fontFamily: 'Poppins',
                  },
                }}
                style={{ marginBottom: 8, width: '220px' }}
                size={'small'}
                required={formField.required}
                helperText={props.error && DateTimePickerError}
                {...props}
              />
            )}
            ampm={false}
            inputFormat="DD/MM/YYYY HH:mm"
            value={date}
            onChange={(e) => handleTime(e, formField.id)}
            label={''}
            disabled={!props.edit}
          />
        </LocalizationProvider>
      </Box>
    )
  }

  const generateSelectField = (
    formField: FormFieldDTO,
    values: string[] | undefined,
    multiple: boolean
  ) => {
    return (
      <Box mb={3} key={formField.id}>
        <p style={{ margin: 0 }}>
          {formField.title} {formField.required && '*'}
        </p>
        <Select
          key={formField.id}
          id={formField.id}
          variant={'outlined'}
          value={values}
          onChange={(e) => handleSelect(e, formField.id, multiple)}
          className={styles.select}
          multiple={multiple}
          disabled={!props.edit}
          required={formField.required}
        >
          {formField.optionValues.map((k) => (
            <MenuItem key={k} value={k}>
              {k}
            </MenuItem>
          ))}
        </Select>
      </Box>
    )
  }

  const generateCheckboxField = (formField: FormFieldDTO, values: string[] | undefined) => {
    return (
      <Box mb={3} key={formField.id + uuidv4()} style={{ display: 'flex', alignItems: 'center' }}>
        <p className={styles.checkbox} style={{ marginRight: '10px' }}>
          {formField.title}
          {formField.required && '*'}
        </p>
        <Checkbox style={{ width: '25px', height: '25px' }} name={formField.id} />
      </Box>
    )
  }

  const generateRadioButtonField = (formField: FormFieldDTO, values: string[] | undefined) => {
    const radioButtons = Array.isArray(formField.optionValues) ? formField.optionValues : []
    return (
      radioButtons.length > 0 && (
        <Box mb={3} key={formField.id}>
          <FormControl component="fieldset">
            <p style={{ margin: 0 }}>
              {formField.title}
              {formField.required && '*'}
            </p>
            <RadioGroup name={formField.title}>
              {radioButtons.map((o, i) => (
                <FormControlLabel
                  key={`${o}-${uuidv4()}`}
                  value={o}
                  control={
                    <Radio
                      required={formField.required}
                      checked={(Array.isArray(values) ? values[0] : values) === o}
                    />
                  }
                  label={<span style={{ fontFamily: 'Poppins' }}>{o}</span>}
                  onChange={() => handleRadioButton(formField.id, o)}
                  disabled={!props.edit}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      )
    )
  }

  const generateFileField = (formField: FormFieldDTO) => {
    const fl = files.get(formField.id)
    return (
      <Box mb={3} key={formField.id + 'box'}>
        <p style={{ margin: 0 }}>
          {formField.title}
          {formField.required && '*'}
        </p>
        <FileItem
          key={formField.id}
          field={formField.id}
          filesID={[]}
          handleFiles={(_, f) => handleFiles(formField.id, f)}
          userID={loggedUser?.id ?? ''}
          cleanFiles={false}
          uploadRemoveFileDirectly={true}
          disabled={!props.edit || (fl ? fl.length > 0 : false)}
          defaultFile={fl ?? []}
          edit={props.edit}
        />
      </Box>
    )
  }

  const generateField = (formField: FormFieldDTO, userFormValue: UserFormValueDTO) => {
    switch (formField.type) {
      case FormFieldType.Text:
      case FormFieldType.TextArea:
      case FormFieldType.Number: {
        const multiline = formField.type === FormFieldType.TextArea
        return generateTextField(formField, userFormValue.values, multiline)
      }

      case FormFieldType.Date:
        return generateDateField(formField, userFormValue.values)

      case FormFieldType.Select:
      case FormFieldType.MultiSelect: {
        const multiple = formField.type === FormFieldType.MultiSelect
        return generateSelectField(formField, userFormValue.values, multiple)
      }

      case FormFieldType.CheckBox:
        return generateCheckboxField(formField, userFormValue.values)

      case FormFieldType.RadioButton:
        return generateRadioButtonField(formField, userFormValue.values)

      case FormFieldType.File:
        return generateFileField(formField)

      case FormFieldType.Time:
        return generateTimeField(formField, userFormValue.values)

      case FormFieldType.DateTime:
        return generateDateTimeField(formField, userFormValue.values)

      case FormFieldType.NoResponse:
        return generateNoResponseField(formField, userFormValue.values)
    }
  }

  const validateUserForm = (uf: UserFormDTO): boolean => {
    if (!saveAndSend) {
      return true
    }

    const fieldsRequired = formFields.filter((ff) => ff.required)
    const fieldsToValidate = fieldsRequired.filter((ff) =>
      uf.userFormValues.find(
        (ufv) =>
          ufv.formFieldID === ff.id &&
          ufv.values &&
          ((ufv.values.length > 0 && ufv.values[0] === '') || ufv.values.length === 0)
      )
    )
    if (fieldsToValidate.length > 0) {
      setErrorMessage(`${t('requiredFields')}: ${fieldsToValidate.map((f) => f.title).join(', ')}`)
      return false
    }

    setErrorMessage('')
    return true
  }

  const goBack = () => navigate(ROUTE_PATIENT_FORMS, { state: { update: true } })

  const saveOrSendForm = (): boolean => {
    const values = userForm.userFormValues.map((ufv) => {
      const ff = formFields.find((ff) => ff.id === ufv.formFieldID)
      if (ff) {
        if (ufv.values && ufv.values.length > 0 && ufv.values[0] === '') {
          const newUserFormValue = Object.assign({ ...ufv }, { ...ufv }, { done: true })
          switch (ff.type) {
            case FormFieldType.Date:
              newUserFormValue.values = [new Date().toDateString()]
              break
            case FormFieldType.CheckBox:
              newUserFormValue.values = ['0']
              break
          }
          return newUserFormValue
        }
      }
      return ufv
    })

    const newUserForm = Object.assign(
      { ...userForm },
      {
        userFormValues: values,
        readingStatus: saveAndSend ? ReadingStatus.Completed : ReadingStatus.Pending,
      }
    )
    // const value = !validateUserForm(newUserForm);
    if (!validateUserForm(newUserForm)) {
      return false
    }

    values.map((v) => {
      return userFormService.updateValue(v).subscribe()
    })

    if (saveAndSend) {
      userFormService.update(newUserForm).subscribe()
      return true
    }

    const userFormTemp: UserFormDTO = {
      id: userForm?.id ?? '',
      userID: userForm?.userID || '',
      circleID: userForm?.circleID || '',
      formID: userForm?.formID || '',
      userFormValues: [],
      readingStatus: userForm.readingStatus,
    }

    newUserForm.userFormValues.map((v) => {
      return userFormService.updateValue(v).subscribe() // Devuelve el valor aquí
    })

    goBack()
    return true
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleCloseModalSend = () => {
    setOpenModalSend(false)
  }

  const handleSaveModal = () => {
    setOpenModal(false)
    saveOrSendForm()
    // goBack()
  }

  const handleSaveModalSend = () => {
    setOpenModalSend(false)
    if (saveOrSendForm()) {
      goBack()
    }
  }

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    // e.preventDefault()
    saveOrSendForm()
  }

  const handleSaveBtn = (e: any) => {
    e.preventDefault()

    setSaveAndSend(false)
    setOpenModal(true)
  }
  const handleSendBtn = (e: any) => {
    e.preventDefault()

    setSaveAndSend(true)
    setOpenModalSend(true)
  }

  return (
    <Box className={genericStyle.pageContainer}>
      {!isLoading ? (
        <>
          {isProfessionalSMS && (
            <>
              <Box className={styles.headerWrapper}>
                <Box className={styles.headerInputRow}>
                  <h4 className={styles.labelHeaderInput}>{t('dateCompletedForm')}</h4>
                  <MobileDatePicker
                    key={userForm.id}
                    value={dateCompleted}
                    onChange={() => {}}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        style={{ margin: 0 }}
                        variant="outlined"
                        size="small"
                        label={''}
                        margin="normal"
                        inputFormat="dd/MM/yyyy"
                        invalidDateMessage={t('invalidDateMessage')}
                        disabled={!props.edit}
                      />
                    )}
                  />
                </Box>
                <Box className={styles.headerInputRow}>
                  <h4 className={styles.labelHeaderInput}>{t('totalScore')}</h4>
                  <TextField
                    inputProps={{ style: { minWidth: 40, padding: '8.5px 14px' } }}
                    // style={{ height: 40}}
                    variant="outlined"
                    id={userForm.id}
                    type="number"
                    disabled={!props.edit}
                    required
                    value={userForm?.totalScore ?? 0}
                    name="totalScore"
                  />
                  {/* <TextFieldItem
                    inputProps={{ style: { textAlign: 'center', width: 40, height: 40, padding: '15px 0px'} }}
                    // style={{ textAlign: 'center', width: 40, height: 40 } }
                    key={userForm.id}
                    type="number"
                    label="label"
                    value={userForm?.totalScore ?? 0}
                    onChange={() => {}}
                    disabled={!props.edit}
                    required
                    field=""
                  /> */}
                </Box>
              </Box>
              <Box display={'flex'} style={{ marginTop: 10 }}>
                <Box>
                  <Chip
                    style={{
                      backgroundColor: '#68b4e1',
                      marginRight: 8,
                      marginBottom: 10,
                    }}
                    label={
                      <span style={{ fontFamily: 'Poppins', color: '#fff', fontWeight: 'bold' }}>
                        {specialty}
                      </span>
                    }
                  />
                </Box>
                <Box>
                  {pathology.map((p, index) => {
                    return (
                      <Chip
                        key={p + uuidv4()}
                        style={{
                          backgroundColor: '#0163a5',
                          marginRight: 8,
                          marginBottom: 10,
                        }}
                        label={
                          <span
                            style={{
                              fontFamily: 'Poppins',
                              color: '#fff',
                              fontWeight: 'bold',
                            }}
                          >
                            {p}
                          </span>
                        }
                      />
                    )
                  })}
                </Box>
              </Box>
            </>
          )}
          <h2>{formTitle}</h2>
          <Box style={{ width: '100%', wordWrap: 'break-word', marginBottom: 14 }}>
            <p style={{ margin: 0 }}>{formDescription}</p>
          </Box>
          <FormCard>
            <form onSubmit={handleSave}>
              {userForm.userFormValues.map((ufv, index) => {
                const ff = formFields.find((ff) => ff.order === index + 1)
                return ff && generateField(ff, ufv)
              })}
              {formFieldNoAnswer !== '' && (
                <p style={{ marginBottom: '2%' }}>{formFieldNoAnswer}</p>
              )}
              {errorMessage && (
                <Box mb={3}>
                  <Alert severity="warning" key="errorMessage" id="errorMessage">
                    {errorMessage}
                  </Alert>
                </Box>
              )}
              <Box display="flex" justifyContent="space-between">
                <Box style={{ marginTop: 8 }}>
                  <AppButton
                    theme={ButtonTheme.NewSecondary}
                    type={'button'}
                    label={props.edit ? t('cancel') : t('back')}
                    handler={goBack}
                  />
                </Box>
                {props.edit && (
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    label={t('save')}
                    type={'button'}
                    handler={handleSaveBtn}
                    // handler={(e) => {
                    //   e.preventDefault()
                    //   setSaveAndSend(false)
                    //   setOpenModal(true)
                    // }}
                  />
                )}
                {props.edit && (
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    label={t('send')}
                    type={'submit'}
                    handler={handleSendBtn}
                    // handler={(e) => {
                    //   e.preventDefault()
                    //   setSaveAndSend(true)
                    //   setOpenModalSend(true)
                    // }}
                  />
                )}
              </Box>
            </form>
          </FormCard>
          <>
            <Modal open={openModal} className={style.eventModal} onClose={handleCloseModal}>
              <>
                <CustomModal
                  handleClose={handleCloseModal}
                  handleSave={handleSaveModal}
                  title={t('saveForm')}
                  warningText={t('saveFormText')}
                />
              </>
            </Modal>
            <Modal open={openModalSend} className={style.eventModal} onClose={handleCloseModalSend}>
              <>
                <CustomModal
                  handleClose={handleCloseModalSend}
                  handleSave={handleSaveModalSend}
                  title={t('send')}
                  warningText={t('sendCustomForm')}
                />
              </>
            </Modal>
          </>
        </>
      ) : (
        <LoadingSpinner className={loaderStyles.loadingSpinner} />
      )}
    </Box>
  )
}
