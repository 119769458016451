import { useTranslation } from 'react-i18next'
import React, { FormEvent, useState } from 'react'
import { emptySymptomDTO, SymptomDTO } from '../../../modules/patient-data/models/SymptomDTO'
import { Alert, Autocomplete, Box, TextField } from '@mui/material'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_SYMPTOMS_CONFIG } from '../../../routes/routes-constants'
import { TextFieldItem } from '../../../components/form-card/TextFieldItem'
import { FormCard } from '../../../components/form-card/FormCard'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { SYMPTOM_SERVICE_KEY } from '../../../modules/patient-data'
import styles from '../../generic/GenericForm.module.css'
import { SymptomService } from '../../../modules/patient-data/services/SymptomService'
import genericStyle from '../../../common/utils/generic.module.css'

enum SymptomErrors {
  NO_PATHOLOGY = 'aPathologyMustBeSelected',
  NO_NAME = 'symptomMustHaveAName',
}

type AutocompletePathologyInterface = {
  key: string
  value: string
}

type SymptomsFormProps = {
  id?: string
}

const patientDataContainer = getPatientDataContainer()
const symptomService = patientDataContainer.get<SymptomService>(SYMPTOM_SERVICE_KEY)

export function Editor(props: SymptomsFormProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [symptom, setSymptom] = useState<SymptomDTO>(emptySymptomDTO())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pathologies, setPathologies] = useState<Map<string, AutocompletePathologyInterface>>(
    new Map()
  )
  const [selectedPathology, setSelectedPathology] = useState<AutocompletePathologyInterface>()

  const [errorMessage, setErrorMessage] = useState<string>('')
  /*
  console.log('sdflkj')
  useEffect(() => {
    pathologyService.getFilteredList(new Query({ sort: [{ field: 'name' }] })).subscribe((pl) => {
      console.log('eoe' + pl.items)
      const newMap = new Map()
      pl.items.forEach((p) => {
        if (p.id) {
          newMap.set(p.id, { key: p.id, value: p.name })
          setPathologies(new Map(newMap))
        }
      })
      if (props.id) {
        symptomService.getByID(props.id).subscribe((res) => {
          if (res) {
            setSymptom(fromModel(res))
            /!* const p = res.pathologies[0]
            setSelectedPathology({
              key: p,
              value: pl.items.find((path) => path.id === p)?.name || '',
            }) *!/
          }
        })
      }
    })
  }, [])
*/

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setSymptom(Object.assign({ ...symptom }, { [e.target.name]: e.target.value }))

  const handlePathology = (event: any, value: any) => {
    if (value) {
      setSelectedPathology(value)
      setSymptom(Object.assign({ ...symptom }, { pathologies: [value.key] }))
    }
  }

  const goBack = () => navigate(ROUTE_SYMPTOMS_CONFIG)

  const validateSymptom = () => {
    if (symptom.name === '') {
      setErrorMessage(SymptomErrors.NO_NAME)
      return false
    } else {
      setErrorMessage('')
      return true
    }
  }

  const saveSymptom = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateSymptom()) {
      return null
    } else {
      if (props.id) {
        symptomService.update(symptom).subscribe((_) => goBack())
      } else {
        symptomService.add(symptom).subscribe((_) => goBack())
      }
    }
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <FormCard>
        <form onSubmit={saveSymptom}>
          <Box mb={3}>
            <Autocomplete
              key={symptom.id}
              id={symptom.id}
              options={Array.from(pathologies.values())}
              getOptionLabel={(opt) => opt.value}
              multiple={false}
              value={selectedPathology ?? { key: '', value: '' }}
              onChange={handlePathology}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label={t('pathologies')} />
              )}
              className={styles.autocompleteStyle}
              noOptionsText={t('noElementsAvailable')}
            />
          </Box>
          <Box mb={3}>
            <TextFieldItem
              field="name"
              value={symptom.name}
              label={'name'}
              type={'text'}
              handleChange={handleInput}
              required={true}
              maxLength={100}
            />
          </Box>
          {errorMessage && (
            <Box mb={3}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage)}
              </Alert>
            </Box>
          )}
          <Box mb={3} display="flex" justifyContent="space-between">
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={goBack}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('save')}
              handler={saveSymptom}
            />
          </Box>
        </form>
      </FormCard>
    </Box>
  )
}
