import { RouteProps } from '../../routes/AppRouter'
import { useTranslation } from 'react-i18next'
import { DentistForm as DF } from '../../features/patient-data'
import { ROUTE_CREATE } from '../../routes/routes-constants'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function DentistConfigForm(props: RouteProps) {
  const { id } = useParams()
  const title = props.title ?? ''
  const { t } = useTranslation()

  return (
    <>
      <CurrentNavHeaderEsfera title={t(title)} />
      <DF id={id === ROUTE_CREATE ? undefined : id} />
    </>
  )
}
