import { TeleConsulta } from '../models/TeleConsulta'
import { Query } from '../../../common/api/Query'
import { Observable } from 'rxjs'
import { Container } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { IConsultaApi } from '../api/TeleConsultaApi'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Message } from '../../messenger_consulta/models/Message'

type Props = {
  apiKey: symbol
}

export interface IConsultaService {
  getByID(id: string): Observable<TeleConsulta | undefined>

  getFilteredItems(q: Query<TeleConsulta>): Observable<TeleConsulta[]>

  add(e: TeleConsulta): Observable<TeleConsulta | undefined>

  update(e: TeleConsulta): Observable<TeleConsulta | undefined>

  delete(id: string): Observable<boolean>
}

export class TeleConsultaService implements IConsultaService {
  protected readonly _apiKey: symbol
  protected _container!: Container
  protected _api!: IConsultaApi
  protected _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IConsultaApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getFilteredItems(q: Query<TeleConsulta>): Observable<TeleConsulta[]> {
    return this._api.getFilteredItems(q).pipe()
  }

  add(e: TeleConsulta): Observable<TeleConsulta | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<TeleConsulta | undefined> {
    return this._api.getByID(id)
  }

  update(e: TeleConsulta): Observable<TeleConsulta | undefined> {
    return this._api.update(e)
  }

  addMessage(m: Message): Observable<Message | undefined> {
    return this._api.addMessage(m)
  }
}
