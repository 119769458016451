// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationsConfig_card__5rm7l {\n  margin: 1rem;\n}\n\n.NotificationsConfig_headerTitle__3CzJM,\n.NotificationsConfig_cellValue__1acSw,\n.NotificationsConfig_headerName__2xlK2 {\n  font-family: 'Poppins', sans-serif;\n  margin: 0;\n  padding: 0;\n}\n\n.NotificationsConfig_headerTitle__3CzJM,\n.NotificationsConfig_cellValue__1acSw {\n  text-align: center;\n}\n\n.NotificationsConfig_cellValue__1acSw::first-letter {\n  text-transform: uppercase;\n}\n\n.NotificationsConfig_cellCenter__277o2 {\n  display: flex;\n  justify-content: center;\n}\n\n.NotificationsConfig_cellCheck__eDhGa {\n  width: 10%;\n}\n\n.NotificationsConfig_actionBtns__VHdzV {\n  display: flex;\n  flex-direction: row;\n  justify-content: end;\n  margin-top: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/features/notifications-config/NotificationsConfig.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;;;EAGE,kCAAkC;EAClC,SAAS;EACT,UAAU;AACZ;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;AAClB","sourcesContent":[".card {\n  margin: 1rem;\n}\n\n.headerTitle,\n.cellValue,\n.headerName {\n  font-family: 'Poppins', sans-serif;\n  margin: 0;\n  padding: 0;\n}\n\n.headerTitle,\n.cellValue {\n  text-align: center;\n}\n\n.cellValue::first-letter {\n  text-transform: uppercase;\n}\n\n.cellCenter {\n  display: flex;\n  justify-content: center;\n}\n\n.cellCheck {\n  width: 10%;\n}\n\n.actionBtns {\n  display: flex;\n  flex-direction: row;\n  justify-content: end;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "NotificationsConfig_card__5rm7l",
	"headerTitle": "NotificationsConfig_headerTitle__3CzJM",
	"cellValue": "NotificationsConfig_cellValue__1acSw",
	"headerName": "NotificationsConfig_headerName__2xlK2",
	"cellCenter": "NotificationsConfig_cellCenter__277o2",
	"cellCheck": "NotificationsConfig_cellCheck__eDhGa",
	"actionBtns": "NotificationsConfig_actionBtns__VHdzV"
};
export default ___CSS_LOADER_EXPORT___;
