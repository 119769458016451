import { getUserContainer } from '../../container/user-module'
import {
  LOGGED_USER_SERVICE_KEY,
  PROFESSIONALTYPE_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users'
import { Box, Modal, Tooltip } from '@mui/material'
import { Field } from '../../components/table'
import { User, UserQuery } from '../../modules/users/models/User'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { Query } from '../../common/api/Query'
import { IUserService } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Search, SearchValue } from '../../components/table_type/types'
import genericStyle from '../../common/utils/generic.module.css'
import patient from '../../assets/role_icons/ico-rol-paciente.svg'
import professionalSMS from '../../assets/role_icons/ico-rol-profesional.svg'
import family from '../../assets/role_icons/ico-rol-familiar.svg'
import externProfessional from '../../assets/role_icons/ico-rol-externo.svg'
import career from '../../assets/role_icons/ico-rol-cuidador.svg'
import { ROUTE_USERS_CREATE } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { RolesService } from '../../modules/users/services/RolesServices'
import { Roles } from '../../modules/users/enums/Roles'
import { Permission } from '../../common/permission'
import { UserPending } from '../../modules/users/models/UserPending'
import { CustomModal } from '../../components/modal/CustomModal'
import style from '../../components/table/AppTable.module.css'
import { Skeleton } from '@mui/material'
import { getUserCircleContainer } from '../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
  USER_CIRCLE_SERVICE_KEY,
} from '../../modules/user-circle'

import { AppUsersTable } from './participant-table/AppParticipantTable'
import { UserCircleService } from '../../modules/user-circle/services/UserCircleConfigService'
import { ProfessionalTypesService } from '../../modules/users/services/ProfessionalTypeService'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const roleService = userContainer.get<RolesService>(ROLES_SERVICE_KEY)
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)

type ParticipantProps = {
  id?: string
}

export interface Participant extends User {
  _professionalType?: string
  _role?: string
}

export function Table(props: ParticipantProps) {
  const user = userCircleActiveService.getActiveFullUserCircle()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loggedUser = loggedUserService.get()
  const { t } = useTranslation()
  const { innerWidth } = window
  const [users, setUsers] = useState<Participant[]>([])
  const [filteredUsers, setFilteredUsers] = useState<Participant[]>([])
  const data = useMemo(() => filteredUsers, [filteredUsers])
  /*
    const [pager, setPager] = useState<Pager>({ offset: 0, limit: 20 })
  */

  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'allFields',
      label: t(''),
      value: '',
      width: '100%',
    },
  ])
  /*
    const [roles, setRoles] = useState<Map<string, string>>(new Map())
  */
  const [usersPending, setUsersPending] = useState<UserPending[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  /*
    const [removeUserPending, setRemoveUserPending] = useState<UserPending>()
  */
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openDissociateModal, setOpenDissociateModal] = useState<boolean>(false)
  /*
    const [userDissociate, setUserDissociate] = useState<User>()
  */
  const navigate = useNavigate()

  useEffect(() => {
    roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        const tmpMap = new Map<string, string>()
        res.forEach((r) => tmpMap.set(r.id, r.name))
        /* setRoles(tmpMap) */
      })

    if (!user?.id) {
      return
    }

    userService.getUserPending(user.id).subscribe((res) => {
      setUsersPending(res)
    })

    let allUsers: Participant[] = []

    userCircleService.getByUserIDWithRelaters(user.user.id).subscribe(async (res) => {
      if (!res) return
      const activeUserCircleParticipants = res.find(
        (userCircle) => userCircle.circleID === user.circle.id
      )
      if (!activeUserCircleParticipants) return
      const patient = activeUserCircleParticipants.user as Participant
      patient._role = Roles.Patient
      allUsers = [
        // añadimos al paciente
        patient,
        ...activeUserCircleParticipants.familiars.map((user: Participant) => {
          user._role = Roles.FamilyOrTutor
          return user
        }),
        ...activeUserCircleParticipants.profExt.map((user: Participant) => {
          user._role = Roles.ProfessionalExtern
          return user
        }),
        ...activeUserCircleParticipants.profSms.map((user: Participant) => {
          user._role = Roles.Professional
          return user
        }),

        ...activeUserCircleParticipants.careers.map((user: Participant) => {
          user._role = Roles.CarerOrTeacher
          return user
        }),
      ]

      // Obtengo las especialidades de los profesionales
      const promises = allUsers.map(async (user: Participant, index) => {
        if (user._role !== Roles.Professional) {
          return user // Devuelvo el usuario sin cambios si no es profesional
        }
        const res = await professionalTypeService.getProfessionalTypeByUserID(user.id).toPromise()
        user._professionalType = res?.name ?? ''
        return user
      })

      const updatedUsers = await Promise.all(promises)
      setUsers(updatedUsers)
      setFilteredUsers(updatedUsers)
    })
  }, [])

  useEffect(() => {
    if (searcher[0].value === '') {
      setFilteredUsers(users)
    } else {
      const nameSearch: string = searcher[0]?.value?.toLowerCase() ?? ''
      setFilteredUsers(
        users.filter((user) => (user.name + ' ' + user.lastname).toLowerCase().includes(nameSearch))
      )
    }
  }, [isLoading, searcher])

  useEffect(() => {
    const interval = setInterval(() => setIsLoading(false), 1000)
    return () => clearInterval(interval)
  }, [users, usersPending, filteredUsers])

  const dissociate = () => {
    /* if (!userDissociate) return
        userService.removeRelated(user?.id ?? '', userDissociate.id).subscribe(() => {
          setOpenDissociateModal(false)
          setPager(cloneDeep<Pager>(pager))
          setIsLoading(true)
        }) */
  }

  const handlerAddUser = () => navigate(ROUTE_USERS_CREATE)

  // const isPatient = (u: User): boolean => roles.get(u.roles[0]) === Roles.Patient

  /* const isCurrentDoctor = (u: User): boolean =>
      loggedUser?.id === u.id && roles.get(u.roles[0]) === Roles.Professional

    const isCurrentFamiliar = (): boolean =>
      users.some((u2) => roles.get(u2.roles[0]) === Roles.FamilyOrTutor && u2.id === loggedUser?.id)

    const checkDissociate = (u: User) => {
      if (!loggedUser || !u.roles.length) {
        return true
      }

      if (isPatient(u) || isCurrentDoctor(u)) {
        return true
      }

      setIsLoading(false)
      return isCurrentFamiliar() && roles.get(u.roles[0]) === Roles.Professional
    } */

  /* const getUserRole = (u: User): string => {
      if (roles.get(u.roles[0]) === Roles.ContentManager) return Roles.Professional
      return roles.get(u.roles[0]) ?? ''
    } */

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleCloseDissociateModal = () => {
    setOpenDissociateModal(false)
  }

  const handleDeleteSymptom = () => {
    /* if (!removeUserPending?.token) {
          setOpenDeleteModal(false)
          return
        }
        userService.deleteUserPending(removeUserPending.token).subscribe((_) => {
          setOpenDeleteModal(false)
          setIsLoading(!isLoading)
        }) */
  }
  const fields: Field<Participant>[] =
    innerWidth > 599
      ? [
          {
            name: 'id',
            label: '',
          },
          {
            name: 'name',
            label: t('fullName'),
          },
          {
            name: 'roles',
            label: t('role'),
            renderFunc: (f, i) => {
              let img = ''
              switch (i._role) {
                case Roles.Patient:
                  if (i.id !== user?.id) {
                    img = family
                    break
                  }
                  img = patient
                  break
                case Roles.FamilyOrTutor:
                  if (i.id === user?.id) {
                    img = patient
                    break
                  }
                  img = family
                  break
                case Roles.Professional:
                  img = professionalSMS
                  break
                case Roles.ProfessionalExtern:
                  img = externProfessional
                  break
                case Roles.CarerOrTeacher:
                  img = career
                  break
                case Roles.ContentManager:
                  img = professionalSMS
                  break
              }
              return (
                <div>
                  {innerWidth < 598 ? (
                    <Box display={'flex'} alignItems={'center'}>
                      <img
                        style={{ width: '15px', height: '15px', margin: '4px', marginLeft: '8px' }}
                        src={img}
                        alt={
                          i._role === Roles.ContentManager
                            ? t(Roles.Professional)
                            : t(i._role as string)
                        }
                      />
                      <h5 style={{ margin: '0' }}>
                        {i._role === Roles.ContentManager
                          ? t(Roles.Professional)
                          : t(i._role as string)}
                      </h5>
                    </Box>
                  ) : (
                    <Tooltip
                      title={
                        (i._role === Roles.ContentManager
                          ? t(Roles.Professional)
                          : t(i._role as string)) ?? ''
                      }
                    >
                      <img
                        src={img}
                        alt={
                          i._role === Roles.ContentManager
                            ? t(Roles.Professional)
                            : t(i._role as string)
                        }
                      />
                    </Tooltip>
                  )}
                </div>
              )
            },
          },
          {
            name: '_professionalType',
            label: t('professionalType'),
            renderFunc: (f, i) => {
              if (i._role !== Roles.Professional) {
                return <p>ieiiee</p>
              }
              return i._professionalType ?? 'asdf'
            },
          },
        ]
      : [
          {
            name: 'id',
            label: '',
          },
          {
            name: 'id',
            label: '',
          },
          {
            name: 'related',
            label: '',
            renderFunc: (f, i) => {
              let img = ''
              switch (i._role) {
                case Roles.Patient:
                  if (i.id !== user?.id) {
                    img = family
                    break
                  }
                  img = patient
                  break
                case Roles.FamilyOrTutor:
                  if (i.id === user?.id) {
                    img = patient
                    break
                  }
                  img = family
                  break
                case Roles.Professional:
                  img = professionalSMS
                  break
                case Roles.ProfessionalExtern:
                  img = externProfessional
                  break
                case Roles.CarerOrTeacher:
                  img = career
                  break
                case Roles.ContentManager:
                  img = professionalSMS
                  break
              }
              return (
                <div>
                  {innerWidth < 599 ? (
                    <Box display={'flex'} alignItems={'center'}>
                      <img
                        style={{ width: '15px', height: '15px', margin: '4px', marginLeft: '8px' }}
                        src={img}
                        alt={
                          i._role === Roles.ContentManager
                            ? t(Roles.Professional)
                            : t(i._role as string)
                        }
                      />
                      <h5 style={{ margin: '0' }}>
                        {i._role === Roles.ContentManager
                          ? t(Roles.Professional)
                          : t(i._role as string)}
                      </h5>
                    </Box>
                  ) : (
                    <Tooltip
                      title={
                        (i._role === Roles.ContentManager
                          ? t(Roles.Professional)
                          : t(i._role as string)) ?? ''
                      }
                    >
                      <img
                        src={img}
                        alt={
                          i._role === Roles.ContentManager
                            ? t(Roles.Professional)
                            : t(i._role as string)
                        }
                      />
                    </Tooltip>
                  )}
                </div>
              )
            },
          },
        ]

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => {
      setSearcher(svs)
    },
  }

  return !isLoading ? (
    <>
      <Box className={genericStyle.pageContainer}>
        <AppUsersTable
          addButtonLabel={t('addParticipant')}
          removeButtonLabel={t('removeParticipant')}
          reInviteButtonLabel={t('reInviteParticipant')}
          handlerAdd={handlerAddUser}
          titleActions={t('Actions')}
          titleCaption={t('caption')}
          search={search}
          fields={fields}
          items={data}
          newItems={usersPending}
          titleNewItems={t('pendingUsers')}
          hasLinkPermission={loggedUserService.userCan(Permission.linkUser)}
        />
      </Box>
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <CustomModal
          handleClose={handleCloseDeleteModal}
          handleSave={handleDeleteSymptom}
          title={t('deleteInvitation')}
          warningText={t('irreversibleInvitationAction')}
        />
      </Modal>
      <Modal open={openDissociateModal} onClose={handleCloseDissociateModal}>
        <CustomModal
          title={t('deleteDissociate')}
          warningText={t('irreversibleDissociateAction')}
          handleClose={handleCloseDissociateModal}
          handleSave={dissociate}
        />
      </Modal>
    </>
  ) : (
    <>
      <Box className={genericStyle.pageContainer}>
        {loggedUserService.userCan(Permission.linkUser) && (
          <Box className={genericStyle.circleButton}>
            <Skeleton width={140} height={70} />
          </Box>
        )}
        <Box className={style.searchContainer}>
          <Skeleton width={120} height={60} />
        </Box>
        <Box>
          <Skeleton variant="text" />
          <Skeleton height={450} />
        </Box>
        {loggedUserService.userCan(Permission.linkUser) &&
          usersPending &&
          usersPending.length > 0 && <Skeleton height={200} />}
      </Box>
      {innerWidth > 598 ? (
        <Box className={genericStyle.captionContainer}>
          <Box>
            <p style={{ color: '#616161', fontSize: '14px', fontWeight: 'bolder', marginTop: '0' }}>
              <Skeleton />
            </p>
          </Box>
          <Box className={genericStyle.roleContainer}>
            <Box className={genericStyle.role}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton />
            </Box>
            <Box className={genericStyle.role}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton />
            </Box>
            <Box className={genericStyle.role}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton />
            </Box>
            <Box className={genericStyle.role}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton />
            </Box>
            <Box className={genericStyle.role}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton />
            </Box>
            <Box className={genericStyle.role}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton />
            </Box>
          </Box>
        </Box>
      ) : (
        ''
      )}
    </>
  )
}
