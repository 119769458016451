import { RouteProps } from '../../routes/AppRouter'
import { EvolutionControl as E } from '../../features/patient-data/evolutionControl/EvolutionControl'
import React from 'react'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function EvolutionControl(props: RouteProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id } = useParams()
  const title = props.title ?? ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <E />
    </>
  )
}
