// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 599px) {\n    .DashboardPatient_boxSection__3zblF {\n        margin: 1rem 0;\n    }\n}", "",{"version":3,"sources":["webpack://src/features/dashboard/patient/DashboardPatient.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":["@media (max-width: 599px) {\n    .boxSection {\n        margin: 1rem 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxSection": "DashboardPatient_boxSection__3zblF"
};
export default ___CSS_LOADER_EXPORT___;
