import { createContext, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

type SelectedMenu = {
  handleSetMenuSelected: (menu: string) => void
}

export const SelectedMenuContext = createContext<SelectedMenu>({
  handleSetMenuSelected: () => {}, // maybe we can improved this one
})

export const useSelectedMenuContext = () => useContext(SelectedMenuContext)

type SelectedMenuProviderProps = {
  handleSetMenuSelected: (menu: string) => void
  children: React.ReactNode
}

export const SelectedMenuProvider: React.FC<SelectedMenuProviderProps> = ({
  handleSetMenuSelected,
  children,
}) => {
  const location = useLocation()

  useEffect(() => {
    handleSetMenuSelected(location.pathname)
  }, [location, handleSetMenuSelected])

  return (
    <SelectedMenuContext.Provider value={{ handleSetMenuSelected }}>
      {children}
    </SelectedMenuContext.Provider>
  )
}
