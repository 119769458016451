import { RouteProps, useNavigate } from 'react-router-dom'
import { DashboardSectionCard } from '../../../components/section-card/DashboardSectionCard'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { NotificationsCardPatient } from './notification-card-patient/NotificationsCardPatient'
import { ChatsCard } from './chats-card/ChatsCard'
import { CirclesCard } from './circles-card/CirclesCard'
import { EducationalContentCard } from './educational-content-card/EducationalContentCard'
import { ActiveQueriesCard } from './active-query-card/ActiveQueriesCard'
import { MonitoredActivityCard } from './monitored-activity-card/MonitoredActivityCard'
import { ActivePlanCard } from './active-plan-card/ActivePlanCard'
import React, { useState } from 'react'
import { ACTIVE_PLAN_CIRCLE_ID, EPILEPSY_CIRCLE_ID } from '../../../common/const'
import { UserCircleWithRelatersAndCircle } from '../../../modules/user-circle/models/UserCircleWithRelatersAndCircle'
import { NumericStatsCard } from './numeric-stats-card/NumericStatsCard'
import { MonthView } from '../../calendar/MonthView'
import { useCalendarEvents } from '../../../hooks/calendarEvents/useCalendarEvents'
import { ROUTE_WEARABLES } from '../../../routes/routes-constants'
import { EpilepsyCard } from './epilepsy-card/EpilepsyCard'
import { DashboardPatientMobile } from './DashboardPatientMobile'
import styles from './DashboardPatient.module.css'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const DashboardPatient = (props: RouteProps) => {
  const { calendarEvents } = useCalendarEvents()

  const [selectedUserCircle, setSelectedUserCircle] = useState<UserCircleWithRelatersAndCircle>()
  const [numberPendingChats, setNumberPendingChats] = useState<number>(0)
  const [numberNewResources, setNumberNewResources] = useState<number>(0)
  const [section, setSection] = useState<number>(-1)
  const isSmallScreen = useMediaQuery('(max-width:599px)')
  const today = new Date()
  const vp = {
    calendarEvents,
    selectedDate: new Date(today.getFullYear(), today.getMonth(), 1),
    handlerEdit: () => {},
    handlerRemove: () => {},
    handlerAdd: () => {},
    handleNextMonth: () => {},
    handlePreviousMonth: () => {},
    canChangeMonth: false,
  }
  const navigate = useNavigate()
  const wearables = () => {
    navigate(ROUTE_WEARABLES)
  }
  return (
    <Box px={isSmallScreen ? 2 : 0} bgcolor={isSmallScreen ? 'white' : 'inherit'}>
      <DashboardSectionCard label={'calendar'}>
        <MonthView {...vp} />
      </DashboardSectionCard>
      <DashboardSectionCard
        label={'generalSection'}
        onClick={() => setSection(-1)}
        showMore={isSmallScreen && section !== -1}
      >
        <Box className={styles.boxSection}>
          {isSmallScreen && (
            <DashboardPatientMobile
              section={section}
              changeSection={(x) => setSection(x)}
              selectedUserCircle={selectedUserCircle}
              SetSelectedUserCircle={setSelectedUserCircle}
              SetNumberNewResources={setNumberNewResources}
              SetNumberPendingChats={setNumberPendingChats}
            />
          )}

          {!isSmallScreen && (
            <Grid width={'100%'} container spacing={2}>
              <Grid mt={4} item xs={12} sm={6} md={4}>
                <NotificationsCardPatient
                  ProfessionalNotifications={true}
                  SetNumberNewResources={setNumberNewResources}
                  selectedUserCircle={selectedUserCircle}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <Grid display={'flex'} xs={12} sm={6} md={12}>
                  <Grid mt={4} mr={2} item xs={12} sm={6} md={6}>
                    <CirclesCard
                      selectedUserCircle={selectedUserCircle}
                      setSelectedUserCircle={setSelectedUserCircle}
                    />
                  </Grid>
                  <Grid mt={4} item xs={12} sm={6} md={6}>
                    <ChatsCard setNumberPendingChats={setNumberPendingChats} />
                  </Grid>
                </Grid>
                <Grid display={'flex'} xs={12} sm={6} md={12}>
                  <Grid mt={4} mr={2} item xs={12} sm={6} md={6}>
                    <EducationalContentCard />
                  </Grid>
                  <Grid mt={4} item xs={12} sm={6} md={6}>
                    <ActiveQueriesCard />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </DashboardSectionCard>
      {selectedUserCircle?.circle.id === ACTIVE_PLAN_CIRCLE_ID && (
        <>
          <DashboardSectionCard label={'wearables'} showMore={true} onClick={wearables}>
            <MonitoredActivityCard />
          </DashboardSectionCard>
          <DashboardSectionCard label={'activePlan'} marginTop={50}>
            <ActivePlanCard />
          </DashboardSectionCard>
        </>
      )}

      {selectedUserCircle?.circle.id === EPILEPSY_CIRCLE_ID && (
        <DashboardSectionCard label={'epilepsya'} marginTop={50}>
          <EpilepsyCard />
        </DashboardSectionCard>
      )}

      <NumericStatsCard
        numberPendingChats={numberPendingChats}
        numberNewResources={numberNewResources}
      ></NumericStatsCard>
    </Box>
  )
}
