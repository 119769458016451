import GenericChartDashboard from '../../../../components/dashboard/generic-chart-dashboard/GenericChartDashboard'
import { ChartEnum } from '../../../../components/dashboard/generic-chart-dashboard/ChartEnum'
import { ActivePlanService } from '../../../../modules/active-plan/services/ActivePlanService'
import React, { useEffect, useMemo, useState } from 'react'
import { Query } from '../../../../common/api/Query'

type PatientStatisicsCardProps = {
  activePlanService: ActivePlanService
}

type Status = {
  type: 'general' | 'suma' | 'cardio' | 'families' | 'parkinson'
  count: number
}

const PatientStatisicsCard: React.FC<PatientStatisicsCardProps> = ({ activePlanService }) => {
  const backgroundColors = ['#0063A6', '#F18B24', '#B6B5B5', '#F1DD25', '#68B3E0', '#4CBF0D']
  const [label, setLabel] = useState<string[]>([])
  const [datasets, setDataSets] = useState<
    { data: number[]; backgroundColor: string; label: string }[]
  >([])
  const statusTypes = useMemo(
    () => ({
      general: 'general',
      suma: 'suma',
      cardio: 'cardio',
      families: 'families',
      parkinson: 'parkinson',
    }),
    []
  )

  useEffect(() => {
    const subscription = activePlanService
      .getFilteredList(new Query({ query: [] }))
      .subscribe((response) => {
        if (!response) return
        const statusCounts = {
          general: response.items.filter((item) => item.protocol === 'general').length,
          suma: response.items.filter((item) => item.protocol === 'suma').length,
          cardio: response.items.filter((item) => item.protocol === 'cardio').length,
          families: response.items.filter((item) => item.protocol === 'families').length,
          parkinson: response.items.filter((item) => item.protocol === 'parkinson').length,
        }

        const filteredStatusCounts: Status[] = Object.entries(statusCounts)
          .filter(([_, count]) => count > 0)
          .map(([type, count]) => ({
            type: type as 'general' | 'suma' | 'cardio' | 'families' | 'parkinson',
            count,
          }))

        setLabel(createEmptyLabel(filteredStatusCounts.length))
        setDataSets(
          filteredStatusCounts.map((item, index) => ({
            data: createArrByLength(filteredStatusCounts.length, item.count, index),
            backgroundColor: backgroundColors[index],
            label: filteredStatusCounts.map((status) => statusTypes[status.type])[index],
          }))
        )
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [activePlanService])

  const createEmptyLabel = (length: number): string[] => {
    const auxArr: string[] = []
    for (let i = 0; i < length; i++) {
      auxArr.push('')
    }
    return auxArr
  }

  const createArrByLength = (length: number, value: number, valueIndex: number): number[] => {
    const auxArr: number[] = []
    for (let i = 0; i < length; i++) {
      if (i === valueIndex) {
        auxArr.push(value)
      } else {
        auxArr.push(0)
      }
    }
    return auxArr
  }

  return (
    <GenericChartDashboard
      datasets={datasets}
      labels={label}
      title={''}
      typeChart={ChartEnum.Bar}
      legendPosition={'bottom'}
    />
  )
}
export default PatientStatisicsCard
