import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ROUTE_HOME } from 'routes/routes-constants'

type SelectedMenu = {
  sMenu: string
  isCircleOpen: boolean
  setIsCircleOpen: Dispatch<SetStateAction<boolean>>
  isProfileOpen: boolean
  setIsProfileOpen: Dispatch<SetStateAction<boolean>>
  isArticleOpen: boolean
  setIsArticleOpen: Dispatch<SetStateAction<boolean>>
}

export const SelectedMenuContext = createContext<SelectedMenu>({
  sMenu: '',
  isCircleOpen: false,
  setIsCircleOpen: () => {},
  isProfileOpen: false,
  setIsProfileOpen: () => {},
  isArticleOpen: false,
  setIsArticleOpen: () => {},
})

export const useSelectedMenuContext = () => useContext(SelectedMenuContext)

type SelectedMenuProviderProps = {
  children: React.ReactNode
}

export const SelectedMenuProvider: React.FC<SelectedMenuProviderProps> = ({ children }) => {
  const location = useLocation()
  const [menuSelected, setMenuSelected] = useState<string>(ROUTE_HOME)
  const [isCircleOpen, setIsCircleOpen] = useState<boolean>(true)
  const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false)
  const [isArticleOpen, setIsArticleOpen] = useState<boolean>(false)

  useEffect(() => {
    const isIdSegment = (segment: string) => {
      // regex que  coincide con el formato UUID
      return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(segment)
    }
    const pathSegments = location.pathname.split('/').filter((segment) => segment.length > 0)

    if (pathSegments.length > 0) {
      // Comprobamos el último segmento
      const lastSegment = pathSegments[pathSegments.length - 1]

      if (isIdSegment(lastSegment)) {
        // Eliminar el último segmento si es un ID
        pathSegments.pop()
      }
    }

    // Reconstruir la nueva URL
    let newPath = '/' + pathSegments.join('/')

    // array para los corner cases de tratamientos y sintomas, se ha añadido un regex por aceptacion que cambia el url no es
    // /esfera sino /circulo
    const pathCornerCases = [
      /\/portaldelpaciente\/(esfera|circulo)\/patient-data\/treatments/,
      /\/portaldelpaciente\/(esfera|circulo)\/patient-data\/symptoms/,
    ]

    if (pathCornerCases.some((cornerCase) => cornerCase.test(newPath))) {
      const basePath = '/portaldelpaciente/esfera/patient-data'

      const basePahtAceptacion = '/sms/portaldelpaciente/circulo/patient-data'

      newPath.includes('/esfera/') ? (newPath = basePath) : (newPath = basePahtAceptacion)
    }
    setMenuSelected(newPath)
    setIsCircleOpen(false)
    setIsProfileOpen(false)
    setIsArticleOpen(false)
  }, [location])

  return (
    <SelectedMenuContext.Provider
      value={{
        sMenu: menuSelected,
        isCircleOpen,
        setIsCircleOpen,
        isProfileOpen,
        setIsProfileOpen,
        isArticleOpen,
        setIsArticleOpen
      }}
    >
      {children}
    </SelectedMenuContext.Provider>
  )
}
