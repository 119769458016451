/* tslint:disable */
//@ts-nocheck
import React, { FormEvent, useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './EpilepticCrisis.module.css'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import { EpilepticalCrisisService } from '../../../../modules/epileptical/services/EpilepticalCrisisService'
import { ROUTE_PATIENT_SYMPTOMS } from '../../../../routes/routes-constants'
import {
  emptyEpilepticalCrisis,
  EpilepticalCrisisDTO,
  fromModel as fromModelCrisis,
} from '../../../../modules/epileptical/models/EpilepticalCrisisDTO'
import { getEpilepticalContainer } from '../../../../container/epileptical-module'
import {
  EPILEPTICAL_CRISIS_DETAILS_SERVICE_KEY,
  EPILEPTICAL_CRISIS_SERVICE_KEY,
  USER_EPILEPTICAL_CONFIG_SERVICE_KEY,
} from '../../../../modules/epileptical'
import { getPatientDataContainer } from '../../../../container/patient-data-module'
import { PatientSymptomService } from '../../../../modules/patient-data/services/PatientSymptomService'
import { PATIENT_SYMPTOM_SERVICE_KEY } from '../../../../modules/patient-data'
import {
  emptyPatientSymptomDTO,
  PatientSymptomDTO,
} from '../../../../modules/patient-data/models/PatientSymptomDTO'
import { SymptomTypes } from '../../../../modules/patient-data/enums/symptomTypes'
import { Details } from './Details'
import {
  emptyEpilepticalCrisisDetails,
  EpilepticalCrisisDetailsDTO,
  fromModel,
} from '../../../../modules/epileptical/models/EpilepticalCrisisDetailsDTO'
import { EpilepticalCrisisDetailsService } from '../../../../modules/epileptical/services/EpilepticalCrisisDetailsService'
import { Mood } from '../../../../modules/epileptical/enums/Mood'
import { CrisisType } from '../../../../modules/epileptical/enums/CrisisType'
import { v4 as uuidv4 } from 'uuid'
import { ModalNewCrisis } from './ModalNewCrisis'
import { UserEpilepticalConfigService } from '../../../../modules/epileptical/services/UserEpilepticalConfigService'
import {
  emptyUserEpilepticalConfig,
  UserEpilepticalConfigDTO,
} from '../../../../modules/epileptical/models/UserEpilepticalConfigDTO'
import { Duration } from '../../../../modules/epileptical/enums/Duration'
import { Zone } from '../../../../modules/epileptical/enums/Zone'
import {
  UserEpilepticalConfig,
  UserEpilepticalConfigQuery,
} from '../../../../modules/epileptical/models/UserEpilepticalConfig'
import { Query, QueryParam } from '../../../../common/api/Query'
import { emptyList, ItemList } from '../../../../common/models/ItemList'
import { EpilepticalCrisis } from '../../../../modules/epileptical/models/EpilepticalCrisis'
import {
  EpilepticalCrisisDetails,
  EpilepticalCrisisDetailsQuery,
} from '../../../../modules/epileptical/models/EpilepticalCrisisDetails'
import { Table } from './Table'
import { Movement } from '../../../../modules/epileptical/enums/Movement'
import { Vision } from '../../../../modules/epileptical/enums/Vision'
import { handlerSwitch } from '../../../../common/utils/handlerSwitch'
import { SkinColor } from '../../../../modules/epileptical/enums/SkinColor'
import { Symptom } from '../../../../modules/epileptical/enums/Symptom'
import { Mouth } from '../../../../modules/epileptical/enums/Mouth'
import { AfterDuration } from '../../../../modules/epileptical/enums/AfterDuration'
import { Awareness } from '../../../../modules/epileptical/enums/Awareness'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'
import { ModalEditCrisis } from './ModalEditCrisis'
import { useNavigate } from 'react-router-dom'
import { getUserCircleContainer } from '../../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../../modules/user-circle'

export type EpilepticalCrisisProps = {
  id?: string
  symptom: AutocompleteSymptomInterface
  userCircleID: string
  loggedUserID?: string
  detailsOpen: (e: boolean) => void
}

type AutocompleteSymptomInterface = {
  pathology: string
  key: string
  value: string
}

const patientDataContainer = getPatientDataContainer()
const patientSymptomService = patientDataContainer.get<PatientSymptomService>(
  PATIENT_SYMPTOM_SERVICE_KEY
)

const epilepticContainer = getEpilepticalContainer()
const epilepticCrisisService = epilepticContainer.get<EpilepticalCrisisService>(
  EPILEPTICAL_CRISIS_SERVICE_KEY
)

const epilepticCrisisDetailsContainer = getEpilepticalContainer()
const epilepticCrisisDetailsService =
  epilepticCrisisDetailsContainer.get<EpilepticalCrisisDetailsService>(
    EPILEPTICAL_CRISIS_DETAILS_SERVICE_KEY
  )

const userEpilepticCrisisConfigService =
  epilepticCrisisDetailsContainer.get<UserEpilepticalConfigService>(
    USER_EPILEPTICAL_CONFIG_SERVICE_KEY
  )

const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

const colors = {
  green: '#92d6c3',
  blue: '#6acafa',
  pink: '#ffa2de',
  yellow: '#ffd539',
  orange: '#ffb229',
  highOrange: '#ff8543',
  red: '#d70c0b',
  black: '#020202',
}

const otherSymptoms = [
  'sleep',
  'alert',
  'confusion',
  'paralysis',
  'incontinence',
  'wound',
  'agitation',
  'headache',
  'limbWeakness',
  'others',
]

export function EpilepticCrisis(props: EpilepticalCrisisProps) {
  const { t } = useTranslation()
  const { innerWidth } = window
  const [isEditing, setIsEditing] = useState<boolean>(false)

  // States of epileptic crisis
  const [rescueMedication, setRescueMedication] = useState<number>()
  const [radioButton, setRadioButton] = useState<string>()
  const [epilepticCrisis, setEpilepticCrisis] =
    useState<EpilepticalCrisisDTO>(emptyEpilepticalCrisis())

  // States of patient symptom
  const [patientSymptom, setPatientSymptom] = useState<PatientSymptomDTO>(emptyPatientSymptomDTO())
  const [patientSymptomAux, setPatientSymptomAux] =
    useState<PatientSymptomDTO>(emptyPatientSymptomDTO())
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [addCrisis, setAddCrisis] = useState<number>(0)

  // States of details epileptic crisis
  const [color, setColor] = useState<string>('')
  const [arrayDetails, setArrayDetails] = useState<EpilepticalCrisisDetailsDTO[]>([])
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [newType, setNewType] = useState<UserEpilepticalConfigDTO>(emptyUserEpilepticalConfig())
  const [selectedColor, setSelectedColor] = useState<string[]>([
    colors.green,
    colors.blue,
    colors.pink,
  ])
  const [intensityRadioButton, setIntensityRadioButton] = useState<string>(t(''))
  const [commentNewCrisisType, setCommentNewCrisisType] = useState<string>('')
  const [durationNewCrisisType, setDurationNewCrisisType] = useState<string>(t(''))
  const [crisisZone, setCrisisZone] = useState<string>('')
  const [epilepticConfig, setEpilepticConfig] =
    useState<ItemList<UserEpilepticalConfig>>(emptyList<UserEpilepticalConfig>())
  const [listEpilepticCrisis, setListEpilepticCrisis] =
    useState<ItemList<EpilepticalCrisis>>(emptyList<EpilepticalCrisis>())
  const [listDetailsCrisis, setListDetailsCrisis] =
    useState<ItemList<EpilepticalCrisisDetails>>(emptyList<EpilepticalCrisisDetails>())

  const [currentCrisisDetail, setCurrentCrisisDetail] = useState<EpilepticalCrisisDetailsDTO>(
    emptyEpilepticalCrisisDetails()
  )

  const [detailsById, setDetailsById] = useState<EpilepticalCrisisDetailsDTO>()
  const [openEditCrisis, setOpenEditCrisis] = useState<boolean>(false)
  const [error, setError] = useState<string>(' ')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [checkedState, setCheckedState] = useState(new Array(otherSymptoms.length).fill(false))
  const [symptomType, setSymptomType] = useState<string>('')
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const activeUser = userCircleActiveService.getActiveFullUserCircle()

  const [colorSelected, setColorSelected] = useState<string>('')

  const handleClose = () => setOpen(false)
  const handleCloseEdit = () => setOpenEdit(false)
  const handleOpen = () => setOpen(true)
  const handleOpenEdit = () => setOpenEdit(true)

  useEffect(() => {
    if (!props.userCircleID) {
      return
    }
    userEpilepticCrisisConfigService
      .getFilteredList(
        new Query({
          query: [new QueryParam<UserEpilepticalConfigQuery>('userCircleID', props.userCircleID)],
        })
      )
      .subscribe((res) => {
        setEpilepticConfig(res)
      })
  }, [open])

  useEffect(() => {
    if (props.loggedUserID && props.userCircleID) {
      setPatientSymptom(
        Object.assign(
          { ...patientSymptom },
          {
            symptomID: props.symptom?.key,
            creatorID: props.loggedUserID,
            symptomType: SymptomTypes.epilepticalCrisis,
            userID: activeUser?.user.id ?? '',
            userCircleID: props.userCircleID,
          }
        )
      )
      setNewType(Object.assign({ ...newType }, { userCircleID: props.userCircleID }))
    }
  }, [open])

  useEffect(() => {
    setIsEditing(!!props.id)
    if (!props.userCircleID) return
    epilepticCrisisService
      .getFilteredList(
        new Query({
          query: [new QueryParam<EpilepticalCrisis>('userCircleID', props.userCircleID)],
        })
      )
      .subscribe((resListEpileptical) => {
        setListEpilepticCrisis(resListEpileptical)
      })
  }, [])

  useEffect(() => {
    setIsEditing(!!props.id)
    if (!props.userCircleID) return
    userEpilepticCrisisConfigService
      .getFilteredList(
        new Query({
          query: [new QueryParam<UserEpilepticalConfigQuery>('userCircleID', props.userCircleID)],
        })
      )
      .subscribe()
  }, [props.userCircleID])

  useEffect(() => {
    setIsEditing(!!props.id)
    if (props.id) {
      patientSymptomService.getByID(props.id).subscribe((res) => {
        if (res) {
          patientSymptom.id = res.id
          patientSymptom.symptomType = res.symptomType
          setSymptomType(res.symptomType)
          if (!res.id) return
          const idCrisis = listEpilepticCrisis.items.find(
            (item) => item.patientSymptomID === res.id
          )

          if (idCrisis) {
            epilepticCrisisService.getByID(idCrisis.id).subscribe((resCrisis) => {
              if (!resCrisis) return
              const patientSymptomAux = patientSymptom
              patientSymptomAux.symptomStartDate = resCrisis.date
              switch (resCrisis.mood) {
                case 1:
                  setRadioButton(() => t('good'))
                  break
                case 2:
                  setRadioButton(() => t('regular'))
                  break
                case 3:
                  setRadioButton(() => t('bad'))
                  break
              }
              setRescueMedication(() => resCrisis.medication)
              setEpilepticCrisis(fromModelCrisis(resCrisis))
              setIsLoading(true)
              const foundDetail = listDetailsCrisis.items.find(
                (detail) => detail.epilepticalCrisisID === resCrisis.id
              )
              // console.log(listDetailsCrisis)
              if (!foundDetail) return

              epilepticCrisisDetailsService.getByID(foundDetail.id).subscribe((detailsById) => {
                if (!detailsById) return
                // console.log(detailsById)
                setDetailsById(() => fromModel(detailsById))
                const auxDetailById = fromModel(detailsById)
                setCurrentCrisisDetail(() => auxDetailById)
              })
            })
          }
        }
      })
    }
  }, [listEpilepticCrisis, listDetailsCrisis, props.id])

  useEffect(() => {
    setIsEditing(!!props.id)
    if (!props.id) return
    if (!epilepticCrisis.id) return
    epilepticCrisisDetailsService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<EpilepticalCrisisDetailsQuery>(
              'epilepticalCrisisID',
              epilepticCrisis.id
            ),
          ],
        })
      )
      .subscribe((resDetailsCrisis) => {
        setListDetailsCrisis(resDetailsCrisis)
        setArrayDetails(resDetailsCrisis.items)
      })
  }, [isLoading, props.id, epilepticCrisis.id])

  const handleDate = (event: any, type: string) => {
    if (!event) {
      return
    }

    const hour =
      type === 'symptomStartDate' ? patientSymptom.symptomStartDate : patientSymptom.finishDate
    const date = event.toDate()

    changeDate(date, hour, type)
  }

  const changeDate = (date: Date, hour: Date, type: string) => {
    // FIXME refactor this ->
    if (isNaN(new Date(date).getTime())) {
      return
    }

    date = new Date(date)
    const newHour = new Date()

    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      newHour.getHours(),
      newHour.getMinutes(),
      newHour.getSeconds()
    )

    setPatientSymptom(Object.assign({ ...patientSymptom }, { [type]: newDate }))
    if (type === 'symptomStartDate') {
      setPatientSymptomAux(Object.assign({ ...patientSymptom }, { [type]: newDate }))
    }
    if (props.id) {
      if (currentCrisisDetail.startDate && currentCrisisDetail.endDate) {
        newDate.setHours(new Date(currentCrisisDetail.startDate).getHours())
        newDate.setMinutes(new Date(currentCrisisDetail.startDate).getMinutes())
        const newStartDate = new Date(newDate)
        newDate.setHours(new Date(currentCrisisDetail.endDate).getHours())
        newDate.setMinutes(new Date(currentCrisisDetail.endDate).getMinutes())
        const newEndDate = new Date(newDate)
        const dt = Object.assign(
          { ...currentCrisisDetail },
          { startDate: new Date(newStartDate), endDate: new Date(newEndDate) }
        )
        setCurrentCrisisDetail(dt)
      }
    }
  }

  const handleChangeRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioButton(() => event.target.value)

    let moodAux: number = 0
    switch (event.target.value) {
      case t('good'):
        moodAux = Mood.good
        break
      case t('regular'):
        moodAux = Mood.regular
        break
      case t('bad'):
        moodAux = Mood.bad
        break
    }
    setEpilepticCrisis(Object.assign({ ...epilepticCrisis }, { mood: moodAux }))
  }

  const handleChangeMedication = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value)
    setRescueMedication(() => value)
    setEpilepticCrisis(Object.assign({ ...epilepticCrisis }, { medication: value }))
  }

  useEffect(() => {
    handleColorTypeCrisis()
  }, [newType])

  const handleColorTypeCrisis = () => {
    let selectedColorAux: string[]

    switch (newType.crisisType) {
      case CrisisType.low:
        selectedColorAux = [colors.green, colors.blue, colors.pink]
        break
      case CrisisType.medium:
        selectedColorAux = [colors.yellow, colors.orange, colors.highOrange]
        break
      case CrisisType.strong:
        selectedColorAux = [colors.red, colors.black]
        break
      default:
        selectedColorAux = [colors.green, colors.blue, colors.pink]
        break
    }
    setSelectedColor(selectedColorAux)
  }

  const handleIntensityCrisisType = (value: string) => {
    newType.id = uuidv4()
    newType.afterDuration = -1
    newType.afterSymptom = []
    newType.awareness = -1
    newType.userCircleID = props.userCircleID ?? ''
    newType.color = ''
    newType.comment = ''
    newType.mouth = -1
    newType.movement = -1
    newType.skinColor = -1
    newType.symptom = -1
    newType.vision = -1
    newType.zone = -1
    newType.duration = -1
    setIntensityRadioButton(value)
    let intensityCrisisTypeAux: number
    switch (value) {
      case t('low'):
        intensityCrisisTypeAux = CrisisType.low
        break
      case t('medium'):
        intensityCrisisTypeAux = CrisisType.medium
        break
      case t('strong'):
        intensityCrisisTypeAux = CrisisType.strong
        break
      default:
        intensityCrisisTypeAux = CrisisType.low
        break
    }
    setNewType(Object.assign({ ...newType }, { crisisType: intensityCrisisTypeAux }))
  }

  const handleTypeCrisis = (value: string, index: number) => {
    const colorCrisis: string = value
    let typeAux: number = 0
    setColor(value)
    setColorSelected(value)
    setCheckedState(() => new Array(otherSymptoms.length).fill(false))
    switch (value) {
      case colors.green:
        typeAux = CrisisType.low
        break
      case colors.blue:
        typeAux = CrisisType.low
        break
      case colors.pink:
        typeAux = CrisisType.low
        break
      case colors.yellow:
        typeAux = CrisisType.medium
        break
      case colors.orange:
        typeAux = CrisisType.medium
        break
      case colors.highOrange:
        typeAux = CrisisType.medium
        break
      case colors.red:
        typeAux = CrisisType.strong
        break
      case colors.black:
        typeAux = CrisisType.strong
        break
    }

    const dt = Object.assign(
      { ...arrayDetails[index] },
      {
        color: colorCrisis,
        crisisType: typeAux,
      }
    )

    if (currentCrisisDetail && props.id) {
      setCurrentCrisisDetail(
        Object.assign({ ...currentCrisisDetail }, { color: colorCrisis, crisisType: typeAux })
      )
      setNewType(Object.assign({ ...newType }, { color: colorCrisis }))
      return
    }
    setArrayDetails((prevArrayDetails) => {
      return prevArrayDetails.map((item, idx) => {
        return idx === index ? dt : item
      })
    })
    setNewType(Object.assign({ ...newType }, { color: colorCrisis }))
  }

  const changeDateDetails = (date: Date, hour: Date, type: string, index: number) => {
    date = patientSymptom.symptomStartDate
    date = new Date(date)
    if (isNaN(new Date(date).getTime())) {
      date = new Date()
    }
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hour.getHours(),
      hour.getMinutes(),
      hour.getSeconds()
    )
    if (currentCrisisDetail && props.id) {
      const dt = Object.assign({ ...currentCrisisDetail }, { [type]: newDate })
      setCurrentCrisisDetail(dt)
      return
    }
    const dt = Object.assign({ ...arrayDetails[index] }, { [type]: newDate })
    setArrayDetails((prevArrayDetails) => {
      return prevArrayDetails.map((item, idx) => {
        return idx === index ? dt : item
      })
    })
  }

  const handleTimeDetails = (event: Date, type: string, index: number) => {
    if (!event) {
      return
    }

    let date = type === 'startDate' ? arrayDetails[index].startDate : arrayDetails[index].endDate
    const hour = event

    if (!date) date = new Date()

    changeDateDetails(date, hour, type, index)
  }

  const handleObservations = (e: string, index: number) => {
    if (currentCrisisDetail && props.id) {
      setCurrentCrisisDetail(Object.assign({ ...currentCrisisDetail }, { observations: e }))
      return
    }
    setArrayDetails((prevArrayDetails) => {
      return prevArrayDetails.map((item, idx) => {
        if (idx === index) {
          // Actualizar el elemento específico en el índice 'index'
          return { ...item, observations: e }
        }
        return item
      })
    })
  }

  const handleCommentNewCrisisType = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCommentNewCrisisType(() => e.target.value)
    setNewType(Object.assign({ ...newType }, { comment: e.target.value }))
  }

  const handleDurationNewCrisisType = (value: string) => {
    setDurationNewCrisisType(value)
    const arrayCase: string[] = [t('oneMin'), t('oneToFiveMin'), t('FiveMin')]
    const arrayValue: Duration[] = [Duration.oneMin, Duration.oneToFiveMin, Duration.FiveMin]
    setNewType(
      Object.assign({ ...newType }, { duration: handlerSwitch(arrayCase, arrayValue, value) })
    )
  }

  const handleCrisisZone = (value: string) => {
    setCrisisZone(value)
    const arrayCase: string[] = [
      t('allBody'),
      t('right'),
      t('left'),
      t('extremity'),
      t('head'),
      t('undefined'),
    ]
    const arrayValue: Zone[] = [
      Zone.all,
      Zone.right,
      Zone.left,
      Zone.extremity,
      Zone.head,
      Zone.undefined,
    ]

    setNewType(Object.assign({ ...newType }, { zone: handlerSwitch(arrayCase, arrayValue, value) }))
  }

  const handlerMovement = (value: string) => {
    const arrayCase: string[] = [
      t('jolting'),
      t('rigidity'),
      t('rigidityJolting'),
      t('automatism'),
      t('hypotonia'),
      t('undefined'),
    ]
    const arrayValue: Movement[] = [
      Movement.jolting,
      Movement.rigidity,
      Movement.rigidityJolting,
      Movement.automatism,
      Movement.hypotonia,
      Movement.undefined,
    ]

    setNewType(
      Object.assign({ ...newType }, { movement: handlerSwitch(arrayCase, arrayValue, value) })
    )
  }
  const handlerVision = (value: string) => {
    const arrayCase: string[] = [
      t('eyesUp'),
      t('eyesClose'),
      t('eyesRight'),
      t('eyesLeft'),
      t('stare'),
      t('blink'),
      t('undefined'),
    ]
    const arrayValue: Vision[] = [
      Vision.eyesUp,
      Vision.eyesClose,
      Vision.eyesRight,
      Vision.eyesLeft,
      Vision.stare,
      Vision.blink,
      Vision.undefined,
    ]

    setNewType(
      Object.assign({ ...newType }, { vision: handlerSwitch(arrayCase, arrayValue, value) })
    )
  }
  const handlerSkinColor = (value: string) => {
    const arrayCase: string[] = [t('blue'), t('pale'), t('noChanges'), t('undefined')]
    const arrayValue: SkinColor[] = [
      SkinColor.blue,
      SkinColor.pale,
      SkinColor.noChanges,
      SkinColor.undefined,
    ]

    setNewType(
      Object.assign({ ...newType }, { skinColor: handlerSwitch(arrayCase, arrayValue, value) })
    )
  }

  const handlerSymptom = (value: string) => {
    const arrayCase: string[] = [
      t('urineControlLoss'),
      t('deposition'),
      t('vomiting'),
      t('undefined'),
      t('others'),
    ]
    const arrayValue: Symptom[] = [
      Symptom.urineControlLoss,
      Symptom.deposition,
      Symptom.vomiting,
      Symptom.undefined,
      Symptom.others,
    ]

    setNewType(
      Object.assign({ ...newType }, { symptom: handlerSwitch(arrayCase, arrayValue, value) })
    )
  }

  const handlerMouth = (value: string) => {
    const arrayCase: string[] = [
      t('dry'),
      t('drooling'),
      t('foam'),
      t('tongueBiting'),
      t('undefined'),
    ]
    const arrayValue: Mouth[] = [
      Mouth.dry,
      Mouth.drooling,
      Mouth.foam,
      Mouth.tongueBiting,
      Mouth.undefined,
    ]

    setNewType(
      Object.assign({ ...newType }, { mouth: handlerSwitch(arrayCase, arrayValue, value) })
    )
  }

  const handlerAfterDuration = (value: string) => {
    const arrayCase: string[] = [t('lessThanThirtyMin'), t('betweenThirtySixty'), t('OverSixty')]
    const arrayValue: AfterDuration[] = [
      AfterDuration.lessThanThirtyMin,
      AfterDuration.betweenThirtySixty,
      AfterDuration.OverSixty,
    ]

    setNewType(
      Object.assign({ ...newType }, { afterDuration: handlerSwitch(arrayCase, arrayValue, value) })
    )
  }
  const handlerAwareness = (value: string) => {
    const arrayCase: string[] = [
      t('fully'),
      t('confused'),
      t('respondsVoice'),
      t('unconscious'),
      t('undefined'),
    ]
    const arrayValue: Awareness[] = [
      Awareness.fully,
      Awareness.confused,
      Awareness.respondsVoice,
      Awareness.unconscious,
      Awareness.undefined,
    ]

    setNewType(
      Object.assign({ ...newType }, { awareness: handlerSwitch(arrayCase, arrayValue, value) })
    )
  }

  const handlerAfterSymptom = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    )
    const auxChecked = updatedCheckedState
    setCheckedState(() => auxChecked)

    const arrayOtherSymptoms: any[] = []
    updatedCheckedState.reduce((sum, currentState, index) => {
      if (currentState === true) {
        return sum + arrayOtherSymptoms.push(t(otherSymptoms[index]))
      }
      return sum
    }, 0)

    setNewType(Object.assign({ ...newType }, { afterSymptom: arrayOtherSymptoms }))
  }
  const goBackButton = () => {
    setError('')
    setArrayDetails([])
    props.detailsOpen(false)
    if (addCrisis > 0) {
      setAddCrisis(0)
      return
    }
    navigate(ROUTE_PATIENT_SYMPTOMS)
  }

  const saveNewCrisisType = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setErrorMessage('')

    const newTypeAux = newType

    if (newTypeAux.crisisType < 0 || newTypeAux.color === '') {
      setErrorMessage('La intensidad y color son obligatorios')
      return
    }

    const crisisWithSameColor = epilepticConfig.items.find((uec) => uec.color === newTypeAux.color)

    if (crisisWithSameColor) {
      newTypeAux.id = crisisWithSameColor.id
      userEpilepticCrisisConfigService.update(newTypeAux).subscribe((res) => {
        if (!res) return
        handleClose()
        handleCloseEdit()
      })
      return
    }

    newTypeAux.id = uuidv4()
    if (newTypeAux.afterSymptom.length === 0) newTypeAux.afterSymptom = [' ']
    userEpilepticCrisisConfigService.add(newTypeAux).subscribe((res) => {
      if (!res) return
      setErrorMessage('')
      // setArrayDetails((prevState) => [...prevState, details])
      handleClose()
      handleCloseEdit()
    })
  }

  const saveSymptom = (e: FormEvent<HTMLFormElement>) => {
    if (patientSymptom.symptomStartDate > new Date()) {
      setDateTimePickerError('La fecha no puede ser mayor a la fecha actual')
      return
    }
    setError('')
    e.preventDefault()

    const arrayDetailsAux: EpilepticalCrisisDetailsDTO[] = [...arrayDetails] ?? []

    if (props.id) {
      if (!currentCrisisDetail) return
      patientSymptom.symptomStartDate = new Date(patientSymptom.symptomStartDate)
      patientSymptom.finishDate = new Date(patientSymptom.finishDate)
      patientSymptom.symptomStartDate.setHours(2, 0, 1, 0)
      patientSymptom.finishDate.setHours(2, 0, 1, 0)
      if (currentCrisisDetail.startDate) {
        patientSymptom.symptomStartDate = currentCrisisDetail.startDate
      }
      if (currentCrisisDetail.endDate) {
        patientSymptom.finishDate = currentCrisisDetail.endDate
      }

      const newPatientSymptom = Object.assign({ ...patientSymptom }, { isUploaded: true })
      newPatientSymptom.symptomType = symptomType

      if (arrayDetailsAux.length > 0) {
        if (!arrayDetailsAux[0].startDate || !arrayDetailsAux[0].endDate) {
          setErrorMessage('Obligatorio fecha de inicio y fecha de fin')
          return
        }

        if (currentCrisisDetail.startDate && currentCrisisDetail.endDate) {
          const newObj = {
            ...newPatientSymptom,
            symptomStartDate: currentCrisisDetail.startDate,
            finishDate: currentCrisisDetail.endDate,
          }
          patientSymptomService.update(newObj).subscribe((res) => {
            if (!res) return
            const ecAux = epilepticCrisis
            if (props.id != null) {
              ecAux.patientSymptomID = props.id
            }
            const idCrisis = listEpilepticCrisis.items.find(
              (item) => item.patientSymptomID === res.id
            )
            if (idCrisis?.id) ecAux.id = idCrisis.id
            ecAux.userCircleID = res.userCircleID
            ecAux.date = res.symptomStartDate
            epilepticCrisisService.update(ecAux).subscribe((res) => {
              if (!res) return

              !arrayDetailsAux.length && navigate(ROUTE_PATIENT_SYMPTOMS)
              const detailsAux = currentCrisisDetail
              if (!detailsAux) return
              detailsAux.epilepticalCrisisID = res.id || ''
              epilepticCrisisDetailsService
                .update(detailsAux)
                .subscribe(() => navigate(ROUTE_PATIENT_SYMPTOMS))
            })
          })
        }
      } else {
        patientSymptom.finishDate = patientSymptom.symptomStartDate
        const newPatient = Object.assign({ ...patientSymptom }, { isUploaded: true })

        patientSymptomService.update(newPatient).subscribe((res) => {
          if (!res) return
          const ecAux = epilepticCrisis
          if (props.id != null) {
            ecAux.patientSymptomID = props.id
          }
          const idCrisis = listEpilepticCrisis.items.find(
            (item) => item.patientSymptomID === res.id
          )
          if (idCrisis?.id) ecAux.id = idCrisis.id
          ecAux.userCircleID = res.userCircleID
          ecAux.date = res.symptomStartDate
          epilepticCrisisService.update(ecAux).subscribe((res) => {
            if (!res) return
            !arrayDetailsAux.length && navigate(ROUTE_PATIENT_SYMPTOMS)
            const detailsAux = currentCrisisDetail
            if (!detailsAux) return
            detailsAux.epilepticalCrisisID = res.id || ''
            epilepticCrisisDetailsService
              .update(detailsAux)
              .subscribe(() => navigate(ROUTE_PATIENT_SYMPTOMS))
          })
        })
      }

      return
    }

    patientSymptom.symptomStartDate = patientSymptomAux.symptomStartDate
    patientSymptom.finishDate = patientSymptomAux.symptomStartDate
    if (arrayDetailsAux[0]) {
      if (!arrayDetailsAux[0].startDate || !arrayDetailsAux[0].endDate) {
        setErrorMessage('Obligatorio hora de inicio y hora de fin')
        return
      }

      arrayDetailsAux[0].startDate.setDate(new Date(patientSymptom.symptomStartDate).getDate())
      arrayDetailsAux[0].endDate.setDate(new Date(patientSymptom.finishDate).getDate())
      const hour = new Date(arrayDetailsAux[0].startDate).getHours()
      const minute = new Date(arrayDetailsAux[0].startDate).getMinutes()
      const hourEnd = new Date(arrayDetailsAux[0].endDate).getHours()
      const minuteEnd = new Date(arrayDetailsAux[0].endDate).getMinutes()

      const aux = arrayDetailsAux[0].startDate
      aux.setHours(hour)
      aux.setMinutes(minute)

      const aux2 = arrayDetailsAux[0].endDate
      aux2.setHours(hourEnd)
      aux2.setMinutes(minuteEnd)
      const newObj: PatientSymptomDTO = Object.assign(
        { ...patientSymptom },
        { symptomStartDate: aux, finishDate: aux2 }
      )
      newObj.idIcon = 'epileptic'

      if (
        Array.isArray(arrayDetailsAux) &&
        arrayDetailsAux.length > 0 &&
        arrayDetailsAux.find((item) => item.color === '')
      ) {
        setError('No se ha seleccionado tipo de crisis.')
        return
      }

      patientSymptomService.add(newObj).subscribe((res) => {
        if (!res) return
        const ecAux = epilepticCrisis
        ecAux.patientSymptomID = res.id ?? ''
        ecAux.userCircleID = res.userCircleID ?? ''
        ecAux.date = res.symptomStartDate
        epilepticCrisisService.add(ecAux).subscribe((resEpilepticCrisis) => {
          if (!resEpilepticCrisis) return

          if (!arrayDetailsAux.length) {
            navigate(ROUTE_PATIENT_SYMPTOMS)
          }

          arrayDetailsAux.forEach((details) => {
            const detailsAux = details
            detailsAux.epilepticalCrisisID = resEpilepticCrisis.id || ''
            detailsAux.id = uuidv4()
            epilepticCrisisDetailsService
              .add(detailsAux)
              .subscribe(() => navigate(ROUTE_PATIENT_SYMPTOMS))
          })
        })
      })
    } else {
      patientSymptom.symptomStartDate.setHours(2, 0, 1, 0)
      patientSymptom.idIcon = 'epileptic'
      patientSymptomService.add(patientSymptom).subscribe((res) => {
        if (!res) return
        const ecAux = epilepticCrisis
        ecAux.patientSymptomID = res.id ?? ''
        ecAux.userCircleID = res.userCircleID || ''
        ecAux.date = res.symptomStartDate
        epilepticCrisisService.add(ecAux).subscribe((resEpilepticCrisis) => {
          if (!resEpilepticCrisis) return

          if (!arrayDetailsAux.length) {
            navigate(ROUTE_PATIENT_SYMPTOMS)
            return
          }

          arrayDetailsAux.forEach((details) => {
            const detailsAux = details
            detailsAux.epilepticalCrisisID = resEpilepticCrisis.id
            detailsAux.id = uuidv4()
            epilepticCrisisDetailsService
              .add(detailsAux)
              .subscribe(() => navigate(ROUTE_PATIENT_SYMPTOMS))
          })
        })
      })
    }
  }

  const handleCrisisConfigEdit = (color: string) => {
    if (epilepticConfig.count === 0) return

    const crisisWithSameColor = epilepticConfig.items.find((uec) => uec.color === color)
    if (crisisWithSameColor) {
      newType.id = crisisWithSameColor.id
      newType.crisisType = crisisWithSameColor.crisisType
      newType.afterDuration = crisisWithSameColor.afterDuration
      newType.afterSymptom = crisisWithSameColor.afterSymptom
      newType.awareness = crisisWithSameColor.awareness
      newType.userCircleID = crisisWithSameColor.userCircleID
      newType.color = crisisWithSameColor.color
      newType.comment = crisisWithSameColor.comment
      newType.mouth = crisisWithSameColor.mouth
      newType.movement = crisisWithSameColor.movement
      newType.skinColor = crisisWithSameColor.skinColor
      newType.symptom = crisisWithSameColor.symptom
      newType.vision = crisisWithSameColor.vision
      newType.zone = crisisWithSameColor.zone
      newType.duration = crisisWithSameColor.duration

      let find: string[] = []
      find = otherSymptoms.filter((os) => newType.afterSymptom.includes(t(os)))
      const findIndex: any[] = []
      let updatedCheckedState: any[] = []
      let auxChecked: any[] = []

      find.forEach((os, i) => {
        const find2 = (element: any) => {
          return element === find[i]
        }
        findIndex.push(otherSymptoms.findIndex(find2))
      })

      updatedCheckedState = checkedState.map((item, index) => {
        return findIndex.includes(index) ? !item : item
      })

      auxChecked = updatedCheckedState
      setCheckedState(() => auxChecked)
    } else {
      newType.id = uuidv4()
      newType.afterDuration = -1
      newType.afterSymptom = []
      newType.awareness = -1
      newType.userCircleID = props.userCircleID ?? ''
      newType.color = ''
      newType.comment = ''
      newType.mouth = -1
      newType.movement = -1
      newType.skinColor = -1
      newType.symptom = -1
      newType.vision = -1
      newType.zone = -1
      newType.duration = -1
    }
  }

  useEffect(() => {
    handleCrisisConfigEdit(colorSelected)
  }, [colorSelected])

  const handlerNewCrisisDetails = () => {
    props.detailsOpen(true)
    const cleanDetails = emptyEpilepticalCrisisDetails()
    cleanDetails.startDate = undefined
    cleanDetails.endDate = undefined

    setArrayDetails((prevState) => [...prevState, cleanDetails])
    setAddCrisis(() => addCrisis + 1)
  }

  const handlerEditCrisis = () => {
    props.detailsOpen(true)
    setOpenEditCrisis(true)
  }

  const editCrisis = () => {
    if (!currentCrisisDetail) return

    return (
      <Details
        isEditing={isEditing}
        patientSymptom={patientSymptom}
        epilepticalCrisisID={epilepticCrisis.id}
        handleTypeCrisis={handleTypeCrisis}
        handleTime={handleTimeDetails}
        handleObservations={handleObservations}
        index={0}
        handleClose={handleClose}
        errorMessage={errorMessage}
        observations={currentCrisisDetail.observations}
        startDate={currentCrisisDetail.startDate}
        endDate={currentCrisisDetail.endDate}
        color={currentCrisisDetail.color}
        type={currentCrisisDetail.crisisType}
        epilepticConfig={epilepticConfig}
        handleOpen={handleOpen}
        handleOpenEdit={handleOpenEdit}
      />
    )
  }

  const addNewCrisis = () => {
    return (
      <>
        {[...Array(addCrisis)].map((k, i) => (
          <Details
            key={i}
            isEditing={isEditing}
            patientSymptom={patientSymptom}
            epilepticalCrisisID={epilepticCrisis.id}
            handleTypeCrisis={handleTypeCrisis}
            handleTime={handleTimeDetails}
            handleObservations={handleObservations}
            index={i}
            handleClose={handleClose}
            errorMessage={errorMessage}
            observations={arrayDetails[i]?.observations}
            startDate={arrayDetails[i]?.startDate}
            endDate={arrayDetails[i]?.endDate}
            color={arrayDetails[i]?.color}
            type={arrayDetails[i]?.crisisType}
            epilepticConfig={epilepticConfig}
            handleOpen={handleOpen}
            handleOpenEdit={handleOpenEdit}
          />
        ))}
      </>
    )
  }

  const handlerCurrentCrisis = (currentCrisisDetails: EpilepticalCrisisDetails) => {
    const aux = fromModel(currentCrisisDetails)
    setCurrentCrisisDetail(aux)
  }

  useEffect(() => {
    addNewCrisis()
  }, [])
  // console.log(detailsById)
  return (
    <Box>
      {addCrisis > 0 ? (
        <>{addNewCrisis()}</>
      ) : openEditCrisis ? (
        editCrisis()
      ) : (
        <>
          <Box className={styles.containerDate}>
            <Typography className={styles.labelDate}>{t('startDate')}*</Typography>
            <Box style={{ borderWidth: '1px', borderColor: 'black' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                <DatePicker
                  key={'date'}
                  PopperProps={{
                    sx: {
                      '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize',
                      },
                      '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                        fontFamily: 'Poppins',
                      },
                      '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                        fontFamily: 'Poppins',
                      },
                      '& .css-3eghsz-PrivatePickersYear-button': {
                        fontFamily: 'Poppins',
                      },
                    },
                  }}
                  onError={(reason, value) => {
                    switch (reason) {
                      case 'invalidDate':
                        setDateTimePickerError(t('invalidDateMessage'))
                        break
                      case 'minDate':
                        setDateTimePickerError(t('minDateMessage'))
                        break
                    }
                  }}
                  renderInput={(props) => (
                    <TextField
                      sx={{
                        '.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input': {
                          fontFamily: 'Poppins',
                        },
                      }}
                      style={{ marginBottom: 8 }}
                      size={'small'}
                      helperText={props.error && DateTimePickerError}
                      {...props}
                    />
                  )}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => handleDate(e, 'symptomStartDate')}
                  value={patientSymptom.symptomStartDate}
                  disableFuture={true}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box
            className={
              innerWidth > 598
                ? styles.ContainterRadioAndInput
                : styles.ContainterRadioAndInputMobile
            }
          >
            <FormControl className={innerWidth > 598 ? styles.formRadioButton : ''}>
              <FormLabel style={{ fontFamily: 'Poppins' }} component="legend">
                ¿Cómo fue el día?
              </FormLabel>
              <RadioGroup
                style={{ fontFamily: 'Poppins' }}
                aria-label="gender"
                name="gender1"
                value={radioButton ?? ''}
                onChange={handleChangeRadioButton}
              >
                <FormControlLabel
                  sx={{ fontFamily: 'Poppins' }}
                  value={t('good')}
                  control={<Radio />}
                  label={t('good')}
                />
                <FormControlLabel
                  sx={{ fontFamily: 'Poppins' }}
                  value={t('regular')}
                  control={<Radio />}
                  label={t('regular')}
                />
                <FormControlLabel
                  sx={{ fontFamily: 'Poppins' }}
                  value={t('bad')}
                  control={<Radio />}
                  label={t('bad')}
                />
              </RadioGroup>
            </FormControl>
            <Box
              className={
                innerWidth > 598 ? styles.ContainerTextField : styles.ContainerTextFieldMobile
              }
            >
              <Typography style={{ fontFamily: 'Poppins' }}>
                Total de medicación de rescate administrada en el día
              </Typography>
              <TextField
                sx={{
                  '.MuiInputBase-input': {
                    fontFamily: 'Poppins',
                  },
                  '.MuiInputLabel-outlined': {
                    fontFamily: 'Poppins',
                  },
                }}
                defaultValue={undefined}
                type="number"
                value={rescueMedication}
                fullWidth={true}
                InputProps={{
                  inputProps: { min: 1 },
                }}
                onChange={(value) => handleChangeMedication(value)}
                id="outlined-basic"
                label={(rescueMedication ?? rescueMedication === 0) ? '' : 'Num'}
                variant="outlined"
              />
            </Box>
          </Box>
          {detailsById ? (
            <>
              <h4 style={{ borderBottom: '2px solid #9d9d9d' }}>Detalles de la crisis</h4>
              <Table
                id={props.id}
                detailId={detailsById}
                handlerEditCrisis={handlerEditCrisis}
                handlerCurrentCrisis={handlerCurrentCrisis}
              />
            </>
          ) : (
            ''
          )}
          {!props.id && (
            <Box className={styles.addDetailsCrisisButton}>
              <AppButton
                theme={ButtonTheme.BasicPrimary}
                label={'Añadir detalle de crisis'}
                handler={() => {
                  handlerNewCrisisDetails()
                }}
                type={'button'}
              />
            </Box>
          )}
        </>
      )}
      {error && <p className={styles.error}>{error}</p>}
      <Box className={styles.saveButton}>
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={goBackButton}
        />
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'button'}
          label={t('save')}
          handler={saveSymptom}
        />
      </Box>
      {open ? (
        <ModalNewCrisis
          open={open}
          handleClose={handleClose}
          handleTypeCrisis={handleTypeCrisis}
          handleIntensityCrisisType={handleIntensityCrisisType}
          handleColorTypeCrisis={handleColorTypeCrisis}
          handleCommentNewCrisisType={handleCommentNewCrisisType}
          handleDurationNewCrisisType={handleDurationNewCrisisType}
          handleCrisisZone={handleCrisisZone}
          saveNewCrisisType={saveNewCrisisType}
          handlerMovement={handlerMovement}
          handlerVision={handlerVision}
          handlerSkinColor={handlerSkinColor}
          handlerSymptom={handlerSymptom}
          handlerMouth={handlerMouth}
          handlerAfterDuration={handlerAfterDuration}
          handlerAfterSymptom={handlerAfterSymptom}
          handlerAwareness={handlerAwareness}
          otherSymptoms={otherSymptoms}
          checkedState={checkedState}
          durationNewCrisisType={durationNewCrisisType}
          commentNewCrisisType={commentNewCrisisType}
          crisisZone={crisisZone}
          color={color}
          index={0}
          newType={newType}
          selectedColor={selectedColor}
          intensityRadioButton={intensityRadioButton}
          innerWidth={innerWidth}
          errorMessage={errorMessage}
          handleEdit={handleCrisisConfigEdit}
          epilepticConfig={epilepticConfig}
          setColorSelected={setColorSelected}
          colorSelected={colorSelected}
        />
      ) : (
        ''
      )}
      {openEdit ? (
        <ModalEditCrisis
          open={openEdit}
          handleClose={handleCloseEdit}
          handleTypeCrisis={handleTypeCrisis}
          handleIntensityCrisisType={handleIntensityCrisisType}
          handleColorTypeCrisis={handleColorTypeCrisis}
          handleCommentNewCrisisType={handleCommentNewCrisisType}
          handleDurationNewCrisisType={handleDurationNewCrisisType}
          handleCrisisZone={handleCrisisZone}
          saveNewCrisisType={saveNewCrisisType}
          handlerMovement={handlerMovement}
          handlerVision={handlerVision}
          handlerSkinColor={handlerSkinColor}
          handlerSymptom={handlerSymptom}
          handlerMouth={handlerMouth}
          handlerAfterDuration={handlerAfterDuration}
          handlerAfterSymptom={handlerAfterSymptom}
          handlerAwareness={handlerAwareness}
          otherSymptoms={otherSymptoms}
          checkedState={checkedState}
          durationNewCrisisType={durationNewCrisisType}
          commentNewCrisisType={commentNewCrisisType}
          crisisZone={crisisZone}
          color={color}
          index={0}
          newType={newType}
          selectedColor={selectedColor}
          intensityRadioButton={intensityRadioButton}
          innerWidth={innerWidth}
          errorMessage={errorMessage}
          handleEdit={handleCrisisConfigEdit}
          epilepticConfig={epilepticConfig}
          setColorSelected={setColorSelected}
          colorSelected={colorSelected}
        />
      ) : (
        ''
      )}
    </Box>
  )
}
