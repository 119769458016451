import { AppBar, Box, Grid, Tab, Tabs, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GenericChart } from '../../../components/generic-chart/GenericChart'
import styles from './EvolutionControl.module.css'
import genericStyle from '../../../common/utils/generic.module.css'
import { GrowthMonitoringQuery } from '../../../modules/patient-data/models/GrowthMonitoring'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { GrowthMonitoringService } from '../../../modules/patient-data/services/GrowthMonitoringService'
import {
  GROWTH_MONITORING_API_KEY,
  PATIENT_DATA_API_KEY,
} from '../../../modules/patient-data/container'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam } from '../../../common/api/Query'
import dayjs from 'dayjs'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { Header } from '../../../components/header/Header'
import { PercentileOrganization } from '../../../modules/patient-data/enums/PercentileOrganization'
import {
  GrowthMonitoringType,
  growthMonitoringTypes,
} from '../../../modules/patient-data/enums/GrowthMonitoringType'
import { PatientDataService } from '../../../modules/patient-data/services/PatientDataService'
import { filterPercentiles } from './percentiles/percentiles'
import { getUserCircleContainer } from '../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../modules/user-circle'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { ROUTE_PATIENT_DATA } from '../../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'

const patientDataContainer = getPatientDataContainer()
const growthMonitoringService =
  patientDataContainer.get<GrowthMonitoringService>(GROWTH_MONITORING_API_KEY)
const patientDataService = getPatientDataContainer().get<PatientDataService>(PATIENT_DATA_API_KEY)
const growthMonitoringType = growthMonitoringTypes()

type DataWPercentile = {
  x: number
  y: number
  percentile: number
}

type Data = {
  x: number
  y: number
}

type DataType = {
  borderColor: string
  borderWidth: number
  data: Data[]
  label: string
  pointRadius: number
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    fontFamily: 'Poppins',
  },
}))

const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export function EvolutionControl() {
  const { t } = useTranslation()
  // const circle = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY).getActiveUser()
  const activeUser = UserCircleActiveService.getActiveFullUserCircle()
  const bDay = activeUser?.user.birthDate
  const classes = useStyles()
  const [Type, setType] = useState<string>('peso ')
  const [Gender, setGender] = useState<string>('niños')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [title, setTitle] = useState<string>('Tabla percentiles de ')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [chartPercentileOMS, setChartPercentileOMS] = useState<DataType[]>([])
  const [chartPercentileOrb, setChartPercentileOrb] = useState<DataType[]>([])
  const [userDataOms, setUserDataOms] = useState<DataType[]>([])
  const [userDataOrb, setUserDataOrb] = useState<DataType[]>([])
  const [omiDataOms, setOmiDataOms] = useState<DataType[]>([])
  const [omiDataOrb, setOmiDataOrb] = useState<DataType[]>([])
  const isSmallScreen = useMediaQuery('(max-width: 599px)');
  
  const [value, setValue] = useState<number>(isSmallScreen ? -1 : 0);
  const [userDataLoading, setUserDataLoading] = useState<boolean>(true)
  const [omiDataLoading, setOmiDataLoading] = useState<boolean>(true)
  const [tableSelected, setTableSelected] = useState<string>('518years')
  const [minOMS, setMinOMS] = useState<number>(0)
  const [maxOMS, setMaxOMS] = useState<number>(0)
  const [minORB, setMinORB] = useState<number>(0)
  const [maxORB, setMaxORB] = useState<number>(0)
  const navigate = useNavigate()

  const monthsDiff = (d1: Date, d2: Date) => {
    let months = (d2.getFullYear() - d1.getFullYear()) * 12
    months -= d1.getMonth()
    months += d2.getMonth()
    return months <= 0 ? 0 : months
  }

  const handleChangeTypeofTable = (value: string) => {
    setTableSelected(value)

    switch (value) {
      case '02years':
        setMinOMS(0)
        setMaxOMS(24)
        setMinORB(0)
        setMaxORB(24)
        break
      case '25years':
        setMinOMS(0)
        setMaxOMS(60)
        setMinORB(0)
        setMaxORB(60)
        break
      case '518years':
        setMinOMS(60)
        setMaxOMS(216)
        setMinORB(60)
        setMaxORB(216)
        break
      default:
        setMinOMS(60)
        setMaxOMS(216)
        setMinORB(60)
        setMaxORB(216)
        break
    }
    setIsLoading(true)
  }

  useEffect(() => {
    if (activeUser) {
      const months = monthsDiff(new Date(activeUser.user.birthDate), new Date())

      switch (true) {
        case months <= 24:
          setTableSelected('02years')
          setMinOMS(0)
          setMaxOMS(24)
          setMinORB(0)
          setMaxORB(24)
          break
        case months > 24 && months <= 60:
          setTableSelected('25years')
          setMinOMS(0)
          setMaxOMS(60)
          setMinORB(0)
          setMaxORB(60)
          break
        case months > 60 && months <= 216:
          setTableSelected('518years')
          setMinOMS(60)
          setMaxOMS(216)
          setMinORB(60)
          setMaxORB(216)
          break
        default:
          setMinOMS(60)
          setMaxOMS(216)
          setMinORB(60)
          setMaxORB(216)
          break
      }
    }
  }, [activeUser])

  useEffect(() => {
    if (isLoading) {
      growthMonitoringService
        .getFilteredList(
          new Query({
            query: [new QueryParam<GrowthMonitoringQuery>('userID', activeUser?.user.id ?? '')],
          })
        )
        .subscribe((res) => {
          const axisOMS: Data[] = []
          const axisOrb: Data[] = []
          const chartOMS: DataType[] = [
            {
              label: activeUser?.user.name ?? '',
              data: [],
              borderWidth: 3,
              borderColor: '#68B3E0',
              pointRadius: 2,
            },
          ]
          const chartOrb: DataType[] = [
            {
              label: activeUser?.user.name ?? '',
              data: [],
              borderWidth: 3,
              borderColor: '#68B3E0',
              pointRadius: 2,
            },
          ]

          res.items.forEach((g) => {
            const xAxis = dayjs(g.date).diff(dayjs(bDay), 'month')
            const yAxis = parseFloat((Math.round(g.value * 10) / 10).toFixed(1))
            if (xAxis >= minOMS && xAxis <= maxOMS && g.type === value + 1)
              axisOMS.push({ x: xAxis, y: yAxis })
            if (xAxis >= minORB && xAxis <= maxORB && g.type === value + 1)
              axisOrb.push({ x: xAxis, y: yAxis })
          })

          chartOMS[0].data = axisOMS
          chartOrb[0].data = axisOrb

          setUserDataLoading(false)
          setUserDataOms(chartOMS)
          setUserDataOrb(chartOrb)
        })
      activeUser &&
        patientDataService.getByCIPA(activeUser.user.cip).subscribe((res) => {
          const axisOMS: Data[] = []
          const axisOrb: Data[] = []
          const chartOMS: DataType[] = [
            {
              label: activeUser?.user.name + ' SMS' || '',
              data: [],
              borderWidth: 3,
              borderColor: '#9459da',
              pointRadius: 2,
            },
          ]
          const chartOrb: DataType[] = [
            {
              label: activeUser?.user.name + ' SMS' || '',
              data: [],
              borderWidth: 3,
              borderColor: '#9459da',
              pointRadius: 2,
            },
          ]

          if (res) {
            const claves = Object.entries(res)
            claves.forEach((n) => {
              if (value === 0) {
                if (n[1].weight) {
                  const xAxis = dayjs(n[1].weightDate).diff(dayjs(bDay), 'month')
                  const yAxis = parseFloat((Math.round(n[1].weight * 10) / 10).toFixed(1))
                  if (xAxis >= minOMS && xAxis <= maxOMS) axisOMS.push({ x: xAxis, y: yAxis })
                  if (xAxis <= maxORB && xAxis >= minORB) axisOrb.push({ x: xAxis, y: yAxis })
                }
              }
              if (value === 1) {
                if (n[1].height) {
                  const xAxis = dayjs(n[1].heightDate).diff(dayjs(bDay), 'month')
                  const yAxis = parseFloat((Math.round(n[1].height * 10) / 10).toFixed(1))
                  if (xAxis >= minOMS && xAxis <= maxOMS) axisOMS.push({ x: xAxis, y: yAxis })
                  if (xAxis <= maxORB && xAxis >= minORB) axisOrb.push({ x: xAxis, y: yAxis })
                }
              }
              if (value === 2) {
                if (n[1].cranialPerimeter) {
                  const xAxis = dayjs(n[1].cranialPerimeterDate).diff(dayjs(bDay), 'month')
                  const yAxis = parseFloat((Math.round(n[1].cranialPerimeter * 10) / 10).toFixed(1))
                  if (xAxis >= minOMS && xAxis <= maxOMS) axisOMS.push({ x: xAxis, y: yAxis })
                  if (xAxis <= maxORB && xAxis >= minORB) axisOrb.push({ x: xAxis, y: yAxis })
                }
              }
            })
          }

          chartOMS[0].data = axisOMS
          chartOrb[0].data = axisOrb

          setOmiDataLoading(false)
          setOmiDataOms(chartOMS)
          setOmiDataOrb(chartOrb)
        })
    }
  }, [isLoading, value, tableSelected])

  useEffect(() => {
    if (!activeUser?.user.gender || userDataLoading || omiDataLoading) {
      return
    }

    if (activeUser?.user.gender === 1) setGender('niñas')

    /* filterPercentiles(
      [PercentileOrganization.OMS, PercentileOrganization.Orbegozo],
      value + 1,
      circle.gender
    ).subscribe((res) => { */

    filterPercentiles(
      [PercentileOrganization.OMS, PercentileOrganization.Orbegozo],
      value + 1,
      activeUser?.user.gender
    ).forEach((dt, i) => {
      const data: DataWPercentile[] = []
      dt.forEach((p) => {
        if (i === 0 && p.month >= minOMS && p.month <= maxOMS) {
          data.push({
            x: p.month,
            y: p.value,
            percentile: p.percentile,
          })
        } else if (i === 1 && p.month >= minORB && p.month <= maxORB) {
          data.push({
            x: p.month,
            y: p.value,
            percentile: p.percentile,
          })
        }
      })
      const percentiles = new Set<number>()
      data.forEach((d) => percentiles.add(d.percentile))

      const dataByPercentiles = new Map<number, Data[]>()

      if (i === 0) {
        Array.from(percentiles.values()).forEach((p) => {
          const dataFiltered = data
            .filter((d) => d.percentile === p && d.x >= minOMS && d.x <= maxOMS)
            .map((d) => {
              return {
                x: d.x,
                y: d.y,
              }
            })
          dataByPercentiles.set(p, dataFiltered)
        })
      } else {
        Array.from(percentiles.values()).forEach((p) => {
          const dataFiltered = data
            .filter((d) => d.percentile === p && d.x <= maxORB && d.x >= minORB)
            .map((d) => {
              return {
                x: d.x,
                y: d.y,
              }
            })
          dataByPercentiles.set(p, dataFiltered)
        })
      }

      const dataByPercentilesToDisplay: DataType[] = []

      if (i === 0) {
        const percentilesDisplayed: number[] = [3, 15, 50, 85, 97]
        percentilesDisplayed.forEach((pd, i) => {
          const dataType: DataType = {
            borderColor: handleOmsColors(i),
            borderWidth: 1,
            data: dataByPercentiles.get(pd) ?? [],
            label: t('percentile') + ' ' + pd,
            pointRadius: 0,
          }
          dataByPercentilesToDisplay.push(dataType)
        })
        setChartPercentileOMS([...dataByPercentilesToDisplay, ...userDataOms, ...omiDataOms])
      } else if (i === 1) {
        const percentilesDisplayed: number[] = [3, 10, 25, 50, 75, 90, 97]
        percentilesDisplayed.forEach((pd, i) => {
          const dataType: DataType = {
            borderColor: handleOrbColors(i),
            borderWidth: 1,
            data: dataByPercentiles.get(pd) ?? [],
            label: t('percentile') + ' ' + pd,
            pointRadius: 0,
          }
          dataByPercentilesToDisplay.push(dataType)
        })
        setChartPercentileOrb([...dataByPercentilesToDisplay, ...userDataOrb, ...omiDataOrb])
      }
    })
    setIsLoading(false)
    /* }) */
  }, [userDataOrb, omiDataOrb])

  useEffect(() => {
    percentileCalc([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], 6)
  }, [chartPercentileOMS])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setIsLoading(true)
    if (newValue === 0) setType('peso ')
    if (newValue === 1) setType('altura ')
    if (newValue === 2) setType('perimetro craneal ')
    setValue(newValue)
  }

  const extractMonthsFromChart = (chartPercentile: DataType[]): number[] => {
    const set = new Set<number>()

    chartPercentile.forEach((cps) => {
      cps.data.forEach((cp: Data) => {
        set.add(cp.x)
      })
    })
    return Array.from(set).sort((a, b) => a - b)
  }

  const handleOmsColors = (percentileIndex: number): string => {
    switch (percentileIndex) {
      case 0:
        return '#ED1C24'
      case 1:
        return '#F5821F'
      case 2:
        return '#00A650'
      case 3:
        return '#F5821F'
      case 4:
        return '#ED1C24'
      default:
        return ''
    }
  }

  const handleOrbColors = (percentileIndex: number): string => {
    switch (percentileIndex) {
      case 0:
        return '#470611'
      case 1:
        return '#ED1C24'
      case 2:
        return '#F5821F'
      case 3:
        return '#00A650'
      case 4:
        return '#F5821F'
      case 5:
        return '#ED1C24'
      case 6:
        return '#470611'
      default:
        return ''
    }
  }

  const percentileCalc = (arr: number[], val: number) =>
    (100 * arr.reduce((acc, v) => acc + (v < val ? 1 : 0) + (v === val ? 0.5 : 0), 0)) / arr.length

  return (
    <Box className={genericStyle.pageContainer}>
      { value !== -1 && 
        <Box mb={3} display={'flex'} justifyContent={'space-between'}>
          <AppButton
            theme={ButtonTheme.NewSecondary}
            type={'button'}
            label={t('back')}
            handler={(e) => handleChange(e,-1)}
          />
        </Box>
      }
      {!isSmallScreen && (
        <Box style={{ marginBottom: '50px' }}>
        <Header label={t('evolutionChart')} />
        </Box>
      )}
      <Box className={styles.evolutionControl}>
        <div className={classes.root}>
          { !isSmallScreen && (
            <AppBar position="static" className={styles.caltabs}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              className={styles.caltabs}
            >
              {Object.keys(growthMonitoringType).map((gmt, i) => (
                <Tab
                  key={gmt}
                  label={
                    <span className={styles.tabLabel}>
                      {t(growthMonitoringType[gmt as unknown as GrowthMonitoringType])}
                    </span>
                  }
                  className={value === i ? styles.parsetabsActive : styles.parsetabs}
                />
              ))}
              {/* <Tab key={"tab-menu"} /> */}
            </Tabs>
            </AppBar>
          )}
          <Box>
            {!isLoading && value == -1 &&
              <Box className={styles.mobileNav}>
                  <AppButton
                    label={t('evolutionControlWeight')}
                    theme={ButtonTheme.whiteAndBlue}
                    handler={(e) => handleChange(e,0)}
                    type={'button'}
                    fullWidth
                  />
                  <AppButton
                    label={t('evolutionControlHeight')}
                    theme={ButtonTheme.whiteAndBlue}
                    handler={(e) => handleChange(e,1)}
                    type={'button'}
                    fullWidth
                  />
                  <AppButton
                    label={t('evolutionControlSkullPerimeter')}
                    theme={ButtonTheme.whiteAndBlue}
                    handler={(e) => handleChange(e,2)}
                    type={'button'}
                    fullWidth
                  />
              </Box>
            }
            {!isLoading && value > -1 ? (
              <>
                <Box>
                  <Box
                    
                  >
                    <h4 className={styles.titleStyle}>Seleccionar rango de edad:</h4>
                  </Box>
                  <Box className={styles.gridContainer}>
                    <Box className={styles.gridItem}>
                      <input
                        type="radio"
                        checked={tableSelected === '02years'}
                        onClick={() => handleChangeTypeofTable('02years')}
                      />{' '}
                      0-2 {t('years')}
                    </Box>
                    <Box className={styles.gridItem}>
                      <input
                        type="radio"
                        checked={tableSelected === '25years'}
                        onClick={() => handleChangeTypeofTable('25years')}
                      />{' '}
                      0-5 {t('years')}
                    </Box>
                    <Box className={styles.gridItem}>
                      <input
                        type="radio"
                        checked={tableSelected === '518years'}
                        onClick={() => handleChangeTypeofTable('518years')}
                      />{' '}
                      5-18 {t('years')}
                    </Box>
                  </Box>
                </Box>
                <Box
                  className={styles.generalContainer}
                >
                  {[chartPercentileOMS, chartPercentileOrb].map((chart, i) =>
                    (tableSelected === '518years' && i === 0) ||
                    (tableSelected === '02years' && i === 1) ? (
                      <></>
                    ) : (
                      <Box
                        key={i}
                        className={styles.chartContainer}
                      >
                        <GenericChart
                          title={
                            i === 0
                              ? title +
                                Type +
                                Gender +
                                ' entre ' +
                                minOMS / 12 +
                                ' y ' +
                                maxOMS / 12 +
                                ' años OMS'
                              : title +
                                Type +
                                Gender +
                                ' entre ' +
                                minORB / 12 +
                                ' y ' +
                                maxORB / 12 +
                                ' años Orbegozo'
                          }
                          type="line"
                          data={chart}
                          labelX={extractMonthsFromChart(chart)}
                          xAxisLabel={t('month')}
                          yAxisLabel={value === 0 ? 'Kgs' : 'Cm'}
                          showTitle={true}
                          showLegend={true}
                          showTooltip={true}
                        />
                      </Box>
                    )
                  )}
                </Box>
              </>
            ) : null}
          </Box>
        </div>
      </Box>
    </Box>
  )
}
