// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Main_mainContainer__3gnaX {\n  display: flex;\n  flex-direction: column;\n  flex-basis: 100%;\n  height: 100%;\n  max-height: 100%;\n  width: 100%;\n  max-width: 100%;\n  overflow: auto;\n  background-color: #fafafb;\n}\n\n/* TO AVOID SET PADDING IN ALL FEATURES WITHOUT NEED TO PUT CSS IN ROUTER */\n/* IT'S SET PADDING IN MAIN CONTAINER CHILD */\n.Main_mainContainer__3gnaX > * > * {\n  padding: 1.5rem;\n}\n\n@media(max-width: 598px){\n  .Main_mainContainer__3gnaX{\n    overflow-y: auto;\n    overflow-x: hidden;\n  }\n  .Main_mainContainer__3gnaX > * > * {\n    padding: 0.5rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/pages/layout/Main.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,cAAc;EACd,yBAAyB;AAC3B;;AAEA,2EAA2E;AAC3E,6CAA6C;AAC7C;EACE,eAAe;AACjB;;AAEA;EACE;IACE,gBAAgB;IAChB,kBAAkB;EACpB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".mainContainer {\n  display: flex;\n  flex-direction: column;\n  flex-basis: 100%;\n  height: 100%;\n  max-height: 100%;\n  width: 100%;\n  max-width: 100%;\n  overflow: auto;\n  background-color: #fafafb;\n}\n\n/* TO AVOID SET PADDING IN ALL FEATURES WITHOUT NEED TO PUT CSS IN ROUTER */\n/* IT'S SET PADDING IN MAIN CONTAINER CHILD */\n.mainContainer > * > * {\n  padding: 1.5rem;\n}\n\n@media(max-width: 598px){\n  .mainContainer{\n    overflow-y: auto;\n    overflow-x: hidden;\n  }\n  .mainContainer > * > * {\n    padding: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "Main_mainContainer__3gnaX"
};
export default ___CSS_LOADER_EXPORT___;
