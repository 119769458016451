import { Alert, Box, FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam } from 'common/api/Query'
import { ProfessionalType } from 'modules/users/models/ProfessionalType'
import genericStyle from 'common/utils/generic.module.css'
import style from './Table.module.css'
import { getUserContainer } from 'container/user-module'
import { getMessengerConsultaContainer } from 'container/messenger-consulta-module'
import { PROFESSIONALTYPE_SERVICE_KEY } from 'modules/users'
import { AUTOMESSAGE_SERVICE_KEY, IAutoMessageService } from 'modules/messenger_consulta'
import { ProfessionalTypesService } from 'modules/users/services/ProfessionalTypeService'
import { Header } from 'components/header/Header'
import { TextFieldItem } from 'components/form-card/TextFieldItem'
import { emptyAutoMessageDTO, toModel } from 'modules/messenger_consulta/models/AutoMessageDTO'
import { AutoMessage } from 'modules/messenger_consulta/models/AutoMessage'
import { useSnackbar } from 'notistack'
import { getCircleContainer } from 'container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from 'modules/circle'
import { CircleQuery } from 'modules/circle/models/Circle'
import { CircleDTO } from 'modules/circle/models/CircleDTO'
import { ROUTE_CONFIGURATION } from 'routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { finalize } from 'rxjs/operators'
import { forkJoin } from 'rxjs'

const userContainer = getUserContainer()
const messageContainer = getMessengerConsultaContainer()
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)
const autoMessageService = messageContainer.get<IAutoMessageService>(AUTOMESSAGE_SERVICE_KEY)

type UserProps = {
  id?: string
}

export function Table(props: UserProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingMsg, setIsLoadingMsg] = useState<boolean>(false)
  const [isPath, setIsPath] = useState<boolean>(true)
  const [related, setRelated] = useState<boolean>(true)
  const [professional, setProfessional] = useState<ProfessionalType[]>()
  const [autoMessage, setAutoMessage] = useState<AutoMessage[]>()
  const [setProf, setSetProf] = useState<string>('')
  const setProfR = useRef('')
  const [professionalType, setProfessionalType] = useState<ProfessionalType>()
  const [setPath, setSetPath] = useState<string>('')
  const setPathR = useRef('')
  const [circle, setCircle] = useState<CircleDTO[]>([])
  const [circleAux, setCircleAux] = useState<CircleDTO[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (!professionalType) return
    circleService
      .getFilteredList(
        new Query<CircleQuery>({
          query: [new QueryParam<CircleQuery>('specialtyID', professionalType?.name)],
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        setCircle(res.items)
      })
  }, [professionalType])

  const getData = () => {
    setIsLoading(true)

    const autoMessage = autoMessageService.getFilteredList(new Query({}))
    const circle = circleService.getFilteredList(new Query<CircleQuery>({}))
    const prof = professionalTypeService.getAll(
      new Query({
        sort: [{ field: 'name' }],
      })
    )

    forkJoin([autoMessage, circle, prof])
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe(([resMessage, resCircle, resProf]) => {
        setAutoMessage(resMessage.items)
        setCircleAux(resCircle.items)
        setProfessional(resProf)
      })
  }

  const handlerChangeProfessional = (event: any) => {
    setSetProf(event.target.value)
    setProfR.current = event.target.value
    setSetPath('')
    setPathR.current = ''
    setRelated(false)
    setIsPath(true)
    setProfessionalType(professional?.find((e) => e.id === event.target.value))
  }

  const handlerChangePathologie = (event: any) => {
    setSetPath(event.target.value)
    setPathR.current = event.target.value
    setIsPath(false)
  }

  const handlerChangeMessage = (id: number, event: any) => {
    let arrObj = autoMessage
    if (autoMessage) {
      arrObj = [...autoMessage]
      arrObj[id].message = event.target.value
    }

    setAutoMessage(arrObj)
  }

  const deleteMessage = (id: string) => {
    autoMessageService.delete(id).subscribe((res) => {
      enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })

      const arr: any[] = []

      autoMessage?.forEach((element, index) => {
        if (element.id !== id) {
          arr.push(element)
        }
      })
      setAutoMessage(arr)
    })
  }

  const validateExistThisPathology = (id: string) => {
    let value = false
    autoMessage?.forEach((element) => {
      if (element.professional === setProf && element.pathology === id) {
        setErrorMessage('thisPathologyAlreadyExists')
        value = true
      }
    })
    return value
  }
  const addNewMessage = () => {
    if (validateExistThisPathology(setPath)) return
    let arr: any[] = []
    if (autoMessage) {
      arr = autoMessage
    }

    const newMessage: AutoMessage = toModel({
      id: emptyAutoMessageDTO().id,
      professional: setProf,
      pathology: setPath,
      message: '',
    })
    const newAutoMessage = [...(autoMessage ?? []), newMessage]
    setAutoMessage(newAutoMessage)
  }

  const handlerSave = () => {
    autoMessage?.forEach((element: any, index: number) => {
      autoMessageService.add(element).subscribe((res) => {
        enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
      })
    })
  }

  const goBack = () => navigate(ROUTE_CONFIGURATION)

  const filter = (a: string, b: any) => {
    let c = ''
    if (a === 'professional') {
      professional?.forEach((element) => {
        if (element.id === b.professional) {
          c = element.name
        }
      })
    } else if (a === 'pathology') {
      circleAux?.forEach((element) => {
        if (element.id === b.pathology) {
          c = element.name
        }
      })
    }
    return c
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <Box className={style.headerBox}>
        <Header label={t('automaticMessage')} />
      </Box>
      <Box className={style.container}>
        {isLoading && <Skeleton style={{ width: '100%', minHeight: '20px' }} />}
        {!isLoading && (
          <>
            <FormControl size="small" variant="outlined" className={style.formControl}>
              <InputLabel id="professional" style={{ zIndex: 0 }}>
                {t('profType')}
              </InputLabel>
              <Select
                labelId="professional"
                id="selectProfessional"
                label={t('profType')}
                value={setProf}
                onChange={handlerChangeProfessional}>
                {professional?.map((element) => {
                  return (
                    <MenuItem key={element.id} value={element.id}>
                      {element.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl size="small" variant="outlined" className={style.formControl}>
              <InputLabel id="circle" style={{ zIndex: 0 }}>
                {t('circle')}
              </InputLabel>
              <Select
                labelId="circle"
                id="selectCircle"
                label={t('circle')}
                value={setPath}
                onChange={handlerChangePathologie}
                disabled={related}>
                {circle?.map((element) => {
                  return (
                    <MenuItem key={element.id} value={element.id}>
                      {element.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <Box className={style.actionBtnRow}>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('addNewMessage')}
                handler={addNewMessage}
                fullWidth
                disabled={isPath}
              />
            </Box>
          </>
        )}
      </Box>
      {errorMessage && (
        <Box my={1.5}>
          <Alert severity="warning" key="errorMessage" id="errorMessage">
            {t(errorMessage)}
          </Alert>
        </Box>
      )}
      <form className={style.form}>
        {autoMessage?.map((element, index) => {
          return (
            <Box key={element.id + index}>
              <Box className={style.formHeader}>
                <Box className={style.formTitleRow}>
                  <span className={style.formTitleLabel}>{t('profType')}</span>
                  <span className={style.formTitleProf}>{filter('professional', element)}</span>
                </Box>
                <Box className={style.formTitleRow}>
                  <span className={style.formTitleLabel}>{t('circle')}</span>
                  <span className={style.formTitleCircle}>{filter('pathology', element)}</span>
                </Box>
              </Box>
              <Box>
                <Box className={style.actionBtnDelete}>
                  <AppButton
                    theme={ButtonTheme.BasicTrasparentOrange}
                    handlerValue={deleteMessage}
                    handler={() => {}}
                    type={'button'}
                    label={t('deleteMessage')}
                    value={element.id}
                  />
                </Box>
                <TextFieldItem
                  field={'message'}
                  value={element.message}
                  label={t('addMessage')}
                  type={'text'}
                  // handleChange={handlerChangeMessage}
                  handlerValue={handlerChangeMessage}
                  valueID={index}
                  rows={5}
                  required={true}
                />
              </Box>
            </Box>
          )
        })}
      </form>
      <Box className={style.actionBtns}>
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('cancel')}
          handler={goBack}
        />
        {autoMessage && autoMessage.length > 0 && (
          <AppButton
            isLoading={isLoading}
            theme={ButtonTheme.NewPrimary}
            type={'button'}
            label={t('save')}
            handler={handlerSave}
          />
        )}
      </Box>
    </Box>
  )
}
