import { Avatar, Box, Divider, LinearProgress, Typography } from '@mui/material'
import style from '../../NavigationEsfera.module.css'
import { Link, useNavigate } from 'react-router-dom'
import { LinkType } from '../../NavigationEsfera'
import { useTranslation } from 'react-i18next'
import notifications from '@assets/left_menu/chat.svg'
import notificationsActive from '@assets/left_menu/chat-active.svg'
import profile from '@assets/esfera/nav-profile-icons/config-profile-icon.svg'
import profileActive from '@assets/esfera/nav-profile-icons/config-profile-icon-active.svg'
import configIcon from '@assets/esfera/nav-profile-icons/ico-config.svg'
import configIconActive from '@assets/esfera/nav-profile-icons/ico-config-active.svg'
import exitIcon from '@assets/esfera/nav-profile-icons/exit-icon.svg'
import React from 'react'
import { useSelectedMenuContext } from 'common/utils/menu-selected-context/MenuSelectedContext'
import { ProfileSubMenu } from './menu'
import { UserCircleWithCircleAndUser } from 'modules/user-circle/models/UserCircleWithCircleAndUser'
import { ROUTE_CONFIGURATION, ROUTE_NOTIFICATIONS, ROUTE_USER_PROFILE } from 'routes/routes-constants'
import { useGetProfilePhoto } from 'hooks/profilePhoto/useGetProfilePhoto'
import { getLinkStyle, getListItemStyle } from 'pages/layout/stylesFunctions'

type ProfileSubMenuContainerProps = {
  login: string
  username: string
  gender: number
  dateOfBirth: string
  notificationNumber: number
  dni: string
  cip: string
  userCircle: UserCircleWithCircleAndUser | undefined
  links: LinkType[]
  handlerLogout: (_: React.MouseEvent<Element, MouseEvent>) => void
  anchorEl: HTMLElement | null
}

const linksProfile: LinkType[] = [
  {
    route: ROUTE_USER_PROFILE,
    title: 'profileConfiguration',
    activeIcon: profileActive,
    icon: profile,
    altSrc: 'profileIcon',
    hoverIcon: profile,
    isVisible: true,
    additionalRoutes: [],
    subMenu: false,
  },
  {
    route: ROUTE_CONFIGURATION,
    title: 'configuration',
    activeIcon: configIconActive,
    icon: configIcon,
    altSrc: 'configurationIcon',
    hoverIcon: configIconActive,
    isVisible: true,
    additionalRoutes: [],
    subMenu: false,
  },
  {
    route: ROUTE_NOTIFICATIONS,
    title: 'notifications',
    activeIcon: notificationsActive,
    icon: notifications,
    altSrc: 'notificationsIcon',
    hoverIcon: notificationsActive,
    isVisible: false, // userCircle !== undefined,
    additionalRoutes: [],
    subMenu: true,
  },
]

export const ProfileSubMenuContainer = ({
  anchorEl,
  userCircle,
  links,
  username,
  login,
  gender,
  dni,
  dateOfBirth,
  notificationNumber,
  cip,
  handlerLogout,
}: ProfileSubMenuContainerProps) => {
  const { t } = useTranslation()
  const { profilePhotoData } = useGetProfilePhoto()
  const { sMenu } = useSelectedMenuContext()
  const navigate = useNavigate()

  const birthDateData = dateOfBirth.split('/')
  const day = parseInt(birthDateData[0])
  const month = parseInt(birthDateData[1])
  const year = parseInt(birthDateData[2])

  const birthDate = new Date(year, month, day).getTime()
  const today = new Date().getTime()
  const years = new Date(today - birthDate).getUTCFullYear() - 1970
  const age = years + ' ' + t('years')

  return (
    <ProfileSubMenu style={style.profileSubMenu} anchorEl={anchorEl}>
      <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
          {profilePhotoData !== '' ? (
            <img
              style={{
                height: '110px',
                width: '110px',
                background: '#FFF',
                borderRadius: 100,
              }}
              src={`data:image/jpeg;base64,${profilePhotoData}`}
              alt={'selectedProfilePhoto'}
            />
          ) : (
            <Avatar style={{ width: '94px', height: '94px' }} />
          )}
          <Box className={style.containerInfoUser}>
            <h6 className={style.userName}>{username}</h6>
            <Typography className={style.userInfo}>{dateOfBirth}</Typography>
            <Typography className={style.userInfo}>{age}</Typography>
            <Typography className={style.userInfo}>DNI: {dni}</Typography>
            <Typography className={style.userInfo}>CIP: {cip}</Typography>
          </Box>
          <Divider orientation="vertical" flexItem className={style.dividerUserProfile} />
          <Box>
            <h6 className={style.userName}>{t('myLearning')}</h6>

            <Box className={style.progressContent}>
              <Typography className={style.userInfo}>{t('generalProgress')}</Typography>
              <LinearProgress color="warning" variant="determinate" value={50} />
              <Typography className={style.progressPercent}>50%</Typography>
            </Box>

            <Box className={style.progressContent}>
              <Typography className={style.userInfo}>Progreso Círculo 1</Typography>
              <LinearProgress color="info" variant="determinate" value={80} />
              <Typography className={style.progressPercent}>80%</Typography>
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem className={style.dividerUserProfile} />
        </Box>
        <Box>
          {linksProfile
            .filter((l) => l.isVisible || (l.title === 'notifications' && userCircle !== undefined))
            .map((l) => (
              <Link
                onClick={() => {
                  navigate(l.route)
                }}
                key={l.title}
                to={l.route}
                className={getLinkStyle(
                  sMenu,
                  style.circleSubMenuActive,
                  ...l.additionalRoutes,
                  l.route
                )}
                id={l.route}
              >
                <div className={style.menuDiv}>
                  <Box
                    key={l.title}
                    className={getListItemStyle(
                      false,
                      sMenu || '',
                      style.menuItemProfileActive,
                      style.menuItemProfile,
                      ...(l?.additionalRoutes || ''),
                      l?.route || ''
                    )}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box style={{ margin: 8, minWidth: 40 }}>
                        <img src={l.icon} alt={t(l.altSrc)} />
                        {l.title === 'notifications' ? (
                          <span className={style.notificationsNumber}>{notificationNumber}</span>
                        ) : (
                          <></>
                        )}
                      </Box>
                      <Box>
                        <h3 className={style.titleNameSubMenu}>{t(l.title)}</h3>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Link>
            ))}
          <div className={style.menuDiv}>
            <Box
              className={getListItemStyle(
                false,
                sMenu || '',
                style.menuItemProfileActive,
                style.menuItemProfile
              )}
              onClick={handlerLogout}
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <Box style={{ margin: 8, minWidth: 40 }}>
                <img src={exitIcon} alt={'exitIcon'} />
              </Box>
              <Box>
                <h3 className={style.titleNameSubMenu}>{t('exit')}</h3>
              </Box>
            </Box>
          </div>
        </Box>
      </Box>
    </ProfileSubMenu>
  )
}
