import React, { useEffect, useState } from 'react'
import { Box, MenuItem, Select, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListView } from './ListView'
import { WeekView } from './WeekView'
import { MonthView } from './MonthView'
import tick from '../../assets/calendar/tick.svg'
import newEvent from '../../assets/events_icons/ico-plus-blue.svg'
import newEventOrange from '../../assets/events_icons/ico-plus-orange.svg'
import styles from './Calendar.module.css'
import { CalendarViewProps } from './types'
import { EventService } from '../../modules/calendar/services/EventService'
import { getCalendarContainer } from '../../container/calendar-module'
import { EVENT_SERVICE_KEY } from '../../modules/calendar'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import dayjs from 'dayjs'
import { useIsEpilepsy } from '../../hooks/pathologies/useIsEpilepsy'
import { useGetActiveUser } from '../../hooks/get-active-user/useGetActiveUser'
import { NewEventModal } from './new-event-modal/NewEventModal'
import { NewPersonalEvent } from './new-event-modal/NewPersonalEvent'
import { GeneralSymptomsModal } from './new-event-modal/GeneralSymptomsModal'
import { ModalAddTreatments } from '../patient-data/treatments/ModalAddtreatments/ModalAddTreatments'
import { NumberStoolsSymptomModal } from './new-event-modal/NumberStoolsSymptomModal'
import { RectalBleedingAndPainScaleModal } from './new-event-modal/RectalBleedingAndPainScaleModal'
import { AllCalendarEventDTO } from '../../modules/calendar/models/AllCalendarEventDTO'
import { useCalendarEvents } from '../../hooks/calendarEvents/useCalendarEvents'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CREATE, ROUTE_PATIENT_SYMPTOMS_FORM } from '../../routes/routes-constants'
import { EventCategory } from '../../modules/calendar/enums/EventCategory'
import useMediaQuery from '@mui/material/useMediaQuery'
import { LoadingMonthView } from './LoadingMonthView'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import { finalize } from 'rxjs/operators'

const userContainer = getUserContainer()

const calendarContainer = getCalendarContainer()
const eventService = calendarContainer.get<EventService>(EVENT_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

enum EventModalItemsEnum {
  personalEvent = 'personalEvent',
  treatments = 'treatments',
  symptoms = 'symptoms',
  numberStools = 'Poo',
  painLevel = 'PainScale',
  rectalBleeding = 'BloodScale',
  epilepticCrisis = 'Epilectic',
  None = '',
}

enum View {
  Week,
  Month,
  Year,
  List,
}

type CalendarProps = {
  selectedDate?: Date
}

export type CalendarFilter = {
  vacunas: boolean
  sintomas: boolean
  tratamientos: boolean
  crisisEpilepticas: boolean
  citaMedica: boolean
  otros: boolean
}

export function Calendar(props: CalendarProps) {
  const { userCircleService } = useCircleConfiguration()
  const circleConfig = userCircleService?.getActiveFullUserCircle()
  const { t } = useTranslation()
  const { activeUser } = useGetActiveUser()
  const [view, setView] = useState<View>(circleConfig && circleConfig.circle.calendarConfig.monthly ? View.Month : circleConfig?.circle.calendarConfig.weekly ? View.Week : View.List)
  const [dateCalendar, setDateCalendar] = useState<Date>(new Date())
  const [tabValue, setTabValue] = useState<number>(circleConfig && circleConfig.circle.calendarConfig.monthly ? 1 : circleConfig?.circle.calendarConfig.weekly ? 2 : 3)
  const [tabsView, setTabsView] = useState<JSX.Element>(<></>)
  const [openNewEventModal, setOpenNewEventModal] = useState<boolean>(false)
  const [selectedModal, setSelectedmodal] = React.useState<EventModalItemsEnum | null>(null)
  const [selectSymptomName, setSelectSymptomName] = useState<string>('')
  const [selectedIdIcon, setSelectedIdIcon] = useState<string>('')
  const [openEditEvent, setOpenEditEvent] = useState<boolean>(false)
  const [currentEvent, setCurrentEvent] = useState<string | undefined>()
  const loggedUser = loggedUserService.get()
  const isMobile = useMediaQuery('(max-width: 599px)')
  const {
    setfilterEvents,
    refreshCalendarEvents,
    setRefreshCalendarEvents,
    setEventsMonthYear,
    isLoading,
  } = useCalendarEvents()

  const [filters, setFilters] = useState<EventCategory[]>([
    EventCategory.PersonalEvent,
    EventCategory.Treatments,
    EventCategory.Symptoms,
    EventCategory.Appointment,
    EventCategory.Vaccines,
    EventCategory.Others,
    EventCategory.EpilepticCrisis,
    EventCategory.TrainingPending,
    EventCategory.TrainingAssisted,
    EventCategory.TrainingAbsent,
  ])

  const othersFilter: EventCategory[] = [
    EventCategory.Others,
    EventCategory.PersonalEvent,
    EventCategory.TrainingPending,
    EventCategory.TrainingAssisted,
    EventCategory.TrainingAbsent,
  ]

  const circleCIP = activeUser?.user.cip || ''
  const circleDoB = dayjs(activeUser?.user.birthDate).format('DD/MM/YYYY') || ''
  const params = new URLSearchParams(window.location.search)
  const [currentTraining, setCurrentTraining] = useState<string>('')
  const navigate = useNavigate()
  const [initialRender, setInitialRender] = useState(true)

  useEffect(() => {
    setRefreshCalendarEvents(!refreshCalendarEvents)
  }, [])

  useEffect(() => {
    if (selectedModal === EventModalItemsEnum.None) {
      setRefreshCalendarEvents(!refreshCalendarEvents)
    }
  }, [selectedModal])

  useEffect(() => {
    if (!initialRender) {
      setfilterEvents(filters)
    } else {
      setInitialRender(false) // Cambiamos el estado para indicar que ya no es la primera renderización
    }
  }, [filters])

  const handlePreviousMonth = () => {
    setEventsMonthYear({
      mes: dateCalendar.getMonth(), // to day js month is 0 based,
      ano: dateCalendar.getFullYear(),
    })
    setTabsView(
      getView(view, {
        calendarEvents: [],
        selectedDate: new Date(dateCalendar.getFullYear(), dateCalendar.getMonth() - 1, 1),
        handlerEdit: editEvent,
        handlerRemove: removeEvent,
        currentTraining,
        handleNextMonth,
        handlePreviousMonth,
        canChangeMonth: true,
      })
    )
    setDateCalendar((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1))
  }
  
  const handleNextMonth = () => {
    setEventsMonthYear({
      mes: dateCalendar.getMonth() + 2, // to day js month is 0 based,
      ano: dateCalendar.getFullYear(),
    })
    setTabsView(
      getView(view, {
        calendarEvents: [],
        selectedDate: new Date(dateCalendar.getFullYear(), dateCalendar.getMonth() + 1, 1),
        handlerEdit: editEvent,
        handlerRemove: removeEvent,
        currentTraining,
        handleNextMonth,
        handlePreviousMonth,
        canChangeMonth: true,
      })
    )
    setDateCalendar((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1))
  }

  useEffect(() => {
    // reseteo la fecha del contexto a la actual
    if (view !== View.Month) {
      setEventsMonthYear({
        mes: new Date().getMonth() + 1,
        ano: new Date().getFullYear(),
      })
      setDateCalendar(new Date())
    }
  }, [view])
  useEffect(() => {
    setTabsView(
      getView(view, {
        calendarEvents: [],
        selectedDate: dateCalendar,
        handlerEdit: editEvent,
        handlerRemove: removeEvent,
        currentTraining,
        handleNextMonth,
        handlePreviousMonth,
        canChangeMonth: true,
      })
    )
  }, [view, currentTraining, dateCalendar])

  useEffect(() => {
    if (!(params.get('from_email') && params.get('circle') && params.get('training'))) {
      return
    }
    setCurrentTraining(params.get('training') ?? '')
  }, [])

  const handleOnClick = (name: string) => {
    switch (name) {
      case EventModalItemsEnum.personalEvent:
        setSelectedmodal(EventModalItemsEnum.personalEvent)
        handleNewEventModal()
        break
      case EventModalItemsEnum.treatments:
        setSelectedmodal(EventModalItemsEnum.treatments)
        handleNewEventModal()
        break
      case EventModalItemsEnum.symptoms:
        setSelectedmodal(EventModalItemsEnum.symptoms)
        handleNewEventModal()
        break
      case EventModalItemsEnum.numberStools:
        setSelectedmodal(EventModalItemsEnum.numberStools)
        handleNewEventModal()
        break
      case EventModalItemsEnum.painLevel:
        setSelectedmodal(EventModalItemsEnum.painLevel)
        handleNewEventModal()
        break
      case EventModalItemsEnum.rectalBleeding:
        setSelectedmodal(EventModalItemsEnum.rectalBleeding)
        handleNewEventModal()
        break
      case EventModalItemsEnum.epilepticCrisis:
        setSelectedmodal(EventModalItemsEnum.epilepticCrisis)
        navigate(`${ROUTE_PATIENT_SYMPTOMS_FORM}/${ROUTE_CREATE}`, { state: { epileptic: true } })
        break
      default:
        break
    }
  }

  const handleNewEventModal = () => {
    setOpenNewEventModal(!openNewEventModal)
  }

  const handleEditEventModal = () => {
    setOpenEditEvent(!openEditEvent)
  }

  const getView = (view: View, vp: CalendarViewProps): JSX.Element => {
    switch (view) {
      case View.Week:
        return <WeekView {...vp} />
      case View.Month:
        return <MonthView {...vp} />
      case View.List:
        return <ListView {...vp} />
      default:
        return <></>
    }
  }

  const editEvent = (event: AllCalendarEventDTO) => {
    setOpenEditEvent(true)
    setCurrentEvent(event.id)
    setDateCalendar(new Date(dateCalendar))
    // navigate(`${ROUTE_CALENDAR_FORM}/${event.id}`, { state: { date: dateCalendar } })
  }

  const removeEvent = (event: AllCalendarEventDTO) => {
    if (event.id) {
      eventService.delete(event.id).pipe(
        finalize(() => {
          setDateCalendar(new Date(dateCalendar))
          setRefreshCalendarEvents(!refreshCalendarEvents)
        })
      )
      .subscribe(() => {})
    }
  }
  const canAppointment = () => {
    const canAdd = ['patient', 'family/Tutor']
    return canAdd.some((role) => loggedUser?.roles?.includes(role))
  }
  const createEvent = () => {
    setOpenNewEventModal(true)
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, tabNumber: number) => {
    // setDate(new Date(props.selectedDate))
    setTabValue(tabNumber)
    switch (tabNumber) {
      case 1:
        setView(View.Month)
        break
      case 2:
        setView(View.Week)
        break
      case 3:
        setView(View.List)
        break
    }
  }

  const filterCategories = [
    { value: EventCategory.Symptoms, label: 'symptoms', show: circleConfig?.circle.calendarConfig.symptoms },
    { value: EventCategory.Treatments, label: 'treatments', show: circleConfig?.circle.calendarConfig.treatments },
    { value: EventCategory.Appointment, label: 'appointment',  show: circleConfig?.circle.calendarConfig.appointments },
    { value: EventCategory.Others, label: 'others',  show: circleConfig?.circle.calendarConfig.others },
    { value: EventCategory.Vaccines, label: 'vaccines',  show: circleConfig?.circle.calendarConfig.vaccines },
    ...(useIsEpilepsy()
      ? [{ value: EventCategory.EpilepticCrisis, label: 'epilepticCrisis' }]
      : []),
  ]

  return (
    <>
      <Box className={styles.legend1}>
        <h2 className={styles.titleCalendar}>
          <span className={styles.showCalendar}>{t('showCalendar')}</span>
        </h2>
        {innerWidth > 599 ? (
          <div className={styles.legendCheck2}>
            <span className={styles.filterCategory}>{t('filterByCategories')}:</span>

            { circleConfig && 
              <>
                 <div className={styles.legendCheck}>
                  <div className={styles.legendCheck2}>
                    { circleConfig.circle.calendarConfig.symptoms && 
                      <label className={styles.labelCheck}>
                      <input
                        type="checkbox"
                        name="sintomas"
                        className={styles.checkboxInput}
                        onChange={
                          // create if exist remove if not exist
                          filters.includes(EventCategory.Symptoms)
                            ? () => setFilters(filters.filter((f) => f !== EventCategory.Symptoms))
                            : () => setFilters([...filters, EventCategory.Symptoms])
                        }
                        checked={filters.includes(EventCategory.Symptoms)}
                      />
                      Síntomas
                      {filters.includes(EventCategory.Symptoms) && (
                        <img src={tick} alt="Icono de tick" className={styles.checkboxImage} />
                      )}
                    </label>
                    }
                    
                    { circleConfig.circle.calendarConfig.treatments && 
                      <label className={styles.labelCheck}>
                        <input
                          type="checkbox"
                          name="tratamientos"
                          className={styles.checkboxInput}
                          onChange={
                            // create if exist remove if not exist
                            filters.includes(EventCategory.Treatments)
                              ? () => setFilters(filters.filter((f) => f !== EventCategory.Treatments))
                              : () => setFilters([...filters, EventCategory.Treatments])
                          }
                          checked={filters.includes(EventCategory.Treatments)}
                        />
                        Tratamientos
                        {filters.includes(EventCategory.Treatments) && (
                          <img src={tick} alt="Icono de tick" className={styles.checkboxImage} />
                        )}
                      </label>
                    }
                    { circleConfig.circle.calendarConfig.appointments && 
                      <label className={styles.labelCheck}>
                        <input
                          type="checkbox"
                          name="citaMedica"
                          className={styles.checkboxInput}
                          onChange={
                            // create if exist remove if not exist
                            filters.includes(EventCategory.Appointment)
                              ? () => setFilters(filters.filter((f) => f !== EventCategory.Appointment))
                              : () => setFilters([...filters, EventCategory.Appointment])
                          }
                          checked={filters.includes(EventCategory.Appointment)}
                        />
                        Cita médica
                        {filters.includes(EventCategory.Appointment) && (
                          <img src={tick} alt="Icono de tick" className={styles.checkboxImage} />
                        )}
                      </label>
                    }
                    {
                      circleConfig.circle.calendarConfig.vaccines &&
                      <label className={styles.labelCheck}>
                        <input
                          type="checkbox"
                          name="Vaccines"
                          className={styles.checkboxInput}
                          onChange={
                            // create if exist remove if not exist
                            filters.includes(EventCategory.Vaccines)
                              ? () => setFilters(filters.filter((f) => f !== EventCategory.Vaccines))
                              : () => setFilters([...filters, EventCategory.Vaccines])
                          }
                          checked={filters.includes(EventCategory.Vaccines)}
                        />
                        {t('vaccines')}
                        {filters.includes(EventCategory.Vaccines) && (
                          <img src={tick} alt="Icono de tick" className={styles.checkboxImage} />
                        )}
                      </label>

                    }

                    { circleConfig.circle.calendarConfig.others && 
                      <label className={styles.labelCheck}>
                        <input
                          type="checkbox"
                          name="otros"
                          className={styles.checkboxInput}
                          onChange={
                            // create if exist remove if not exist
                            filters.includes(EventCategory.Others)
                              ? () => setFilters(filters.filter((f) => !othersFilter.includes(f)))
                              : () => setFilters([...filters, ...othersFilter])
                          }
                          checked={filters.map((f) => othersFilter.includes(f)).includes(true)}
                        />
                        Otros
                        {filters.map((f) => othersFilter.includes(f)).includes(true) && (
                          <img src={tick} alt="Icono de tick" className={styles.checkboxImage} />
                        )}
                      </label> 
                    }

                    {useIsEpilepsy() && (
                      <label className={styles.labelCheck}>
                        <input
                          name="crisisEpilepticas"
                          onChange={
                            // create if exist remove if not exist
                            filters.includes(EventCategory.EpilepticCrisis)
                              ? () =>
                                  setFilters(
                                    filters.filter((f) => f !== EventCategory.EpilepticCrisis)
                                  )
                              : () => setFilters([...filters, EventCategory.EpilepticCrisis])
                          }
                          checked={filters.includes(EventCategory.EpilepticCrisis)}
                          type="checkbox"
                          className={styles.checkboxInput}
                        />
                        Crisis Epilépticas
                        {filters.includes(EventCategory.EpilepticCrisis) && (
                          <img src={tick} alt="Icono de tick" className={styles.checkboxImage} />
                        )}
                      </label>
                    )}
                  </div>
              </div>
              </>
            }
          </div>
        ) : (
          <div className={styles.legendCheck2}>
            <Select
              MenuProps={{
                MenuListProps: {
                  sx: {
                    'li.MuiButtonBase-root': {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      fontFamily: 'Poppins',
                    },
                  },
                },
              }}
              SelectDisplayProps={{
                style: {
                  fontFamily: 'Poppins',
                },
              }}
              fullWidth
              variant="outlined"
              style={{ backgroundColor: '#f5f2f2', height: '40px' }}
              inputProps={{ 'aria-label': 'Without label' }}
              value={filters}
              onChange={(e) => {
                const value = Number(e.target.value)
                const toggleFilter = (category: EventCategory) => {
                  setFilters((prevFilters) =>
                    prevFilters.includes(category)
                      ? prevFilters.filter((f) => f !== category)
                      : [...prevFilters, category]
                  )
                }

                if (value === EventCategory.Others) {
                  setFilters((prevFilters) =>
                    prevFilters.includes(EventCategory.Others)
                      ? prevFilters.filter((f) => !othersFilter.includes(f))
                      : [...prevFilters, ...othersFilter]
                  )
                } else {
                  toggleFilter(value)
                }
              }}
            >
              {filterCategories.filter((f) => f.show).map(({ value, label }) => (
                <MenuItem
                  key={value}
                  value={value}
                  style={{ color: !filters.includes(value) ? '' : '#f88c24' }}
                >
                  {t(label)}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
      </Box>
      <Box className={styles.createEvent}>
        <Box>
          <AppButton
            theme={canAppointment() ? ButtonTheme.newEventOrange : ButtonTheme.disabelEvent}
            type={'button'}
            label={t('createAppointment')}
            marginStartIcon={{ marginRight: '10px' }}
            startIcon={!isMobile ? newEventOrange : ''}
            handler={() =>
              (window.location.href = `https://sede-pru.carm.es/sms/citainternet/secure/inicio.xhtml?id=${circleCIP}&fechaNacimiento=${circleDoB}`)
            }
            vertical={true}
          />
        </Box>
        <Box>
          <AppButton
            theme={ButtonTheme.newEvent}
            type={'button'}
            label={t('newEvent')}
            marginStartIcon={{ marginRight: '10px' }}
            startIcon={!isMobile ? newEvent : ''}
            handler={createEvent}
            vertical={true}
          />
        </Box>
      </Box>
      {openNewEventModal && (
        <NewEventModal
          open={openNewEventModal}
          close={handleNewEventModal}
          onClick={handleOnClick}
          name={selectSymptomName}
          setName={setSelectSymptomName}
          setIdIcon={setSelectedIdIcon}
        />
      )}
      {selectedModal === EventModalItemsEnum.treatments && (
        <ModalAddTreatments
          open={selectedModal === EventModalItemsEnum.treatments}
          onClose={() => setSelectedmodal(EventModalItemsEnum.None)}
        />
      )}
      {openEditEvent && (
        <NewPersonalEvent
          open={openEditEvent}
          onClose={handleEditEventModal}
          id={currentEvent}
          date={dateCalendar}
        />
      )}
      {selectedModal === EventModalItemsEnum.personalEvent && (
        <NewPersonalEvent
          open={selectedModal === EventModalItemsEnum.personalEvent}
          onClose={() => setSelectedmodal(EventModalItemsEnum.None)}
        />
      )}
      {selectedModal === EventModalItemsEnum.symptoms && (
        <GeneralSymptomsModal
          open={selectedModal === EventModalItemsEnum.symptoms}
          onClose={() => setSelectedmodal(EventModalItemsEnum.None)}
        />
      )}
      {(selectedModal === EventModalItemsEnum.numberStools ||
        selectedModal === EventModalItemsEnum.painLevel) && (
        <NumberStoolsSymptomModal
          open={selectedModal === EventModalItemsEnum.numberStools}
          close={() => setSelectedmodal(EventModalItemsEnum.None)}
          onClick={handleOnClick}
          name={selectSymptomName}
          idIcon={selectedIdIcon}
        />
      )}
      {selectedModal === EventModalItemsEnum.painLevel && (
        <RectalBleedingAndPainScaleModal
          open={selectedModal === EventModalItemsEnum.painLevel}
          close={() => setSelectedmodal(EventModalItemsEnum.None)}
          onClick={handleOnClick}
          name={selectSymptomName}
          idIcon={selectedIdIcon}
        />
      )}
      {selectedModal === EventModalItemsEnum.rectalBleeding && (
        <RectalBleedingAndPainScaleModal
          open={selectedModal === EventModalItemsEnum.rectalBleeding}
          close={() => setSelectedmodal(EventModalItemsEnum.None)}
          onClick={handleOnClick}
          name={selectSymptomName}
          idIcon={selectedIdIcon}
        />
      )}
      {
        circleConfig &&
        <Box className={styles.calendarContainer} key={'box1'}>
          <Box className={styles.tabsCalendar}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={
                window.innerWidth < 599
                  ? { '& .MuiTab-root': { minWidth: 'calc(33.33% - 0.7em)' } }
                  : {}
              }
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              <div style={{ width: '1rem', height: 1, display: window.innerWidth < 599 ? 'inline' : 'none'}}></div>
              <Tab
              style={{ display: circleConfig?.circle.calendarConfig.monthly ? '': 'none'}}
              label={<span className={styles.tabsStyle}>{t('Monthly')}</span>}
              className={tabValue === 1 ? styles.tabCustomActive : styles.tabCustom}
              />
            
              <Tab
                style={{ display: circleConfig?.circle.calendarConfig.weekly ? '': 'none'}}
                label={<span className={styles.tabsStyle}>{t('Weekly')}</span>}
                className={tabValue === 2 ? styles.tabCustomActive : styles.tabCustom}
              />
              <Tab
                style={{ display: circleConfig?.circle.calendarConfig.diary ? '': 'none'}}
                label={<span className={styles.tabsStyle}>{t('Daily')}</span>}
                className={tabValue === 3 ? styles.tabCustomActive : styles.tabCustom}
              />
            </Tabs>
          </Box>
          { isLoading && (circleConfig.circle.calendarConfig.monthly || circleConfig.circle.calendarConfig.weekly || circleConfig.circle.calendarConfig.diary) && 
            <Box flexGrow="1" key={'box3'} className={styles.calendar}>
            <LoadingMonthView selectedDate={dateCalendar} />
          </Box>
          }
          { !isLoading && (circleConfig.circle.calendarConfig.monthly || circleConfig.circle.calendarConfig.weekly || circleConfig.circle.calendarConfig.diary) && 
            <Box flexGrow="1" key={'box3'} className={styles.calendar}>
              {tabsView}
            </Box>
          }
        </Box>
      }
    </>
  )
}
