import { Box, Modal, Typography } from '@mui/material'
import style from './SelectGroup.module.css'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import React, { useEffect, useRef, useState } from 'react'
import icoDelete from 'assets/buttons/close.svg'
import { useTranslation } from 'react-i18next'
import { SportCenter } from 'modules/sport-center/models/SportCenter'
import { TrainingGroup } from 'modules/training-group/models/TrainingGroup'
import { ItemList } from 'common/models/ItemList'
import { InclusionEFDMessage } from './InclusionEFDMessage'
import { UserTrainingGroup } from 'modules/user-training-group/models/UserTrainingGroup'
import { Map } from './Map'
import TileLayer from 'ol/layer/Tile'
import { XYZ } from 'ol/source'
import { Map as OlMap, View as OlView } from 'ol'
import { fromLonLat } from 'ol/proj'
import { VerifyQuery } from './VerifyQuery'
import { SelectGroupItem } from './SelectGroupItem'

type SelectGroupProps = {
  open: boolean
  handlerClose: () => void
  handlerAccepted: () => void
  handlerSetGroup: (groupID: string) => void
  selectedGroup: TrainingGroup | undefined
  sportCenters: ItemList<SportCenter>
  groupsMap: Map<string, TrainingGroup[]>
  registeredUserMap: Map<string, number>
  userCircleGroup: UserTrainingGroup
  isChoosable: boolean
  isPending: boolean
}

export function SelectGroup(props: SelectGroupProps) {
  const { t } = useTranslation()
  const [currentCenter, setCurrentCenter] = useState<string>('')
  const [currentGroup, setCurrentGroup] = useState<boolean[]>([])
  const [openEFDMessage, setOpenEFDMessage] = useState<boolean>(false)
  const [openVerifyGroup, setOpenVerifyGroup] = useState<boolean>(false)
  const baseLayer = useRef<TileLayer<XYZ>>(
    new TileLayer({
      source: new XYZ({
        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      }),
    })
  )

  const murciaLon = -1.130395
  const murciaLat = 37.992276

  const initialMap = useRef<OlMap>(
    new OlMap({
      target: undefined,
      layers: [baseLayer.current],
      view: new OlView({
        center: fromLonLat([murciaLon, murciaLat]),
        zoom: 10,
      }),
    })
  )

  const checkAlreadySubscribed = (group: string): boolean => {
    const groups = props.groupsMap.get(currentCenter)

    if (!groups) return true

    if (group === props.userCircleGroup.groupID) return true

    if (
      groups.find((g) => {
        const registered = props.registeredUserMap.get(g.id)
        if (!registered) return false
        return g.capacity <= registered
      })
    )
      return true

    return false
  }

  const handleSelectGroup = (index: number, group: string) => {
    const auxArr = createGroupCheckBox()
    auxArr[index] = true
    setCurrentGroup(auxArr)
    props.handlerSetGroup(group)
  }

  const createGroupCheckBox = (): boolean[] => {
    const tmp = props.groupsMap.get(currentCenter)
    const auxArr = tmp
      ? tmp.map(() => {
          return false
        })
      : []
    return auxArr
  }

  useEffect(() => {
    if (currentGroup.length === 0) {
      setCurrentGroup(createGroupCheckBox())
    }
    if (!currentCenter) {
      if (props.sportCenters.items && props.selectedGroup !== undefined) {
        const trainingGroup = props.selectedGroup
        const cc = props.sportCenters.items.find((v) => v.id === trainingGroup.centerID)
        const value = cc && cc.id ? cc.id : props.sportCenters.items[0].id
        setCurrentCenter(value)
      }
    }
  }, [props.open])

  useEffect(() => {
    if (!props.open) return
    baseLayer.current = new TileLayer({
      source: new XYZ({
        url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      }),
    })

    initialMap.current = new OlMap({
      target: undefined,
      layers: [baseLayer.current],
      view: new OlView({
        center: fromLonLat([murciaLon, murciaLat]),
        zoom: 10,
      }),
    })
  }, [props.open])

  const handlerOpenVerifyGroupMessage = () => {
    if (!currentGroup.find((group) => group)) return
    setOpenVerifyGroup(true)
  }

  const handlerCloseEFDMessage = () => {
    setOpenEFDMessage(false)
  }

  const handlerCloseVerifyGroupMessage = () => {
    setOpenVerifyGroup(false)
  }

  const handlerVerifyGroup = () => {
    setOpenVerifyGroup(false)
    setOpenEFDMessage(true)
  }

  const handlerAcceptEFDMessage = () => {
    setOpenEFDMessage(false)
    setCurrentCenter('')
    setCurrentGroup(createGroupCheckBox())
    props.handlerAccepted()
  }

  const handlerSelectCenter = (centerID: string) => {
    setCurrentCenter(centerID)
    setCurrentGroup(createGroupCheckBox())
  }

  return (
    <>
      <Modal open={props.open} onClose={props.handlerClose} className={style.modal}>
        <Box className={style.modalContainer}>
          <Box className={style.header}>
            <img style={{ cursor: 'pointer' }} onClick={props.handlerClose} src={icoDelete} />
          </Box>
          <Typography className={style.headerTitle}>
            {t(props.isChoosable ? 'selectSPCenterAndGroup' : 'availableSPCenterAndGroup')}
          </Typography>
          <Box className={style.rowHeader}>
            <Box className={style.rowHeader}>{t('selectGroupForAP')}</Box>
            <Box className={style.rowHeaderSubtitle}>
              <span>{t('circle')}: </span>
              <b>{t('activePlan')}</b>
            </Box>
          </Box>
          <Box className={style.containerRow}>
            <Box className={style.mapBox}>
              <Box className={style.mapBorder}>
                <Map
                  sportCenters={props.sportCenters.items}
                  selectedSportCenter={currentCenter}
                  handleSelectedSportCenter={handlerSelectCenter}
                  baseLayer={baseLayer.current}
                  initialMap={initialMap.current}
                />
              </Box>
            </Box>
            <Box className={style.containerGroup}>
              <b>
                {t('groups')}{' '}
                {props.sportCenters.items.find((center) => center.id === currentCenter)?.name}
              </b>
              <Box className={style.scrollContainer}>
                {props.groupsMap
                  .get(
                    props.sportCenters.items.find(
                      (center, indexCenter) => center.id === currentCenter
                    )?.id ?? ''
                  )
                  ?.map((group, groupIndex) => (
                    <SelectGroupItem
                      key={group.id}
                      id={group.id}
                      index={groupIndex}
                      name={group.name}
                      isChoosable={props.isChoosable}
                      timeTable={group.timeTable}
                      capacity={group.capacity}
                      registeredUserMap={props.registeredUserMap}
                      checked={currentGroup[groupIndex]}
                      disabled={checkAlreadySubscribed(group.id)}
                      handleSelectGroup={() => handleSelectGroup(groupIndex, group.id)}
                    />
                  ))}
              </Box>
            </Box>
          </Box>
          {props.isChoosable && (
            <Box className={style.actionBtns}>
              <AppButton
                theme={ButtonTheme.whiteAndBlue}
                type={'button'}
                label={t('cancel')}
                handler={props.handlerClose}
              />
              {/* FALTA EL HANDLER #merge Teleconsultas con Calendario Sintomas
                <AppButton
                  theme={ButtonTheme.newEvent}
                  type={'button'}
                  label={props.isPending ? t('selectGroup') : t('changeGroup')}
                  handler={handlerOpenEFDMessage}
                /> */}
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('changeGroup')}
                handler={handlerOpenVerifyGroupMessage}
              />
            </Box>
          )}
        </Box>
      </Modal>
      <VerifyQuery
        open={openVerifyGroup}
        handleClose={handlerCloseVerifyGroupMessage}
        handelAcceptGroupQuery={handlerVerifyGroup}
      />
      <InclusionEFDMessage
        open={openEFDMessage}
        handleClose={handlerCloseEFDMessage}
        handelAcceptEFD={handlerAcceptEFDMessage}
      />
    </>
  )
}
