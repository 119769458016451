import { ArticleCategory } from '../enums/ArticleCategory'
import { LangType } from '../enums/LangType'
import { Query } from '../../../common/api/Query'
import { FormType } from '../../forms/enums/FormType'
import { ArticleDTO } from './ArticleDTO'

export type ArticleProps = {
  id?: string | undefined
  category: ArticleCategory
  lang: LangType
  title: string
  content: string
  files: string[]
  metaTitle: string
  metaDescription: string
  ogTitle: string
  ogDescription: string
  ogImage: string[]
  slug: string // unique
  createdAt: Date
  updatedAt: Date
  tags: string[]
  // new
  userCircleID: string
  rejectedReason: string
  authorName: string
  categoryText: string
  author: string
  type: string
  circleId: string
  userId: string
  relevance: string
  visualizations: number
  sharedTimes: number
  likes: number
  comments: string[]
  interactions: number
  status: string
  scope: number
}

export class Article {
  private readonly _id: string | undefined
  private readonly _createdAt: Date

  private allFields?: string
  private _category: string
  private _lang: LangType
  private _title: string
  private _content: string
  private _files: string[]
  private _metaTitle: string
  private _metaDescription: string
  private _ogTitle: string
  private _ogDescription: string
  private _ogImage: string[]
  private _slug: string
  private _updatedAt: Date
  private _tags: string[]
  // new
  private _userCircleID: string | undefined
  private _authorName: string | undefined
  private _rejectedReason: string | undefined
  private _categoryText: string | undefined
  private _author: string | undefined
  private _type: string
  private _circleId: string
  private _userID: string
  private _relevance: string
  private _visualizations: number
  private _sharedTimes: number
  private _likes: number
  private _comments: string[]
  private _interactions: number
  private _status: string
  private _scope: number

  constructor(props: ArticleDTO) {
    this._id = props.id
    this._createdAt = props.createdAt
    this._category = props.category
    this._lang = props.lang
    this._title = props.title
    this._content = props.content
    this._files = props.files
    this._metaTitle = props.metaTitle
    this._metaDescription = props.metaDescription
    this._ogTitle = props.ogTitle
    this._ogDescription = props.ogDescription
    this._ogImage = props.ogImage
    this._slug = props.slug
    this._updatedAt = props.updatedAt
    this._tags = props.tags
    // new
    this._userCircleID = props.userCircleID
    this._rejectedReason = props.rejectedReason
    this._authorName = props.authorName
    this._categoryText = props.categoryText
    this._author = props.author
    this._type = props.type
    this._circleId = props.circleId
    this._userID = props.userId
    this._relevance = props.relevance
    this._visualizations = props.visualizations
    this._sharedTimes = props.sharedTimes
    this._likes = props.likes
    this._comments = props.comments
    this._interactions = props.interactions
    this._status = props.status
    this._scope = props.scope
  }

  get id(): string | undefined {
    return this._id
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get title(): string {
    return this._title
  }

  set title(value: string) {
    this._title = value
  }

  get content(): string {
    return this._content
  }

  set content(value: string) {
    this._content = value
  }

  get category(): string {
    return this._category
  }

  set category(value: string) {
    this._category = value
  }

  get userCircleID(): string | undefined {
    return this._userCircleID
  }

  set userCircleID(value: string) {
    this._userCircleID = value
  }

  set type(value: string) {
    this._type = value
  }

  get type(): string {
    return this._type
  }

  set categoryText(value: string) {
    this._categoryText = value
  }

  get categoryText(): string | undefined {
    return this._categoryText
  }

  set rejectedReason(value: string) {
    this._rejectedReason = value
  }

  get rejectedReason(): string | undefined {
    return this._rejectedReason
  }

  set authorName(value: string) {
    this._authorName = value
  }

  get authorName(): string | undefined {
    return this._authorName
  }

  set author(value: string) {
    this._author = value
  }

  get author(): string | undefined {
    return this._author
  }

  get lang(): LangType {
    return this._lang
  }

  set lang(value: LangType) {
    this._lang = value
  }

  get files(): string[] {
    if (!this._files) return []
    return [...this._files]
  }

  addFile(value: string) {
    this._files.push(value)
  }

  removeFile(value: string) {
    this._files = this._files.filter((f) => f !== value)
  }

  get metaTitle(): string {
    return this._metaTitle
  }

  set metaTitle(value: string) {
    this._metaTitle = value
  }

  get metaDescription(): string {
    return this._metaDescription
  }

  set metaDescription(value: string) {
    this._metaDescription = value
  }

  get ogTitle(): string {
    return this._ogTitle
  }

  set ogTitle(value: string) {
    this._ogTitle = value
  }

  get ogDescription(): string {
    return this._ogDescription
  }

  set ogDescription(value: string) {
    this._ogDescription = value
  }

  get ogImage(): string[] {
    return this._ogImage
  }

  addOgImage(value: string) {
    this._ogImage.push(value)
  }

  removeOgImage(value: string) {
    this._ogImage = this._ogImage.filter((i) => i !== value)
  }

  get slug(): string {
    return this._slug
  }

  set slug(value: string) {
    this._slug = value
  }

  get updatedAt(): Date {
    return this._updatedAt
  }

  set updatedAt(value: Date) {
    this._updatedAt = value
  }

  get tags(): string[] {
    if (!this._tags) return []
    return [...this._tags]
  }

  set tags(value: string[]) {
    this._tags = value
  }

  addTag(value: string) {
    this._tags.push(value)
  }

  removeTag(tagID: string) {
    this._tags = this._tags.filter((t) => t !== tagID)
  }

  // new
  get circleId(): string {
    return this._circleId
  }

  set circleId(value: string) {
    this._circleId = value
  }

  get userId(): string {
    return this._userID
  }

  // eslint-disable-next-line accessor-pairs
  set userID(value: string) {
    this._userID = value
  }

  get relevance(): string {
    return this._relevance
  }

  set relevance(value: string) {
    this._relevance = value
  }

  get visualizations(): number {
    return this._visualizations
  }

  set visualizations(value: number) {
    this._visualizations = value
  }

  get sharedTimes(): number {
    return this._sharedTimes
  }

  set sharedTimes(value: number) {
    this._sharedTimes = value
  }

  get likes(): number {
    return this._likes
  }

  set likes(value: number) {
    this._likes = value
  }

  get comments(): string[] {
    return this._comments
  }

  set comments(value: string[]) {
    this._comments = value
  }

  get interactions(): number {
    return this._interactions
  }

  set interactions(value: number) {
    this._interactions = value
  }

  get status(): string {
    return this._status
  }

  set status(value: string) {
    this._status = value
  }

  get scope(): number {
    return this._scope
  }

  set scope(value: number) {
    this._scope = value
  }
}

export class ArticleQuery extends Query<Article> {
  ids?: string[]
  title?: string
  userID?: string
  creator?: string
  allFields?: string
  isTemplate?: FormType
  specialities?: string
  others?: string

  fillWithCreatorName?: string
}
