/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArticleCategory } from '../enums/ArticleCategory'
import { ArticleStatus } from '../enums/ArticleStatus'
import { LangType } from '../enums/LangType'
import { Article } from './Article'
import { v4 as uuidv4 } from 'uuid'

export interface ArticleDTO {
  id: string | undefined
  category: string
  lang: LangType
  title: string
  content: string
  files: string[]
  metaTitle: string
  metaDescription: string
  ogTitle: string
  ogDescription: string
  ogImage: string[]
  slug: string // unique
  createdAt: Date
  updatedAt: Date
  tags: string[]
  // new
  userCircleID?: string
  rejectedReason?: string | undefined
  author?: string | undefined
  authorName?: string | undefined
  categoryText?: string | undefined
  type: string
  circleId: string
  userId: string
  relevance: string
  visualizations: number
  sharedTimes: number
  likes: number
  comments: string[]
  interactions: number
  status: string
  scope: number
}

export function emptyArticleDTO() {
  return {
    id: uuidv4(),
    category: '',
    lang: LangType.Spanish,
    title: '',
    content: '',
    files: [],
    metaTitle: '',
    metaDescription: '',
    ogTitle: '',
    ogDescription: '',
    ogImage: [],
    slug: 'slug',
    createdAt: new Date(),
    updatedAt: new Date(),
    tags: [],
    // new
    userCircleID: '',
    rejectedReason: undefined,
    authorName: '',
    type: '',
    circleID: '',
    circleId: '',
    userId: '',
    relevance: '',
    visualizations: 0,
    sharedTimes: 0,
    likes: 0,
    comments: [],
    interactions: 0,
    status: ArticleStatus[ArticleStatus.borrador],
    scope: 0,
  }
}

export function fromModel(a: Article): ArticleDTO {
  return {
    id: a.id,
    category: a.category,
    lang: a.lang,
    title: a.title,
    content: a.content,
    files: a.files || [],
    metaTitle: a.metaTitle,
    metaDescription: a.metaDescription,
    ogTitle: a.ogTitle,
    ogDescription: a.ogDescription,
    ogImage: a.ogImage || [],
    slug: a.slug, // unique
    createdAt: a.createdAt,
    updatedAt: a.updatedAt,
    tags: a.tags,
    // new
    userCircleID: a.userCircleID,
    rejectedReason: a.rejectedReason,
    authorName: a.authorName,
    type: a.type,
    circleId: a.circleId,
    userId: a.userId,
    relevance: a.relevance,
    visualizations: a.visualizations,
    sharedTimes: a.sharedTimes,
    likes: a.likes,
    comments: a.comments,
    interactions: a.interactions,
    status: a.status,
    scope: a.scope,
  }
}

export function toModel(d: ArticleDTO): Article {
  return new Article(d)
}
