import { RouteProps } from '../../routes/AppRouter'
import { VaccinesForm } from '../../features/vaccines'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function EditVaccine(props: RouteProps) {
  const { id } = useParams()
  const title = props.title ?? ''
  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <VaccinesForm id={id} title={title} />
    </>
  )
}
