/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { SourceType } from '../../../modules/notifications/enums/SourceType'
import { Notification } from '../../../modules/notifications/models/Notification'
import { DeliveryStatus } from '../../../modules/notifications/enums/DeliveryStatus'
import { PriorityLevel } from '../../../modules/notifications/enums/PriorityLevel'
import { Message } from 'm../../modules/notifications/models/Message'
import { TransportType } from '../../../common/enums/TransportType'
import { Box, Checkbox, Grid, Input, Modal, TextField } from '@mui/material'
import { CustomModal } from '../../../components/modal/CustomModal'
import style from './CustomModal.module.css'
import { Header } from '../../../components/header/Header'
import close from '../../../assets/table_icons/ico-eliminar.svg'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { PieChart } from '@mui/x-charts/PieChart'
import styles from '../symptoms/Editor.module.css'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import Button from '@mui/material/Button'
import InformePlanActiva from './informePlanActiva.json'
import {
  axisClasses,
  BarChart,
  BarPlot,
  ChartsXAxis,
  ChartsYAxis,
  LineChart,
  LinePlot,
  ResponsiveChartContainer,
} from '@mui/x-charts'
import Textarea from '@react-buddy/ide-toolbox/dist/previews/tools-panel/props-edit-table/table-items/table-item/table-item-control/textarea'
import { PrintPDFComponent } from './PrintPDF'
import { getReportContainer } from '../../../container/report-module'
import { ReportService } from '../../../modules/report/services/ReportService'
import { REPORT_SERVICE_KEY } from '../../../modules/report'

function DataLabel({ label, data }: any) {
  return (
    <Grid item xs={6} padding={'7px'}>
      <label style={{ fontWeight: 'bold' }}>{label}: </label>
      <span>{data}</span>
    </Grid>
  )
}

function SubHeader({ label }: any) {
  return (
    <Grid
      item
      xs={12}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      paddingTop={'30px'}
      borderBottom={'2px solid #68b3e0'}
    >
      <div>
        <p style={{ fontWeight: 'bold' }}>{label}</p>
      </div>
    </Grid>
  )
}

function GeneralData({ t, data }: any) {
  return (
    <>
      <SubHeader label={t('generalData')} />
      <Grid
        container
        display={'flex'}
        marginTop={'15px'}
        border={'1px solid rgb(104 213 231)'}
        borderRadius={'17px'}
        padding={'15px'}
      >
        <DataLabel label={'Nombre del paciente'} data={data.patientName} />
        <DataLabel label={'Tipo de programa'} data={data.programType} />
        <DataLabel label={'Edad'} data={data.age} />
        <DataLabel label={'Fecha de inicio del programa'} data={data.dateStartProgram} />
        <DataLabel label={'Centro de salud'} data={data.center} />
        <DataLabel label={'Fecha de finalización del programa'} data={data.dateFinishProgram} />
        <DataLabel label={'Profesional'} data={data.professional} />
        <DataLabel label={'Periodo del informe'} data={data.period} />
        <DataLabel label={'Categoría del profesional'} data={data.professionalCategory} />
      </Grid>
    </>
  )
}

function SpecificData({ t, data }: any) {
  return (
    <>
      <SubHeader label={t('specificData')} />
      <Grid
        container
        display={'flex'}
        marginTop={'15px'}
        border={'1px solid rgb(104 213 231)'}
        borderRadius={'17px'}
        padding={'15px'}
      >
        <DataLabel label={'Centro deportivo'} data={data.sportCenter} />
        <DataLabel label={'Aforo'} data={data.capacity} />
        <DataLabel label={'Dirección'} data={data.address} />
        <DataLabel label={'Nombre EFD'} data={data.EFDName} />
        <DataLabel label={'Ayuntamiento asociado'} data={data.center} />
        <DataLabel label={'Correo EFD'} data={data.EFDEmail} />
        <DataLabel label={'Periodo'} data={data.period} />
        <DataLabel label={'Dias de entrenamiento'} data={data.trainingDays} />
        <DataLabel label={'Grupo al que pertenece'} data={data.group} />
      </Grid>
    </>
  )
}

function RCV({ t, data }: any) {
  return (
    <>
      <SubHeader label={t('RCV')} />
      <Grid
        container
        display={'flex'}
        marginTop={'15px'}
        border={'1px solid rgb(104 213 231)'}
        borderRadius={'17px'}
        padding={'15px'}
      >
        <DataLabel label={'Riesgo cardiovascular'} data={data.rcv} />
      </Grid>
    </>
  )
}

function TotalSessions({ t, data }: any) {
  return (
    <>
      <SubHeader label={t('totalSessions')} />
      <Grid
        container
        display={'flex'}
        marginTop={'15px'}
        border={'1px solid rgb(104 213 231)'}
        borderRadius={'17px'}
        padding={'15px'}
        justifyContent={'center'}
      >
        <DataLabel
          label={'Número total de sesiones durante el programa'}
          data={data.totalSessions + ' sesiones'}
        />
        <DataLabel
          label={'Sesiones realizadas totales'}
          data={data.totalProgramSessions + ' sesiones'}
        />
        <DataLabel
          label={'Sesiones marcadas como no asistidas'}
          data={data.unattendedSessions + ' sesiones'}
        />
        <DataLabel
          label={'Asistencia promedio total'}
          data={data.averageTotalAttendance + ' sesiones'}
        />
        <Grid
          item
          display={'flex'}
          padding={'15px'}
          flexDirection={'column'}
          border={'1px solid rgb(104 213 231)'}
          borderRadius={'17px'}
          justifyContent={'center'}
        >
          <h4 style={{ textAlign: 'center' }}>Porcentaje de Asistencia</h4>
          <PieChart
            series={[
              {
                data: [
                  {
                    id: 0,
                    value: data.totalProgramSessions.toString(),
                    label: 'Sesiones realizadas',
                  },
                  {
                    id: 1,
                    value: data.unattendedSessions.toString(),
                    label: 'Sesiones no asistidas',
                  },
                ],
              },
            ]}
            width={600}
            height={200}
          />
        </Grid>
      </Grid>
    </>
  )
}

const valueFormatter = (value: string) =>
  new Date(value).toLocaleDateString('es-ES', { day: 'numeric', month: 'numeric', year: 'numeric' })

function AttendanceMonth({ t, data }: any) {
  return (
    <>
      <SubHeader label={t('attendanceMonth')} />
      <Grid
        container
        display={'flex'}
        marginTop={'15px'}
        border={'1px solid rgb(104 213 231)'}
        borderRadius={'17px'}
        padding={'15px'}
        justifyContent={'center'}
      >
        <Grid
          item
          display={'flex'}
          padding={'15px'}
          flexDirection={'column'}
          border={'1px solid rgb(104 213 231)'}
          borderRadius={'17px'}
          justifyContent={'center'}
        >
          <h4 style={{ textAlign: 'center' }}>Asistencia</h4>
          <BarChart
            dataset={data.items}
            xAxis={[{ scaleType: 'band', dataKey: 'date', valueFormatter }]}
            series={[{ dataKey: 'attendance' }]}
            width={500}
            height={300}
            barLabel="value"
          />
        </Grid>
      </Grid>
    </>
  )
}

function IntensityMonth({ t, data }: any) {
  return (
    <>
      <SubHeader label={t('intensityTrainMonth')} />
      <Grid
        container
        display={'flex'}
        marginTop={'15px'}
        border={'1px solid rgb(104 213 231)'}
        borderRadius={'17px'}
        padding={'15px'}
        justifyContent={'center'}
      >
        <Grid
          item
          display={'flex'}
          padding={'15px'}
          flexDirection={'column'}
          border={'1px solid rgb(104 213 231)'}
          borderRadius={'17px'}
          justifyContent={'center'}
        >
          <h4 style={{ textAlign: 'center' }}>Intensidad</h4>
          <LineChart
            dataset={data.items}
            disableLineItemHighlight={false}
            grid={{ horizontal: true }}
            xAxis={[{ scaleType: 'band', dataKey: 'date', valueFormatter }]}
            series={[{ curve: 'linear', dataKey: 'intensity' }]}
            width={500}
            height={300}
          />
        </Grid>
      </Grid>
    </>
  )
}

function AntroData({ t, data }: any) {
  return (
    <>
      <SubHeader label={t('antroData')} />
      <Grid
        container
        display={'flex'}
        marginTop={'15px'}
        border={'1px solid rgb(104 213 231)'}
        borderRadius={'17px'}
        padding={'15px'}
        justifyContent={'center'}
      >
        <Grid
          item
          display={'flex'}
          padding={'15px'}
          width={'85%'}
          flexDirection={'column'}
          border={'1px solid rgb(104 213 231)'}
          borderRadius={'17px'}
          justifyContent={'center'}
        >
          <h4 style={{ textAlign: 'center' }}>Peso e IMC</h4>
          <ResponsiveChartContainer
            dataset={data.items}
            xAxis={[
              {
                scaleType: 'band',
                id: 'quarters',
                dataKey: 'date',
                valueFormatter,
              },
            ]}
            yAxis={[{ id: 'money' }, { id: 'quantities' }]}
            series={[
              {
                type: 'line',
                id: 'revenue',
                yAxisKey: 'money',
                dataKey: 'imc',
              },
              {
                type: 'bar',
                id: 'cookies',
                yAxisKey: 'quantities',
                dataKey: 'weight',
              },
            ]}
            height={400}
            margin={{ left: 70, right: 70 }}
            sx={{
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-25px, 0)',
              },
              [`.${axisClasses.right} .${axisClasses.label}`]: {
                transform: 'translate(30px, 0)',
              },
            }}
          >
            <BarPlot />
            <LinePlot />
            <ChartsXAxis axisId="quarters" label="Quarters" labelFontSize={18} />
            <ChartsYAxis axisId="quantities" label="Peso" />
            <ChartsYAxis axisId="money" position="right" label="Kg/m2" />
          </ResponsiveChartContainer>
        </Grid>
      </Grid>
    </>
  )
}

function Condition({ t, data }: any) {
  return (
    <>
      <SubHeader label={t('condition')} />
      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        marginTop={'15px'}
        border={'1px solid rgb(104 213 231)'}
        borderRadius={'17px'}
        padding={'15px'}
        justifyContent={'center'}
      >
        <Grid
          item
          display={'flex'}
          padding={'15px'}
          flexDirection={'column'}
          border={'1px solid rgb(104 213 231)'}
          borderRadius={'17px'}
          justifyContent={'center'}
        >
          <h4 style={{ textAlign: 'center' }}>Capacidad aeróbica</h4>
          <BarChart
            dataset={data.items}
            xAxis={[{ scaleType: 'band', dataKey: 'date', valueFormatter }]}
            series={[{ dataKey: 'drawerTestValue' }]}
            width={800}
            height={300}
          />
        </Grid>

        <Grid
          item
          display={'flex'}
          padding={'15px'}
          flexDirection={'column'}
          border={'1px solid rgb(104 213 231)'}
          borderRadius={'17px'}
          justifyContent={'center'}
        >
          <h4 style={{ textAlign: 'center' }}>Test del cajon y Lanzamiento de balón</h4>
          <BarChart
            dataset={data.items}
            xAxis={[{ scaleType: 'band', dataKey: 'date', valueFormatter }]}
            series={[{ dataKey: 'ballTestThrow' }, { dataKey: 'mileTestValue' }]}
            width={800}
            height={300}
          />
        </Grid>

        <Grid
          item
          display={'flex'}
          padding={'15px'}
          flexDirection={'column'}
          border={'1px solid rgb(104 213 231)'}
          borderRadius={'17px'}
          justifyContent={'center'}
        >
          <h4 style={{ textAlign: 'center' }}>Test del flamenco</h4>
          <BarChart
            dataset={data.items}
            grid={{ horizontal: true }}
            xAxis={[{ scaleType: 'band', dataKey: 'date', valueFormatter }]}
            series={[{ dataKey: 'flamingoTest' }]}
            width={800}
            height={300}
          />
        </Grid>
      </Grid>
    </>
  )
}

function SF12({ t, data }: any) {
  return (
    <>
      <SubHeader label={t('SF12')} />
      <Grid
        container
        display={'flex'}
        marginTop={'15px'}
        border={'1px solid rgb(104 213 231)'}
        borderRadius={'17px'}
        padding={'15px'}
        justifyContent={'center'}
      >
        <Grid
          item
          display={'flex'}
          padding={'15px'}
          flexDirection={'column'}
          border={'1px solid rgb(104 213 231)'}
          borderRadius={'17px'}
          justifyContent={'center'}
        >
          <h4 style={{ textAlign: 'center' }}>Cuestionario de salud</h4>
          <LineChart
            dataset={data.items}
            disableLineItemHighlight={false}
            grid={{ horizontal: true }}
            xAxis={[{ scaleType: 'band', dataKey: 'date', valueFormatter }]}
            series={[
              { curve: 'linear', dataKey: 'mentalHealth', label: 'Salud mental' },
              { curve: 'linear', dataKey: 'optimalPercentage', label: 'Porcentaje óptimo' },
              { curve: 'linear', dataKey: 'physicalHealth', label: 'Salud mental' },
            ]}
            width={500}
            height={300}
          />
        </Grid>
      </Grid>
    </>
  )
}

function EfdComments({ t, data }: any) {
  return (
    <>
      <SubHeader label={t('efdComments')} />
      <Grid
        container
        display={'flex'}
        marginTop={'15px'}
        border={'1px solid rgb(104 213 231)'}
        borderRadius={'17px'}
        padding={'15px'}
        justifyContent={'left'}
      >
        <Grid
          item
          display={'flex'}
          padding={'15px'}
          flexDirection={'column'}
          borderRadius={'17px'}
          justifyContent={'start'}
        >
          <span>Lorem ipsum</span>
        </Grid>
      </Grid>
    </>
  )
}

const reportContainer = getReportContainer()
const reportService = reportContainer.get<ReportService>(REPORT_SERVICE_KEY)

export function ReportPreview({ handleClose, reportId, dataToPrint, reportData }: any) {
  const { t } = useTranslation()
  const [description, setDescription] = useState('')
  // const [reportProps, setReportProps] = useState<ReportProps>(EmptyReportProps())
  const [openMessageCreated, setOpenMessageCreated] = useState<boolean>(false)
  const [previewReportModal, setPreviewReportModal] = useState<boolean>(false)
  const [title, setTitle] = useState('')
  const [toDate, setToDate] = useState<Date>(new Date())
  const [dataToReport, setDataToReport] = useState({})
  const [generalData, setGeneralData] = useState({})
  const [specificData, setSpecificData] = useState({})
  const [totalSessions, setTotalSessions] = useState(null)
  const [attendance, setAttendance] = useState(null)
  const [intensityMonth, setIntensityMonth] = useState(null)
  const [antroData, setAntroData] = useState(null)
  const [condition, setCondition] = useState(null)
  const [efdComments, setEfdComments] = useState(null)
  const [rcv, setRCV] = useState(null)
  const [sf12, setSf12] = useState(null)
  const [dataPrint, setDataToPrint] = useState({
    generalData: {},
    attendanceMonth: {},
    SF12: {},
    specificData: {},
    intensityMonth: {},
    efdComments: {},
    RCV: {},
    antroData: {},
    totalSessions: {},
    condition: {},
  })

  const [fromDate, setFromDate] = useState<Date>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  )

  useEffect(() => {
    if (dataToPrint.generalData) {
      reportService.getGeneralInformationbyId(reportId).subscribe((resp) => {
        if (localStorage.getItem('selected user circle')) {
          const userCircle = JSON.parse(localStorage.getItem('selected user circle') ?? 'null').user
          console.log(reportData)
          setGeneralData({
            patientName: userCircle.name + ' ' + userCircle.lastname,
            age: '45',
            programType: resp?.programType,
            // @ts-ignore
            period:
              new Date(reportData.startDate).toLocaleDateString('es-ES', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }) +
              ' - ' +
              new Date(reportData.finishDate).toLocaleDateString('es-ES', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }),
            professional: resp?.prescribingProfessional,
            center: resp?.healthCentre,
            professionalCategory: resp?.professionalCategory,
            // @ts-ignore
            dateStartProgram: new Date(resp.programStartDate).toLocaleDateString('es-ES', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }),
            // @ts-ignore
            dateFinishProgram: new Date(resp.programFinishDate).toLocaleDateString('es-ES', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            }),
          })
        }
      })
    }
    if (dataToPrint.specificData) {
      reportService.getSpecificInformationbyId(reportId).subscribe((resp) => {
        setSpecificData({
          sportCenter: resp?.sportCentre,
          capacity: resp?.capacity,
          address: resp?.address,
          EFDName: resp?.efdName,
          center: resp?.cityHall,
          EFDEmail: resp?.efdEmail,
          period:
            new Date(resp.programStartDate).toLocaleDateString('es-ES', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            }) +
            ' - ' +
            new Date(resp.programFinishDate).toLocaleDateString('es-ES', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            }),
          trainingDays: 'test', // TODO
          group: resp.group,
        })
      })
    }
    if (dataToPrint.RCV) {
      reportService.getRcvValorationById(reportId).subscribe((resp) => {
        setRCV(resp)
      })
    }
    if (dataToPrint.sessionsAttendance) {
      reportService.getSessionsAttendancebyId(reportId).subscribe((resp) => {
        setTotalSessions(resp)
      })
    }
    if (dataToPrint.attendanceMonth) {
      reportService.getAvgAttendanceMonthById(reportId).subscribe((resp) => {
        if (resp) {
          setAttendance(resp)
        }
      })
    }
    if (dataToPrint.antroData) {
      reportService.getAnthropometricById(reportId).subscribe((resp) => {
        setAntroData(resp)
      })
    }
    if (dataToPrint.intensityMonth) {
      reportService.getAvgIntensityMonthById(reportId).subscribe((resp) => {
        setIntensityMonth(resp)
      })
    }
    if (dataToPrint.condition) {
      reportService.getConditionById(reportId).subscribe((resp) => {
        setCondition(resp)
      })
    }

    // if (dataToPrint.condition) {
    //   reportService.getAvgConditionMonthById(reportId).subscribe(resp => {
    //     console.log(resp)
    //   })
    // }

    if (dataToPrint.SF12) {
      reportService.getHealthReportById(reportId).subscribe((resp) => {
        setSf12(resp)
      })
    }

    if (dataToPrint.efdComments) {
      reportService.getEFDCommentsById(reportId).subscribe((resp) => {
        setEfdComments(resp)
      })
    }
  }, [])
  // }, [dataPrint])

  useEffect(() => {
    dataToPrint = { ...dataToPrint, generalData, specificData }
    setDataToPrint(dataToPrint)
  }, [dataToPrint])

  const handleSave = (event: any) => {}

  const handleFromDateChange = (date: any) => {
    if (date) {
      setFromDate(date.toDate())
    }
  }
  const handlePreview = () => {
    setPreviewReportModal(true)
  }

  const handleToDateChange = (date: any) => {
    if (date) {
      setToDate(date.toDate())
    }
  }

  const handleEdit = () => {}

  const handleDownload = () => {
    PrintPDFComponent(<ReportToPrint dataPrint={dataPrint} header={t('planActivaReport')} />).then(
      (resp) => console.log(resp)
    )
  }
  return (
    <div className={style.agoraContainer}>
      <Box
        className={style.containerInside}
        style={{ height: '600px', overflow: 'auto', justifyContent: 'space-between' }}
      >
        <Box style={{ overflow: 'auto' }}>
          <Header label={t('planActivaReport')} icon={close} onClick={handleClose} />
          <Grid container>
            {generalData && <GeneralData t={t} data={generalData} />}
            {specificData && <SpecificData t={t} data={specificData} />}
            {rcv && <RCV t={t} data={rcv} />}
            {totalSessions && <TotalSessions t={t} data={totalSessions} />}
            {attendance && <AttendanceMonth t={t} data={attendance} />}
            {intensityMonth && <IntensityMonth t={t} data={intensityMonth} />}
            {antroData && <AntroData t={t} data={antroData} />}
            {condition && <Condition t={t} data={condition} />}
            {sf12 && <SF12 t={t} data={sf12} />}
            {efdComments && <EfdComments t={t} data={dataPrint.efdComments} />}
          </Grid>
        </Box>
        {/* <Box display="flex" justifyContent="space-between"> */}
        {/*  <Box style={{ paddingTop: '20px' }}> */}
        {/*    <AppButton */}
        {/*      theme={ButtonTheme.NewPrimary} */}
        {/*      type={'submit'} */}
        {/*      label={t('edit')} */}
        {/*      handler={handleClose} */}
        {/*    /> */}
        {/*  </Box> */}

        {/* <Box style={{ paddingTop: '20px' }}> */}
        {/*  <AppButton */}
        {/*    theme={ButtonTheme.NewPrimary} */}
        {/*    type={'submit'} */}
        {/*    label={t('generate')} */}
        {/*    handler={handleDownload} */}
        {/*  /> */}
        {/* </Box> */}
        {/* </Box> */}
      </Box>
    </div>
  )
}

export function ReportToPrint({ dataPrint, header }: any) {
  const { t } = useTranslation()
  console.log(dataPrint)
  return (
    <div className={style.agoraContainer + 'printPDF'}>
      <Box className={style.containerInside}>
        <Box>
          <Header label={header} />
          <Grid container>
            {dataPrint.generalData && <GeneralData t={t} data={dataPrint.generalData} />}
            {dataPrint.specificData && <SpecificData t={t} data={dataPrint.specificData} />}
            {dataPrint.RCV && <RCV t={t} data={dataPrint.RCV} />}
            {dataPrint.totalSessions && <TotalSessions t={t} data={dataPrint.totalSessions} />}
            {dataPrint.attendance && <AttendanceMonth t={t} data={dataPrint.attendance} />}
            {dataPrint.intensityMonth && <IntensityMonth t={t} data={dataPrint.intensityMonth} />}
            {dataPrint.antroData && <AntroData t={t} data={dataPrint.antroData} />}
            {dataPrint.condition && <Condition t={t} data={dataPrint.condition} />}
            {dataPrint.sf12 && <SF12 t={t} data={dataPrint.sf12} />}
            {dataPrint.efdComments && <EfdComments t={t} data={dataPrint.efdComments} />}
          </Grid>
        </Box>
      </Box>
    </div>
  )
}
