import style from './NumericStatsCard.module.css'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam } from '../../../../common/api/Query'
import { UserFormQuery } from '../../../../modules/forms/models/UserForm'
import { UserFormService } from '../../../../modules/forms/services/UserFormService'
import { USER_FORM_SERVICE_KEY } from '../../../../modules/forms'
import { getFormContainer } from '../../../../container/form-module'
import { getUserCircleContainer } from '../../../../container/user-circle-module'
import {
  IUserCircleActiveService,
  USER_CIRCLE_ACTIVE_SERVICE_KEY,
} from '../../../../modules/user-circle'
import { ReadingStatus } from '../../../../modules/forms/enums/ReadingStatus'
import { useNavigate } from 'react-router-dom'
import {
  ROUTE_MESSENGER,
  ROUTE_PATIENT_FORMS,
  ROUTE_RESOURCES,
} from '../../../../routes/routes-constants'
import Box from '@mui/material/Box'

type NumericStatsCardProps = {
  numberPendingChats: number
  numberNewResources: number
}

const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
export const NumericStatsCard: React.FC<NumericStatsCardProps> = (props) => {
  const { t } = useTranslation()
  const activeFullUserCircle = UserCircleActiveService.getActiveFullUserCircle()
  const [pendingForms, setPendingForms] = React.useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    userFormService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<UserFormQuery>('userID', activeFullUserCircle?.user?.id ?? ''),
            new QueryParam<UserFormQuery>('userCircleID', activeFullUserCircle?.id ?? ''),
          ],
          sort: [{ field: 'assigned', desc: true }],
        })
      )
      .subscribe((res) => {
        let counter = 0
        res.items.forEach((form) => {
          if (form.readingStatus === ReadingStatus.Pending) {
            counter++
          }
        })
        setPendingForms(counter)
      })
  }, [])

  return (
    <Box className={style.container}>
      <Box className={style.containerWrapper}>
        <Box className={style.containerItem}>
          <div className={style.underLainedTitle}>{t('newForms')}</div>
          <div className={style.numberStat}>{pendingForms || 0}</div>
          <div className={style.showmore} onClick={() => navigate(ROUTE_PATIENT_FORMS)}>
            {t('showDetails')} &gt;
          </div>
        </Box>
        <Box className={style.containerItem}>
          <div className={style.underLainedTitle}>{t('pendingChats')}</div>
          <div className={style.numberStat}>{props.numberPendingChats || 0}</div>
          <div className={style.showmore} onClick={() => navigate(ROUTE_MESSENGER)}>
            {t('showDetails')} &gt;
          </div>
        </Box>
        <Box className={style.containerItem}>
          <div className={style.underLainedTitle}>{t('newResources')}</div>
          <div className={style.numberStat}>{props.numberNewResources || 0}</div>
          <div className={style.showmore} onClick={() => navigate(ROUTE_RESOURCES)}>
            {t('showDetails')} &gt;
          </div>
        </Box>
        <Box className={style.containerItem}>
          <div className={style.underLainedTitle}>{t('Access')}</div>
          <div className={style.numberStat}>{activeFullUserCircle?.user.accesses || 0}</div>
          <div className={style.lastUpdate}>{t('lastUpdate')}</div>
        </Box>
      </Box>
    </Box>
  )
}
