import { DashboardSectionCard } from '../../../../components/section-card/DashboardSectionCard'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_QUERY, ROUTE_TELECONSULTATION } from '../../../../routes/routes-constants'
import { Query, QueryParam } from '../../../../common/api/Query'
import { Consulta } from '../../../../modules/messenger_consulta/models/Consulta'
import { getMessengerConsultaContainer } from '../../../../container/messenger-consulta-module'
import { ConsultaService } from '../../../../modules/messenger_consulta/services/ConsultaService'
import { CONSULTA_SERVICE_KEY } from '../../../../modules/messenger_consulta'
import { getUserContainer } from '../../../../container/user-module'
import { LoggedUserService } from '../../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../../modules/users'
import { IUserCircleActiveService } from '../../../../modules/user-circle/services/UserCircleActiveService'
import { getUserCircleContainer } from '../../../../container/user-circle-module'
import { USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../../../modules/user-circle'
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import style from '../chats-card/ChatsCard.module.scss'
import queryIcon from '../../../../assets/dashboard/queryIcon.svg'
import { listItemTextStyle } from '../notification-card-patient/NotificationsCardStyleMui'
import styleNoti from '../notification-card-patient/NotificationCard.module.scss'
import { ConsultaDTO } from '../../../../modules/messenger_consulta/models/ConsultaDTO'

type ActiveQueriesCardProps = {}

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const queryService = getMessengerConsultaContainer().get<ConsultaService>(CONSULTA_SERVICE_KEY)
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

const mockConsultaData: ConsultaDTO[] = [
  {
    id: '1',
    name: 'Consulta 1',
    description: 'Descripción de la consulta 1',
    users: [], // Aquí puedes añadir los usuarios de la consulta
    messages: [], // Aquí puedes añadir los mensajes de la consulta
    unreadMessages: 0,
    closed: false,
    circleID: 'circle1',
    userCircleID: 'userCircle1',
    pathologyID: 'pathology1',
    consultaType: 'type1',
  },
  {
    id: '2',
    name: 'Consulta 2',
    description: 'Descripción de la consulta 2',
    users: [], // Aquí puedes añadir los usuarios de la consulta
    messages: [], // Aquí puedes añadir los mensajes de la consulta
    unreadMessages: 5,
    closed: true,
    circleID: 'circle2',
    userCircleID: 'userCircle2',
    pathologyID: 'pathology2',
    consultaType: 'type2',
  },
  // Puedes añadir más datos de prueba aquí
]

export const ActiveQueriesCard: React.FC<ActiveQueriesCardProps> = () => {
  const loggedUser = loggedUserService.get()
  const activeUser = UserCircleActiveService.getActiveFullUserCircle()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [conversationCollection, setConversationCollection] =
    useState<ConsultaDTO[]>(mockConsultaData)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading || !loggedUser?.id) {
      return
    }
    queryService
      .getFilteredItems(
        new Query({
          query: [
            new QueryParam<Consulta>('userID', activeUser?.user.id ?? ''),
            new QueryParam<Consulta>('circleID', activeUser?.circle?.id ?? ''),
          ],
        })
      )
      .subscribe((res) => {
        // setConversationCollection(res)
        setIsLoading(false)
      })
  }, [isLoading])

  const handlerGotoQueries = () => {
    navigate(ROUTE_QUERY)
  }
  return (
    <DashboardSectionCard label={'activeQuerys'} showMore={true} onClick={handlerGotoQueries}>
      {conversationCollection.length > 0 &&
        conversationCollection.slice(-3).map((conversation) => (
          <ListItem
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => navigate(ROUTE_TELECONSULTATION)}
            key={conversation.id}
          >
            <Box width={'100%'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                mr={1}
                ml={1}
                className={style.chatContainer}
              >
                <ListItemAvatar>
                  <Avatar
                    src={queryIcon}
                    style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF', padding: '0' }}
                  />
                </ListItemAvatar>
                <ListItemText sx={listItemTextStyle} className={styleNoti.listItemText}>
                  {conversation.name}
                </ListItemText>
              </Box>
            </Box>
          </ListItem>
        ))}
    </DashboardSectionCard>
  )
}
