import { TimeTable } from 'modules/training-group/models/TimeTable'
import { useTranslation } from 'react-i18next'
import style from './SelectGroup.module.css'
import { Box } from '@mui/material'
import { groupColors } from 'common/enums/Colors'
import { fullWeekdayTypes } from 'common/enums/Weekday'

interface SelectGroupItemsProps {
  index: number
  id: string
  name: string
  isChoosable: boolean
  disabled: boolean
  checked: boolean
  timeTable: TimeTable[]
  capacity: number
  registeredUserMap: Map<string, number>
  handleSelectGroup: () => void
}

export function SelectGroupItem(props: SelectGroupItemsProps) {
  const { t } = useTranslation()
  return (
    <Box className={style.groupBox}>
      <Box className={style.groupBoxRow}>
        <Box style={{ background: groupColors[props.index] }} className={style.nameGroup}>
          <span className={style.nameGroupText}>{props.name}</span>
        </Box>
        <Box className={style.middleGroupRadio}>
          <Box className={style.middleGroup}>
            <Box className={style.timeGroup}>
              {props.timeTable.map((tt) => (
                <Box className={style.timeGroupDay} key={tt.id}>
                  <b>{t(fullWeekdayTypes()[tt.weekDay])}</b>
                  <span>{getTime(tt)}</span>
                </Box>
              ))}
            </Box>
            <Box className={style.capacityGroup}>
              <Box className={style.capacityRow}>
                <b>{t('personCapacity')}</b>
                <span>{props.capacity}</span>
              </Box>
              <Box className={style.capacityRow}>
                <b>{t('registered')}</b>
                <span>{props.registeredUserMap.get(props.id)}</span>
              </Box>
            </Box>
          </Box>
          {props.isChoosable && (
            <Box className={style.choosableGroup}>
              <label className={style.choosableLabel}>
                <input
                  type="radio"
                  name="teleCall"
                  className={style.choosableCheck}
                  disabled={props.disabled}
                  onChange={() => props.handleSelectGroup()}
                  checked={props.checked}
                />
              </label>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

function addCero(n: number): string {
  let value = '0'
  if (n.toString().length === 1) {
    value += n
  } else {
    value = n.toString()
  }
  return value
}

function getTime(tt: TimeTable): string {
  return (
    '' +
    addCero(tt.startTime.getHours()) +
    ':' +
    addCero(tt.startTime.getMinutes()) +
    ' - ' +
    addCero(tt.finishTime.getHours()) +
    ':' +
    addCero(tt.finishTime.getMinutes())
  )
}
