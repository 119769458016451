/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { DataPatientContainerConfig } from '../container'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Wearable, WearableQuery } from '../models/Wearable'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { toModel, WearableDTO } from '../models/WearableDTO'
import { UserToken } from '../models/UserToken'
import { UserTokenDTO } from '../models/UserTokenDTO'
import { toModel as userCodeToModel } from '../../patient-data/models/UserTokenDTO'

export interface IWearableApi extends IInit {
  getFilteredItems(q: Query<WearableQuery>): Observable<Wearable[]>
  getHeartRate(q: Query<WearableQuery>): Observable<any>
  getHeartRateByDate(q: Query<WearableQuery>): Observable<any>
  getTemperatureByDate(q: Query<WearableQuery>): Observable<any>
  createToken(e: UserTokenDTO): Observable<UserToken | undefined>
  deleteToken(id: string): Observable<boolean>
  getLoginType(id: string): Observable<number>
}

export class WearableApi implements IWearableApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as DataPatientContainerConfig).moduleFullUrl
  }

  getFilteredItems(q: Query<WearableQuery>): Observable<Wearable[]> {
    // @ts-ignore
    return this._httpClient.get<Wearable[]>({ url: prepareURL(this._url + '/wearables', q) }).pipe(
      // @ts-ignore
      map<WearableDTO[], Wearable[]>((dto) => {
        return dto
        // dto.map((d) => toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  getHeartRate(q: Query<WearableQuery>): Observable<any> {
    // @ts-ignore
    return this._httpClient
      .get<any>({ url: prepareURL(this._url + '/wearables/heart-rate-intraday', q) })
      .pipe(
        // @ts-ignore
        map<any, any>((dto) => {
          return dto
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getHeartRateByDate(q: Query<WearableQuery>): Observable<any> {
    // @ts-ignore
    return this._httpClient
      .get<any>({ url: prepareURL(this._url + '/wearables/heart-rate-by-date-range', q) })
      .pipe(
        // @ts-ignore
        map<any, any>((dto) => {
          return dto
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getTemperatureByDate(q: Query<WearableQuery>): Observable<any> {
    // @ts-ignore
    return this._httpClient
      .get<any>({ url: prepareURL(this._url + '/wearables/temperature-by-date-range', q) })
      .pipe(
        // @ts-ignore
        map<any, any>((dto) => {
          return dto
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  createToken(e: UserTokenDTO): Observable<UserToken | undefined> {
    return this._httpClient.post<UserToken>({ url: this._url + '/user-tokens', body: e }).pipe(
      map<UserTokenDTO, UserToken>((dto) => userCodeToModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  deleteToken(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/user-tokens/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getData(date: string): Observable<any> {
    return this._httpClient
      .get<any>({ url: prepareURL(this._url + '/wearables/date/' + date) })
      .pipe(
        // map<WearableDTO[], Wearable[]>((dto) => dto.map((d) => toModel(d))),
        (response) => {
          return response
        },
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  getLoginType(id: string): Observable<number> {
    return this._httpClient.get<number>({ url: `${this._url}/user-tokens/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(0)
      })
    )
  }
}
