import { Query } from '../../../common/api/Query'

export type ReportProps = {
  id?: string
  createdByUserID: string
  userCircleID: string
  createdAt: Date
  startDate: Date
  finishDate: Date
  generalInformation: boolean
  specificInformation: boolean
  rcvValoration: boolean
  sessionsAttendance: boolean
  avgAttendanceMonth: boolean
  avgIntensityMonth: boolean
  anthropometricData: boolean
  physicalCondition: boolean
  healthFormulary: boolean
  efdComments: boolean
  previsualization: boolean
}

export class Report {
  private _id: string | undefined
  private _createdByUserID: string
  private _userCircleID: string
  private _createdAt: Date
  private _startDate: Date
  private _finishDate: Date
  private _generalInformation: boolean
  private _specificInformation: boolean
  private _rcvValoration: boolean
  private _sessionsAttendance: boolean
  private _avgAttendanceMonth: boolean
  private _avgIntensityMonth: boolean
  private _anthropometricData: boolean
  private _physicalCondition: boolean
  private _healthFormulary: boolean
  private _efdComments: boolean
  private _previsualization: boolean

  constructor(props: ReportProps) {
    this._id = props.id
    this._createdByUserID = props.createdByUserID
    this._userCircleID = props.userCircleID
    this._createdAt = props.createdAt
    this._startDate = props.startDate
    this._finishDate = props.finishDate
    this._generalInformation = props.generalInformation
    this._specificInformation = props.specificInformation
    this._rcvValoration = props.rcvValoration
    this._sessionsAttendance = props.sessionsAttendance
    this._avgAttendanceMonth = props.avgAttendanceMonth
    this._avgIntensityMonth = props.avgIntensityMonth
    this._anthropometricData = props.anthropometricData
    this._physicalCondition = props.physicalCondition
    this._healthFormulary = props.healthFormulary
    this._efdComments = props.efdComments
    this._previsualization = props.previsualization
  }

  get id(): string {
    return this._id as string
  }

  get createdByUserID(): string {
    return this._createdByUserID
  }

  set createdByUserID(value: string) {
    this._createdByUserID = value
  }

  get userCircleID(): string {
    return this._userCircleID
  }

  set userCircleID(value: string) {
    this._userCircleID = value
  }

  get createdAt(): Date {
    return this._createdAt
  }

  set createdAt(value: Date) {
    this._createdAt = value
  }

  get startDate(): Date {
    return this._startDate
  }

  set startDate(value: Date) {
    this._startDate = value
  }

  get finishDate(): Date {
    return this._finishDate
  }

  set finishDate(value: Date) {
    this._finishDate = value
  }

  get generalInformation(): boolean {
    return this._generalInformation
  }

  set generalInformation(value: boolean) {
    this._generalInformation = value
  }

  get specificInformation(): boolean {
    return this._specificInformation
  }

  set specificInformation(value: boolean) {
    this._specificInformation = value
  }

  get rcvValoration(): boolean {
    return this._rcvValoration
  }

  set rcvValoration(value: boolean) {
    this._rcvValoration = value
  }

  get sessionsAttendance(): boolean {
    return this._sessionsAttendance
  }

  set sessionsAttendance(value: boolean) {
    this._sessionsAttendance = value
  }

  get avgAttendanceMonth(): boolean {
    return this._avgAttendanceMonth
  }

  set avgAttendanceMonth(value: boolean) {
    this._avgAttendanceMonth = value
  }

  get avgIntensityMonth(): boolean {
    return this._avgIntensityMonth
  }

  set avgIntensityMonth(value: boolean) {
    this._avgIntensityMonth = value
  }

  get anthropometricData(): boolean {
    return this._anthropometricData
  }

  set anthropometricData(value: boolean) {
    this._anthropometricData = value
  }

  get physicalCondition(): boolean {
    return this._physicalCondition
  }

  set physicalCondition(value: boolean) {
    this._physicalCondition = value
  }

  get healthFormulary(): boolean {
    return this._healthFormulary
  }

  set healthFormulary(value: boolean) {
    this._healthFormulary = value
  }

  get efdComments(): boolean {
    return this._efdComments
  }

  set efdComments(value: boolean) {
    this._efdComments = value
  }

  get previsualization(): boolean {
    return this._previsualization
  }

  set previsualization(value: boolean) {
    this._previsualization = value
  }
}

export class ReportQuery extends Query<Report> {
  ids?: string[]
  id?: string
  userCircleID?: string
  userProfessionalID?: string
  professionalMessage?: string
  status?: string
  startDate?: Date
  finishDate?: Date
  reason?: string
  observation?: string
  patientByAge?: number
  patientByHospital?: number
  withUser?: string
}
