// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CircleStateCard_chartContainer__2wcUz {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n\ncanvas {\n  max-height: 300px;\n}\n\n.CircleStateCard_noData__ML9Fm {\n  width: 100%; /* Ocupa todo el ancho del contenedor */\n  text-align: center;\n  font-size: 3rem;\n  color: var(--color-dark-blue);\n  padding-top: 30px;\n}", "",{"version":3,"sources":["webpack://src/features/dashboard/profesional/pie-circle-state-card/CircleStateCard.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AACA;EACG,WAAA,EAAA,uCAAA;EACA,kBAAA;EACA,eAAA;EACA,6BAAA;EACA,iBAAA;AAEH","sourcesContent":[".chartContainer {\n    display: flex ;\n    flex-direction: column ;\n    align-items: flex-end ;\n    \n}\ncanvas{\n    max-height: 300px;\n}\n.noData{\n   width: 100%;          /* Ocupa todo el ancho del contenedor */\n   text-align: center; \n   font-size: 3rem;\n   color:var(--color-dark-blue);\n   padding-top: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartContainer": "CircleStateCard_chartContainer__2wcUz",
	"noData": "CircleStateCard_noData__ML9Fm"
};
export default ___CSS_LOADER_EXPORT___;
