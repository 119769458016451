import { Message } from '../../messenger_consulta/models/Message'
import { TeleConsulta } from './TeleConsulta'
import { ClosedStatus } from '../enums/ClosedStatus'
import { v4 as uuidv4 } from 'uuid'

export interface TeleconsultaDTO {
  id: string | undefined
  name: string
  description: string
  patient: string
  professional: string
  messages: Message[] | undefined
  unreadMessages: number
  closed: ClosedStatus
  userCircleID: string
  date: Date
}

export function emptyTeleConsultaDTO(user: string, userCircleID: string) {
  const id = uuidv4()
  return {
    id,
    name: '',
    description: '',
    patient: user,
    professional: '',
    messages: [],
    unreadMessages: 0,
    closed: ClosedStatus.Opened,
    userCircleID: userCircleID,
    date: new Date(),
  }
}

export function fromModel(c: TeleConsulta): TeleconsultaDTO {
  return {
    id: c.id,
    name: c.name,
    description: c.description,
    patient: c.patient,
    professional: c.professional,
    messages: c.messages,
    unreadMessages: c.unreadMessages,
    closed: c.closed,
    userCircleID: c.userCircleID,
    date: c.date,
  }
}

export function fromListModel(c: TeleConsulta[]): TeleconsultaDTO[] {
  return c.map((item) => fromModel(item))
}

export function toModel(d: TeleconsultaDTO): TeleConsulta {
  return new TeleConsulta({
    id: d.id,
    name: d.name,
    description: d.description,
    patient: d.patient,
    professional: d.professional,
    messages: d.messages,
    unreadMessages: d.unreadMessages,
    closed: d.closed,
    userCircleID: d.userCircleID,
    date: d.date,
  })
}
