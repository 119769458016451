import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { ConfidentialityTermService } from '../../modules/confidentialityTerm/services/ConfidentialityTermService'
import { getConfidentialityTermContainer } from '../../container/confidentialityTerm-module'
import { CONFIDENTIALITY_TERM_SERVICE_KEY } from '../../modules/confidentialityTerm'
import { ConfidentialityTerm as CT } from '../../modules/confidentialityTerm/models/ConfidentialityTerm'
import {
  emptyConfidentialityTermDTO,
  toModel,
  fromModel,
} from '../../modules/confidentialityTerm/models/ConfidentialityTermDTO'
import { TreatPersonalData } from './TreatPersonalData'
import { ExternalAgent } from './ExternalAgent'
import style from '../../Login.module.css'
import style2 from './ConfiedentialityTermStyle.module.css'
import concierge from '../../assets/brand_logo/conserjeria.png'
import murciaHealth from '../../assets/brand_logo/murciasalud.png'
import { getUserContainer } from '../../container/user-module'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { Box, Checkbox, Divider, FormControlLabel } from '@mui/material'
import Button from '@mui/material/Button'
import logoEsfera from '../../assets/esfera/logo-esfera.svg'
import stylesHeader from '../../pages/layout/HeaderEsfera.module.css'
import { styled } from '@mui/material/styles'

const confTermService = getConfidentialityTermContainer().get<ConfidentialityTermService>(
  CONFIDENTIALITY_TERM_SERVICE_KEY
)
const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

type ConfidentialityTermProps = {
  handlerAcceptedTerm?: (value: boolean) => void
}

export function ConfidentialityTerm(props: ConfidentialityTermProps) {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [confTerm, setConfTerm] = useState<CT>(toModel(emptyConfidentialityTermDTO()))

  const [personalDataCheck, setPersonalDataCheck] = useState<boolean>(false)
  const [personalData, setPersonalData] = useState<boolean>(false)
  const [disablePersonalData, setDisablePersonalData] = useState<boolean>(true)
  const [externalAgentCheck, setExternalAgentCheck] = useState<boolean>(false)
  const [externalAgent, setExternalAgent] = useState<boolean>(false)
  const [disableExternalAgent, setDisableExternalAgent] = useState<boolean>(true)
  const loggedUser = loggedUserService.get()

  const handlerNext = (
    e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault()
    if (!loggedUser?.id) return
    const ct = fromModel(confTerm)
    ct.userID = loggedUser.id
    confTermService.add(ct).subscribe(() => {
      if (props.handlerAcceptedTerm) {
        props.handlerAcceptedTerm(false)
      }
      window.location.reload()
    })
  }

  const handlerBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setPersonalData(false)
    setExternalAgent(false)
  }

  const handlerPersonalData = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    setPersonalData(true)
  }

  const handlerChangePersonalData = (event: any) => {
    setPersonalDataCheck(event.target.checked)
  }

  const handlerDisablePersonalData = (value: boolean) => {
    setDisablePersonalData(value)
  }

  const handlerExternalAgent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    setExternalAgent(true)
  }

  const handlerChangeExternalAgent = (event: any) => {
    setExternalAgentCheck(event.target.checked)
  }

  const handlerDisableExternalAgent = (value: boolean) => {
    setDisableExternalAgent(value)
  }

  const handlerDisableButton = () => {
    return !(personalDataCheck && externalAgentCheck)
  }

  const CustomButton = styled(Button)({
    backgroundColor: '#68B3E0',
    color: 'white',
    '&:hover': {
      backgroundColor: '#5a9bcf',
    },
  })

  return (
    <>
      <Box
        className={stylesHeader.header}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box className={style2.headerTop}>
          <Box>
            <img src={logoEsfera} alt={t('logo')} width={30} height={30} />
          </Box>
          <Box className={style2.headerTopRight}>
            <img src={concierge} alt={t('concierge')} width={30} height={30} />
            <Divider
              orientation={'vertical'}
              style={{ height: '28px', marginRight: 31, marginLeft: 18, borderColor: '#68B3E0' }}
            />
            <img
              src={murciaHealth}
              alt={t('murciaHealth')}
              width={30}
              height={30}
            />
          </Box>
        </Box>
      </Box>

      <Box style={{ flex: 1, backgroundColor: '#F9F9FA' }}>
        {personalData ? (
          <TreatPersonalData handlerEnableCheck={handlerDisablePersonalData} />
        ) : externalAgent ? (
          <ExternalAgent handlerEnableCheck={handlerDisableExternalAgent} />
        ) : (
          <Box className={style2.loginContainer} display={'flex'}>
            <Box className={style2.login}>
              <h2>Aceptación de la política de privacidad</h2>
              <h4>Antes de continuar, debe aceptar la política de privacidad del SMS</h4>

              <form className={style.confTermFields}>
                <p style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    label={''}
                    control={
                      <Checkbox
                        disabled={disablePersonalData}
                        checked={personalDataCheck}
                        onChange={handlerChangePersonalData}
                      />
                    }
                  />
                  <div
                    onClick={(e) => handlerPersonalData(e)}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      padding: '10px 0 0 0 ',
                      color: 'lightskyblue',
                      textDecoration: 'underline',
                    }}
                  >
                    Tratamiento de datos personales
                  </div>
                </p>
                <p style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    label={''}
                    control={
                      <Checkbox
                        disabled={disableExternalAgent}
                        checked={externalAgentCheck}
                        onChange={handlerChangeExternalAgent}
                      />
                    }
                  />
                  <div
                    onClick={(e) => handlerExternalAgent(e)}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      padding: '10px 0 0 0 ',
                      color: 'lightskyblue',
                      textDecoration: 'underline',
                    }}
                  >
                    Cláusula de confidencialidad para agentes externos
                  </div>
                </p>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'center',
                    marginTop: '2em',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={(e) => handlerNext(e)}
                    disabled={handlerDisableButton()}
                  >
                    <p style={{ fontWeight: 'bolder', color: 'white' }}>Aceptar</p>
                  </Button>
                </div>
              </form>
            </Box>
          </Box>
        )}

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1%',
            width: '100%',
          }}
        >
          <form className={personalData || externalAgent ? style2.backButton : style2.noneButton}>
            <CustomButton type="submit" variant="contained" onClick={(e) => handlerBack(e)}>
              Volver
            </CustomButton>
          </form>
        </div>
      </Box>
    </>
  )
}
