import { Container, IInit } from '../../../common/container/Container'
import { TeleConsulta } from '../models/TeleConsulta'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { TeleconsultaDTO, fromModel, toModel } from '../models/TeleconsultaDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Message } from '../../messenger_consulta/models/Message'
import {
  MessageDTO,
  messageFromModel,
  messageToModel,
} from '../../messenger_consulta/models/ConsultaDTO'
import { MessengerConsultaContainerConfig } from '../../messenger_consulta/container'

export interface IConsultaApi extends IInit {
  getByID(id: string): Observable<TeleConsulta | undefined>

  getFilteredItems(q: Query<TeleConsulta>): Observable<TeleConsulta[]>

  add(e: TeleConsulta): Observable<TeleConsulta | undefined>

  addMessage(m: Message): Observable<Message | undefined>

  update(e: TeleConsulta): Observable<TeleConsulta | undefined>

  delete(id: string): Observable<boolean>
}

export class TeleConsultaApi implements IConsultaApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url =
      (this._container.config as MessengerConsultaContainerConfig).moduleFullUrl + '/teleConsulta'
  }

  add(e: TeleConsulta): Observable<TeleConsulta | undefined> {
    return this._httpClient.post<TeleConsulta>({ url: this._url, body: fromModel(e) }).pipe(
      map<TeleconsultaDTO, TeleConsulta>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  addMessage(m: Message): Observable<Message | undefined> {
    return this._httpClient
      .post<Message>({
        url: `${
          (this._container.config as MessengerConsultaContainerConfig).moduleFullUrl
        }/messages_teleConsulta`,
        body: messageFromModel(m),
      })
      .pipe(
        map<MessageDTO, Message>((d) => messageToModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<TeleConsulta | undefined> {
    return this._httpClient.get<TeleConsulta>({ url: `${this._url}/${id}` }).pipe(
      map<TeleconsultaDTO, TeleConsulta>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredItems(q: Query<TeleConsulta>): Observable<TeleConsulta[]> {
    return this._httpClient.get<TeleConsulta[]>({ url: prepareURL(this._url, q) }).pipe(
      map<TeleconsultaDTO[], TeleConsulta[]>((dto) => dto.map((d) => toModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  update(e: TeleConsulta): Observable<TeleConsulta | undefined> {
    return this._httpClient.put<TeleConsulta>({ url: this._url, body: fromModel(e) }).pipe(
      map<TeleconsultaDTO, TeleConsulta>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
