import React, { useEffect, useState } from 'react'
import { Avatar, Box, Fab } from '@mui/material'
import styles from './ShortDescription.module.css'
import { makeStyles } from '@mui/styles'
import { Description } from '@mui/icons-material'
import dayjs from 'dayjs'
import 'dayjs/locale/es'

type ShortDescriptionProps = {
  notReadMessages: number
  conversationID: string | undefined
  conversationName: string
  conversationDescription: string
  lastMessage: string
  lastMessageDate: Date | undefined
  avatarUrl: string
  description: string
  handlerConversation: (conversationID: string | undefined) => void
  isCurrentConversation: boolean
}

const useStyles = makeStyles({
  secondary: {
    backgroundColor: '#0063A6',
    opacity: '0.27',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: '14px',
    '&:hover': {
      background: '#0063A6',
      opacity: '0.27',
    },
  },
})

export function ShortDescription(props: ShortDescriptionProps): JSX.Element {
  const classes = useStyles()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lastMessagePresentation, setLastMessagePresentation] = useState<JSX.Element>(<></>)

  const handleClick = () => {
    props.handlerConversation(props.conversationID)
  }

  dayjs.locale('es')
  const formatMessageDate = (date: Date | undefined) => {
    if (!date) {
      return ''
    }
    const now = dayjs()
    const messageDate = dayjs(date)

    if (now.isSame(messageDate, 'day')) {
      return 'hoy'
    } else if (now.subtract(1, 'day').isSame(messageDate, 'day')) {
      return 'ayer'
    } else if (now.isSame(messageDate, 'week')) {
      return messageDate.format('dddd') // Formato del día de la semana (ej: 'viernes')
    } else {
      return messageDate.format('DD MMMM') // Formato del día y mes (ej: '15 junio')
    }
  }

  const containerClassName = (isCurrent: boolean): string => {
    if (isCurrent) {
      return styles.conversation + ' ' + styles.activeConversation
    }
    return styles.conversation
  }

  useEffect(() => {
    const result = props.lastMessage.split('*')
    if (result.length === 1) {
      setLastMessagePresentation(<p className={styles.lastMessage}>{props.lastMessage}</p>)
    } else {
      setLastMessagePresentation(
        <p className={styles.lastMessage}>
          <Description />
          {result[2]}
        </p>
      )
    }
  }, [props.lastMessage])

  return (
    <Box
      key={props.conversationID}
      className={containerClassName(props.isCurrentConversation)}
      display="flex"
      onClick={handleClick}
    >
      <Box className={styles.avatarWrapper} flexDirection="column">
        <Avatar
          src={props.avatarUrl}
          style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF', padding: '0' }}
        />
      </Box>
      <Box className={styles.conversationBlock}>
        <h3 className={styles.conversationName}>{props.conversationName}</h3>
        <h4 className={styles.conversationDescription}>{props.conversationDescription}</h4>
      </Box>
      <h4 className={styles.converstationDate}>{formatMessageDate(props.lastMessageDate)}</h4>

      {props.notReadMessages !== 0 ? (
        <Box className={styles.conversationBadge} flexDirection="column">
          <Fab
            className={styles.notReadMessages}
            color={'secondary'}
            size={'small'}
            classes={{ secondary: classes.secondary }}
          >
            {props.notReadMessages}
          </Fab>
        </Box>
      ) : null}
    </Box>
  )
}
