import { Box, Popper } from '@mui/material'
import { useSelectedMenuContext } from 'common/utils/menu-selected-context/MenuSelectedContext'
import { styleMenu } from 'pages/layout/stylesFunctions'
import React from 'react'

type CircleSubMenuProps = {
  children: React.ReactNode
  style: string
  anchorEl: HTMLElement | null
}

export const CircleSubMenu = ({ children, style, anchorEl }: CircleSubMenuProps) => {
  const { isCircleOpen, setIsCircleOpen } = useSelectedMenuContext()

  return (
    <div onMouseEnter={() => setIsCircleOpen(true)}>
      <Popper
        anchorEl={anchorEl}
        id={isCircleOpen ? 'simple-popper' : undefined}
        open={isCircleOpen ?? false}
        style={styleMenu}
        transition={true}
      >
        <Box
         onMouseLeave={() => setIsCircleOpen(false)}
         className={style}>
          {children}
        </Box>
      </Popper>
    </div>
  )
}
