import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardComponent, GenericChartDashboardComponent } from './EpilepsyCard'
import { UserForm } from '../../../../modules/forms/models/UserForm'

type LifeQualityCardProps = {
  userForms: UserForm[]
}
export const LifeQuality: React.FC<LifeQualityCardProps> = ({ userForms }) => {
  const { t } = useTranslation()
  const [qualityLife, setQualityLife] = useState<number[]>([])
  const [monthLabel, setMonthLabel] = useState<string[]>([])

  const labels = [
    'En',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sept',
    'Oct',
    'Nov',
    'Dic',
  ]

  useEffect(() => {
    if (userForms.length === 0) return
    getScores()
  }, [userForms])

  const getScores = () => {
    const month: Date[] = []
    const result: number[] = []
    month.push(new Date(2022, 1, 1))
    result.push(55)
    month.push(new Date(2022, 2, 1))
    month.push(new Date(2022, 3, 1))
    month.push(new Date(2022, 5, 1))
    month.push(new Date(2022, 6, 1))
    result.push(60)
    result.push(65)
    result.push(70)
    result.push(75)
    result.push(80)
    userForms.forEach((form, index) => {
      month.push(new Date(form.userFormValues[0].date))
      result.push(form.totalScore ?? 0)
    })

    setMonthLabel(month.map((m) => labels[m.getMonth()] + ' ' + m.getFullYear()))
    setQualityLife(result)
  }

  const datasets = [
    {
      label: t('qualityLife'),
      data: qualityLife,
      borderColor: 'blue',
    },
  ]

  return (
    <CardComponent title={t('qualityLife')}>
      <GenericChartDashboardComponent
        datasets={datasets}
        labels={monthLabel}
        title={''}
        typeChart={'bar'}
        legendPosition={'bottom'}
      />
    </CardComponent>
  )
}
