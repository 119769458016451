import { Box, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@mui/material'
import { Header } from '../../components/header/Header'
import style from '../../components/modal/CustomModal.module.css'
import { useTranslation } from 'react-i18next'
import { Comments } from '../../modules/content/models/Comments'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import close from '../../assets/table_icons/ico-eliminar.svg'
import React, { useEffect, useState } from 'react'
import { ItemList } from '../../common/models/ItemList'
import { getUserContainer } from '../../container/user-module'
import { IUserService, USER_SERVICE_KEY } from '../../modules/users'

const authorService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
type modalProps = {
  title: string
  articleTitle?: string
  author?: string
  name?: string
  description?: string
  comments?: ItemList<Comments>
  handleCreateVariable?: (name: string, description: string) => void
  handleClose: () => void
}

const authorList = new Map()

const NoComments: React.FC = () => <div className={style.noComments}>Sin comentarios</div>

const CommentItem: React.FC<{ key: string; obj: Comments }> = ({ obj }) => {
  const [author, setAuthor] = useState<string>('')

  useEffect(() => {
    authorService.getByID(obj.userID).subscribe((author) => {
      if (!author) {
        return
      }
      setAuthor(`${author.name} ${author.lastname}`)
    })
  }, [])

  return (
    <div>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={author} src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
        <ListItemText primary={author} secondary={obj.content} />
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  )
}

export const CommentsModal = (props: modalProps): JSX.Element => {
  const { t } = useTranslation()

  useEffect(() => {
    if (props.comments) {
      props.comments.forEach((obj: Comments) => {
        authorService.getByID(obj.userID).subscribe((author) => {
          if (!author) {
            return
          }
          authorList.set(obj.userID, author.name + ' ' + author.lastname)
        })
      })
    }
  }, [])

  return (
    <div>
      <div className={style.agoraContainer}>
        <Box className={style.containerInside}>
          <Header label={t('comments')} icon={close} onClick={props.handleClose} />
          <form style={{ height: '500px' }}>
            <Box mb={3}>
              <b>{t('article')}: </b>
              <span>{props.articleTitle}</span>
            </Box>

            <Box>
              <List
                sx={{
                  width: '100%',
                  maxWidth: '100%',
                  maxHeight: 400,
                  overflow: 'auto',
                  bgcolor: 'background.paper',
                }}
              >
                {props.comments && props.comments?.length > 0 ? (
                  props.comments.map((comment: Comments) => (
                    <CommentItem key={comment.id} obj={comment} />
                  ))
                ) : (
                  <NoComments />
                )}
              </List>
            </Box>

            <Box mt={1} mb={1} className={style.buttonsCancel}>
              <Box>
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('cancel')}
                  handler={props.handleClose}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </div>
    </div>
  )
}
