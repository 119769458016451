/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Avatar, Box } from '@mui/material'
import styles from '../messenger/ShortDescription.module.css'
import womanIcon from '../../assets/chatIcons/woman.svg'
import maleIcon from '../../assets/chatIcons/male.svg'
import { reduceString } from '../../common/utils/strings'
import {
  dateToDateTimeOnlyDayString,
  dateToDateTimeOnlyHourString,
  dateToDateTimeWeekMonthString,
} from '../../common/utils/date'

import { differenceInDays } from 'date-fns'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { ConversationUser } from '../../modules/messenger/models/ConversationUser'

function isToday(date: Date) {
  const today = new Date()
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

function isSameWeek(date: Date) {
  const today = new Date()
  const difference = differenceInDays(today, date)
  // Comprobar que ha pasado más de 1 día pero menos de 7 días
  return difference >= 1 && difference <= 6
}

function isMoreThanAWeekAgo(date: Date) {
  const today = new Date()
  const difference = differenceInDays(today, date)
  return difference >= 7
}

export type NotificationCardProps = {
  id: string | undefined
  clickEvent: any
  title?: string
  description?: string
  data: any
  gender?: string
  avatarUrl: string
  lastMessage?: string
  date?: Date
}

function dataPrettier(data: string) {
  return (
    <>
      <p>{new Date(data).toLocaleDateString('es-ES')}</p>
      <p>{new Date(data).toLocaleTimeString()}</p>
    </>
  )
}

export function NotificationCard(props: NotificationCardProps): JSX.Element {
  const [gender, setGender] = useState('')

  const handleData = () => {
    props.clickEvent(props)
  }
  return (
    <Box key={props.id} className={styles.conversationWall} display="flex" onClick={handleData}>
      <Box className={styles.avatarWrapper} flexDirection="column">
        <div>{props.gender}</div>
        <Avatar
          src={gender === 'female' ? womanIcon : maleIcon}
          style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF', padding: '0' }}
        />
      </Box>
      <Box className={styles.conversationBlock}>
        <h3 className={styles.conversationName}>{props.title}</h3>
      </Box>
      <Box className={styles.conversationBlock} style={{ textAlign: 'right' }}>
        {props.data.updatedAt && dataPrettier(props.data.updatedAt.toString())}
      </Box>
    </Box>
  )
}
