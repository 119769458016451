import { Box } from '@mui/material'
import style from '../../NavigationEsfera.module.css'
import { Link, useNavigate } from 'react-router-dom'
import { LinkType } from '../../NavigationEsfera'
import { useTranslation } from 'react-i18next'
import { useSelectedMenuContext } from 'common/utils/menu-selected-context/MenuSelectedContext'
import { ArticleSubMenu } from './menu'
import { getLinkStyle, getListItemStyle } from 'pages/layout/stylesFunctions'

type ArticleSubMenuProps = {
  links: LinkType[]
  anchorEl: HTMLElement | null
}

export const ArticleSubMenuContainer = ({
  links,
  anchorEl,
}: ArticleSubMenuProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { sMenu } = useSelectedMenuContext()

  return (
    <ArticleSubMenu
      anchorEl={anchorEl}
      style={style.subMenuContainer}
    >
      <>
        <h2>{t('library')}</h2>
        <Box style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
          {links
            .filter((l) => l.isVisible && l.subMenuArticle)
            .map((l) => (
              <Link
                onClick={() => {
                  navigate(l.route)
                }}
                key={l.title}
                to={l.route}
                className={getLinkStyle(
                  sMenu,
                  style.circleSubMenuActive,
                  ...l.additionalRoutes,
                  l.route
                )}
                id={l.route}
              >
                <div className={style.menuDiv}>
                  <Box
                    style={{ padding: '0 60px 0 60px' }}
                    key={l.title}
                    className={getListItemStyle(
                      false,
                      sMenu || '',
                      style.circleSubMenuItem,
                      style.circleSubMenuItemActive,
                      ...(l?.additionalRoutes || ''),
                      l?.route || ''
                    )}
                  >
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <Box style={{ margin: 8, minWidth: 40 }}>
                        {l.route === sMenu ? (
                          <img src={l.activeIcon} alt={t(l.altSrc)} />
                        ) : (
                          <img src={l.icon} alt={t(l.altSrc)} />
                        )}
                      </Box>
                      <Box>
                        <h3 className={style.titleNameSubMenu}>{t(l.title)}</h3>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Link>
            ))}
        </Box>
      </>
    </ArticleSubMenu>
  )
}
