// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EpilepsyCard_card__3kw2t {\n  height: 100%;\n  margin-right: 8px;\n  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) !important;\n  border-radius: 7px !important;\n}\n\n.EpilepsyCard_containerCard__11WYp {\n  display: flex;\n  flex-direction: column;\n}\n\n.EpilepsyCard_rowSpaceBetween__1k3-e {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.EpilepsyCard_adverseEffectsContainer__2EBtl {\n  display: flex;\n  flex-direction: column;\n  height: 250px;\n  overflow-y: auto;\n}\n\n.EpilepsyCard_adverseEffectsItem__2xIg9 {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  border-bottom: 1px solid #68B3E0;\n  padding: 10px 0;\n}", "",{"version":3,"sources":["webpack://src/features/dashboard/patient/epilepsy-card/EpilepsyCard.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;EACA,sDAAA;EACA,6BAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACA;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gCAAA;EACA,eAAA;AACF","sourcesContent":[".card{\n  height:100%;\n  margin-right: 8px;\n  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) !important;\n  border-radius: 7px !important;\n}\n\n.containerCard{\n  display:flex;\n  flex-direction: column;\n}\n\n.rowSpaceBetween{\ndisplay: flex;\nflex-direction: row;\njustify-content: space-between;\n}\n\n.adverseEffectsContainer{\n  display: flex;\n  flex-direction: column;\n  height: 250px;\n  overflow-y: auto;\n}\n\n.adverseEffectsItem{\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  border-bottom: 1px solid #68B3E0;\n  padding: 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "EpilepsyCard_card__3kw2t",
	"containerCard": "EpilepsyCard_containerCard__11WYp",
	"rowSpaceBetween": "EpilepsyCard_rowSpaceBetween__1k3-e",
	"adverseEffectsContainer": "EpilepsyCard_adverseEffectsContainer__2EBtl",
	"adverseEffectsItem": "EpilepsyCard_adverseEffectsItem__2xIg9"
};
export default ___CSS_LOADER_EXPORT___;
