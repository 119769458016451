// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MonitoredActivityCard_cardProfile__EJqBO {\n  border: 1px solid #68B3E0 !important;\n  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1490196078) !important;\n  background-color: #f7fbfd !important;\n  height: 100%;\n}\n\n.MonitoredActivityCard_images__QOmW5 {\n  width: 80px;\n  height: 80px;\n}\n\n.MonitoredActivityCard_containerCard__3TD3r {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n\n.MonitoredActivityCard_divider__3nMQH {\n  margin: 0 20px;\n  height: 40px;\n}\n\n.MonitoredActivityCard_profileText__38Wyl {\n  font-family: \"Poppins\", sans-serif;\n  font-weight: bold;\n  color: #000000;\n}\n\n.MonitoredActivityCard_card__2vftf {\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://src/features/dashboard/patient/monitored-activity-card/MonitoredActivityCard.module.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,kEAAA;EACA,oCAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AAEF;;AACA;EACE,cAAA;EACA,YAAA;AAEF;;AACA;EACE,kCAAA;EACA,iBAAA;EACA,cAAA;AAEF;;AACA;EACE,YAAA;AAEF","sourcesContent":[".cardProfile{\n  border: 1px solid #68B3E0 !important;\n  box-shadow: 0px 4px 5px 0px #00000026 !important;\n  background-color: #f7fbfd !important;\n  height: 100%;\n}\n\n.images{\n  width: 80px;\n  height:80px;\n}\n.containerCard{\n  display:flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n\n.divider{\n  margin: 0 20px;\n  height: 40px;\n}\n\n.profileText{\n  font-family: 'Poppins', sans-serif;\n  font-weight: bold;\n  color: #000000;\n}\n\n.card {\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardProfile": "MonitoredActivityCard_cardProfile__EJqBO",
	"images": "MonitoredActivityCard_images__QOmW5",
	"containerCard": "MonitoredActivityCard_containerCard__3TD3r",
	"divider": "MonitoredActivityCard_divider__3nMQH",
	"profileText": "MonitoredActivityCard_profileText__38Wyl",
	"card": "MonitoredActivityCard_card__2vftf"
};
export default ___CSS_LOADER_EXPORT___;
