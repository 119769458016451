import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardComponent, GenericChartDashboardComponent } from './EpilepsyCard'
import { UserForm } from '../../../../modules/forms/models/UserForm'

type SleepQualityCardProps = {
  userForms: UserForm[]
}
export const SleepQuality: React.FC<SleepQualityCardProps> = ({ userForms }) => {
  const { t } = useTranslation()
  const [sleepQuality, setSleepQuality] = useState<number[]>([])
  const [monthLabel, setMonthLabel] = useState<string[]>([])

  const labels = [
    'En',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sept',
    'Oct',
    'Nov',
    'Dic',
  ]

  useEffect(() => {
    if (userForms.length === 0) return
    getScores()
  }, [userForms])

  const getScores = () => {
    const month: Date[] = []
    const result: number[] = []

    userForms.forEach((form, index) => {
      month.push(new Date(form.userFormValues[0].date))
      result.push(form.totalScore ?? 0)
    })

    setMonthLabel(month.map((m) => labels[m.getMonth()] + ' ' + m.getFullYear()))
    setSleepQuality(result)
  }

  const datasets = [
    {
      label: t('sleepQuality'),
      data: sleepQuality,
      borderColor: 'blue',
    },
  ]

  return (
    <CardComponent title={t('sleepQuality')}>
      <GenericChartDashboardComponent
        datasets={datasets}
        labels={monthLabel}
        title={''}
        typeChart={'bar'}
        legendPosition={'bottom'}
      />
    </CardComponent>
  )
}
