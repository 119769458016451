import React, { FormEvent, useEffect, useRef, useState } from 'react'
import {
  emptyFormDTO,
  emptyTemplateDTO,
  FormDTO,
  FormFieldDTO,
  fromModel,
} from '../../../modules/forms/models/FormDTO'
import { getFormContainer } from '../../../container/form-module'
import { FormService } from '../../../modules/forms/services/FormService'
import { FORM_SERVICE_KEY, USER_FORM_SERVICE_KEY } from '../../../modules/forms'
import { FormCard } from '../../../components/form-card/FormCard'
import { TextFieldItem } from '../../../components/form-card/TextFieldItem'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { ROUTE_FORM_GENERATOR } from '../../../routes/routes-constants'
import { isSpecialType } from '../../../common/utils/enums'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../../common/utils/generic.module.css'
import style from '../Editor.module.css'
import { Cron, cronString } from '../../../modules/forms/enums/Cron'
import { PreviewForm } from '../PreviewForm'
import { NewFormSection } from '../NewFormSection'
import { ConfigFormSection } from '../config-form-section/ConfigFormSection'
import { ProfessionalType } from '../../../modules/users/models/ProfessionalType'
import { ProfessionalTypesService } from '../../../modules/users/services/ProfessionalTypeService'
import { LOGGED_USER_SERVICE_KEY, PROFESSIONALTYPE_SERVICE_KEY } from '../../../modules/users'
import { getUserContainer } from '../../../container/user-module'
import { Query, QueryParam } from '../../../common/api/Query'
import { ILoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { Alert, Box, Divider, SelectChangeEvent } from '@mui/material'
import { QuestionsFormSection } from '../questions-form-section/QuestionsFormSection'
import { FormType } from '../../../modules/forms/enums/FormType'
import { UserFormService } from '../../../modules/forms/services/UserFormService'
import { UserForm } from '../../../modules/forms/models/UserForm'
import { useNavigate } from 'react-router-dom'
import { CircleDTO } from '../../../modules/circle/models/CircleDTO'
import { Circle, CircleQuery } from '../../../modules/circle/models/Circle'
import { getCircleContainer } from '../../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../../modules/circle'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'

enum FormFieldsError {
  NO_FIELDS = 'formShouldHaveAtLeastAField',
  ORDER = 'formFieldsErrorOrder',
  NO_OPTIONS = 'formFieldsErrorNoOptions',
}

type EditorProps = {
  id?: string
  refMain?: React.RefObject<HTMLDivElement>
}

const userContainer = getUserContainer()
const formContainer = getFormContainer()
const formService = formContainer.get<FormService>(FORM_SERVICE_KEY)
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    zIndex: 2,
    height: 60,
    borderBottom: '1px solid #ccc',
  },
  selected: {
    borderBottom: `3px solid #f37e31`,
    borderRadius: 'inherit',
    fontFamily: 'Poppins',
    fontSize: '1.08rem',
    marginLeft: 0,
    fontWeight: 'bold',
  },
}))

export function TemplateGeneratorForm(props: EditorProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const cronTypes = cronString()
  const loggedUser = loggedUserService.get()
  const navigate = useNavigate()

  const [form, setForm] = useState<FormDTO>(emptyTemplateDTO())
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isRecurrence, setIsRecurrence] = useState<boolean>(false)
  const [cron, setCron] = useState<string>(cronTypes[Cron.Empty])
  const [preview, setPreview] = useState<boolean>(false)
  const [dataPreview, setDataPreview] = useState<FormDTO>(emptyFormDTO())
  const [professionalSpecialities, setProfessionalSpecialities] = useState<ProfessionalType[]>([])
  const [professionalType, setProfessionalType] = useState<ProfessionalType>()
  const [currentCirclePathology, setCurrentCirclePathology] = useState<string[]>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedProfessionalType, setSelectedProfessionalType] = useState<ProfessionalType>()
  const [circle, setCircle] = useState<CircleDTO[]>([])
  const [selectedButton, setSelectedButton] = React.useState('newForm')
  const [pathologies, setPathologies] = useState<Circle[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [recoveredFormField, setRecoveredFormField] = useState<FormFieldDTO[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [usersFormEdit, setUsersFormEdit] = useState<UserForm[]>([])
  const isContentManager = loggedUser?.roles?.some((role) => role.startsWith('manager'))

  // constrols the scroll
  const newFormRef = useRef<HTMLDivElement>(null)
  const configFormRef = useRef<HTMLDivElement>(null)
  const questionsFormRef = useRef<HTMLDivElement>(null)
  const navBarRef = useRef<HTMLDivElement>(null)
  const [navBarHeight, setNavBarHeight] = useState(0)
  const isCreator = isContentManager ?? false

  const handleSmoothScroll = <T extends HTMLElement>(ref: React.RefObject<T>) => {
    if (ref.current) {
      const targetElement = ref.current
      targetElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleClickNavBarScroll = (ref: React.RefObject<HTMLDivElement>, id: string) => {
    setSelectedButton(id)
    handleSmoothScroll(ref)
  }

  const handlerRecoveredFormField = (f: FormFieldDTO[]) => {
    form.formFields = f
    // setRecoveredFormField(f)
  }

  useEffect(() => {
    const handleScroll = () => {
      const navBar = props.refMain?.current
      const newForm = newFormRef.current
      const configForm = configFormRef.current
      const questionsForm = questionsFormRef.current

      const windowHeight = window.innerHeight
      const scrollTop = window.pageYOffset

      if (navBar && newForm && configForm && questionsForm) {
        const navBarHeight = navBar.offsetHeight
        const newFormTop =
          newForm.getBoundingClientRect().top + scrollTop - navBarHeight + newForm.offsetHeight
        const configFormTop =
          configForm.getBoundingClientRect().top +
          scrollTop -
          navBarHeight +
          configForm.offsetHeight
        const questionsFormTop =
          questionsForm.getBoundingClientRect().top +
          scrollTop -
          navBarHeight +
          questionsForm.offsetHeight

        if (scrollTop >= 0 && scrollTop < newFormTop + windowHeight / 2) {
          setSelectedButton('newForm')
        } else if (
          scrollTop >= newFormTop + windowHeight / 2 &&
          scrollTop < configFormTop + windowHeight / 2
        ) {
          setSelectedButton('configForm')
        } else if (
          scrollTop >= configFormTop + windowHeight / 2 &&
          scrollTop < questionsFormTop + windowHeight / 2
        ) {
          setSelectedButton('questionsForm')
        } else {
          setSelectedButton('')
        }
      }
    }

    const navBar = navBarRef.current
    if (navBar) {
      setNavBarHeight(navBar.offsetHeight)
    }

    props.refMain?.current?.addEventListener('scroll', handleScroll)

    return () => {
      props.refMain?.current?.removeEventListener('scroll', handleScroll)
    }
  }, [navBarHeight])

  const handleChangeRecurrence = (s: string, sd: Date, fd: Date) => {
    if (props.id) return
    setCron(s)
    setForm(Object.assign({ ...form }, { startCron: sd, finishCron: fd }))
  }

  const handleRecurrence = (b: boolean) => {
    if (b) {
      setIsRecurrence(true)
      setForm(Object.assign({ ...form }, { permanent: 0 }))
    } else {
      setIsRecurrence(false)
    }
  }

  useEffect(() => {
    if (!props?.id) {
      return
    }
    formService.getByID(props.id).subscribe((res) => {
      if (res) {
        setForm(fromModel(res))
        if (res.repeating !== '0 0 0 0 0') {
          setIsRecurrence(true)
          setCron(res.repeating)
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!loggedUser?.id) return
    const listAux: ProfessionalType[] = []
    const isContentManager = loggedUser?.roles?.some((role) => role.startsWith('manager'))
    if (isContentManager) {
      professionalTypeService
        .getAll(
          new Query({
            sort: [{ field: 'name' }],
            pager: { offset: 0, limit: -1 },
          })
        )
        .subscribe((res) => {
          if (!res) return
          setProfessionalSpecialities(res)
        })
    } else {
      professionalTypeService.getProfessionalTypeByUserID(loggedUser?.id).subscribe((res) => {
        if (!res) return
        listAux.push(res)
        setProfessionalSpecialities(listAux)
        setProfessionalType(listAux[0])
      })
    }
  }, [])

  useEffect(() => {
    if (!professionalType) return
    circleService
      .getFilteredList(
        new Query<CircleQuery>({
          query: [new QueryParam<CircleQuery>('specialtyID', professionalType?.name)],
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setCircle(res.items)
      })
  }, [professionalType, currentCirclePathology])

  useEffect(() => {
    const auxArrayPathologies: Circle[] = []
    if (props.id) {
      if (!form) return
      circleService.getFilteredList(new Query<CircleQuery>({})).subscribe((res) => {
        if (!res) return
        res.items.forEach((p, index) => {
          if (form.circles.includes(p.name)) {
            auxArrayPathologies.push(p)
          }
        })
        if (!currentCirclePathology || currentCirclePathology?.length <= 0) {
          setCurrentCirclePathology(auxArrayPathologies.map((p) => p.name))
        }
        setPathologies(auxArrayPathologies)
      })
    }
  }, [form])

  /*  useEffect(() => {
    if (!currentCirclePathology) return
    const auxFormPathology: FormPathologyDTO[] = []
    currentCirclePathology?.forEach((p) => {
      pathologiesService
        .getFilteredList(
          new Query<PathologyQuery>({
            query: [new QueryParam<PathologyQuery>('name', p)],
          })
        )
        .subscribe((res) => {
          res.items.forEach((i) => {
            if (form.id) {
              auxFormPathology.push({
                id: uuidv4(),
                formID: form.id,
                circleID: i.id,
              })
            }
          })
        })
    })
    setFormPathologies(auxFormPathology)
  }, [currentCirclePathology]) */

  useEffect(() => {
    if (!props?.id) {
      return
    }
    formService.getByID(props.id).subscribe((res) => {
      if (res) {
        setForm(fromModel(res))
        if (res.repeating !== '0 0 0 0 0') {
          setIsRecurrence(true)
          setCron(res.repeating)
        }
      }
      if (!res?.id) return
      userFormService
        .getFilteredList(
          new Query({
            query: [{ name: 'formID', value: res?.id }],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setUsersFormEdit(res.items)
        })

      professionalTypeService.getByName(res.specialities).subscribe((res) => {
        if (!res) return
        setProfessionalType(res)
      })
    })
  }, [props.id])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm(Object.assign({ ...form }, { [e.target.name]: e.target.value }))
  }

  const handleSelectPathology = (e: string) => {
    setCurrentCirclePathology([])
    professionalTypeService.getByName(e).subscribe((res) => {
      if (res) {
        const aux: ProfessionalType = res
        setSelectedProfessionalType(aux)
        setProfessionalType(aux)
      }
    })
  }

  const handleDeletePathology = (type: string, p: string) => {
    if (type === 'professionalType') {
      if (professionalType?.name) {
        setProfessionalType(undefined)
        setCurrentCirclePathology([])
      }
    }
    if (type === 'circle') {
      const filteredArray = currentCirclePathology?.filter(function (e) {
        return e !== p
      })
      setCurrentCirclePathology(filteredArray)
    }
    console.info('You clicked the delete icon.')
  }
  const handleSelectCircle = (event: SelectChangeEvent<typeof currentCirclePathology>) => {
    const {
      target: { value },
    } = event
    setCurrentCirclePathology(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const changeFormFields = (formFields: FormFieldDTO[]) => (form.formFields = formFields)

  const saveForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateForm()) {
      return
    }

    if (form.formFields.filter((ff) => ff.title === '').length > 0) {
      setErrorMessage('Los campos de título de las preguntas son obligatorios')
      return
    }
    const newFormFields: FormFieldDTO[] = []
    form.formFields.forEach((ff) => {
      newFormFields.push(Object.assign({ ...ff }, { formID: form.id }))
    })

    if (form.description === '' || form.title === '') {
      setErrorMessage('Los campos de título y descripción son obligatorios')
      return
    }

    const newForm = Object.assign(
      { ...form },
      {
        formFields: newFormFields,
        repeating: cron,
        isTemplate: FormType.Template,
        creator: loggedUser?.id,
        specialities: professionalType?.name ?? ' ',
        circles: currentCirclePathology?.map((c) => c).join(',') ?? ' ',
      }
    )
    if (!isRecurrence) newForm.repeating = '0 0 0 0 0'
    if (props.id) {
      formService.update(newForm).subscribe(() => goBack())
      return
    }
    formService.add(newForm).subscribe(() => goBack())
  }

  const validateForm = () => {
    if (
      form.formFields.some((f) =>
        form.formFields.find((ff) => ff.id !== f.id && ff.order + '' === f.order + '')
      )
    ) {
      setErrorMessage(FormFieldsError.ORDER)
      return false
    }

    if (
      form.formFields.some(
        (f) => isSpecialType(f.type) && (!f.optionValues || f.optionValues?.length === 0)
      )
    ) {
      setErrorMessage(FormFieldsError.NO_OPTIONS)
      return false
    }

    if (form.formFields.length === 0) {
      setErrorMessage(FormFieldsError.NO_FIELDS)
      return false
    }

    setErrorMessage('')
    return true
  }

  const goBack = () => navigate(ROUTE_FORM_GENERATOR)

  const showPreview = () => {
    const newFormFields: FormFieldDTO[] = []
    form.formFields.sort((a, b) => a.order - b.order)
    form.formFields.forEach((ff) => {
      newFormFields.push(Object.assign({ ...ff }, { formID: form.id }))
    })
    const newForm = Object.assign({ ...form }, { formFields: newFormFields, repeating: cron })
    setDataPreview(newForm)
    setPreview(true)
  }

  return (
    <div ref={navBarRef} style={{ height: 'auto' }} className={genericStyle.pageContainer}>
      {preview ? (
        <>
          <PreviewForm
            title={dataPreview.title}
            description={dataPreview.description}
            created={dataPreview.created}
            formFields={dataPreview.formFields}
            repeating={dataPreview.repeating}
            startCron={dataPreview.startCron}
            finishCron={dataPreview.finishCron}
            isTemplate={dataPreview.isTemplate}
            creator={dataPreview.creator}
            fieldWithoutAnswer={dataPreview.fieldWithoutAnswer}
            id=""
            circles={dataPreview.circles}
            specialities={dataPreview.specialities}
            checkBoxesSelected={dataPreview.checkBoxesSelected}
            monthRecurrence={dataPreview.monthRecurrence}
          />

          <Box display="flex" justifyContent="flex-end">
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type="button"
              label={t('closePreview')}
              handler={() => {
                setPreview(false)
              }}
            />
          </Box>
        </>
      ) : (
        <FormCard>
          <>
            <div
              ref={navBarRef}
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: '#fff',
                zIndex: 2,
                height: 200,
                paddingTop: 20,
                width: '100%',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  marginBottom: 30,
                  justifyContent: 'space-between',
                  paddingRight: '3.5rem',
                  paddingLeft: '3.01rem',
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <div>
                    <Box>
                      <Button
                        className={style.buttonsNav}
                        aria-selected={selectedButton === 'newForm'}
                        variant="text"
                        onClick={(event) => {
                          handleClickNavBarScroll(newFormRef, 'newForm')
                        }}
                      >
                        <p
                          style={{ fontSize: window.innerWidth <= 1510 ? '0.8rem' : '' }}
                          className={
                            selectedButton === 'newForm' ? classes.selected : style.textNav
                          }
                        >
                          {t('newForm')}
                        </p>
                      </Button>
                    </Box>
                  </div>
                  <div>
                    <Box>
                      <Button
                        className={style.buttonsNav}
                        aria-selected={selectedButton === 'configForm'}
                        variant="text"
                        onClick={(event) => {
                          handleClickNavBarScroll(configFormRef, 'configForm')
                        }}
                      >
                        <p
                          style={{ fontSize: window.innerWidth <= 1510 ? '0.8rem' : '' }}
                          className={
                            selectedButton === 'configForm' ? classes.selected : style.textNav
                          }
                        >
                          {t('configuration')}
                        </p>
                      </Button>
                    </Box>
                  </div>
                  <div>
                    <Box>
                      <Button
                        className={style.buttonsNav}
                        style={{ width: window.innerWidth <= 1510 ? 'auto' : '' }}
                        aria-selected={selectedButton === 'questionsForm'}
                        variant="text"
                        onClick={() => handleClickNavBarScroll(questionsFormRef, 'questionsForm')}
                      >
                        <p
                          style={{ fontSize: window.innerWidth <= 1510 ? '0.8rem' : '' }}
                          className={
                            selectedButton === 'questionsForm' ? classes.selected : style.textNav
                          }
                        >
                          {t('questions')}
                        </p>
                      </Button>
                    </Box>
                  </div>
                </div>
                <Box style={{ display: 'flex' }}>
                  <Box style={{ width: window.innerWidth <= 1510 ? 'auto' : '' }}>
                    {isCreator && (
                      <AppButton
                        theme={ButtonTheme.NewPrimary}
                        type={'button'}
                        label={
                          <span style={{ fontSize: window.innerWidth <= 1510 ? '0.7rem' : '' }}>
                            {window.innerWidth <= 1320 ? t('preview') : t('previewForm')}
                          </span>
                        }
                        handler={() => {
                          showPreview()
                        }}
                      />
                    )}
                  </Box>
                  <div className={style.separator} />
                  <Box style={{ width: window.innerWidth <= 1510 ? 'auto' : '' }}>
                    {isCreator && (
                      <AppButton
                        theme={ButtonTheme.NewPrimary}
                        type={'submit'}
                        label={
                          <span style={{ fontSize: window.innerWidth <= 1510 ? '0.7rem' : '' }}>
                            {t('save')}
                          </span>
                        }
                        handler={saveForm}
                      />
                    )}
                  </Box>
                  <div className={style.separator} />
                  <Box style={{ width: window.innerWidth <= 1510 ? 'auto' : '' }}>
                    <AppButton
                      theme={ButtonTheme.NewSecondary}
                      type={'button'}
                      label={
                        <span style={{ fontSize: window.innerWidth <= 1510 ? '0.7rem' : '' }}>
                          {isCreator ? t('cancel') : t('back')}
                        </span>
                      }
                      handler={goBack}
                    />
                  </Box>
                </Box>
              </Box>

              <Divider
                light
                style={{
                  backgroundColor: '#68b3e0',
                  marginTop: 4,
                  height: 4,
                  marginBottom: 20,
                  marginRight: '3.5rem',
                  marginLeft: '3.5rem',
                }}
              />

              <Box
                style={{
                  backgroundColor: '#fff',
                  paddingRight: '3.5rem',
                  paddingLeft: '3.5rem',
                  display: 'flex',
                  marginBottom: 3,
                }}
                className={style.box}
              >
                <b className={style.titleLabel}>{t('title')}*</b>
                <Box display="flex" style={{ backgroundColor: '#fff' }} className={style.textField}>
                  <TextFieldItem
                    field="title"
                    value={form.title}
                    label={''}
                    type={'text'}
                    handleChange={handleInput}
                    rows={undefined}
                    required={true}
                    disabled={!isCreator}
                    maxLength={100}
                  />
                </Box>
              </Box>
            </div>
            <div
              onSubmit={(event) => event.preventDefault()}
              style={{ paddingRight: '3.5rem', paddingLeft: '3.5rem' }}
            >
              <div>
                <div ref={newFormRef} style={{ height: 200 }}>
                  <NewFormSection form={form} handleInput={handleInput} isCreator={isCreator} />
                </div>

                <div ref={configFormRef} style={{ height: 'auto' }}>
                  <ConfigFormSection
                    isRecurrence={isRecurrence}
                    handleRecurrence={handleRecurrence}
                    professionalSpecialities={professionalSpecialities}
                    professionalType={professionalType}
                    handleSelectPathology={handleSelectPathology}
                    handleDeletePathology={handleDeletePathology}
                    currentCirclePathology={currentCirclePathology}
                    loggedUser={loggedUser}
                    circle={circle}
                    handleSelectCircle={handleSelectCircle}
                    handleChangeRecurrence={handleChangeRecurrence}
                    propsId={props.id}
                    form={form}
                    pathologies={pathologies}
                    isCreator={isCreator}
                  />
                </div>

                {errorMessage && (
                  <Box mb={3}>
                    <Alert severity="warning" key="errorMessage" id="errorMessage">
                      {t(errorMessage)}
                    </Alert>
                  </Box>
                )}
                <div ref={questionsFormRef}>
                  <QuestionsFormSection
                    formFieldsProps={form.formFields.sort((a, b) => a.order - b.order)}
                    form={form}
                    handlerChange={changeFormFields}
                    handlerRecoveredFormField={handlerRecoveredFormField}
                    preview={preview}
                    recoverFormFields={recoveredFormField}
                    isCreator={isCreator}
                  />
                </div>
              </div>
            </div>
          </>
        </FormCard>
      )}
    </div>
  )
}
