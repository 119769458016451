import { Box, Popper } from '@mui/material'
import { useSelectedMenuContext } from 'common/utils/menu-selected-context/MenuSelectedContext'
import { styleMenu } from 'pages/layout/stylesFunctions'
import React from 'react'

type ProfileSubMenuProps = {
  children: React.ReactNode
  style: string
  anchorEl: HTMLElement | null
}

export const ProfileSubMenu = ({ children, style, anchorEl }: ProfileSubMenuProps) => {
  const { isProfileOpen, setIsProfileOpen } = useSelectedMenuContext()
  return (
    <div onMouseEnter={() => setIsProfileOpen(true)}>
      <Popper
        anchorEl={anchorEl}
        id={isProfileOpen ? 'simple-popper' : undefined}
        open={isProfileOpen ?? false}
        style={styleMenu}
        transition={true}
      >
        <Box onMouseLeave={() => setIsProfileOpen(false)} className={style}>
          {children}
        </Box>
      </Popper>
    </div>
  )
}
