import React, { useEffect, useRef, useState } from 'react'
import { Box, Modal } from '@mui/material'
import { ShortDescription } from './ShortDescription'
import { Message } from '../../modules/messenger/models/Message'
import { Searcher } from './Searcher'
import { Messages } from './Messages'
import { Header } from './Header'
import { Sender } from './Sender'
import 'dayjs'
import style from './Messenger.module.css'
import { CustomModal } from '../../components/modal/CustomModal'
import { File } from '../../modules/files/models/File'
import { Conversation } from '../../modules/messenger/models/Conversation'
import { reduceString } from '../../common/utils/strings'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { getMessengerContainer } from '../../container/messenger-module'
import { ConversationService } from '../../modules/messenger/services/ConversationService'
import { CONVERSATION_SERVICE_KEY } from '../../modules/messenger'
import { Query, QueryParam } from '../../common/api/Query'
import { ConversationEditor } from './ConversationEditor'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { LoadingSpinner } from 'components/loading-spinner/LoadingSpinner'
import styles from '../../components/loading-spinner/LoadingSpinner.module.css'
import { getNotificationContainer } from '../../container/notification-module'
import { NotificationService } from '../../modules/notifications/services/NotificationService'
import { NOTIFICATION_SERVICE_KEY } from '../../modules/notifications'
import { useGlobalRenderHeaderContext } from '../../common/utils/GlobalRenderHeader'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ConversatonUserGender } from '../../modules/messenger/models/ConversationUser'
import womanIcon from '../../assets/chatIcons/woman.svg'
import maleIcon from '../../assets/chatIcons/male.svg'
import open_message_icon from '../../assets/query/open_message.svg'


const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const messengerContainer = getMessengerContainer()
const conversationsService = messengerContainer.get<ConversationService>(CONVERSATION_SERVICE_KEY)
const notificationContainer = getNotificationContainer()
const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
export type MessengerProps = {
  conversationID?: string
}

export function Messenger(props: MessengerProps): JSX.Element {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const fullUserCircle = UserCircleActiveService.getActiveFullUserCircle()
  const [currentConversation, setCurrentConversation] = useState<Conversation>()
  const [chatList, setChatList] = useState<JSX.Element[]>([])
  const [conversationCollection, setConversationCollection] = useState<Conversation[]>([])
  const previousConversation = usePreviousConversation() ?? currentConversation
  const [originalConversations, setOriginalConversations] = useState<Conversation[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const { render, setRender } = useGlobalRenderHeaderContext()

  useEffect(() => {
    if (props.conversationID) {
      conversationsService.getByID(props.conversationID).subscribe((res) => {
        if (res) {
          setCurrentConversation(res)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (!isLoading || !loggedUser?.id) {
      return
    }
    conversationsService
      .getFilteredItems(
        new Query({
          query: [
            new QueryParam<Conversation>('userID', loggedUser.id),
            new QueryParam<Conversation>('userCircleID', fullUserCircle?.id ?? ''),
          ],
        })
      )
      .subscribe((res) => {
        setChatList(res.map((c) => conversationToShortDescription(c)))
        setConversationCollection(res)
        setOriginalConversations(res)
        setIsLoading(false)
      })
  }, [isLoading])

  useEffect(() => {
    if (!currentConversation) {
      setConversationCollection([...conversationCollection])
    }
  }, [currentConversation])

  function usePreviousConversation() {
    const ref = useRef(currentConversation)
    useEffect(() => {
      ref.current = currentConversation
    })
    return ref.current
  }

  const conversationToShortDescription = (conversation: Conversation) => {
    const messages = orderMessagesByCreationDate(conversation.messages)
    const lengthLimit = 70
    const lastMessage =
      conversation.messages.length === 0
        ? ''
        : reduceString(messages[messages.length - 1].text, lengthLimit)
    const conversationName = reduceString(conversation.name, lengthLimit)
    return (
      <ShortDescription
        key={conversation.id}
        conversationID={conversation.id}
        conversationName={conversationName}
        conversationDescription={conversation.description}
        avatarUrl={
          conversation.users[0].userGender === ConversatonUserGender.female ? womanIcon : maleIcon
        }
        description={conversation.description}
        lastMessage={lastMessage}
        lastMessageDate={messages.length > 0 ? messages[messages.length - 1].createdAt : undefined}
        notReadMessages={conversation.unreadMessages}
        handlerConversation={handleShortDescription}
        isCurrentConversation={conversation.id === currentConversation?.id}
      />
    )
  }

  const orderMessagesByCreationDate = (messages: Message[]): Message[] => {
    return [...messages].sort((m1, m2) => m1.createdAt.getTime() - m2.createdAt.getTime())
  }

  const handleShortDescription = (conversationID: string | undefined) => {
    const conversation = conversationCollection.find((c) => c.id === conversationID)
    if (conversation) {
      conversation.unreadMessages = 0
      setCurrentConversation(conversation)
      // TODO set notification to viewed
      if (conversation.id)
        notificationService.updateStatusByMessage(conversation.id).subscribe((res) => {
          setRender(!render)
        })
    }
  }

  useEffect(() => {
    replaceChatList()
  }, [currentConversation])

  const replaceChatList = () => {
    if (!currentConversation || !previousConversation) return

    const mapResult = [...chatList]
    replaceConversation(mapResult, previousConversation)
    replaceConversation(mapResult, currentConversation)

    setChatList(mapResult)
  }

  const replaceConversation = (conversationList: JSX.Element[], conversation: Conversation) => {
    const index = conversationCollection.findIndex((c) => c.id === conversation.id)
    conversationList.splice(index, 1, conversationToShortDescription(conversation))
  }

  useEffect(() => {
    const mapResult = [...conversationCollection].map((c) => conversationToShortDescription(c))
    setChatList(mapResult)

    replaceOriginalConversations()
  }, [conversationCollection])

  const replaceOriginalConversations = () => {
    if (originalConversations) {
      const newOriginalConversations = [...originalConversations]
      conversationCollection.forEach((c) => {
        const index = newOriginalConversations.findIndex((o) => o.id === c.id)
        if (index !== -1) {
          newOriginalConversations.splice(index, 1, c)
        }
      })
      setOriginalConversations(newOriginalConversations)
    }
  }

  const handleSearch = (s: string) => {
    const filteredConversations = originalConversations?.filter((c) => {
      const name = c.name.toLowerCase()
      return name.includes(s.toLowerCase())
    })
    if (filteredConversations) {
      setConversationCollection(filteredConversations)
    }
  }

  const handleSender = (m: Message, f: File[]) => {
    if (currentConversation) {
      f.forEach((file) => m.addFile(file.id ?? ''))
      const conversation = cloneDeep<Conversation>(currentConversation)

      conversation.addMessage(m)
      // conversationsService.update(conversation).subscribe((_) => {
      setCurrentConversation(conversation)
      replaceConversationCollection(conversation)
      // })
      conversationsService.addMessage(m).subscribe()
    }
  }

  function replaceConversationCollection(conversation: Conversation) {
    const conversations = [...conversationCollection]

    const index = conversations.findIndex((c) => c.id === conversation.id)
    conversations.splice(index, 1, conversation)

    setConversationCollection(conversations)
  }

  const reverseConversationCollection = () => {
    setConversationCollection([...conversationCollection.reverse()])
  }

  const handleNewConversation = (): void => {
    setCurrentConversation(undefined)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleSaveModal = (c: Conversation, isUpdate: boolean) => {
    setOpenModal(false)
    setCurrentConversation(undefined)
    if (isUpdate) {
      conversationsService.update(c).subscribe((res) => {
        if (res) {
          const conversationsCollectionsCopy = cloneDeep<Conversation[]>(conversationCollection)
          const index = conversationsCollectionsCopy.findIndex((c) => c.id === res?.id)
          conversationsCollectionsCopy[index] = res
          setConversationCollection([...conversationsCollectionsCopy])
          setCurrentConversation(cloneDeep<Conversation>(conversationsCollectionsCopy[index]))
        }
      })
    } else {
      conversationsService.add(c).subscribe((res) => {
        if (res) {
          if (originalConversations) setOriginalConversations([...originalConversations, res])
          setConversationCollection([...conversationCollection, res])
          setCurrentConversation(res)
        }
      })
    }
  }

  const handleEditConversation = () => {
    setOpenModal(true)
  }

  const handleDeleteConversation = () => {
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteEvent = () => {
    if (!currentConversation?.id) {
      return
    }
    conversationsService.delete(currentConversation.id).subscribe((_) => {
      setIsLoading(true)
      setOriginalConversations(
        originalConversations?.filter((c) => c.id !== currentConversation.id)
      )
      setConversationCollection(
        conversationCollection.filter((c) => c.id !== currentConversation.id)
      )
      setCurrentConversation(undefined)
      setIsLoading(false)
    })
    setOpenModal(false)
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  const handlerBacktoList = () => {
    setCurrentConversation(undefined)
  }

  return (
    <>
      {!isLoading ? (
        <div className={style.messengerContainer}>
          <Box
            className={
              window.innerWidth < 599 && currentConversation
                ? style.backButtonContaienr
                : style.displayNone
            }
          >
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('back')}
              handler={handlerBacktoList}
            />
          </Box>
          <Box
            component="section"
            className={
              window.innerWidth > 599 || !currentConversation
                ? style.conversationWidget
                : style.displayNone
            }
          >
            <Box
              className={style.searcherContainer}
              component="section"
              display="flex"
              justifyContent="space-between"
            >
              <Searcher
                handler={handleSearch}
                reverse={reverseConversationCollection}
                handleNewConversation={handleNewConversation}
              />
            </Box>
            <Box className={style.newConversationBox}>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('newConversation')}
                handler={handleNewConversation}
              />
            </Box>
            <Box className={style.chatList} component="section">
              {chatList}
            </Box>
          </Box>
          {currentConversation && (
            <>
            <Box
              className={style.messengerWidget}
            >
              <Box className={style.headerContainer}>
                <Header
                  headerName={currentConversation.name || ''}
                  avatarUrl={
                    currentConversation.users[0].userGender === ConversatonUserGender.female
                      ? womanIcon
                      : maleIcon
                  }
                  creatorID={currentConversation.users.find((u) => u.isAdmin)?.userID ?? ''}
                  description={currentConversation.description || ''}
                  handleEdit={handleEditConversation}
                  handleDelete={handleDeleteConversation}
                  conversationUsers={currentConversation.users.map((cu) => cu.userID)}
                />
              </Box>
              <Box className={style.messagesContainer} flexGrow={1}>
                <Messages
                  messages={currentConversation.messages || []}
                  conversationUsers={currentConversation.users}
                />
              </Box>
              { window.innerWidth > 599 && 
                <Box>
                  <Sender handler={handleSender} conversationId={currentConversation.id ?? ''} />
                </Box>
              }
            </Box>
            {
              window.innerWidth < 599 &&
              <Box>
                <Sender handler={handleSender} conversationId={currentConversation.id ?? ''} />
              </Box>
            }
          </>
          )}
          <Modal
            open={openDeleteModal}
            className={style.eventModal}
            onClose={handleCloseDeleteModal}
          >
            <CustomModal
              handleClose={handleCloseDeleteModal}
              handleSave={handleDeleteEvent}
              title={t('deleteConversation')}
              warningText={t('irreversibleConversationAction')}
            />
          </Modal>
          {!currentConversation && (
            <Box className={style.emptyConversation}>
              <img className={style.imgCreateOrSelectConversation} src={open_message_icon} width={34} height={34} alt="Open conversation image" />
              <p className={style.labelCreateOrSelectConversation}>
                {t('createOrSelectConversation')}
              </p>
              <div className={style.orangeUnderscore}></div>
            </Box>
          )}
        </div>
      ) : (
        <LoadingSpinner className={styles.loadingSpinner} />
      )}
      <Modal
        className={style.modalNewConversation}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ConversationEditor
          conversation={currentConversation}
          handleClose={handleCloseModal}
          handleSave={handleSaveModal}
        />
      </Modal>
    </>
  )
}
