import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_QUERY, ROUTE_TELECONSULTATION } from 'routes/routes-constants'
import { Query, QueryParam } from 'common/api/Query'
import { Consulta } from 'modules/messenger_consulta/models/Consulta'
import { getMessengerConsultaContainer } from 'container/messenger-consulta-module'
import { ConsultaService } from 'modules/messenger_consulta/services/ConsultaService'
import { CONSULTA_SERVICE_KEY } from 'modules/messenger_consulta'
import { getUserContainer } from 'container/user-module'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { IUserCircleActiveService } from 'modules/user-circle/services/UserCircleActiveService'
import { getUserCircleContainer } from 'container/user-circle-module'
import { USER_CIRCLE_ACTIVE_SERVICE_KEY } from 'modules/user-circle'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import style from './ActiveQueriesCard.module.css'
import queryIcon from 'assets/dashboard/queryIcon.svg'
import { listItemTextStyle } from '../notification-card-patient/NotificationsCardStyleMui'
import { ConsultaDTO } from 'modules/messenger_consulta/models/ConsultaDTO'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

type ActiveQueriesCardProps = {}

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const queryService = getMessengerConsultaContainer().get<ConsultaService>(CONSULTA_SERVICE_KEY)
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export const ActiveQueriesCard: React.FC<ActiveQueriesCardProps> = () => {
  const loggedUser = loggedUserService.get()
  const activeUser = UserCircleActiveService.getActiveFullUserCircle()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [conversationCollection, setConversationCollection] = useState<ConsultaDTO[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  const { userCircleService } = useCircleConfiguration()
  const circleSelected = userCircleService?.getActiveFullUserCircle()

  useEffect(() => {
    if (!isLoading || !loggedUser?.id) {
      return
    }
    queryService
      .getFilteredItems(
        new Query({
          query: [
            new QueryParam<Consulta>('userID', activeUser?.user.id ?? ''),
            new QueryParam<Consulta>('circleID', activeUser?.circle?.id ?? ''),
          ],
        })
      )
      .subscribe((res) => {
        setConversationCollection(res)
        setIsLoading(false)
      })
  }, [isLoading])

  const handlerGotoQueries = () => {
    navigate(ROUTE_QUERY)
  }
  return (
    <DashboardSectionCard
      label={'activeQuerys'}
      showMore={Boolean(circleSelected?.circle)}
      onClick={handlerGotoQueries}
    >
      {conversationCollection.length > 0 && (
        <List>
          {conversationCollection.map((conversation) => (
            <ListItem
              sx={{ paddingLeft: 0, paddingRight: 0 }}
              onClick={() => navigate(ROUTE_TELECONSULTATION)}
              key={conversation.id}
              className={style.chatContainer}
            >
              <ListItemAvatar>
                <Avatar
                  src={queryIcon}
                  style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF', padding: '0' }}
                />
              </ListItemAvatar>
              <ListItemText sx={listItemTextStyle} className={style.listItemText}>
                {conversation.name}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </DashboardSectionCard>
  )
}
