import { Message } from './Message'
import { Query } from '../../../common/api/Query'

export type SegmentedNotificationParams = {
  id?: string
  name: string
  pathology: string
  fromAge: number
  toAge: number
  treatment: string
  diagnostic: string
  userId: string
  circleId: string
  userCircleId: string
  messageTitle?: string
  messageDescription?: string
  createdAt: Date
  updatedAt: Date
}

export class SegmentedNotification {
  private _id: string | undefined
  private _name: string
  private _pathology: string
  private _fromAge: number
  private _toAge: number
  private _treatment: string
  private _diagnostic: string
  private _userId: string
  private _circleId: string
  private _userCircleId: string
  private _messageTitle: string | undefined
  private _messageDescription: string | undefined
  private readonly _createdAt: Date
  private _updatedAt: Date

  constructor(p: SegmentedNotificationParams) {
    this._id = p.id
    this._name = p.name
    this._pathology = p.pathology
    this._fromAge = p.fromAge
    this._toAge = p.toAge
    this._treatment = p.treatment
    this._diagnostic = p.diagnostic
    this._userId = p.userId
    this._circleId = p.circleId
    this._userCircleId = p.userCircleId
    this._messageTitle = p.messageTitle
    this._messageDescription = p.messageDescription
    this._createdAt = new Date()
    this._updatedAt = p.updatedAt
  }

  get id(): string | undefined {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get pathology(): string {
    return this._pathology
  }

  set pathology(value: string) {
    this._pathology = value
  }

  get fromAge(): number {
    return this._fromAge
  }

  set fromAge(value: number) {
    this._fromAge = value
  }

  get toAge(): number {
    return this._toAge
  }

  set toAge(value: number) {
    this._toAge = value
  }

  get treatment(): string {
    return this._treatment
  }

  set treatment(value: string) {
    this._treatment = value
  }

  get diagnostic(): string {
    return this._diagnostic
  }

  set diagnostic(value: string) {
    this._diagnostic = value
  }

  get userId(): string {
    return this._userId
  }

  set userId(value: string) {
    this._userId = value
  }

  get circleId(): string {
    return this._circleId
  }

  set circleId(value: string) {
    this._circleId = value
  }

  get userCircleId(): string {
    return this._userCircleId
  }

  set userCircleId(value: string) {
    this._userCircleId = value
  }

  get messageTitle(): string | undefined {
    return this._messageTitle
  }

  set messageTitle(value: string) {
    this._messageTitle = value
  }

  get messageDescription(): string | undefined {
    return this._messageDescription
  }

  set messageDescription(value: string) {
    this._messageDescription = value
  }

  get updatedAt(): Date {
    return this._updatedAt
  }

  get createdAt(): Date {
    return this._createdAt
  }
}

export type SegmentedMessageParams = {
  id?: string
  title: string
  description: string
  segmentedId: string
  createdAt: Date
  updatedAt: Date
}

export class SegmentedMessage {
  private _id: string | undefined
  private _title: string
  private _description: string
  private _segmentedId: string
  private readonly _createdAt: Date
  private _updatedAt: Date

  constructor(p: SegmentedMessageParams) {
    this._id = p.id
    this._title = p.title
    this._description = p.description
    this._segmentedId = p.segmentedId
    this._createdAt = new Date()
    this._updatedAt = p.updatedAt
  }

  get id(): string | undefined {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get title(): string {
    return this._title
  }

  set title(value: string) {
    this._title = value
  }

  get description(): string {
    return this._description
  }

  set description(value: string) {
    this._description = value
  }

  get segmentedId(): string {
    return this._segmentedId
  }

  set segmentedId(value: string) {
    this._segmentedId = value
  }

  get updatedAt(): Date {
    return this._updatedAt
  }

  set updatedAt(date: Date) {
    this._updatedAt = date
  }

  get createdAt(): Date {
    return this._createdAt
  }
}

export class NotificationQuery extends Query<Message> {}
