// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableSkeleton_container__1m3-H {\n    margin-top: 20px;\n    padding: 10px;\n}\n\n.TableSkeleton_searcher__1-Qs6 {\n    display: flex;\n    justify-content: end;\n    margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://src/components/table-skeleton/TableSkeleton.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,mBAAmB;AACvB","sourcesContent":[".container {\n    margin-top: 20px;\n    padding: 10px;\n}\n\n.searcher {\n    display: flex;\n    justify-content: end;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TableSkeleton_container__1m3-H",
	"searcher": "TableSkeleton_searcher__1-Qs6"
};
export default ___CSS_LOADER_EXPORT___;
