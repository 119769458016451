import React, { useEffect, useState } from 'react'
import GenericChartDashboard from '../../../../components/dashboard/generic-chart-dashboard/GenericChartDashboard'
import { ChartEnum } from '../../../../components/dashboard/generic-chart-dashboard/ChartEnum'
import { getActivePlanContainer } from '../../../../container/active-plan-module'
import { ActivePlanService } from '../../../../modules/active-plan/services/ActivePlanService'
import { ACTIVE_PLAN_SERVICE_KEY } from '../../../../modules/active-plan'

const activePlanService = getActivePlanContainer().get<ActivePlanService>(ACTIVE_PLAN_SERVICE_KEY)

const PatientNumberCard = () => {
  const [datasets, setDatasets] = useState<
    { data: number[]; backgroundColor: string; label: string }[]
  >([])
  const [numberUser, setNumberUser] = useState<number[]>([])
  const labels = [
    '0-10',
    '11-20',
    '21-30',
    '31-40',
    '41-50',
    '51-60',
    '61-70',
    '71-80',
    '81-90',
    '+91',
  ]

  const backgroundColors = [
    '#0063A6',
    '#F18B24',
    '#B6B5B5',
    '#F1DD25',
    '#68B3E0',
    '#4CBF0D',
    '#6048C3',
    '#8A4242',
    '#4B4B4B',
    '#757112',
  ]

  useEffect(() => {
    activePlanService.getUserNumberByActivePlan().subscribe((res) => {
      if (!res) return
      setNumberUser(res)
      setDatasets(
        res.map((item, index) => ({
          data: createArrByLength(res.length, item, index),
          backgroundColor: backgroundColors[index],
          label: labels[index],
        }))
      )
    })
  }, [])

  const createArrByLength = (length: number, value: number, valueIndex: number): number[] => {
    const auxArr: number[] = []
    for (let i = 0; i < length; i++) {
      if (i === valueIndex) {
        auxArr.push(value)
      } else {
        auxArr.push(0)
      }
    }
    return auxArr
  }

  const createEmptyLabel = (length: number): string[] => {
    const auxArr: string[] = []
    for (let i = 0; i < length; i++) {
      auxArr.push('')
    }
    return auxArr
  }

  return (
    <GenericChartDashboard
      datasets={datasets}
      labels={createEmptyLabel(numberUser.length)}
      title={''}
      typeChart={ChartEnum.Bar}
      legendPosition="bottom"
    />
  )
}

export default PatientNumberCard
