import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react'

import style from './cron.module.css'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'
import { FormDTO } from '../../modules/forms/models/FormDTO'
import styles from '../../features/user-profile/Editor.module.css'
import {
  Box,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from '@mui/material'

// Hardcoded end date for checkbox active
const END_DATE = 2027

export type CronItemProps = {
  handleChange: (s: string, sd: Date, fd: Date) => void
  id?: string
  form: FormDTO
  isRecurrence?: boolean
  handlerMonthRecurrence?: (b: number) => void
  recoveredCron?: (
    cron: string,
    mc: number,
    startDate: Date,
    finishDate: Date,
    weekDay: string[]
  ) => void
  preview?: boolean
  cronOldState?: string
  weekDayRecovered?: string[]
  isCreator: boolean
  finishDate?: Date
}

export function CronItemMockup(props: CronItemProps): JSX.Element {
  const { t } = useTranslation()

  const weekDaysNames = [
    t('monday'),
    t('tuesday'),
    t('wednesday'),
    t('thursday'),
    t('friday'),
    t('saturday'),
    t('sunday'),
  ]

  const [weekDay, setWeekDay] = useState<string[]>(
    props.weekDayRecovered && props.weekDayRecovered?.length > 0 ? props.weekDayRecovered : []
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [weekQuantity, setWeekQuantity] = useState<number>(1)
  const initialValue = props.form ? props.form.monthRecurrence : 1
  const [monthQuantity, setMonthQuantity] = useState<number>(initialValue)
  const [startDate, setStartDate] = useState<Date>(
    (props.id ?? props.form.startCron !== new Date()) ? props.form.startCron : new Date()
  )
  const [finishDate, setFinishDate] = useState<Date>(
    (props.id ?? props.form.startCron !== new Date()) ? props.form.finishCron : new Date()
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [days, setDays] = useState<string>('*')
  const [months, setMonths] = useState<string>('*')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [monthsRanges, setMonthsRanges] = useState<string>('*')
  // const [isLoading, setIsLoading] = useState<boolean>(false)
  const [cron, setCron] = useState<string>(
    props.form.repeating !== '0 0 0 0 0' ? props.form.repeating : '0 09 * * *'
  )
  const [tabValue, setTabValue] = useState<number>(0)
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const [NoEndDate, setNoEndDate] = useState<boolean>(props.form.finishCron.getFullYear() === 2027)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const startDateR = useRef(new Date())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const finishDateR = useRef(new Date())
  const wR = useRef([''])
  const monthQuantityR = useRef(1)
  // const weekQuantityR = useRef(1)
  /*  const currentYearLastDate = new Date(new Date().getFullYear(), 11, 31) */

  useEffect(() => {
    if (!props.recoveredCron) return
    props.recoveredCron(cron, monthQuantity, startDate, finishDate, weekDay)
  }, [props.preview, monthQuantity, weekDay])

  // let w: string[] = []
  useEffect(() => {
    props.handleChange(cron, startDate, finishDate)
  }, [cron, startDate, finishDate])

  useEffect(() => {
    if (!props.handlerMonthRecurrence) return
    props.handlerMonthRecurrence(monthQuantity)
  }, [monthQuantity])

  useEffect(() => {
    // if (!props.id) return
    if (props.form.repeating === '0 0 0 0 0') {
      setTabValue(0)
      return
    }
    const str = props.form.repeating
    if (!str) return
    const arr = str.split(' ')

    const isDifferentFromAsterisk = arr.slice(4).some((value) => value !== '*')
    if (isDifferentFromAsterisk) {
      setTabValue(1)
    }
    if (!arr) return
    const weekDays = arr[4].split(',')
    const newWeeDaysNames = weekDaysNames.filter((value, index) =>
      weekDays.includes(index.toString())
    )
    setWeekDay(newWeeDaysNames)

    const isMensual = arr.slice(5).some((value) => value !== '*')
    if (isMensual) {
      setTabValue(2)
    }
  }, [props.id, props.preview])

  // Para ver como funciona cron --> https://crontab.guru/

  /*  function handleChange(v: string) {
    if (!props.isCreator) return
    const parse = Number.parseInt(v)
    switch (parse) {
      case 0:
        //diario
        setTabValue(0)
        setCron('00 09 * * *')
        setMonthQuantity(0)
        break
      case 1:
        //semanal
        setTabValue(1)
        setCron('00 09 * * *')
        setMonthQuantity(0)
        handleWeekDay()
        break
      case 2:
        //mensual
        setCron('0 09 ' + startDateR.current + ' * *')
        setTabValue(2)
        handleMonth()
        break
      default:
        setTabValue(0)
        setCron('00 09 * * *')
    }
  } */

  const handleWeekDayChange = (event: SelectChangeEvent<string[]>, child: ReactNode) => {
    wR.current = (event.target.value as string[]).sort(
      (a, b) => weekDaysNames.indexOf(a) - weekDaysNames.indexOf(b)
    )

    setWeekDay(wR.current)

    handleWeekDay()
  }

  function handleWeekDay() {
    let daysAux = ''
    const s = startDateR.current.getDay()

    for (let j = s; j < s + 7; j++) {
      wR.current.forEach((e, i) => {
        const n = getWeekDayNumber(e)
        let m = j
        if (j > 6) {
          m -= 7
        }

        if (m === n) {
          daysAux += n + ','
        }
      })
      if (j === s + 6) {
        daysAux = daysAux.substring(0, daysAux.length - 1)
      }
    }
    setDays(daysAux)
    if (daysAux === '') {
      setCron('0 09 * * *')
    } else {
      setCron('0 09 * * ' + daysAux)
    }
  }

  /*  function handleWeekQuantityChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    let parse = Number.parseInt(event.target.value)
    if (Number.isNaN(parse) || parse < 1) {
      parse = 1
    }
    setWeekQuantity(parse)
    weekQuantityR.current = parse
    handleWeekDay()
  } */

  // working
  function handleMonthChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    let parse = Number.parseInt(event.target.value)
    if (Number.isNaN(parse) || parse < 1) {
      parse = 1
    }
    monthQuantityR.current = parse
    setMonthQuantity(parse)
    handleMonth()
  }

  function handleMonth() {
    const sd = startDateR.current.getDate()
    let month = ''
    const initialMonth = startDateR.current.getMonth() + 1

    for (let i = 0; i < Math.trunc(12 / monthQuantityR.current); i++) {
      let indexMonths = (initialMonth + monthQuantityR.current * i) % 12
      if (indexMonths === 0) indexMonths = 12
      month = month + (i > 0 ? ',' : '') + indexMonths.toString()
    }
    setCron('0 09 ' + sd + ' ' + month + ' * ')
  }

  function handleStartDateChange(event: any) {
    if (event) {
      setStartDate(event.toDate())

      if (tabValue === 2) {
        handleMonthRanges(undefined, event.toDate(), undefined)
      }
    }
  }

  function handleFinishDateChange(event: any) {
    if (event) {
      setFinishDate(event.toDate())
      if (tabValue === 2) {
        handleMonthRanges(undefined, undefined, event.toDate())
      }
    }
  }

  function handleMonthRanges(
    mQuantity: number | undefined,
    sDate: Date | undefined,
    fDate: Date | undefined
  ) {
    let mQuantity2
    mQuantity === undefined ? (mQuantity2 = monthQuantity) : (mQuantity2 = mQuantity)

    let sDate2
    sDate === undefined ? (sDate2 = startDate) : (sDate2 = sDate)

    let fDate2
    fDate === undefined ? (fDate2 = finishDate) : (fDate2 = fDate)

    if (fDate === undefined) {
      const endDate = new Date(startDate)
      endDate.setFullYear(END_DATE)
      fDate = endDate
    }

    const date = new Date()
    const currentMonth = date.getMonth() + 1
    let ms = ''
    const numberOfMonths = Math.abs(
      (fDate2.getFullYear() - sDate2.getFullYear()) * 12 +
        (fDate2.getMonth() - sDate2.getMonth()) -
        1
    )
    for (let i = currentMonth; i < currentMonth + numberOfMonths; i += mQuantity2) {
      if (i === currentMonth) {
        ms += i
      } else {
        if (i > 12) {
          ms += ',' + (i % 12)
        } else {
          ms += ',' + i
        }
      }
      setMonths(ms)
    }

    const cron = '00 09' + monthsRanges + ' ' + months + ' ' + days
    setCron(cron)
  }

  const getWeekDayNumber = (day: string) => {
    switch (day) {
      case t('monday'):
        return 0
      case t('tuesday'):
        return 1

      case t('wednesday'):
        return 2

      case t('thursday'):
        return 3

      case t('friday'):
        return 4

      case t('saturday'):
        return 5

      case t('sunday'):
        return 6
    }
  }

  const handleRecurrence = (value: number) => {
    if (tabValue === value) {
      setTabValue(-1)
    } else {
      setTabValue(value)
    }
  }

  const handleNoEndDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNoEndDate(event.target.checked)
    if (event.target.checked) {
      const endDate = new Date(startDate)
      endDate.setFullYear(END_DATE)
      setFinishDate(endDate)
    }
  }
  return (
    <>
      <Box className={style.mockupCronContainer} display="flex">
        <Box
          className={style.mockupCronTypeContainer}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <RadioGroup>
            <ListItem
              disabled={!props.isCreator}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                height: '164px',
              }}
            >
              <label className={style.checkboxContainer}>
                <input
                  type="radio"
                  name="recurrenceDaily"
                  className={styles.check}
                  disabled={!props.isCreator}
                  onChange={() => handleRecurrence(0)}
                  checked={tabValue === 0}
                />
                {t('Daily')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="radio"
                  name="recurrenceWeekly"
                  className={styles.check}
                  disabled={!props.isCreator}
                  onChange={() => handleRecurrence(1)}
                  checked={tabValue === 1}
                />
                {t('Weekly')}
              </label>
              <label className={style.checkboxContainer}>
                <input
                  type="radio"
                  name="recurrenceMonthly"
                  className={styles.check}
                  disabled={!props.isCreator}
                  onChange={() => handleRecurrence(2)}
                  checked={tabValue === 2}
                />
                {t('Monthly')}
              </label>
            </ListItem>
          </RadioGroup>
        </Box>
        <Box style={{ margin: 'auto' }}>
          <p className={style.eventParraf} hidden={tabValue !== 0}>
            {t('El evento tendrá lugar todos los días')}
          </p>
          <List hidden={tabValue !== 1}>
            <ListItem>
              <Box>
                <Select
                  style={{ width: '240px' }}
                  labelId="select-week-label"
                  id="select-week"
                  multiple
                  disabled={!props.isCreator}
                  value={weekDay}
                  onChange={handleWeekDayChange}
                  input={<Input type="checkbox" className={style.inputWeek} />}
                  renderValue={(selected) => selected.join(', ')}
                  className={style.mockupSelect}
                >
                  {weekDaysNames.map((weekDayName) => (
                    <MenuItem
                      key={weekDayName}
                      value={weekDayName}
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <ListItemText primary={weekDayName} />
                      <input
                        type="checkbox"
                        name="weekDay"
                        className={styles.check}
                        style={{ width: '16px', height: '16px', margin: 0, padding: 0 }}
                        disabled={!props.isCreator}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setWeekDay([...weekDay, weekDayName])
                          } else {
                            setWeekDay(weekDay.filter((value) => value !== weekDayName))
                          }
                        }}
                        checked={weekDay.includes(weekDayName)}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </ListItem>
          </List>
          <Box className={style.monthlyContent} hidden={tabValue !== 2}>
            {t('Repetir cada')}
            <Input
              style={{ margin: '0 10px', width: '60px', fontFamily: 'Open Sans, sans-serif' }}
              id={'month'}
              key={'month'}
              value={monthQuantity}
              onChange={(e) => handleMonthChange(e)}
              name="week"
              type="number"
              disabled={!props.isCreator}
            />
            {t('meses')}
          </Box>
        </Box>
        <Box className={style.mockupRepeatingContainer}>
          <List>
            <ListItem>
              <InputLabel>{t('Rango de repetición')}</InputLabel>
            </ListItem>
            <ListItem>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                <DatePicker
                  key={'startDate'}
                  onError={(reason, value) => {
                    switch (reason) {
                      case 'invalidDate':
                        setDateTimePickerError(t('invalidDateMessage'))
                        break
                      case 'minDate':
                        setDateTimePickerError(t('minDateMessage'))
                        break
                    }
                  }}
                  renderInput={(props) => (
                    <TextField
                      className={style.inputDate}
                      style={{ width: '90%' }}
                      size={'small'}
                      helperText={props.error && DateTimePickerError}
                      {...props}
                    />
                  )}
                  inputFormat=" DD/MM/YYYY"
                  label={t('Comienzo')}
                  value={startDate}
                  minDate={props.id ? props.form.startCron : new Date()}
                  onChange={(e) => handleStartDateChange(e)}
                  disabled={!props.isCreator}
                />
              </LocalizationProvider>
            </ListItem>
            <ListItem>
              {NoEndDate ? null : (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <DatePicker
                    key={'finishDate'}
                    onError={(reason, value) => {
                      switch (reason) {
                        case 'invalidDate':
                          setDateTimePickerError(t('invalidDateMessage'))
                          break
                        case 'minDate':
                          setDateTimePickerError(t('minDateMessage'))
                          break
                      }
                    }}
                    renderInput={(props) => (
                      <TextField
                        style={{ width: '90%' }}
                        size={'small'}
                        helperText={props.error && DateTimePickerError}
                        {...props}
                      />
                    )}
                    inputFormat=" DD/MM/YYYY"
                    label={t('Finalizar el')}
                    value={finishDate}
                    minDate={startDate}
                    onChange={(e) => handleFinishDateChange(e)}
                    disabled={!props.isCreator}
                  />
                </LocalizationProvider>
              )}
            </ListItem>
            <ListItem>
              <Box display={'flex'} flexDirection={'row'}>
                <label className={style.labelWithoutEndDate}>
                  <input
                    style={{ marginRight: '16px' }}
                    type="checkbox"
                    name="withoutEndDate"
                    value="no"
                    className={styles.check}
                    onChange={handleNoEndDateChange}
                    checked={NoEndDate}
                  />
                  <span>{t('withoutEndDate')}</span>
                </label>
              </Box>
            </ListItem>
          </List>
        </Box>
      </Box>
    </>
  )
}
