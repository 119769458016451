import React from 'react'
import { Box, Card, CardContent } from '@mui/material'
import { Header } from '../header/Header'
import { useTranslation } from 'react-i18next'
import style from './DashboardSectionCard.module.scss'

type DashboardSectionCardProps = {
  label: string
  children: React.ReactNode
  showMore?: boolean
  icon?: string
  onClick?: () => void
  borderBottom?: string
  marginTop?: number
}

export const DashboardSectionCard: React.FC<DashboardSectionCardProps> = ({
  label,
  children,
  showMore,
  icon,
  onClick,
  borderBottom,
  marginTop,
}) => {
  const { t } = useTranslation()

  return (
    <Box className={style.box} sx={{ marginTop: `${marginTop}px` }}>
      <Card className={style.card}>
        <CardContent className={style.card}>
          <Header
            label={t(label)}
            showMore={showMore}
            icon={icon}
            onClick={onClick}
            borderBottom={borderBottom}
          />
          {children}
        </CardContent>
      </Card>
    </Box>
  )
}
