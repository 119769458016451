import { DashboardSectionCard } from '../../../../components/section-card/DashboardSectionCard'
import React, { useEffect, useState } from 'react'
import { getUserCircleContainer } from '../../../../container/user-circle-module'
import { IUserCircleService, USER_CIRCLE_SERVICE_KEY } from '../../../../modules/user-circle'
import { ILoggedUserService } from '../../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../../modules/users'
import { getUserContainer } from '../../../../container/user-module'
import { CircleDTO } from '../../../../modules/circle/models/CircleDTO'
import { Box, ListItem, ListItemText } from '@mui/material'
import style from '../../patient/chats-card/ChatsCard.module.scss'
import { listItemTextStyle } from '../../patient/notification-card-patient/NotificationsCardStyleMui'

type CirclesCardProps = {
  selectedUserCircle: CircleDTO | undefined
  setSelectedUserCircle: (userCircle: CircleDTO) => void
}

const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const ProfessionalCirclesCard: React.FC<CirclesCardProps> = ({
  setSelectedUserCircle,
  selectedUserCircle,
}) => {
  const [circles, setCircles] = useState<CircleDTO[]>([])
  const loggedUser = loggedUserService.get()
  useEffect(() => {
    userCircleService
      .getWichIncludesUserIDWithRelatersAndCircle(loggedUser?.id ?? '')
      .subscribe((res) => {
        if (res) {
          // obtenemos un array unico de circulos
          const uniqueCircles: CircleDTO[] = []
          res.forEach((userCircle) => {
            if (!uniqueCircles.find((circle) => circle.id === userCircle.circle.id)) {
              uniqueCircles.push(userCircle.circle)
            }
          })
          setCircles(uniqueCircles)
        }
      })
  }, [])

  return (
    <DashboardSectionCard label={'yourCircles'}>
      <Box
        sx={{
          maxHeight: '290px',
          overflowY: 'auto',
        }}
      >
        {circles.map((circle) => (
          <ListItem
            key={circle.id}
            onClick={(event) => {
              setSelectedUserCircle(circle)
            }}
            sx={{
              hover: 'none',
              cursor: 'pointer',
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Box width={'100%'}>
              <Box
                sx={{
                  backgroundColor:
                    selectedUserCircle?.id === circle.id ? 'rgb(241, 139, 36, 0.15)' : 'white',
                  borderColor:
                    selectedUserCircle?.id === circle.id ? 'rgb(241, 139, 36)' : '#68B3E0',
                }}
                display={'flex'}
                alignItems={'center'}
                textAlign={'center'}
                mr={1}
                ml={1}
                className={style.chatContainer}
              >
                <ListItemText sx={listItemTextStyle}>{circle.name}</ListItemText>
              </Box>
            </Box>
          </ListItem>
        ))}
      </Box>
    </DashboardSectionCard>
  )
}
