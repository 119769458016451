// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Table_pageContainer__2eSzi {\n    background: #ffffff;\n    margin: 25px;\n    box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n    padding: 15px;\n}\n\n.Table_header__3hJOw {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    grid-gap: 1rem;\n    gap: 1rem;\n}\n\n.Table_actionBtns__1smHI {\n    display: none;\n}\n\n@media (max-width: 599px) {\n    .Table_actionBtns__1smHI {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n        grid-gap: 1rem;\n        gap: 1rem;\n        margin-top: 1rem;\n    }\n\n    .Table_addBtn__1p8RA {\n        display: none;\n    }\n}", "",{"version":3,"sources":["webpack://src/features/patient-data/symptoms/Table.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,6CAA6C;IAC7C,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,cAAS;IAAT,SAAS;AACb;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;QACb,mBAAmB;QACnB,8BAA8B;QAC9B,cAAS;QAAT,SAAS;QACT,gBAAgB;IACpB;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".pageContainer {\n    background: #ffffff;\n    margin: 25px;\n    box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n    padding: 15px;\n}\n\n.header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    gap: 1rem;\n}\n\n.actionBtns {\n    display: none;\n}\n\n@media (max-width: 599px) {\n    .actionBtns {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n        gap: 1rem;\n        margin-top: 1rem;\n    }\n\n    .addBtn {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "Table_pageContainer__2eSzi",
	"header": "Table_header__3hJOw",
	"actionBtns": "Table_actionBtns__1smHI",
	"addBtn": "Table_addBtn__1p8RA"
};
export default ___CSS_LOADER_EXPORT___;
