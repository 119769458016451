import { Container, ContainerItem } from '../common/container/Container'
import { REPORT_SERVICE_KEY, REPORT_API_KEY, REPORT_MODULE } from '../modules/report'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { ReportProps } from '../modules/report/container'
import { ReportService } from '../modules/report/services/ReportService'
import { ReportApi } from '../modules/report/api/ReportApi'

let container: Container

function init(p: ReportProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(REPORT_API_KEY, new ContainerItem({ instance: new ReportApi() }))
  items.set(
    REPORT_SERVICE_KEY,
    new ContainerItem({ instance: new ReportService({ apiKey: REPORT_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getReportContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[REPORT_MODULE],
      },
    })
  }

  return container
}
