import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_MESSENGER } from 'routes/routes-constants'
import { Query, QueryParam } from 'common/api/Query'
import { Conversation } from 'modules/messenger/models/Conversation'
import { getMessengerContainer } from 'container/messenger-module'
import { ConversationService } from 'modules/messenger/services/ConversationService'
import { CONVERSATION_SERVICE_KEY } from 'modules/messenger'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { useGetActiveUser } from 'hooks/get-active-user/useGetActiveUser'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { listItemTextStyle } from '../notification-card-patient/NotificationsCardStyleMui'
import style from './ChatsCard.module.css'
import maleIcon from '@assets/chatIcons/male.svg'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import { ConversationDTO } from 'modules/messenger/models/ConversationDTO'

type ChatsCardProps = {
  setNumberPendingChats: (number: number) => void
}

const messengerContainer = getMessengerContainer()
const conversationsService = messengerContainer.get<ConversationService>(CONVERSATION_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
// const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

const notificationsPerPage = 7

export const ChatsCard: React.FC<ChatsCardProps> = (props) => {
  const { activeUser } = useGetActiveUser()
  const loggedUser = loggedUserService.get()
  const [conversationCollection, setConversationCollection] = useState<ConversationDTO[]>([])
  const { userCircleService } = useCircleConfiguration()
  const circleSelected = userCircleService?.getActiveFullUserCircle()

  // const [conversationUsers, setConversationUsers] = useState<ConversationUser[]>([])
  // const [gender, setGender] = useState<string>('')
  const [page] = useState<number>(1)
  const navigate = useNavigate()

  useEffect(() => {
    if (!loggedUser) return
    conversationsService
      .getFilteredItems(
        new Query({
          pager: { offset: (page - 1) * notificationsPerPage, limit: notificationsPerPage },
          query: [
            new QueryParam<Conversation>('userID', loggedUser.id),
            new QueryParam<Conversation>('circleID', activeUser.circle?.id ?? ''),
          ],
        })
      )
      .subscribe((res) => {
        setConversationCollection(res)
        props.setNumberPendingChats(res.length)
        /* const conversationUsersArray: ConversationUser[] = []
        res.forEach((conversation) => {
          conversationUsersArray.push(...conversation.users)
        }) */
        // setConversationUsers(conversationUsersArray)
      })
  }, [])

  // implement if they explicitly ask us that they want to differentiate the icons by gender.
  // In the backend change the checkoutDoctor to bring the gender

  /* useEffect(() => {
    const fetchGender = async () => {
      const adminUser = conversationUsers.find((cuser) => cuser.isAdmin)
      if (adminUser) {
        const ownerID = adminUser.userID
        console.log('ownerID', ownerID)
        const res = await userService.getByID(ownerID).toPromise()
        if (res) {
          console.log('resGender', res)
          setGender(res.gender === 1 ? 'female' : 'male')
        }
      }
    }
    fetchGender()
  }, [conversationCollection]) */

  const handlerGotToChats = () => {
    navigate(ROUTE_MESSENGER)
  }

  return (
    <DashboardSectionCard
      label={'pendingChats'}
      showMore={Boolean(circleSelected?.circle)}
      onClick={handlerGotToChats}
    >
      {conversationCollection.length > 0 && (
        <List>
          {conversationCollection.map((conversation) => (
            <ListItem
              sx={{ paddingLeft: 0, paddingRight: 0 }}
              key={conversation.id}
              onClick={handlerGotToChats}
              className={style.chatContainer}
            >
              <ListItemAvatar>
                <Avatar
                  src={maleIcon}
                  style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF', padding: '0' }}
                />
              </ListItemAvatar>
              <ListItemText sx={listItemTextStyle} className={style.listItemText}>
                {conversation.name}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </DashboardSectionCard>
  )
}
