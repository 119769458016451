import { Container } from '../../common/container/Container'

export type TeleConsultaContainerConfig = {
  moduleFullUrl: string
}

export type TeleConsultaProps = {
  parentContainer: Container
  config: TeleConsultaContainerConfig
}

export const TELECONSULTA_MODULE = Symbol('TELECONSULTA_MODULE')

export const TELECONSULTA_API_KEY = Symbol('CONSULTA_API_KEY')

export const TELECONSULTA_SERVICE_KEY = Symbol('TELECONSULTA_SERVICE_KEY')
