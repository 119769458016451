import React, { ChangeEvent } from 'react'

import style from './AddFile.module.css'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import attach_file_icon from '../../assets/events_icons/ico-clip-adjunto.svg'
import { validExtensions } from './Extensions'
import mobile_file_icon from '../../assets/buttons/add_file.svg'

type AddFileProps = {
  handlerChangeAttachFile: (event: ChangeEvent<HTMLInputElement>) => void
  isOnlyIcon?: boolean
  disabled?: boolean
  isMultiple?: boolean
  lengthFiles: number
  maxFiles?: number
  buttonLabel?: string
  icon?: string
}

export function AddFile(props: AddFileProps): JSX.Element {
  const { t } = useTranslation()

  const buttonLabel = props.buttonLabel ?? 'addFile'
  const defaultMaxFiles = 3

  return (
    <label
      className={getBtnAttachFileIconStyle(
        props.lengthFiles,
        props.disabled ?? false,
        props.maxFiles ?? defaultMaxFiles
      )}
    >
      {props.isOnlyIcon ? (
        <>
          <input
            type="file"
            multiple={props.isMultiple ?? true}
            disabled={props.lengthFiles >= 3 || props.disabled}
            onChange={props.handlerChangeAttachFile}
            aria-label={t(buttonLabel, { count: props.maxFiles })}
          />
          <img src={mobile_file_icon} />
        </>
      ) : (
        <>
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'button'}
            label={t(buttonLabel)}
            labelOptions={{ count: props.maxFiles ?? defaultMaxFiles }}
            span={true}
            startIcon={attach_file_icon}
            handler={() => {}}
          />
          <input
            type="file"
            multiple={props.isMultiple ?? true}
            accept={validExtensions}
            disabled={props.lengthFiles >= (props.maxFiles ?? defaultMaxFiles) || props.disabled}
            onChange={props.handlerChangeAttachFile}
            aria-label={t(buttonLabel, { count: props.maxFiles })}
          />
        </>
      )}
    </label>
  )
}

const getBtnAttachFileIconStyle = (
  addedFiles: number,
  disabled: boolean,
  maxFiles: number
): string =>
  `${style.btnAttachFile} ${
    addedFiles >= maxFiles || disabled ? style.btnAttachFileDisabled : style.btnAttachFileActive
  }`
