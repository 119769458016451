import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ARTICLES } from '../../routes/routes-constants'
import { Article } from '../../modules/content/models/Article'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { Query, QueryParam } from '../../common/api/Query'
import { Pager, Search, SearchValue } from '../../components/table_type/types'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { AppTable, Field } from '../../components/table'
import { useTranslation } from 'react-i18next'
import { getUserContainer } from '../../container/user-module'
import { IUserService, USER_SERVICE_KEY } from '../../modules/users'
import { ArticleRelevance } from 'modules/content/enums/ArticleRelevance'
import { ArticleStatus, statusTypes } from 'modules/content/enums/ArticleStatus'
import { getCircleContainer } from '../../container/circle-module'
import { CircleService } from '../../modules/circle/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../modules/circle'
import { FormQuery } from '../../modules/forms/models/Form'
import relevanciaNaranja from '../../assets/esfera/article/relevancia naranja.svg'
import relevanciaRojo from '../../assets/esfera/article/relevancia rojo.svg'
import relevanciaVerde from '../../assets/esfera/article/relevancia verde.svg'
import likeGreen from '../../assets/buttons/Like-green.svg'
import likeRed from '../../assets/buttons/Like-red.svg'
import style from './Search.module.css'
// import seeIcon from '../../assets/table_icons/ico-ver.svg'

const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authorService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const circleService = getCircleContainer().get<CircleService>(CIRCLE_SERVICE_KEY)

type ArticleProps = {
  tagIDList: string[]
  circles: number[]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authorList = new Map()
const circleList = new Map()

export function TableHistory(props: ArticleProps) {
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [articles, setArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [articlesPerPage, setArticlesPerPage] = useState<number>(100)
  const [pager, setPager] = useState<Pager>()
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filtering, setFiltering] = useState<boolean>(false)
  const [searcher, setSearcher] = useState<SearchValue<FormQuery>[]>([
    {
      name: 'allFields',
      label: t('search') + '...',
      value: '',
    },
  ])

  const statusOptions = statusTypes()

  useEffect(() => {
    setIsLoading(true)
  }, [props.tagIDList])

  const getArticles = () => {
    const query = [
      new QueryParam<Article>('type', 'article'),
      new QueryParam<Article>('categories', props.circles),
      new QueryParam<Article>('status', [
        ArticleStatus[ArticleStatus.publicado],
        ArticleStatus[ArticleStatus.rechazado],
      ]),
    ]

    if (searcher[0].value !== '' && searcher[0].value !== undefined) {
      query.push(new QueryParam<Article>('titleCoincidences', searcher[0].value))
    }

    articlesService
      .getFilteredList(
        new Query({
          query,
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        const articleList = emptyList<Article>()

        articleList.count = 0
        for (let i = res.items.length - 1; i >= 0; i--) {
          const articleItem = res.items[i]

          articlesService.getAuthorAndCategory(articleItem.id).subscribe((response) => {
            if (response !== undefined) {
              articleItem.author = response.author
              articleItem.categoryText = response.category
            }
            articleList.items.push(articleItem)
            articleList.count++
            articleList.items = articleList.items.sort((a, b) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            })
          })
        }
        setTimeout(() => {
          setArticles(articleList)
          setCount(res.count)
        }, 500)
      })

    circleService.getAllCirclesWithSpecialtyAndCreator().subscribe((res) => {
      res.items.forEach((o: any) => circleList.set(o.id, o.name))
    })
  }

  useEffect(() => {
    if (props.circles.length === 0) {
      return
    }
    getArticles()
  }, [props.circles])

  useEffect(() => {
    getArticles()
  }, [searcher])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: articlesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, articlesPerPage])

  const search: Search<FormQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<FormQuery>[]) => {
      setFiltering(!!svs[0].value)

      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
    },
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setArticlesPerPage(10)
      return
    }
    setArticlesPerPage(Number.parseInt(event.target.value))
  }

  const handleSee = (article: Article) => navigate(`${ROUTE_ARTICLES}/${article.id}`)

  const showContentChildrens = (paragraph: any) => {
    if (paragraph?.children != null) {
      return (
        <div>
          {paragraph.children.map((item: any) => {
            // eslint-disable-next-line react/jsx-key
            return <span>{showContentChildrens(item)}</span>
          })}
        </div>
      )
    } else {
      return <span>{paragraph.text}</span>
    }
  }

  const fields: Field<Article>[] = [
    {
      name: 'title',
      label: t('title'),
      renderFunc: (f, i) => (
        <span style={{ margin: '0px', cursor: 'pointer' }} onClick={() => handleSee(i)}>
          {i.title}
        </span>
      ),
    },
    {
      name: 'createdAt',
      label: t('creationDate'),
      renderFunc: (f, i) => new Date(i.createdAt).toLocaleDateString(),
    },
    {
      name: 'updatedAt',
      label: t('lastUpdate'),
      renderFunc: (f, i) => new Date(i.updatedAt).toLocaleDateString(),
    },
    {
      name: 'content',
      label: t('description'),
      renderFunc: (f, i) => {
        try {
          const jsonContent = JSON.parse(i.content)
          const textContent = jsonContent.map((itemElement: any) =>
            showContentChildrens(itemElement)
          )
          return <div className={style.contentPreview}>{textContent}</div>
        } catch (error) {
          return <div className={style.contentPreview}>{i.content}</div>
        }
      },
    },
    {
      name: 'userID',
      label: t('author'),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      renderFunc: (f, i) => {
        const articleAuthor = i.authorName
        return articleAuthor
      },
    },
    {
      name: 'circleId',
      label: t('circle'),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      renderFunc: (f, i) => {
        const circleName = i.categoryText
        return circleName
      },
    },
    {
      name: 'relevance',
      label: t('relevance'),
      renderFunc: (f, i) => {
        let relevanceIcon = ''
        let colorRelevance = ''

        switch (i.relevance) {
          case ArticleRelevance[ArticleRelevance.baja]:
            colorRelevance = '#6ce32b'
            relevanceIcon = relevanciaVerde
            break
          case ArticleRelevance[ArticleRelevance.media]:
            colorRelevance = 'orange'
            relevanceIcon = relevanciaNaranja
            break
          case ArticleRelevance[ArticleRelevance.alta]:
            colorRelevance = 'red'
            relevanceIcon = relevanciaRojo
            break
          default:
            break
        }

        return (
          <span style={{ color: colorRelevance }}>
            <img src={relevanceIcon}></img>
          </span>
        )
      },
    },
    {
      name: 'status',
      label: t('status'),
      renderFunc: (f, i) => {
        let colorStatus = ''
        let textStatus = ''
        let iconStatus = ''
        let iconClass = ''

        switch (i.status) {
          case ArticleStatus[ArticleStatus.borrador]:
            textStatus = statusOptions[ArticleStatus.borrador]
            colorStatus = 'orange'
            break
          case ArticleStatus[ArticleStatus.pendiente]:
            textStatus = statusOptions[ArticleStatus.pendiente]
            colorStatus = 'silver'
            break
          case ArticleStatus[ArticleStatus.publicado]:
            textStatus = statusOptions[ArticleStatus.publicado]
            colorStatus = '#6ce32b'
            iconStatus = likeGreen
            break
          case ArticleStatus[ArticleStatus.rechazado]:
            textStatus = statusOptions[ArticleStatus.rechazado]
            colorStatus = 'red'
            iconClass = style.inverted
            iconStatus = likeRed
            break
          case ArticleStatus[ArticleStatus.validando]:
            textStatus = statusOptions[ArticleStatus.validando]
            colorStatus = 'blue'
            break
          default:
            break
        }

        return (
          <b className={'status' + i.status} style={{ color: colorStatus }}>
            {textStatus}
            &nbsp;&nbsp;&nbsp;
            <img className={iconClass} src={iconStatus} />
          </b>
        )
      },
    },
  ]

  return (
    <AppTable
      items={articles.items}
      rowKeyField="id"
      fields={fields}
      search={search}
      // actions={actions}
      pager={pager}
    />
  )
}
