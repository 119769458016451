import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Modal, Typography } from '@mui/material'
import { AppTable } from '../../components/table_AP'
import { ActionsComponent } from '../../components/table_AP/ActionsComponent'
import { Pager, Search, SearchValue, Field } from '../../components/table_type/types'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam, QueryParamN } from '../../common/api/Query'
import styles from './Table.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { AddSeeEditCityHallModal } from './AddSeeEditCityHallModal'
import { CityHall, CityHallQuery } from '../../modules/cityHall/models/CityHall'
import { CityHallService } from '../../modules/cityHall/services/CityHallService'
import { CITY_HALL_SERVICE_KEY } from '../../modules/cityHall'
import { getCityHallContainer } from '../../container/cityHall-module'
import add_icon from '../../assets/active_plan_icons/add_icon.svg'
import delete_icon from '../../assets/active_plan_icons/delete_icon.svg'
import { enqueueSnackbar } from 'notistack'
import { CustomModal } from 'components/modal/CustomModal'
import { finalize } from 'rxjs/operators'
// import { useSnackbar } from 'notistack'

const cityHallService = getCityHallContainer().get<CityHallService>(CITY_HALL_SERVICE_KEY)
type TableProps = {
  id?: string
}

export function Table(props: TableProps) {
  const { t } = useTranslation()
  const [cityHall, setCityHall] = useState<ItemList<CityHall>>(emptyList<CityHall>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [formsPerPage, setFormsPerPage] = useState<number>(10)
  const [searcher, setSearcher] = useState<SearchValue<CityHallQuery>[]>([
    { name: 'name', label: t('search') + '...' },
  ])
  const [openCityHallModal, setOpenCityHallModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  // const [showDelete, setShowDelete] = useState<boolean>(false)
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>(
    cityHall.items.reduce<Record<string, boolean>>((acc, item) => {
      if (item?.id !== undefined) {
        acc[item.id] = false
      }
      return acc
    }, {})
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // const { enqueueSnackbar } = useSnackbar()
  const [selectedCityHallData, setSelectedCityHallData] = useState<CityHall>()
  const [selectedCityHallID, setSelectedCityHallID] = useState<string>('')
  const showDelete = useMemo(() => Object.values(checkedItems).includes(true), [checkedItems])

  const getData = () => {
    setIsLoading(true)
    cityHallService
      .getFilteredList(
        new Query({
          query: [...searcherQuery(searcher)],
          pager: { offset: formsPerPage * page, limit: formsPerPage },
          sort: [{ field: 'name', desc: false }],
        })
      )
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe((res) => {
        if (!res) return
        setCityHall(res)
        setCount(res.count)
      })
  }

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: formsPerPage,
      handleChangeRowsPerPage,
    })
    getData()
  }, [page, count, formsPerPage, searcher])

  const handleCheckedChange = (key: string) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [key]: !prevCheckedItems[key],
    }))
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setFormsPerPage(10)
      return
    }
    setFormsPerPage(Number.parseInt(event.target.value))
  }

  const handlerCityHallModal = () => {
    setOpenCityHallModal(!openCityHallModal)
    setSelectedCityHallID('')
    setSelectedCityHallData(undefined)
  }

  const search: Search<CityHallQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<CityHallQuery>[]) => {
      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
    },
  }

  const fields: Field<CityHall>[] = [
    {
      name: 'name',
      label: t('cityHalls'),
    },
    {
      name: 'city',
      label: t('municipality'),
    },
    {
      name: 'contact',
      label: t('contactPerson'),
      renderFunc: (f, i) => (i.contact.length !== 0 ? i.contact[0].name : ''),
    },
    {
      name: 'contact',
      label: t('electronicMail'),
      renderFunc: (f, i) => (i.contact.length !== 0 ? i.contact[0].email.split(',')[0] : ''),
    },
    {
      name: 'contact',
      label: t('phone'),
      renderFunc: (f, i) => (i.contact.length !== 0 ? i.contact[0].phone.split(',')[0] : ''),
    },
  ]

  const handlerDelete = () => {
    for (const key in checkedItems) {
      if (checkedItems[key]) {
        cityHallService.delete(key).subscribe((res) => {
          if (!res) {
            enqueueSnackbar(t('cantDeleteSportingSpace'), { variant: 'error' })
          }
          setIsLoading(!isLoading)
          // enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
        })
      }
    }
    setCheckedItems(
      cityHall.items.reduce<Record<string, boolean>>((acc, item) => {
        if (item?.id !== undefined) {
          acc[item.id] = false
        }
        return acc
      }, {})
    )
    setOpenDeleteModal(false)
  }

  const handleTabCellClicked = (id: string) => {
    setOpenCityHallModal(true)
    setSelectedCityHallID(id)
    setSelectedCityHallData(cityHall.items.find((value) => value.id === id))
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true)
  }

  return (
    <>
      <Box style={{ borderBottom: 1, borderColor: 'divider' }}>
        <Box style={{ padding: '1em', display: 'flex' }}>
          <Box className={styles.containerActions} mb={3} display="flex" flexDirection="column">
            <Typography className={styles.actionsTitle}>{t('Actions')}</Typography>
            <div className={styles.dividerStyleOne} />
            <ActionsComponent
              search={search}
              searchComponent={true}
              handleFileSelect={() => {}}
              handleDelete={() => {}}
            />
            <div className={styles.dividerStyleTwo} />
            <Box className={styles.boxStyle}>
              <AppButton
                theme={ButtonTheme.AddCenter}
                type={'button'}
                label={t('addCityHall')}
                handler={handlerCityHallModal}
                startIcon={add_icon}
              />
            </Box>
            {showDelete && (
              <Box className={styles.boxStyle}>
                <AppButton
                  theme={ButtonTheme.RemoveCenter}
                  type={'button'}
                  label={t('removeCityHall')}
                  handler={handleOpenDeleteModal}
                  startIcon={delete_icon}
                />
              </Box>
            )}
            <div className={styles.dividerStyleThree} />
          </Box>
          <Box className={styles.containerTable}>
            <AppTable
              isLoading={isLoading}
              items={cityHall.items}
              rowKeyField="id"
              fields={fields}
              search={search}
              pager={pager}
              isPreventDefault={true}
              checked={checkedItems}
              handlerChecked={handleCheckedChange}
              tabCellClicked={handleTabCellClicked}
            />
          </Box>
          {openCityHallModal && (
            <AddSeeEditCityHallModal
              open={openCityHallModal}
              handleClose={handlerCityHallModal}
              id={selectedCityHallID}
              cityHallData={selectedCityHallData}
              handleRefreshTable={() => {
                setIsLoading(!isLoading)
              }}
            />
          )}
        </Box>
      </Box>
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <CustomModal
          handleClose={handleCloseDeleteModal}
          handleSave={handlerDelete}
          title={t('removeCityHall')}
          warningText={t('irreversibleCityHallAction')}
        />
      </Modal>
    </>
  )
}

const searcherQuery = (
  svs: SearchValue<CityHallQuery>[]
): QueryParam<CityHallQuery>[] | QueryParamN<CityHallQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
