import GenericChartDashboard from '../../../../components/dashboard/generic-chart-dashboard/GenericChartDashboard'
import { ChartEnum } from '../../../../components/dashboard/generic-chart-dashboard/ChartEnum'
import { useEffect, useState } from 'react'
import { getActivePlanContainer } from '../../../../container/active-plan-module'
import { ActivePlanService } from '../../../../modules/active-plan/services/ActivePlanService'
import { ACTIVE_PLAN_SERVICE_KEY } from '../../../../modules/active-plan'
import { ActivePlanQuery } from '../../../../modules/active-plan/models/ActivePlan'
import { Query, QueryParam } from '../../../../common/api/Query'
import {
  DashBoardDTO,
  emptyDashBoardDTO,
  toDashBoardModel,
} from '../../../../modules/active-plan/models/DashBoardDTO'
import { getUserContainer } from '../../../../container/user-module'
import { ILoggedUserService } from '../../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../../modules/users'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useTranslation } from 'react-i18next'

const activePlanService = getActivePlanContainer().get<ActivePlanService>(ACTIVE_PLAN_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

type dataSetType = {
  label: string
  data: number[]
  backgroundColor: string
}

const PatientGroupCard = () => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  // const [dashBoard, setDashBoard] = useState<DashBoardDTO>(toDashBoardModel(emptyDashBoardDTO()))
  const backgroundColors = ['#0063A6', '#F18B24', '#B6B5B5']
  const [datasets, setDatasets] = useState<dataSetType[]>([])
  const [selectValue, setSelectValue] = useState<string>('0')
  const [label, setLabel] = useState<string[]>([])

  // Crear un conjunto de datos para cada rango de edad
  const getDatasets = (dashBoard: DashBoardDTO) => {
    if (dashBoard && dashBoard.labels) {
      setDatasets(
        dashBoard.labels.map((label, index) => ({
          label: isNumber(label) ? label + ' Años' : label, // La etiqueta es el rango de edad
          data: createArrByLength(dashBoard.data.length, dashBoard.data[index], index), // Cada conjunto de datos tiene un solo valor
          backgroundColor: getColor(index), // Color correspondiente al rango de edad
        }))
      )
    }
  }

  const isNumber = (value: string): boolean => {
    const aux = Number(value)
    return !Number.isNaN(aux)
  }

  const getColor = (n: number): string => {
    if (backgroundColors.length > n) {
      return backgroundColors[n]
    }
    let value = true
    let auxNum = n
    while (value) {
      auxNum = auxNum - backgroundColors.length
      if (backgroundColors.length > auxNum) {
        value = false
        return backgroundColors[auxNum]
      }
    }
    return ''
  }

  const createEmptyLabel = (length: number): string[] => {
    const auxArr: string[] = []
    for (let i = 0; i < length; i++) {
      auxArr.push('')
    }
    return auxArr
  }

  const createArrByLength = (length: number, value: number, valueIndex: number): number[] => {
    const auxArr: number[] = []
    for (let i = 0; i < length; i++) {
      if (i === valueIndex) {
        auxArr.push(value)
      } else {
        auxArr.push(0)
      }
    }
    return auxArr
  }

  useEffect(() => {
    activePlanService
      .patientNumberList(
        new Query({
          query: [
            new QueryParam<ActivePlanQuery>('userProfessionalID', loggedUser?.id ?? ''),
            new QueryParam<ActivePlanQuery>('status', 'active'),
            new QueryParam<ActivePlanQuery>(
              selectValue === '0' ? 'patientByAge' : 'patientByHospital',
              1
            ),
          ],
        })
      )
      .subscribe((res) => {
        if (!res) {
          getDatasets(toDashBoardModel(emptyDashBoardDTO()))
        } else {
          getDatasets(res)
        }
        setLabel(createEmptyLabel(res && res.data ? res.data.length : 0))
      })
  }, [selectValue])

  const handleSelect = (e: SelectChangeEvent) => {
    setSelectValue(e.target.value)
  }

  return (
    <>
      <GenericChartDashboard
        datasets={datasets}
        labels={label}
        title={'Grupo'}
        typeChart={ChartEnum.Bar}
        legendPosition={'bottom'}
      />
      <FormControl>
        <Select value={selectValue} onChange={handleSelect}>
          <MenuItem value={'0'}>{t('groupByAge')}</MenuItem>
          <MenuItem value={'1'}>{t('Clinic')}</MenuItem>
        </Select>
      </FormControl>
    </>
  )
}
export default PatientGroupCard
