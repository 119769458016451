import { Alert, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'

import { useTranslation } from 'react-i18next'

import { Query, QueryParam } from '../../../common/api/Query'

import { ProfessionalType } from '../../../modules/users/models/ProfessionalType'

import genericStyle from '../../../common/utils/generic.module.css'

import { Pathology } from '../../../modules/patient-data/models/Pathology'
import { getUserContainer } from '../../../container/user-module'
import { getMessengerConsultaContainer } from '../../../container/messenger-consulta-module'

import { PROFESSIONALTYPE_SERVICE_KEY } from '../../../modules/users'
import { AUTOMESSAGE_SERVICE_KEY, IAutoMessageService } from '../../../modules/messenger_consulta'

import { ProfessionalTypesService } from '../../../modules/users/services/ProfessionalTypeService'
import { Header } from '../../../components/header/Header'
import { TextFieldItem } from '../../../components/form-card/TextFieldItem'
import { emptyAutoMessageDTO } from '../../../modules/messenger_consulta/models/AutoMessageDTO'
import { AutoMessage } from '../../../modules/messenger_consulta/models/AutoMessage'
import { useSnackbar } from 'notistack'
import { getCircleContainer } from '../../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../../modules/circle'
import { CircleQuery } from '../../../modules/circle/models/Circle'
import { CircleDTO } from '../../../modules/circle/models/CircleDTO'
import { ROUTE_CONFIGURATION } from '../../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'

const userContainer = getUserContainer()
const messageContainer = getMessengerConsultaContainer()
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)
const autoMessageService = messageContainer.get<IAutoMessageService>(AUTOMESSAGE_SERVICE_KEY)

type UserProps = {
  id?: string
}

export function Table(props: UserProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isPath, setIsPath] = useState<boolean>(true)
  const [related, setRelated] = useState<boolean>(true)
  const [professional, setProfessional] = useState<ProfessionalType[]>()
  const [autoMessage, setAutoMessage] = useState<AutoMessage[]>()
  const [setProf, setSetProf] = useState<string>('')
  const setProfR = useRef('')
  const [professionalType, setProfessionalType] = useState<ProfessionalType>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pathologies, setPathologies] = useState<Pathology[]>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pathologyAll, setPathologyAll] = useState<Pathology[]>()
  const [setPath, setSetPath] = useState<string>('')
  const setPathR = useRef('')
  const currentAutoMessageR = useRef(emptyAutoMessageDTO())
  const [circle, setCircle] = useState<CircleDTO[]>([])
  const [circleAux, setCircleAux] = useState<CircleDTO[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    professionalTypeService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        setProfessional(res)
      })
    /* if (setProf !== '') {
      pathologyService.getByTypeID(setProf).subscribe({
        next: (res) => {
          setPathologies(res.items)
        },
        error: (err) => {
        },
        complete: () => {},
      })
    }

    if (once) {
      pathologyService.getFilteredList(new Query({})).subscribe((res) => {
        setPathologyAll(res.items)
        setOnce(false)
      })

      autoMessageService.getFilteredList(new Query({})).subscribe((res) => {
        setAutoMessage(res.items)
      })
    } */
  }, [isLoading])

  useEffect(() => {
    autoMessageService.getFilteredList(new Query({})).subscribe((res) => {
      setAutoMessage(res.items)
    })
  }, [])

  useEffect(() => {
    circleService.getFilteredList(new Query<CircleQuery>({})).subscribe((res) => {
      setCircleAux(res.items)
    })
  }, [])

  useEffect(() => {
    if (!professionalType) return
    circleService
      .getFilteredList(
        new Query<CircleQuery>({
          query: [new QueryParam<CircleQuery>('specialtyID', professionalType?.name)],
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        setCircle(res.items)
      })
  }, [professionalType])

  const handlerChangeProfessional = (event: any) => {
    setSetProf(event.target.value)
    setProfR.current = event.target.value
    setSetPath('')
    setPathR.current = ''
    setRelated(false)
    setIsLoading(!isLoading)
    setIsPath(true)
    setProfessionalType(professional?.find((e) => e.id === event.target.value))
  }

  const handlerChangePathologie = (event: any) => {
    setSetPath(event.target.value)
    setPathR.current = event.target.value
    setIsPath(false)
  }

  const handlerChangeMessage = (id: number, event: any) => {
    let arrObj = autoMessage
    if (autoMessage) {
      arrObj = [...autoMessage]
      arrObj[id].message = event.target.value
    }

    setAutoMessage(arrObj)
  }

  const deleteMessage = (id: string) => {
    autoMessageService.delete(id).subscribe((res) => {})
    const arr: any[] = []

    autoMessage?.forEach((element, index) => {
      if (element.id !== id) {
        arr.push(element)
      }
    })
    setAutoMessage(arr)
    setIsLoading(!isLoading)
  }

  const validateExistThisPathology = (id: string) => {
    let value = false
    autoMessage?.forEach((element) => {
      if (element.professional === setProf && element.pathology === id) {
        setErrorMessage('thisPathologyAlreadyExists')
        value = true
      }
    })
    return value
  }
  const addNewMessage = () => {
    if (validateExistThisPathology(setPath)) return
    let arr: any[] = []
    if (autoMessage) {
      arr = autoMessage
    }
    currentAutoMessageR.current = Object.assign(
      { ...currentAutoMessageR.current },
      { id: emptyAutoMessageDTO().id, professional: setProf, pathology: setPath, message: '' }
    )
    arr.push(currentAutoMessageR.current)
    setAutoMessage(arr)
    setIsLoading(!isLoading)
  }

  const handlerSave = () => {
    autoMessage?.forEach((element: any, index: number) => {
      autoMessageService.add(element).subscribe((res) => {
        enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
        goBack()
      })
    })
  }

  const goBack = () => navigate(ROUTE_CONFIGURATION)

  const filter = (a: string, b: any) => {
    let c = ''
    if (a === 'professional') {
      professional?.forEach((element) => {
        if (element.id === b.professional) {
          c = element.name
        }
      })
    } else if (a === 'pathology') {
      circleAux?.forEach((element) => {
        if (element.id === b.pathology) {
          c = element.name
        }
      })
    }
    return c
  }

  const getWindowDimension = () => {
    const { innerWidth: width } = window
    let value = true
    if (width < 800) {
      value = false
    }
    return value
  }

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Box style={{ borderBottom: 1, borderColor: 'divider', marginBottom: 20 }}>
          <Header label={t('automaticMessage')} />
        </Box>
        <Box
          style={
            getWindowDimension()
              ? { display: 'flex', flexDirection: 'row', width: '100%' }
              : { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }
          }
        >
          <FormControl
            variant="outlined"
            style={
              getWindowDimension()
                ? {
                    minWidth: 250,
                    width: '400px',
                    height: '45px',
                    marginRight: '40px',
                    backgroundColor: '#f7f6f7',
                  }
                : { width: '100%' }
            }
          >
            <InputLabel id="professional" style={{ zIndex: 0 }}>
              {t('profType')}
            </InputLabel>
            <Select
              style={{ height: '45px' }}
              labelId="professional"
              id="selectProfessional"
              label={t('profType')}
              value={setProf}
              onChange={handlerChangeProfessional}
            >
              {professional?.map((element, index) => {
                return (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            style={
              getWindowDimension()
                ? {
                    minWidth: 250,
                    width: '400px',
                    height: '45px',
                    marginRight: '40px',
                    backgroundColor: '#f7f6f7',
                  }
                : { width: '100%', marginTop: 10, marginBottom: 10 }
            }
          >
            <InputLabel id="circle" style={{ zIndex: 0 }}>
              {t('circle')}
            </InputLabel>
            <Select
              style={{ height: '45px' }}
              labelId="circle"
              id="selectCircle"
              label={t('circle')}
              value={setPath}
              onChange={handlerChangePathologie}
              disabled={related}
            >
              {circle?.map((element, index) => {
                return (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <Box>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('addNewMessage')}
              handler={addNewMessage}
              disabled={isPath}
            />
          </Box>
        </Box>
        {errorMessage && (
          <Box mb={3}>
            <Alert severity="warning" key="errorMessage" id="errorMessage">
              {t(errorMessage)}
            </Alert>
          </Box>
        )}
        <br />
        <Box
          style={
            getWindowDimension()
              ? { display: 'flex', flexDirection: 'row', width: '92vh' }
              : { display: 'flex', flexDirection: 'column' }
          }
        >
          <Box style={{ flexGrow: 1 }}>
            <form
              style={getWindowDimension() ? { marginTop: 20, marginRight: 10 } : { marginTop: 20 }}
            >
              {autoMessage?.map((element, index) => {
                return (
                  <>
                    <Box
                      style={
                        getWindowDimension()
                          ? {
                              display: 'flex',
                              flexDirection: 'row',
                            }
                          : {
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }
                      }
                      key={element.id + index}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginRight: 30,
                        }}
                      >
                        <Box style={{ fontWeight: 'bold', marginRight: 10 }}>{t('profType')}</Box>
                        <Box
                          style={{
                            backgroundColor: '#76c4fd',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: 100,
                            paddingRight: 18,
                            paddingLeft: 18,
                            paddingTop: 7,
                            paddingBottom: 7,
                          }}
                        >
                          {filter('professional', element)}
                        </Box>
                      </Box>
                      <Box
                        style={
                          getWindowDimension()
                            ? {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }
                            : {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: 10,
                              }
                        }
                      >
                        <Box style={{ fontWeight: 'bold', marginRight: 10 }}>{t('circle')}</Box>
                        <Box
                          style={{
                            backgroundColor: '#186cb4',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: 100,
                            paddingRight: 18,
                            paddingLeft: 18,
                            paddingTop: 7,
                            paddingBottom: 7,
                          }}
                        >
                          {filter('pathology', element)}
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      style={
                        getWindowDimension()
                          ? { marginBottom: 40 }
                          : {
                              marginBottom: 40,
                              marginRight: '10px',
                              flexDirection: 'column',
                              display: 'flex',
                              justifyContent: 'center',
                            }
                      }
                    >
                      <Box style={{ display: 'flex', justifyContent: 'end', flexGrow: 3 }}>
                        <AppButton
                          theme={ButtonTheme.BasicTrasparentOrange}
                          handlerValue={deleteMessage}
                          handler={() => {}}
                          type={'button'}
                          label={t('deleteMessage')}
                          value={element.id}
                        />
                      </Box>
                      <TextFieldItem
                        field={'message'}
                        value={element.message}
                        label={t('addMessage')}
                        type={'text'}
                        // handleChange={handlerChangeMessage}
                        handlerValue={handlerChangeMessage}
                        valueID={index}
                        rows={5}
                        required={true}
                      />
                    </Box>
                  </>
                )
              })}
            </form>
          </Box>
        </Box>

        <Box></Box>
        <Box
          style={
            getWindowDimension()
              ? { display: 'flex', justifyContent: 'flex-end', marginBottom: 4 }
              : { display: 'flex', justifyContent: 'flex-end', marginBottom: 1 }
          }
        >
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'button'}
            label={t('save')}
            handler={handlerSave}
          />
        </Box>
      </Box>
    </>
  )
}
