import { FormCard } from '../../components/form-card/FormCard'
import React, { useContext, useEffect, useState } from 'react'
import { emptyFileDTO, FileDTO, fromModel } from '../../modules/files/models/FileDTO'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ROUTE_SHARE_RESOURCES } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam } from '../../common/api/Query'
import { User, UserQuery } from '../../modules/users/models/User'
import { getUserContainer } from '../../container/user-module'
import {
  LOGGED_USER_SERVICE_KEY,
  PROFESSIONALTYPE_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users/container'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { IUserService } from '../../modules/users'
import { getFileContainer } from '../../container/file-module'
import { getSharedWithContainer } from '../../container/shared-with-module'
import { FileService } from '../../modules/files/services/FileService'
import { SharedWithService } from '../../modules/sharedWith/services/SharedWithService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { SHARED_WITH_SERVICE_KEY } from '../../modules/sharedWith'
import { FileItem } from '../../components/form-card/FileItem'
import { File as F } from '../../modules/files/models/File'
import genericStyle from '../../common/utils/generic.module.css'
import { useGlobalContext } from '../../common/utils/GlobalRoleContext'
import { LoadingFile } from '../../components/loading-spinner/LoadingSpinner'
import { AssignResourceSection } from './asign-resources-section/AssignResourceSection'
import { SharedWith } from '../../modules/sharedWith/models/SharedWith'
import {
  fromModel as shareFromModel,
  SharedWithDTO,
} from '../../modules/sharedWith/models/SharedWithDTO'
import { ConfigResourceSection } from './config-resources-section/ConfigResourceSection'
import { ProfessionalType } from '../../modules/users/models/ProfessionalType'
import { Alert, Box, Modal, SelectChangeEvent } from '@mui/material'
import { ProfessionalTypesService } from '../../modules/users/services/ProfessionalTypeService'
import { v4 as uuidv4 } from 'uuid'
import { EditorContext } from '../../common/utils/context/EditorContext'
import { RolesType } from '../../common/enums/RolesType'
import { CustomModal } from '../../components/modal/CustomModal'
import { CheckboxAssignType } from '../../modules/forms/enums/CheckboxAssignType'
import { useNavigate } from 'react-router-dom'
import { CircleDTO } from '../../modules/circle/models/CircleDTO'
import { CircleQuery } from '../../modules/circle/models/Circle'
import { getCircleContainer } from '../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../modules/circle'

type EditorProps = {
  id?: string
  isDir: boolean
  parentID: string | null
  breadcrumbUrl?: Map<string, string>
  refMain?: React.RefObject<HTMLDivElement>
}

type AutocompleteUserInterface = {
  key: string
  value: string
  role: string
}

export type AssignedUser = {
  userID: string
  circleID: string | null
}

enum ResourceErrors {
  NO_FILE = 'noFile',
}

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const fileContainer = getFileContainer()
const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)
const shareContainer = getSharedWithContainer()
const shareService = shareContainer.get<SharedWithService>(SHARED_WITH_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
const userContainer = getUserContainer()
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)

export function Editor(props: EditorProps) {
  const {
    handlerAssignedUser,
    handleCheckBoxSelected,
    recoveredCheckBoxRoles,
    recoveredCheckBox,
    preview,
    saving,
    handleSaveForm,
    recoveredSelected,
    // resourceContext,
    shareWith,
    selected,
    // setResourceContext,
    setSelected,
    checkBoxSelected,
    setShareWith,
  } = useContext(EditorContext)

  const { t } = useTranslation()
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [circles, setCircles] = useState<CircleDTO[]>([])
  const loggedUser = loggedUserService.get()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [users, setUsers] = useState<Map<string, AutocompleteUserInterface>>(new Map())
  const [files, setFiles] = useState<F[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { role } = useGlobalContext()
  const [isSending, setIsSending] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const [allPatients, setAllPatients] = useState<User[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [weekDayRecovered, setWeekDayRecovered] = useState<string[]>([])
  const [professionalSpecialities, setProfessionalSpecialities] = useState<ProfessionalType[]>([])
  const [professionalType, setProfessionalType] = useState<ProfessionalType>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pathologies, setPathologies] = useState<CircleDTO[]>([])
  const [parentFile, setParentFile] = useState<FileDTO>(emptyFileDTO(loggedUser?.id, '', true))
  const [currentCirclePathology, setCurrentCirclePathology] = useState<string[]>([])
  const isExternProfessional = loggedUser?.roles?.some(
    (role) => role === RolesType.ProfessionalExtern
  )
  const [openModalPediatric, setOpenModalPediatric] = useState<boolean>(false)
  const isContentManager = loggedUser?.roles?.some((role) => role === RolesType.ContentManager)
  const isCaseManager = ['managerHealthChild', 'managerActivePlan', 'managerEpileptic'].some(
    (role) => loggedUser?.roles?.includes(role)
  )
  const [resourceContext, setResourceContext] = useState<FileDTO>(
    emptyFileDTO(loggedUser?.id, '', true)
  )

  useEffect(() => {
    if (props.parentID === '' || props.parentID === null) return
    fileService.getByID(props.parentID).subscribe((res) => {
      if (!res) return
      setParentFile(res)
    })
  }, [props.parentID])

  useEffect(() => {
    if (isExternProfessional) {
      userService.getUsersByExternal(loggedUser?.id ?? '').subscribe((res) => {
        setAllPatients(res)
      })
      return
    }
    userService.getUsersByDoctor(loggedUser?.id ?? '').subscribe((res) => {
      setAllPatients(res)
    })
  }, [])

  useEffect(() => {
    if (props.id) {
      fileService.getByID(props.id).subscribe((res) => {
        if (res) {
          setResourceContext(fromModel(res))
          setFiles([res])
          const circleArray = res.circles.split(',')
          setCurrentCirclePathology(circleArray)

          if (!isContentManager) {
            if (professionalSpecialities.length <= 0) return
            professionalTypeService.getByName(professionalSpecialities[0].name).subscribe((res) => {
              if (!res) return
              setProfessionalType(res)
            })
          } else {
            professionalTypeService.getByName(res.specialities).subscribe((res) => {
              if (!res) return
              setProfessionalType(res)
            })
          }
        }
      })
    } else {
      setShareWith([])
      setCurrentCirclePathology([])
      setSelected([])
      setResourceContext(
        Object.assign(emptyFileDTO(loggedUser?.id, '', true), {
          isDir: props.isDir,
          parent: props.parentID,
        })
      )
    }

    if (loggedUser?.id) {
      userService.getRelated(loggedUser.id).subscribe((res) => {
        if (res) {
          const ids = res.relatedUsers.map((r) => r.id)
          if (loggedUser.id) ids.push(loggedUser.id)
          userService
            .getFilteredItems(
              new Query({
                query: [new QueryParam<UserQuery>('ids', ids)],
              })
            )
            .subscribe((ul) => {
              const tmpMap = new Map()
              tmpMap.set('every', { key: 'every', value: 'every' })
              ul.forEach((u) => {
                if (u.id !== loggedUser?.id) {
                  tmpMap.set(u.id, {
                    key: u.id,
                    value: u.name,
                    role: role.length ? role[0].name : '',
                  })
                }
              })
              setUsers(new Map(tmpMap))

              getSelected(ids, ul, files)
            })
        }
      })
    }
  }, [])

  const getSelected = (related: string[], user: User[], file: F[]) => {
    user.forEach((u) => {
      // eslint-disable-next-line array-callback-return
      file[0]?.sharedWith.map((sw) => {
        if (sw === u.id) {
          selected.push({
            uuid: uuidv4(),
            id: u.id,
            name: u.name,
            birthDate: u.birthDate,
            cip: u.cip,
            dni: u.dni,
            circleID: u.id,
            roles: u.roles,
            userCircleID: '',
          })
        }
      })
    })
    setSelected(selected)
  }

  useEffect(() => {
    if (!loggedUser?.id) return
    const listAux: ProfessionalType[] = []

    if (isCaseManager) {
      professionalTypeService
        .getAll(
          new Query({
            sort: [{ field: 'name' }],
            pager: { offset: 0, limit: -1 },
          })
        )
        .subscribe((res) => {
          if (!res) return
          setProfessionalSpecialities(res)
        })
    } else {
      professionalTypeService.getProfessionalTypeByUserID(loggedUser?.id).subscribe((res) => {
        if (!res) return
        listAux.push(res)
        setProfessionalSpecialities(listAux)
        setProfessionalType(listAux[0])
      })
    }
  }, [])

  useEffect(() => {
    if (isSending) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0
          }
          const diff = Math.random() * 10
          return Math.min(oldProgress + diff, 100)
        })
      }, 500)

      clearInterval(timer)
    }
  })

  useEffect(() => {
    if (!props.id) return
    shareService
      .getFilteredItems(
        new Query({
          query: [new QueryParam('fileId', props.id)],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setShareWith(res)
      })
  }, [props.id])

  useEffect(() => {
    if (props.parentID === null || props.parentID === '') return
    shareService
      .getFilteredItems(
        new Query({
          query: [new QueryParam('fileId', props.parentID)],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setShareWith(res)
      })
  }, [props.parentID])

  useEffect(() => {
    if (!professionalType) return
    circleService
      .getFilteredList(
        new Query<CircleQuery>({
          query: [new QueryParam<CircleQuery>('specialtyID', professionalType?.name)],
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setCircles(res.items)
      })
  }, [professionalType, currentCirclePathology])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setResourceContext({ ...resourceContext, [e.target.name]: e.target.value })

  const handleFiles = (field: string, value: F[]) => {
    if (!Array.isArray(value)) {
      return
    }
    setFiles([...value])
    if (value.length > 0) {
      const f = fromModel(value[0])
      setResourceContext(
        Object.assign(
          { ...resourceContext },
          { data: f.data, extension: f.extension, mimeType: f.mimeType }
        )
      )
    }
  }

  const handleCloseModalPediatric = () => {
    setOpenModalPediatric(false)
  }

  const goBack = () => {
    if (props.parentID) {
      navigate(`${ROUTE_SHARE_RESOURCES}/${props.parentID}`, {
        state: { breadcrumbUrl: props.breadcrumbUrl },
      })
    } else {
      navigate(ROUTE_SHARE_RESOURCES, { state: { breadcrumbUrl: props.breadcrumbUrl } })
    }
  }

  const validateResource = (): boolean => {
    if (!props.isDir && files.length === 0) {
      setErrorMessage(ResourceErrors.NO_FILE)
      setIsSending(false)
      return false
    }

    setErrorMessage('')
    return true
  }

  const handleSelectPathology = (e: string) => {
    if (!props.id) {
      setCurrentCirclePathology([])
    }
    professionalTypeService.getByName(e).subscribe((res) => {
      if (res) {
        const aux: ProfessionalType = res
        setProfessionalType(aux)
      }
    })
  }

  const handleDeletePathology = (type: string, p: string) => {
    if (type === 'professionalType') {
      if (professionalType?.name) {
        setProfessionalType(undefined)
        setCurrentCirclePathology([])
      }
    }
    if (type === 'circle') {
      const filteredArray = currentCirclePathology?.filter(function (e) {
        return e !== p
      })
      if (!filteredArray) return
      setCurrentCirclePathology(filteredArray)
    }
  }
  const handleSelectCircle = (event: SelectChangeEvent<typeof currentCirclePathology>) => {
    const {
      target: { value },
    } = event
    if (Array.isArray(value)) {
      setCurrentCirclePathology(value)
    }
  }

  async function saveResource() {
    setIsSending(true)

    if (!validateResource()) {
      return
    }

    const checkBoxesSelectedString = checkBoxSelected.join(',')

    const newResource = Object.assign(
      { ...resourceContext },
      {
        sharedWith: [],
        specialities: professionalType?.name,
        circles: currentCirclePathology?.map((c) => c).join(','),
        circleId: currentCirclePathology
          ?.map((c) => circles.filter((cir) => cir.name === c)[0].id)
          .join(','),
        checkBoxesSelected: checkBoxesSelectedString,
      }
    )

    if (props.id) {
      fileService.update(newResource).subscribe((res) => {
        if (res) {
          if (selected.length !== 0 && newResource.checkBoxesSelected === '') {
            const shareArr: SharedWithDTO[] = []
            // eslint-disable-next-line array-callback-return
            selected.map((s) => {
              const share = new SharedWith({
                userId: s.id || '',
                fileId: newResource.id ?? '',
                userCircleId: s.userCircleID ?? '',
                creatorId: newResource.creator,
              })

              shareArr.push(shareFromModel(share))
            })

            shareService.createNewIndividualShare(shareArr).subscribe((res) => {
              goBack()
            })
          } else {
            goBack()
          }
        }
        setIsSending(false)
      })
    } else {
      fileService.add(newResource).subscribe((res) => {
        if (res) {
          if (selected.length !== 0 && newResource.checkBoxesSelected === '') {
            const shareArr: SharedWithDTO[] = []
            // eslint-disable-next-line array-callback-return
            selected.map((s) => {
              const share = new SharedWith({
                userId: s.id ?? '',
                fileId: newResource.id ?? '',
                userCircleId: s.userCircleID ?? '',
                creatorId: newResource.creator,
              })

              shareArr.push(shareFromModel(share))
            })
            shareService.createNewIndividualShare(shareArr).subscribe((res) => {
              goBack()
            })
          } else {
            goBack()
          }
        }
        setIsSending(false)
      })
    }

    goBack()
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <FormCard>
        <>
          {isSending ? (
            <LoadingFile progress={progress} />
          ) : (
            <Box style={{ marginBottom: 8, display: 'flex', justifyContent: 'flex-end' }}>
              <Box style={{ marginRight: 4 }}>
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('cancel')}
                  handler={goBack}
                />
              </Box>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'submit'}
                label={t('save')}
                handler={() => {
                  const isPediatric = professionalType?.name === 'Pediatra'
                  const checkBoxesSelected = checkBoxSelected.some(
                    (c) => c === CheckboxAssignType.AllMyPatients
                  )
                  if (isPediatric && checkBoxesSelected) {
                    setOpenModalPediatric(true)
                    return
                  }
                  saveResource().then()
                }}
              />
            </Box>
          )}

          <Box style={{ marginBottom: 13 }}>
            <TextFieldItem
              field="name"
              value={resourceContext.name}
              label={'name'}
              type={'text'}
              handleChange={handleInput}
              rows={undefined}
              required={true}
              maxLength={120}
            />
          </Box>
          <Box style={{ marginBottom: 13 }}>
            {!props.isDir && (
              <FileItem
                key={'files'}
                field={'files'}
                filesID={[]}
                handleFiles={handleFiles}
                userID={loggedUser?.id ?? ''}
                cleanFiles={false}
                uploadRemoveFileDirectly={false}
                disabled={files.length > 0}
                defaultFile={files}
                edit={!!props.id}
              />
            )}
          </Box>

          {errorMessage && (
            <Box style={{ marginBottom: 13 }}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage)}
              </Alert>
            </Box>
          )}

          <ConfigResourceSection
            professionalSpecialities={professionalSpecialities}
            professionalType={professionalType}
            handleSelectPathology={handleSelectPathology}
            handleDeletePathology={handleDeletePathology}
            currentCirclePathology={currentCirclePathology}
            loggedUser={loggedUser}
            circles={circles}
            handleSelectCircle={handleSelectCircle}
            propsId={props.id}
            resource={resourceContext}
            pathologies={pathologies}
            preview={preview}
            weekDayRecovered={weekDayRecovered}
            isFormEdit={true}
          />
          {!isCaseManager && (
            <AssignResourceSection
              allPatients={allPatients}
              handlerAssignedUser={handlerAssignedUser}
              handleSaveForm={handleSaveForm}
              saving={saving}
              id={resourceContext.id}
              mainRef={props.refMain}
              shareWith={shareWith}
              currentCirclePathology={currentCirclePathology}
              recoveredSelected={recoveredSelected}
              selected={selected}
              preview={preview}
              handleCheckBoxSelected={handleCheckBoxSelected}
              recoveredCheckBoxRoles={recoveredCheckBoxRoles}
              checkboxRecover={recoveredCheckBox}
              resource={resourceContext}
              parentFile={parentFile}
              propsParentID={props.parentID}
            />
          )}
        </>
      </FormCard>
      {openModalPediatric && (
        <Modal open={openModalPediatric} onClose={handleCloseModalPediatric}>
          <>
            <CustomModal
              handleClose={handleCloseModalPediatric}
              handleSave={saveResource}
              title={t('modalPediatric')}
              warningText={t('irreversibleModalPediatricAction')}
            />
          </>
        </Modal>
      )}
    </Box>
  )
}
