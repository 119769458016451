import i18n from 'i18n/index'

export const CIRCLES_TITLE = i18n.t('circles')
export const CIRCLES_REGISTER_HOME_TITLE = i18n.t('accessPatientCircle')
export const USERS_TITLE = i18n.t('users')
export const USER_PROFILE_TITLE = i18n.t('userProfile')
export const DASHBOARD_TITLE = i18n.t('scoreCard')
export const PROMS_PREMS_TITLE = i18n.t('proms_prems')
export const FORM_GENERATOR_TITLE = i18n.t('generateForms')
export const FORM_GENERATOR_EDITOR_TITLE = `${FORM_GENERATOR_TITLE} - ${i18n.t('newForm')}`
export const FORM_GENERATOR_RESULTS_TITLE = i18n.t('formResults')
export const FORM_GENERATOR_REMOVE_TITLE = i18n.t('removeForms')
export const FORM_GENERATOR_USERS_TITLE = `${FORM_GENERATOR_TITLE} - ${i18n.t('sendForms')}`
export const MESSENGER_TITLE = i18n.t('messenger')
export const CALENDAR_TITLE = i18n.t('calendar')
export const ARTICLES_TITLE = i18n.t('library')
export const TUTORIAL_CONTENT_TITLE = i18n.t('tutorialContent')
export const NEW_TUTORIAL = i18n.t('newTutorialContent')
export const ADMIN_TUTORIAL_TEMPLATES = i18n.t('adminTutorialTemplates')
export const TEMPLATES_PRO_TITLE = i18n.t('templatesPro')
export const NEW_TEMPLATE = i18n.t('newTemplate')
export const ARTICLES_MY_POSTS = i18n.t('myPosts')
export const ARTICLES_NEW_POST = i18n.t('newPost')
export const ARTICLES_VALIDATE_TITLE = `${i18n.t('validate')} ${i18n.t('content')}`
export const ARTICLES_HISTORY = `${i18n.t('historyContent')}`
export const TAGS_TITLE = `${i18n.t('library')} - ${i18n.t('tags')}`
export const CONFIGURATION_TITLE = i18n.t('configuration')
export const PATIENTS_FORMS_TITLE = i18n.t('patientsForms')
export const PATIENT_FORM_VIEW_TITLE = i18n.t('form')
export const RESOURCES_TITLE = i18n.t('resources')
export const RESOURCES_SHARED_WITH_ME_TITLE = i18n.t('resourcesSharedWithMe')
export const RESOURCES_NOTIFICATIONS_CONFIG_TITLE = i18n.t('notificationsConfig')
export const SYMPTOMS_CONFIG_TITLE = i18n.t('symptomsConfig')
export const DENTIST_CONFIG_TITLE = i18n.t('dentistConfig')
export const DENTIST_MAP_TITLE = i18n.t('dentists')
export const DENTIST_LIST_TITLE = i18n.t('dentistList')
export const PATHOLOGY_SYMPTOM_TITLE = i18n.t('createSymptom')
export const ROLE_PERMISSIONS_TITLE = i18n.t('title_role_permissions')
export const PATIENT_DATA_TITLE = i18n.t('patientData')
export const SYMPTOMS_TITLE = i18n.t('symptoms')
export const TREATMENTS_TITLE = i18n.t('treatments')
export const VACCINATION_SCHEDULE_TITLE = i18n.t('vaccinationSchedule')
export const EVOLUTION_CONTROL_TITLE = i18n.t('evolutionControl')
export const PATIENT_CALENDAR_CONFIG_TITLE = i18n.t('patientCalendarConfig')
export const MEDICAL_DATA_TITLE = i18n.t('medicalData')
export const LANDMARK_TITLE = i18n.t('landmarks')
export const GROWTH_MONITORING_TITLE = i18n.t('growthMonitoring')
export const FAMILIAR_DATA_TITLE = i18n.t('familiarData')
export const MOBILE_MENU_TITLE = i18n.t('menu')
export const WEARABLES_TITLE = i18n.t('wearables')
export const GOALS_TITLE = i18n.t('goals')
export const VACCINES_TITLE = i18n.t('vaccines')
export const VACCINES_CREATE_TITLE = i18n.t('newVaccine')
export const EDIT_VACCINE_TITLE = i18n.t('editVaccine')
export const CREATE_PARTICIPANT_TITLE = i18n.t('addParticipant')
export const CIRCLE_REGISTER_TITLE = i18n.t('register')
export const PASSWORD_CHANGE_TITLE = i18n.t('passwordChange')
export const SHARE_RESOURCES = i18n.t('shareResources')
export const QUERY_CONFIG_TITLE = i18n.t('configQuery')
export const QUERY_TITLE = i18n.t('query')
export const SPORT_CENTRE_CONFIG_TITLE = i18n.t('sportCentreConfig')
export const CITY_HALL_CONFIG_TITLE = i18n.t('cityHallConfig')
export const CIRCLE_CONFIGURATOR_TITLE = i18n.t('circleConfigurator')
export const ACTIVE_PLAN_DATA_TITLE = i18n.t('activePlan')
export const SEGMENTEDE_NOTIFICATIONS_TITLE = i18n.t('segmentedNotifications')
export const TELECONSULTATION_TITLE = i18n.t('Teleconsult')
