import React, { FormEvent, useEffect, useState } from 'react'
import { emptyTagDTO, fromModel, TagDTO } from '../../modules/content/models/TagDTO'
import { TagService } from '../../modules/content/services/TagService'
import { TAG_SERVICE_KEY } from '../../modules/content'
import { FormCard } from '../../components/form-card/FormCard'
import { Box, TextField } from '@mui/material'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_MY_POSTS_CONTENT, ROUTE_TAG_LIST } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { getContentContainer } from '../../container/content-module'
import { Query } from '../../common/api/Query'
import styles from '../generic/GenericForm.module.css'
import genericStyle from '../../common/utils/generic.module.css'
import { Autocomplete } from '@mui/material'

type EditorProps = {
  id?: string
}

interface AutocompleteTagInterface {
  key: string
  value: string
}

const tagContainer = getContentContainer()
const tagService = tagContainer.get<TagService>(TAG_SERVICE_KEY)

export function Editor(props: EditorProps) {
  const { t } = useTranslation()

  const [tag, setTag] = useState<TagDTO>(emptyTagDTO())
  const [tagMap, setTagMap] = useState<Map<string, AutocompleteTagInterface>>(new Map())
  const [tagMapTmp, setTagMapTmp] = useState<Map<string, AutocompleteTagInterface>>(new Map())
  const [parentSection, setParentSection] = useState<AutocompleteTagInterface>()
  const navigate = useNavigate()
  const viewTags = () => navigate(ROUTE_TAG_LIST)

  useEffect(() => {
    if (props.id) {
      tagService.getByID(props.id).subscribe((res) => {
        if (res) {
          const newTag = fromModel(res)
          if (newTag.parentSection) {
            tagService.getByID(newTag.parentSection).subscribe((ps) => {
              if (ps) {
                setParentSection({ key: ps.id, value: ps.name })
              }
            })
          }
          setTag(newTag)
        }
      })
    }
    const result = new Map()
    tagService.getFilteredList(new Query({ sort: [{ field: 'name' }] })).subscribe((res) => {
      // eslint-disable-next-line array-callback-return
      res.items.map((t) => {
        result.set(t.id, { key: t.id, value: t.name })
        setTagMapTmp(new Map(result))
      })
    })
  }, [])

  useEffect(() => {
    const result = new Map()
    Array.from(tagMapTmp.keys()).forEach((k) => {
      if (k !== tag.id) {
        result.set(k, tagMapTmp.get(k))
      }
    })
    setTagMap(new Map(result))
  }, [tagMapTmp])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setTag(Object.assign({ ...tag }, { [e.target.name]: e.target.value }))

  const saveTag = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (props.id) {
      tagService.update(tag).subscribe(() => viewTags())
    } else {
      tagService.add(tag).subscribe(() => viewTags())
    }
  }

  const goBack = () => navigate(ROUTE_MY_POSTS_CONTENT)

  const handleParentChange = (value: any) => {
    setParentSection(value)
    setTag(Object.assign({ ...tag }, { parentSection: value.key }))
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <FormCard>
        <form onSubmit={(e) => saveTag(e)}>
          <Box mb={3}>
            <TextFieldItem
              field="name"
              value={tag.name}
              label={'name'}
              type={'text'}
              handleChange={handleInput}
              rows={0}
              required={true}
            />
          </Box>

          <Box mb={3}>
            <TextFieldItem
              field="description"
              value={tag.description}
              label={'description'}
              type={'text'}
              handleChange={handleInput}
              rows={10}
              required={true}
            />
          </Box>

          {/*<Box mb={3}>*/}
          {/*  <Autocomplete*/}
          {/*    key={tag.id}*/}
          {/*    id={`${tag.id}-standard`}*/}
          {/*    options={Array.from(tagMap.values())}*/}
          {/*    getOptionLabel={(opt) => opt.value}*/}
          {/*    value={parentSection ?? { key: '', value: '' }}*/}
          {/*    onChange={(_, v) => handleParentChange(v)}*/}
          {/*    renderInput={(params) => (*/}
          {/*      <TextField {...params} variant="standard" label={t('speciality')} />*/}
          {/*    )}*/}
          {/*    className={styles.autocompleteStyle}*/}
          {/*    noOptionsText={t('noElementsAvailable')}*/}
          {/*  />*/}
          {/*</Box>*/}

          <Box display="flex" justifyContent="space-between">
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={() => viewTags()}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('save')}
              handler={() => {}}
            />
          </Box>
        </form>
      </FormCard>
    </Box>
  )
}
