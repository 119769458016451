import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { UserTypeExternalProfessionalContainerConfig } from '../container'
import { toModel } from '../models/UserTypeExternalProfessionalDTO'
import { UserTypeExternalProfessional } from '../models/UserTypeExternalProfessional'
import { UserTypeExternalProfessionalDTO } from '../models/UserTypeExternalProfessionalDTO'
import {
  TrainerCheckEmail,
  TrainerCheckEmailDTO,
  toModel as toModelCheckEmail,
} from '../models/trainerCheckEmail'

export interface IUserTypeExternalProfessionalApi extends IInit {
  getByID(id: string): Observable<UserTypeExternalProfessional | undefined>

  trainerCheckEmail(email: string): Observable<TrainerCheckEmail | undefined>

  getFilteredList(
    q: Query<UserTypeExternalProfessional>
  ): Observable<ItemList<UserTypeExternalProfessional>>

  add(e: UserTypeExternalProfessionalDTO): Observable<UserTypeExternalProfessional | undefined>

  update(e: UserTypeExternalProfessionalDTO): Observable<UserTypeExternalProfessional | undefined>

  delete(id: string): Observable<boolean>
}

export class UserTypeExternalProfessionalApi implements IUserTypeExternalProfessionalApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url =
      (this._container.config as UserTypeExternalProfessionalContainerConfig).moduleFullUrl +
      '/userTypeExternalProfessional'
  }

  add(e: UserTypeExternalProfessionalDTO): Observable<UserTypeExternalProfessional | undefined> {
    return this._httpClient.post<UserTypeExternalProfessional>({ url: this._url, body: e }).pipe(
      map<UserTypeExternalProfessionalDTO, UserTypeExternalProfessional>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<UserTypeExternalProfessional | undefined> {
    return this._httpClient.get<UserTypeExternalProfessional>({ url: `${this._url}/${id}` }).pipe(
      map<UserTypeExternalProfessionalDTO, UserTypeExternalProfessional>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  trainerCheckEmail(email: string): Observable<TrainerCheckEmail | undefined> {
    return this._httpClient
      .get<TrainerCheckEmail>({ url: `${this._url}/trainerEmailCheck/${email}` })
      .pipe(
        map<TrainerCheckEmailDTO, TrainerCheckEmail>((d) => toModelCheckEmail(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredList(
    q: Query<UserTypeExternalProfessional>
  ): Observable<ItemList<UserTypeExternalProfessional>> {
    return this._httpClient
      .get<ItemList<UserTypeExternalProfessional>>({ url: prepareURL(`${this._url}`, q) })
      .pipe(
        map<ItemList<UserTypeExternalProfessionalDTO>, ItemList<UserTypeExternalProfessional>>(
          (dto) => {
            const itemList = emptyList<UserTypeExternalProfessional>()
            itemList.count = dto.count
            itemList.items = dto.items.map((d) => toModel(d))
            return itemList
          }
        ),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<UserTypeExternalProfessional>())
        })
      )
  }

  update(e: UserTypeExternalProfessionalDTO): Observable<UserTypeExternalProfessional | undefined> {
    return this._httpClient.put<UserTypeExternalProfessional>({ url: this._url, body: e }).pipe(
      map<UserTypeExternalProfessionalDTO, UserTypeExternalProfessional>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
