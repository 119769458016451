import { RouteProps } from '../../routes/AppRouter'
import { useTranslation } from 'react-i18next'
import { DashboardPatient as AP } from '../../features/dashboard/patient'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function Dashboard(props: RouteProps) {
  const title = props.title ?? ''
  const subtitle = props.circleSubtitle ?? ''
  const { t } = useTranslation()

  return (
    <>
      <CurrentNavHeaderEsfera
        title={t(title)}
        circleSubtitle={t(subtitle)}
        headerTitle={''}
        divider={true}
      />
      <AP />
    </>
  )
}
