import { Container, ContainerItem } from '../common/container/Container'
import { MessageService } from '../modules/notifications/services/MessageService'
import { NotificationService } from '../modules/notifications/services/NotificationService'
import { UserConfigService } from '../modules/notifications/services/UserConfigService'
import {
  MESSAGE_API_KEY,
  MESSAGES_SERVICE_KEY,
  NOTIFICATION_API_KEY,
  NOTIFICATION_SERVICE_KEY,
  NOTIFICATIONS_MODULE,
  NotificationsProps,
  SEGMENTED_NOTIFICATIONS_SERVICE_KEY,
  SEGMENTED_NOTIFICATION_API_KEY,
  USER_CONFIG_API_KEY,
  USER_CONFIG_SERVICE_KEY,
} from '../modules/notifications/container'
import { UserConfigApi } from '../modules/notifications/api/UserConfigApi'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { NotificationApi } from '../modules/notifications/api/NotificationApi'
import { SegmentedNotificationApi } from '../modules/notifications/api/SegmentedNotificationApi'
import { MessageApi } from '../modules/notifications/api/MessageApi'

let container: Container

function init(p: NotificationsProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(NOTIFICATION_API_KEY, new ContainerItem({ instance: new NotificationApi() }))
  items.set(
    SEGMENTED_NOTIFICATION_API_KEY,
    new ContainerItem({ instance: new SegmentedNotificationApi() })
  )
  items.set(MESSAGE_API_KEY, new ContainerItem({ instance: new MessageApi() }))
  items.set(USER_CONFIG_API_KEY, new ContainerItem({ instance: new UserConfigApi() }))

  items.set(
    MESSAGES_SERVICE_KEY,
    new ContainerItem({ instance: new MessageService({ apiKey: MESSAGE_API_KEY }) })
  )
  items.set(
    NOTIFICATION_SERVICE_KEY,
    new ContainerItem({ instance: new NotificationService({ apiKey: NOTIFICATION_API_KEY }) })
  )
  items.set(
    USER_CONFIG_SERVICE_KEY,
    new ContainerItem({ instance: new UserConfigService({ apiKey: USER_CONFIG_API_KEY }) })
  )
  items.set(
    SEGMENTED_NOTIFICATIONS_SERVICE_KEY,
    new ContainerItem({
      instance: new UserConfigService({ apiKey: SEGMENTED_NOTIFICATION_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getNotificationContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[NOTIFICATIONS_MODULE],
      },
    })
  }

  return container
}
