import React, { useEffect, useRef, useState } from 'react'
import { Box, Modal } from '@mui/material'
import { ShortDescription } from './ShortDescription'
import { Message } from '../../modules/messenger_consulta/models/Message'
import { Searcher } from './Searcher'
import { Messages } from './Messages'
import { Header } from './Header'
import { Sender } from './Sender'
import { v4 as uuidv4 } from 'uuid'

import 'dayjs'
import style from './Messenger.module.css'
import { File } from '../../modules/files/models/File'
import { reduceString } from '../../common/utils/strings'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Query, QueryParam } from '../../common/api/Query'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { getTeleConsultaContainer } from '../../container/tele-consulta-module'
import { TeleConsultaService } from '../../modules/teleconsultation/services/TeleConsultaService'
import { TELECONSULTA_SERVICE_KEY } from '../../modules/teleconsultation'
import { TeleConsulta } from '../../modules/teleconsultation/models/TeleConsulta'
import { ClosedStatus } from '../../modules/teleconsultation/enums/ClosedStatus'
import dayjs from 'dayjs'
import {
  fromListModel,
  TeleconsultaDTO,
  toModel,
} from '../../modules/teleconsultation/models/TeleconsultaDTO'
import { ModalTeleconsultationForm } from './ModalTeleconsultationForm'
import { ObjectForm } from '../query/ConversationEditor'

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const teleConsultaService =
  getTeleConsultaContainer().get<TeleConsultaService>(TELECONSULTA_SERVICE_KEY)

export type QueryProps = {
  conversationID?: string
}
const UserCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

export function TeleConsultation(props: QueryProps): JSX.Element {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const activeFullUserCircle = UserCircleActiveService.getActiveFullUserCircle()
  const [currentTeleconsultation, setCurrentTeleconsultation] = useState<TeleconsultaDTO>()
  const [chatList, setChatList] = useState<JSX.Element[]>([])
  const [teleconsultationCollection, setTeleconsultationCollection] = useState<TeleconsultaDTO[]>(
    []
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [message, setMessage] = useState<string>('')
  const previousConversation = usePreviousConversation() ?? currentTeleconsultation
  const [originalConversations, setOriginalConversations] = useState<TeleconsultaDTO[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showClosedQuery, setShowClosedQuery] = useState<boolean>(false)
  const [openTeleconsultationForm, setOpenTeleconsultationForm] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isAdd, setIsAdd] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [object, setObject] = useState<ObjectForm>({
    whatHappen: [],
    currentTreatment: '',
    timeWithoutCrisis: '',
    symptom: [],
    typeCrisis: '',
    durationCrisis: '',
    healthcare: '',
    medicationAdministered: '',
    timeWithoutAwareness: '',
    nextAppointment: null,
    dontKnowAppointment: '',
  })

  useEffect(() => {
    if (props.conversationID) {
      teleConsultaService.getByID(props.conversationID).subscribe((res) => {
        if (res) {
          setCurrentTeleconsultation(res)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (!isLoading || !loggedUser?.id) {
      return
    }
    teleConsultaService
      .getFilteredItems(
        new Query({
          query: [
            new QueryParam<TeleConsulta>('patient', activeFullUserCircle?.user.id ?? ''),
            new QueryParam<TeleConsulta>('userCircleID', activeFullUserCircle?.id ?? ''),
          ],
          sort: [{ field: 'date' }],
        })
      )
      .subscribe((res) => {
        console.log(res)
        setChatList(res.map((c) => conversationToShortDescription(c)))
        setTeleconsultationCollection(fromListModel(res))
        setOriginalConversations(res)
        setIsLoading(false)
      })
  }, [isLoading])

  useEffect(() => {
    if (!currentTeleconsultation) {
      setTeleconsultationCollection([...teleconsultationCollection])
    }
  }, [currentTeleconsultation])

  function usePreviousConversation() {
    const ref = useRef(currentTeleconsultation)
    useEffect(() => {
      ref.current = currentTeleconsultation
    })
    return ref.current
  }

  const handleFormMessage = (m: string) => {
    const message = new Message({
      id: uuidv4(),
      text: m,
      author: loggedUser?.id ?? '',
      createdAt: new Date(),
      isPinned: false,
      reactions: [],
      message2Users: [],
      consultaID: currentTeleconsultation?.id ?? '',
      rootID: '',
      parentID: '',
    })
    if (currentTeleconsultation) {
      const conversation = cloneDeep<TeleConsulta>(toModel(currentTeleconsultation))

      conversation.addMessage(message)
      // conversationsService.update(conversation).subscribe((_) => {
      setCurrentTeleconsultation(conversation)
      teleConsultaService.addMessage(message).subscribe()
    }
  }
  const handleObject = (objt: ObjectForm) => {
    setObject(objt)
  }

  const conversationToShortDescription = (conversation: TeleconsultaDTO) => {
    const messages = orderMessagesByCreationDate(conversation.messages ?? [])
    const lengthLimit = 70
    const lastMessage =
      conversation.messages?.length === 0
        ? ''
        : reduceString(messages[messages.length - 1].text, lengthLimit)

    console.log(conversation)
    const conversationName = reduceString(conversation.name, lengthLimit)
    return showClosedQuery ? (
      <ShortDescription
        key={conversation.id}
        conversationID={conversation.id}
        conversationName={conversationName}
        conversationDescription={conversation.description}
        avatarUrl={'avatarUrl'}
        description={conversation.description}
        lastMessage={lastMessage}
        notReadMessages={conversation.unreadMessages}
        handlerConversation={handleShortDescription}
        isCurrentConversation={conversation.id === currentTeleconsultation?.id}
      />
    ) : conversation.closed === ClosedStatus.Opened ? (
      <ShortDescription
        key={conversation.id}
        conversationID={conversation.id}
        conversationName={conversationName}
        conversationDescription={conversation.description}
        avatarUrl={'avatarUrl'}
        description={conversation.description}
        lastMessage={lastMessage}
        notReadMessages={conversation.unreadMessages}
        handlerConversation={handleShortDescription}
        isCurrentConversation={conversation.id === currentTeleconsultation?.id}
      />
    ) : (
      <></>
    )
  }

  const orderMessagesByCreationDate = (messages: Message[]): Message[] => {
    return [...messages].sort(
      (m1, m2) => new Date(m1.createdAt).getTime() - new Date(m2.createdAt)?.getTime()
    )
  }

  const handleShortDescription = (conversationID: string | undefined) => {
    const conversation = teleconsultationCollection.find((c) => c.id === conversationID)
    if (conversation) {
      conversation.unreadMessages = 0
      // FIXME problem with async setState currentConversation
      setCurrentTeleconsultation(conversation)
      setIsLoading(true)
    }
  }
  useEffect(() => {
    if (!currentTeleconsultation) return
    teleConsultaService.getByID(currentTeleconsultation.id ?? '').subscribe((res) => {
      setCurrentTeleconsultation(res)
    })
  }, [isAdd])

  useEffect(() => {
    replaceChatList()
  }, [currentTeleconsultation])

  const replaceChatList = () => {
    if (!currentTeleconsultation || !previousConversation) return

    const mapResult = [...chatList]
    replaceConversation(mapResult, previousConversation)
    replaceConversation(mapResult, currentTeleconsultation)

    setChatList(mapResult)
  }

  const replaceConversation = (conversationList: JSX.Element[], conversation: TeleconsultaDTO) => {
    const index = teleconsultationCollection.findIndex((c) => c.id === conversation.id)
    conversationList.splice(index, 1, conversationToShortDescription(conversation))
  }

  useEffect(() => {
    const mapResult = [...teleconsultationCollection].map((c) => conversationToShortDescription(c))
    setChatList(mapResult)
    replaceOriginalConversations()
  }, [teleconsultationCollection])

  const replaceOriginalConversations = () => {
    if (originalConversations) {
      const newOriginalConversations = [...originalConversations]
      teleconsultationCollection.forEach((c) => {
        const index = newOriginalConversations.findIndex((o) => o.id === c.id)
        if (index !== -1) {
          newOriginalConversations.splice(index, 1, c)
        }
      })
      setOriginalConversations(newOriginalConversations)
    }
  }

  const handleSearch = (s: string) => {
    const filteredConversations = originalConversations?.filter((c) => {
      const name = c.name.toLowerCase()
      return name.includes(s.toLowerCase())
    })
    if (filteredConversations) {
      setTeleconsultationCollection(filteredConversations)
    }
  }

  const handleSender = (m: Message, f: File[]) => {
    if (currentTeleconsultation) {
      f.forEach((file) => m.addFile(file.id ?? ''))
      const conversation = cloneDeep<TeleConsulta>(toModel(currentTeleconsultation))

      conversation.addMessage(m)
      // conversationsService.update(conversation).subscribe((_) => {
      setCurrentTeleconsultation(conversation)
      replaceConversationCollection(conversation)
      // })
      teleConsultaService.addMessage(m).subscribe()
    }
  }

  function replaceConversationCollection(conversation: TeleConsulta) {
    const conversations = [...teleconsultationCollection]

    const index = conversations.findIndex((c) => c.id === conversation.id)
    conversations.splice(index, 1, conversation)

    setTeleconsultationCollection(conversations)
  }

  const reverseConversationCollection = () => {
    setTeleconsultationCollection([...teleconsultationCollection.reverse()])
  }

  const handleNewConversation = (): void => {
    if (activeFullUserCircle) {
      const professional =
        activeFullUserCircle?.profSmsID?.length > 0 ? activeFullUserCircle?.profSmsID[0] : ''
      const newTeleconsulta = new TeleConsulta({
        id: uuidv4(),
        name: activeFullUserCircle?.user?.name + ' ' + dayjs().format('DD/MM/YYYY'),
        description: '',
        messages: [],
        patient: loggedUser?.id ?? '',
        professional: professional ?? '',
        closed: ClosedStatus.Opened,
        unreadMessages: 0,
        userCircleID: activeFullUserCircle?.id ?? '',
        date: new Date(),
      })

      teleConsultaService.add(newTeleconsulta).subscribe((res) => {
        setCurrentTeleconsultation(res)
      })
    }
  }

  const handleCloseQuery = () => {
    if (currentTeleconsultation) {
      currentTeleconsultation.closed = ClosedStatus.Closed

      teleConsultaService.update(toModel(currentTeleconsultation)).subscribe((res) => {
        setIsLoading(true)
      })
    }
  }

  return (
    <>
      {!isLoading ? (
        <div className={style.messengerContainer}>
          <Box component="section" width="30%" className={style.conversationWidget}>
            <h3 style={{ font: 'Montserrat' }}>{t('chats')}</h3>
            <Box
              className={style.searcherContainer}
              component="section"
              display="flex"
              justifyContent="space-between"
            >
              <Searcher handler={handleSearch} reverse={reverseConversationCollection} />
            </Box>
            {/*   <Box className={style.switchButtonContainer}>
              <p>Mostrar consultas cerradas</p>
              <SwitchCustom
                checked={showClosedQuery}
                onClick={() => {
                  setShowClosedQuery(!showClosedQuery)
                  setIsLoading(true)
                }}
              />
            </Box> */}
            <Box className={style.addNewConversationContainer}>
              <AppButton
                label={t('newConversation1')}
                type={'button'}
                theme={ButtonTheme.newEvent}
                handler={handleNewConversation}
              />
            </Box>
            <Box className={style.chatList} style={{ maxHeight: '50vh' }} component="section">
              {chatList}
            </Box>
          </Box>
          {currentTeleconsultation && (
            <Box
              className={style.messengerWidget}
              component="section"
              width="70%"
              display="flex"
              flexDirection="column"
            >
              <Box className={style.orangeLine}>
                <Header
                  headerName={currentTeleconsultation.name || ''}
                  avatarUrl={undefined}
                  creatorID={currentTeleconsultation.patient}
                  description={currentTeleconsultation.description || ''}
                  handleEdit={() => {}}
                  handleDelete={() => {}}
                  handleCloseQuery={handleCloseQuery}
                  currentConversation={currentTeleconsultation}
                  conversationUsers={[]}
                />
              </Box>
              <Box className={style.messagesContainer} flexGrow={1} overflow="auto">
                <Messages
                  dayOfTeleconsultation={currentTeleconsultation.date}
                  handleTeleconsultationForm={() => setOpenTeleconsultationForm(true)}
                  messages={currentTeleconsultation.messages ?? []}
                />
              </Box>
              <Box className={style.senderContainer}>
                <Sender
                  handler={handleSender}
                  conversationId={currentTeleconsultation.id ?? ''}
                  conversationClosed={currentTeleconsultation.closed}
                />
              </Box>
            </Box>
          )}

          {!currentTeleconsultation && (
            <Box className={style.emptyConversation}>
              <h1 className={style.labelCreateOrSelectConversation}>
                {t('createOrSelectConversation')}
              </h1>
            </Box>
          )}
        </div>
      ) : null}
      <Modal
        className={style.modalNewConversation}
        open={openTeleconsultationForm}
        onClose={() => setOpenTeleconsultationForm(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalTeleconsultationForm
          handleCloseEpilepticCrisisModal={() => setOpenTeleconsultationForm(false)}
          saveConversation={() => {}}
          message={message}
          handleMessage={(m: string) => handleFormMessage(m)}
          handleObject={handleObject}
          object={object}
          errorMessage={error}
        />
      </Modal>
    </>
  )
}
