import {
  TableBody,
  TableCell,
  TableRow,
  Skeleton,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material'
import { Actions, Field } from '../table_type/types'
import { v4 as uuidv4 } from 'uuid'
import unchecked from '../../assets/buttons/OFF-Blue.svg'
import checked from '../../assets/buttons/checked.svg'
import {
  tableCellRadioStyles,
  tableCellStyles,
  tableRowIsGuestWithoutBorderStyles,
  tableRowStyles,
} from './StylesMaterialUI'

export type BodyProps<T> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly actions?: Actions<T>
  readonly isLoading?: boolean
  readonly pages?: number
  readonly handlerChecked?: (key: string) => void
  readonly tabCellClicked?: (key: string) => void
  readonly checked: Record<string, boolean>
  addDeleteFile?: (file: File) => void
  isGuest?: boolean
}

export function Body<T extends Record<string, any>>(props: BodyProps<T>) {
  const rows = Array.from({ length: props.pages ?? 5 }, (_, index) => index + 1)
  return (
    <TableBody style={{ borderRadius: '25px' }}>
      {props.isLoading && (
        <>
          {rows.map((r) => (
            <TableRow key={`loading_body_${r}`}>
              <TableCell>
                <Skeleton />
              </TableCell>
              {Object.values(props.fields).map((i) => {
                return (
                  <TableCell key={`loading_cell_fields_${i.label}`}>
                    <Skeleton />
                  </TableCell>
                )
              })}

              {props.actions && props.actions.items?.length > 0 && (
                <TableCell key={`loading_cell_actions_${r}`}>
                  <Box display="flex" justifyContent="space-between">
                    {props.actions.items.map((a, i) => {
                      return (
                        <Skeleton
                          key={`loading_cell_actions_${r}_${i}`}
                          variant="circular"
                          width={10}
                          height={10}
                        />
                      )
                    })}
                  </Box>
                </TableCell>
              )}
            </TableRow>
          ))}
        </>
      )}
      {!props.isLoading && (
        <>
          {props.items.map((item) => (
            <TableRow
              onClick={() =>
                props.isGuest
                  ? props.handlerChecked && props.handlerChecked(item[props.rowKeyField])
                  : null
              }
              sx={props.isGuest ? tableRowIsGuestWithoutBorderStyles : tableRowStyles}
              key={item[props.rowKeyField]}
            >
              <TableCell sx={tableCellRadioStyles}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  sx={{ paddingLeft: 2, display: props.isGuest ? 'none' : '' }}
                >
                  <FormControlLabel
                    value=""
                    control={
                      <Checkbox
                        icon={<img src={unchecked} alt="unchecked" />}
                        checkedIcon={<img src={checked} alt="checked" />}
                        key={'props.label' + 'checkbox'}
                        name={'props.label' + 'checkbox'}
                        checked={!!props.checked[item[props.rowKeyField]]}
                        onChange={() =>
                          props.handlerChecked && props.handlerChecked(item[props.rowKeyField])
                        }
                      />
                    }
                    label=""
                  />
                </RadioGroup>
              </TableCell>
              {Object.values(props.fields).map((field, index) => {
                const style = field.styleFunc ? field.styleFunc(field, item) : ''
                return (
                  <TableCell
                    className={style}
                    sx={tableCellStyles}
                    key={(field.name as string) + uuidv4()}
                    onClick={() =>
                      props.tabCellClicked && props.tabCellClicked(item[props.rowKeyField])
                    }
                  >
                    {field.renderFunc ? field.renderFunc(field, item) : item[field.name].toString()}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </>
      )}
    </TableBody>
  )
}
