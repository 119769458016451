import React, { useEffect, useState } from 'react'
import { Modal, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import { YearViewProps } from './types'
import dayjs from 'dayjs'
import style from './YearView.module.css'
import { YearMobileModal, YearMobileViewProps } from './YearMobileModal'

// TODO Request events here
export function YearView(props: YearViewProps) {
  const Today = new Date()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [years, setYears] = useState<string[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [months, setMonths] = useState<string[]>(
    Array.from({ length: 12 }, (item, i) => {
      return new Date(0, i).toLocaleString('es-ES', { month: 'long' })
    })
  )
  const [openMobileModal, setOpenMobileModal] = useState<boolean>(false)
  const [currentEvents, setCurrentEvents] = useState<YearMobileViewProps[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedYear, setSelectedYear] = useState<number>(Today.getFullYear())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedMonth, setSelectedMonth] = useState<number>(Today.getMonth())

  const getDescriptionByName = (e: string) => {
    let description = ''
    if (props.handlerTooltip) {
      const aux = props.handlerTooltip.get(e)
      if (aux) {
        description = aux
      }
    }
    return description
  }

  useEffect(() => {
    if (isLoading) {
      const newYears: string[] = []
      props.events?.forEach((e) => {
        if (!newYears.includes(dayjs(e.startDate).format('YYYY'))) {
          newYears.push(dayjs(e.startDate).format('YYYY'))
        }
      })
      newYears.sort()
      setYears([...newYears])
    }
    setIsLoading(false)
  }, [isLoading])

  const handlerMobileModal = () => {
    setOpenMobileModal(!openMobileModal)
  }

  const handlerDataMobileModal = (selectedDate: Date) => {
    const selectedMonth = dayjs(selectedDate).month()
    const selectedYear = dayjs(selectedDate).year()

    const filteredEvents =
      props.events?.filter((e) => {
        const eventMonth = dayjs(e.startDate).month()
        const eventYear = dayjs(e.startDate).year()
        return eventMonth === selectedMonth && eventYear === selectedYear
      }) ?? []

    const mappedEvents: YearMobileViewProps[] = filteredEvents.map((e) => ({
      id: e.id ?? '',
      title: e.title,
      description: e.description,
      year: e.startDate.getFullYear(),
    }))

    setCurrentEvents(mappedEvents)
    handlerMobileModal()
  }

  return (
    <>
      <Table style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow className={style.yearHeader}>
            <TableCell />
            {years.map((year) => (
              <TableCell key={year} size={'small'} style={{ border: 'solid 1px #e3e3e3' }}>
                <div className={style.yearContainer}>{year}</div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {months.map((month, monthIndex) => (
            <TableRow key={month} className={style.monthRow} hover={true}>
              <TableCell align={'left'} size={'medium'}>
                <div className={style.monthContainer}>
                  {month.charAt(0).toUpperCase() + month.slice(1).toLowerCase()}
                </div>
              </TableCell>
              {years.map((year) => (
                <TableCell key={month + year} className={style.tableRows}>
                  {window.innerWidth < 599 &&
                  Array.isArray(props.events) &&
                  props.events.length > 0 ? (
                    <>
                      {props.events?.map(
                        (e) =>
                          dayjs(e.startDate).format('YYYY') === year.toString() &&
                          dayjs(e.startDate).locale('es').format('MMMM').toUpperCase() ===
                            month.toUpperCase() && (
                            <Tooltip key={e.id} title={getDescriptionByName(e.description)}>
                              <div
                                className={
                                  e.startDate <= Today
                                    ? style.monthEventInoculatedMobile
                                    : style.monthEventMobile
                                }
                                key={e.id}
                                onClick={() => {
                                  setSelectedYear(Number(year))
                                  setSelectedMonth(monthIndex)
                                  handlerDataMobileModal(new Date(Number(year), monthIndex, 1))
                                }}
                              ></div>
                            </Tooltip>
                          )
                      )}
                    </>
                  ) : (
                    <>
                      {props.events?.map(
                        (e) =>
                          dayjs(e.startDate).format('YYYY') === year.toString() &&
                          dayjs(e.startDate).locale('es').format('MMMM').toUpperCase() ===
                            month.toUpperCase() && (
                            <Tooltip key={e.id} title={getDescriptionByName(e.description)}>
                              <div
                                className={
                                  e.startDate <= Today
                                    ? style.monthEventInoculated
                                    : style.monthEvent
                                }
                                key={e.id}
                              >
                                {e.description}
                              </div>
                            </Tooltip>
                          )
                      )}
                    </>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Modal open={openMobileModal} className={style.eventModal} onClose={handlerMobileModal}>
        <YearMobileModal handlerClose={handlerMobileModal} events={currentEvents} />
      </Modal>
    </>
  )
}
