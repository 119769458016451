import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardComponent, GenericChartDashboardComponent } from './EpilepsyCard'
import { UserForm, UserFormQuery } from '../../../../modules/forms/models/UserForm'
import { getFormContainer } from '../../../../container/form-module'
import { UserFormService } from '../../../../modules/forms/services/UserFormService'
import { USER_FORM_SERVICE_KEY } from '../../../../modules/forms'
import { Query, QueryParam } from '../../../../common/api/Query'
import { PROMS_beckItems } from '../../../../modules/forms/models/PROMS_beckItemsProps'
import { MenuItem, Select, Skeleton } from '@mui/material'

type PsychologicalHealthCardProps = {
  userForms: UserForm[]
}

const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)

export const PsychologicalHealth: React.FC<PsychologicalHealthCardProps> = ({ userForms }) => {
  const { t } = useTranslation()
  const [psychologicalHealth, setPsychologicalHealth] = useState<number[]>([])
  const [monthLabel, setMonthLabel] = useState<string[]>([])
  const [psychologicalHealthItems, setPsychologicalHealthItems] = useState<PROMS_beckItems[]>([])

  const items = [
    'totalScore',
    'sadness',
    'pessimism',
    'failure',
    'lossOfPleasure',
    'guilt',
    'punishment',
  ]
  const [selectedItem, setSelectedItem] = useState<string>(items[0])
  const [isLoading, setIsLoading] = useState(true)

  const [datasets, setDataSets] = useState([
    {
      label: t('PsychologicalHealth'),
      data: psychologicalHealth,
      borderColor: 'blue',
    },
  ])

  useEffect(() => {
    setDataSets([
      {
        label: t('PsychologicalHealth'),
        data: psychologicalHealth,
        borderColor: 'blue',
      },
    ])
  }, [psychologicalHealth])

  useEffect(() => {
    let data: number[] = []
    switch (selectedItem) {
      case 'totalScore':
        data = psychologicalHealth
        break
      case 'sadness':
        data = psychologicalHealthItems.map((f) => f.sadness)
        break
      case 'pessimism':
        data = psychologicalHealthItems.map((f) => f.pessimism)
        break
      case 'failure':
        data = psychologicalHealthItems.map((f) => f.failure)
        break
      case 'lossOfPleasure':
        data = psychologicalHealthItems.map((f) => f.lossOfPleasure)
        break
      case 'guilt':
        data = psychologicalHealthItems.map((f) => f.guilt)
        break
      case 'punishment':
        data = psychologicalHealthItems.map((f) => f.punishment)
        break
    }
    setDataSets([
      {
        label: t('PsychologicalHealth'),
        data,
        borderColor: 'blue',
      },
    ])
  }, [selectedItem])

  const labels = [
    'En',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sept',
    'Oct',
    'Nov',
    'Dic',
  ]

  useEffect(() => {
    setIsLoading(true)
    userFormService
      .getPromsBeckItems(
        new Query<UserFormQuery>({
          query: [
            new QueryParam(
              'ids',
              userForms.map((f) => f.id ?? '')
            ),
          ],
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        if (res) {
          setPsychologicalHealthItems(res)
        }
      })
    if (userForms.length === 0) return
    getScores()
  }, [userForms])

  const getScores = () => {
    const month: Date[] = []
    const result: number[] = []

    userForms.forEach((form, index) => {
      month.push(new Date(form.userFormValues[0].date))
      result.push(form.totalScore ?? 0)
    })

    setMonthLabel(month.map((m) => labels[m.getMonth()] + ' ' + m.getFullYear()))
    setPsychologicalHealth(result)
  }

  return (
    <CardComponent title={t('PsychologicalHealth')}>
      {isLoading ? (
        <>
          <Skeleton variant="rectangular" width="100%"></Skeleton>
        </>
      ) : (
        <>
          <GenericChartDashboardComponent
            datasets={datasets}
            labels={monthLabel}
            title={''}
            typeChart={'bar'}
            legendPosition={'bottom'}
          />

          <Select
            MenuProps={{
              MenuListProps: {
                sx: {
                  'li.MuiButtonBase-root': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    fontFamily: 'Poppins',
                    paddingLeft: '10px',
                  },
                },
              },
            }}
            SelectDisplayProps={{
              style: {
                paddingLeft: '10px',
                fontFamily: 'Poppins',
                fontSize: '14px',
              },
            }}
            fullWidth
            variant="outlined"
            style={{
              backgroundColor: '#f5f2f2',
              width: '50%',
              marginLeft: '50%',
              marginTop: '20px',
              height: '40px',
            }}
            inputProps={{ 'aria-label': 'Without label' }}
            value={selectedItem}
            defaultValue={items[0]}
            onChange={(e) => setSelectedItem(e.target.value)}
          >
            {items.map((k: string, index: number) => (
              <MenuItem key={k + index} value={k}>
                {t(k)}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </CardComponent>
  )
}
