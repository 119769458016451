import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { AppTable } from '../../components/table_AP'
import { Pager, Search, SearchValue, Field } from '../../components/table_type/types'
import { SportCenter, SportCenterQuery } from '../../modules/sport-center/models/SportCenter'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam, QueryParamN } from '../../common/api/Query'
import styles from './Table.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { AddSeeEditSportCenterModal } from './AddSeeEditSportCenterModal'
import { getSportCenterContainer } from '../../container/sport-center-module'
import { SportCenterService } from '../../modules/sport-center/services/SportCenterService'
import { SPORT_CENTER_SERVICE_KEY } from '../../modules/sport-center'
import { ActionsComponent } from '../../components/table_AP/ActionsComponent'
import { getCityHallContainer } from '../../container/cityHall-module'
import { CityHall, CityHallQuery } from '../../modules/cityHall/models/CityHall'
import { CityHallService } from '../../modules/cityHall/services/CityHallService'
import { CITY_HALL_SERVICE_KEY } from '../../modules/cityHall'
// import { useSnackbar } from 'notistack'
import {
  emptySportCenterDTO,
  SportCenterDTO,
  fromModel as fromModelSportCenter,
} from '../../modules/sport-center/models/SportCenterDTO'
import add_icon from '../../assets/active_plan_icons/add_icon.svg'
import delete_icon from '../../assets/active_plan_icons/delete_icon.svg'
import { enqueueSnackbar } from 'notistack'

const sportCenterService =
  getSportCenterContainer().get<SportCenterService>(SPORT_CENTER_SERVICE_KEY)
const cityHallService = getCityHallContainer().get<CityHallService>(CITY_HALL_SERVICE_KEY)

type TableProps = {
  id?: string
}
export function Table(props: TableProps) {
  const { t } = useTranslation()
  const [sportCenterArr, setSportCenterArr] =
    useState<ItemList<SportCenter>>(emptyList<SportCenter>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [formsPerPage, setFormsPerPage] = useState<number>(10)
  const [searcher, setSearcher] = useState<SearchValue<SportCenterQuery>[]>([
    { name: 'name', label: t('search') + '...' },
  ])
  const [openSportCenterModal, setSportCenterModal] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openCityHallModal, setCityHallModal] = useState<boolean>(false)
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>(
    sportCenterArr.items.reduce<Record<string, boolean>>((acc, item) => {
      if (item?.id !== undefined) {
        acc[item.id] = false
      }
      return acc
    }, {})
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [cityHall, setCityHall] = useState<ItemList<CityHall>>(emptyList<CityHall>())
  // const { enqueueSnackbar } = useSnackbar()
  const [sportCenter, setSportCenter] = useState<SportCenterDTO>(emptySportCenterDTO)
  const [edit, setEdit] = useState<boolean>(false)

  const tableRefresh = useCallback((searcher: any, formsPerPage: number, page: number) => {
    const centerIDArr: string[] = []
    sportCenterService
      .getFilteredList(
        new Query({
          query: [...searcherQuery(searcher)],
          pager: { offset: formsPerPage * page, limit: formsPerPage },
          sort: [{ field: 'name', desc: false }],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setSportCenterArr(res)
        setCount(res.count)

        res.items.map((center) => {
          return centerIDArr.push(center.cityHallID)
        })
      })

    cityHallService
      .getFilteredList(
        new Query({
          query: [new QueryParam<CityHallQuery>('ids', centerIDArr)],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setCityHall(res)
      })
  }, [])

  useEffect(() => {
    tableRefresh(searcher, formsPerPage, page)
  }, [searcher, formsPerPage, page, isLoading])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setFormsPerPage(10)
      return
    }
    setFormsPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: formsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, formsPerPage, isLoading])

  const handlerSportCenterModal = () => {
    if (!openCityHallModal) setIsLoading(!isLoading)
    setSportCenterModal(!openSportCenterModal)
  }

  const search: Search<SportCenterQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<SportCenterQuery>[]) => {
      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
    },
  }

  const fields: Field<SportCenter>[] = [
    {
      name: 'name',
      label: t('center'),
      styleFunc: (f, i) => styles.tableFont,
    },
    {
      name: 'postalCode',
      label: t('CP'),
    },
    {
      name: 'id',
      label: t('municipality'),
      renderFunc: (f, i) => findCityHall(i.cityHallID, 'city'),
    },
    {
      name: 'id',
      label: t('cityHall'),
      renderFunc: (f, i) => findCityHall(i.cityHallID, 'name'),
    },
  ]

  const findCityHall = (cityHallID: string, value: string): string => {
    const cityHallObject = cityHall.items.find((value) => cityHallID === value.id)
    if (value === 'city') {
      return cityHallObject ? cityHallObject.city : ''
    } else {
      return cityHallObject ? cityHallObject.name : ''
    }
  }

  const handleCheckedChange = (key: string) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [key]: !prevCheckedItems[key],
    }))
  }

  const handlerDelete = () => {
    for (const key in checkedItems) {
      if (checkedItems[key]) {
        sportCenterService.delete(key).subscribe((res) => {
          if (!res) {
            enqueueSnackbar(t('cantDeleteSportingSpace'), { variant: 'error' })
          }
          setIsLoading(!isLoading)
        })
      }
    }
  }

  const handlerNew = () => {
    setEdit(false)
    handlerSportCenterModal()
  }

  const handlerTabCellClicked = (id: string) => {
    setEdit(true)
    if (id) {
      sportCenterService.getByID(id).subscribe((res) => {
        if (!res) return
        setSportCenter(fromModelSportCenter(res))
        handlerSportCenterModal()
      })
    }
  }

  return (
    <Box style={{ borderBottom: 1, borderColor: 'divider' }}>
      <Box style={{ padding: '1em', display: 'flex' }}>
        <Box className={styles.containerActions} mb={3} display="flex" flexDirection="column">
          <Typography className={styles.actionsTitle}>{t('Actions')}</Typography>
          <div className={styles.dividerStyleOne} />
          <ActionsComponent
            search={search}
            searchComponent={true}
            handleFileSelect={() => {}}
            handleDelete={() => {}}
          />
          <div className={styles.dividerStyleTwo} />
          <Box className={styles.boxStyle}>
            <AppButton
              theme={ButtonTheme.AddCenter}
              type={'button'}
              label={t('addSportCenter')}
              handler={handlerNew}
              startIcon={add_icon}
            />
          </Box>

          <Box className={styles.boxStyle}>
            <AppButton
              theme={ButtonTheme.RemoveCenter}
              type={'button'}
              label={t('removeSportCenter')}
              handler={handlerDelete}
              startIcon={delete_icon}
            />
          </Box>
          <div className={styles.dividerStyleThree} />
        </Box>

        <Box className={styles.containerTable}>
          <AppTable
            items={sportCenterArr.items}
            rowKeyField="id"
            fields={fields}
            search={search}
            pager={pager}
            isPreventDefault={true}
            checked={checkedItems}
            handlerChecked={handleCheckedChange}
            tabCellClicked={handlerTabCellClicked}
          />
        </Box>
        {openSportCenterModal && (
          <>
            <AddSeeEditSportCenterModal
              open={openSportCenterModal}
              handleClose={handlerSportCenterModal}
              sportCenterEdit={edit ? sportCenter : undefined}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

const searcherQuery = (
  svs: SearchValue<SportCenterQuery>[]
): QueryParam<SportCenterQuery>[] | QueryParamN<SportCenterQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
