export type PROMS_beckItemsProps = {
  userFormID: string
  sadness: number
  pessimism: number
  failure: number
  lossOfPleasure: number
  guilt: number
  punishment: number
}

export class PROMS_beckItems {
  private readonly _userFormID: string
  private _sadness: number
  private _pessimism: number
  private _failure: number
  private _lossOfPleasure: number
  private _guilt: number
  private _punishment: number

  constructor(p: PROMS_beckItemsProps) {
    this._userFormID = p.userFormID
    this._sadness = p.sadness
    this._pessimism = p.pessimism
    this._failure = p.failure
    this._lossOfPleasure = p.lossOfPleasure
    this._guilt = p.guilt
    this._punishment = p.punishment
  }

  get userFormID(): string {
    return this._userFormID
  }

  get sadness(): number {
    return this._sadness
  }

  get pessimism(): number {
    return this._pessimism
  }

  get failure(): number {
    return this._failure
  }

  get lossOfPleasure(): number {
    return this._lossOfPleasure
  }

  get guilt(): number {
    return this._guilt
  }

  get punishment(): number {
    return this._punishment
  }
}
