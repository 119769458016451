import { Box, Divider, Modal, Typography } from '@mui/material'
import style from './ActivePlan.module.css'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import React from 'react'
import icoDelete from '../../../assets/buttons/close.svg'
import { useTranslation } from 'react-i18next'

type IncusionEFDMessageProps = {
  open: boolean
  handleClose: () => void
  handelAcceptGroupQuery: () => void
}

export function VerifyQuery(props: IncusionEFDMessageProps) {
  const { t } = useTranslation()

  return (
    <Modal open={props.open} onClose={props.handleClose} className={style.modalMain}>
      <Box className={style.containerModal}>
        <Box className={style.contentHeader}>
          <img style={{ cursor: 'pointer' }} onClick={props.handleClose} src={icoDelete} />
        </Box>
        <Box>
          <Typography className={style.headerTitle}>{t('verifyQueryTitle')}</Typography>
          <Divider
            style={{
              marginRight: '15px',
              marginLeft: '15px',
              borderColor: '#f47105',
              marginBottom: '15px',
            }}
          />
        </Box>
        <Box className={style.content}>{t('verifyQuery')}</Box>
        <Box className={style.containerRowButtonModal}>
          <AppButton
            theme={ButtonTheme.whiteAndBlue}
            type={'button'}
            label={t('back')}
            handler={props.handleClose}
          />
          <AppButton
            theme={ButtonTheme.newEvent}
            type={'button'}
            label={t('accept')}
            handler={props.handelAcceptGroupQuery}
          />
        </Box>
      </Box>
    </Modal>
  )
}
