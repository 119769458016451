import { PROMS_beckItems } from './PROMS_beckItemsProps'

export interface PROMS_beckItemsDTO {
  userFormID: string
  sadness: number
  pessimism: number
  failure: number
  lossOfPleasure: number
  guilt: number
  punishment: number
}

export function emptyPromsBeckItems(userFormID: string): PROMS_beckItemsDTO {
  return {
    userFormID,
    sadness: 0,
    pessimism: 0,
    failure: 0,
    lossOfPleasure: 0,
    guilt: 0,
    punishment: 0,
  }
}

export function beckIIToModel(dto: PROMS_beckItemsDTO): PROMS_beckItems {
  return new PROMS_beckItems(dto)
}
