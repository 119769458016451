import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardComponent, GenericChartDashboardComponent } from './ActivePlanCard'
import { Box, FormControl, MenuItem, Select } from '@mui/material'
import styles from '../../dashBoardStyle.module.css'
import { UserForm } from '../../../../modules/forms/models/UserForm'
import { ActivePlanFromFields } from '../../../../common/enums/activePlan'

type FitnessLevelCardProps = {
  userForm: UserForm[]
  selectValue: number
  handlerSelect: (value: number) => void
}
export const FitnessLevelCard: React.FC<FitnessLevelCardProps> = ({
  userForm,
  selectValue,
  handlerSelect,
}) => {
  const { t } = useTranslation()
  const [monthLabel, setMonthLabel] = useState<string[]>([])
  const selectOption: string[] = [t('aerobicCondition'), t('force'), t('flexibility'), t('balance')]
  const [datasets, setDatasets] = useState<
    { label: string; data: number[]; borderColor: string }[]
  >([])

  const labels = [
    'En',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sept',
    'Oct',
    'Nov',
    'Dic',
  ]

  useEffect(() => {
    if (userForm.length === 0) return
    getChartValue()
  }, [userForm, selectValue])

  const getChartValue = () => {
    let auxUserForm = [...userForm]
    auxUserForm = auxUserForm.sort(function (a, b) {
      return (
        new Date(b.userFormValues[0].date).getTime() - new Date(a.userFormValues[0].date).getTime()
      )
    })

    const month: number[] = []
    const result: number[] = []
    auxUserForm.forEach((ufValue) => {
      ufValue.userFormValues.forEach((formField) => {
        if (
          formField.formFieldID === getCommonField(selectValue)[0] ||
          formField.formFieldID === getCommonField(selectValue)[1]
        ) {
          const auxValue = formField.getValues()
          if (typeof auxValue === 'object' && auxValue.length > 0 && auxValue[0]) {
            result.push(parseInt(auxValue[0]))
          }

          month.push(new Date(formField.date).getMonth())
        }
      })
    })
    const auxMonth: string[] = []
    month.forEach((monthNumber) => {
      auxMonth.push(labels[monthNumber])
    })

    setMonthLabel(auxMonth)
    setDatasets([
      {
        label: selectOption[selectValue],
        data: result,
        borderColor: 'blue',
      },
    ])
  }

  const getCommonField = (value: number): string[] => {
    const result: string[] = []
    switch (value) {
      case 0:
        result.push(ActivePlanFromFields.FCFormFieldAerobicConditionInicio)
        result.push(ActivePlanFromFields.FCFormFieldAerobicConditionFinal)
        break
      case 1:
        result.push(ActivePlanFromFields.FCFormFieldForceInicio)
        result.push(ActivePlanFromFields.FCFormFieldForceFinal)
        break
      case 2:
        result.push(ActivePlanFromFields.FCFormFieldFlexibilityInicio)
        result.push(ActivePlanFromFields.FCFormFieldFlexibilityFinal)
        break
      case 3:
        result.push(ActivePlanFromFields.FCFormFieldBalanceInicio)
        result.push(ActivePlanFromFields.FCFormFieldBalanceFinal)
        break
    }
    return result
  }

  const chartComponent = () => {
    return (
      <GenericChartDashboardComponent
        datasets={datasets}
        labels={monthLabel}
        title={''}
        typeChart={'line'}
        legendPosition={'bottom'}
      />
    )
  }

  useEffect(() => {
    chartComponent()
  }, [selectValue])

  return (
    <CardComponent title={t('fitnessLevel')}>
      {chartComponent()}
      <Box className={styles.selectMainRow}>
        <FormControl>
          <Select
            labelId="test-select-label"
            id="demo-simple-select"
            value={selectValue}
            label=""
            onChange={(e) => handlerSelect(e.target.value as number)}
          >
            {selectOption.map((option, index) => (
              <MenuItem key={index} value={index}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </CardComponent>
  )
}
