import React, { ChangeEvent, useEffect, useState } from 'react'
import { Box, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import style from './Searcher.module.css'
import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { useGetActiveUser } from '../../hooks/get-active-user/useGetActiveUser'

type SearcherProps = {
  handler: (search: string) => void
  reverse: () => void
  handleNewConversation?: () => void
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function Searcher(props: SearcherProps): JSX.Element {
  const { t } = useTranslation()
  const { activeUser } = useGetActiveUser()
  const [search, setSearch] = useState<string>('')
  const loggedUser = loggedUserService.get()
  /* const [descToAsc, setDescToAsc] = useState<boolean>(false) */

  const handlerChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setSearch(event.target.value)

  useEffect(() => {
    props.handler(search)
  }, [search])

  /* const handleFilterChange = () => {
    props.reverse()
    setDescToAsc(!descToAsc)
  } */

  const isProfessional = (): boolean => {
    if (!loggedUser) return false
    if (typeof activeUser.profSmsID === 'string' && activeUser.profSmsID === loggedUser.id) {
      return true
    }
    return activeUser.profSmsID.includes(loggedUser?.id ?? '')
  }

  return (
    <>
      <Box className={style.inputBox}>
        <TextField
          className={style.inputSearcher}
          label={t('filterByConversation')}
          variant={'outlined'}
          type="text"
          value={search}
          onChange={handlerChange}
        />
      </Box>

      <div
        className={style.addNewConversationContainer}
        onClick={props.handleNewConversation}
        style={{
          visibility: !isProfessional() ? 'visible' : 'hidden',
          zIndex: 0,
        }}
      ></div>
    </>
  )
}

/* function containerClassName(descToAsc: boolean): string {
  if (descToAsc) {
    return style.toAsc + ' ' + style.filterListIcon
  }
  return style.toDesc
} */
