// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Editor_header__2uV5g {\n  margin-top: 0;\n  font-weight: bold;\n  font-size: 1rem;\n  padding-bottom: 10px;\n  border-bottom: 3px solid var(--color-light-blue);\n  margin-bottom: 40px;\n}\n\n.Editor_box__1Ork6 {\n}\n\n.Editor_buttonsNav__3eXe6 {\n  text-transform: none !important;\n}\n\n.Editor_textNav__1HvSg {\n  font-family: Poppins, serif !important;\n  font-size: 1.08rem;\n  margin-left: 0;\n}\n\n.Editor_textField__eRsRM {\n  margin-left: 30px;\n  width: 100%;\n}\n\n.Editor_checkBox__393lg {\n  margin-left: 20px;\n  width: 100%;\n}\n\n.Editor_titleLabel__1_FQ8 {\n  margin-right: 85px;\n}\n\n.Editor_descriptionLabel__2npG6 {\n  margin-right: 33px;\n}\n\n.Editor_separator__1xfw5 {\n  margin: 0 10px 0;\n}\n\nb {\n  padding-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://src/features/premsProms/Editor.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,eAAe;EACf,oBAAoB;EACpB,gDAAgD;EAChD,mBAAmB;AACrB;;AAEA;AACA;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".header {\n  margin-top: 0;\n  font-weight: bold;\n  font-size: 1rem;\n  padding-bottom: 10px;\n  border-bottom: 3px solid var(--color-light-blue);\n  margin-bottom: 40px;\n}\n\n.box {\n}\n\n.buttonsNav {\n  text-transform: none !important;\n}\n\n.textNav {\n  font-family: Poppins, serif !important;\n  font-size: 1.08rem;\n  margin-left: 0;\n}\n\n.textField {\n  margin-left: 30px;\n  width: 100%;\n}\n\n.checkBox {\n  margin-left: 20px;\n  width: 100%;\n}\n\n.titleLabel {\n  margin-right: 85px;\n}\n\n.descriptionLabel {\n  margin-right: 33px;\n}\n\n.separator {\n  margin: 0 10px 0;\n}\n\nb {\n  padding-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Editor_header__2uV5g",
	"box": "Editor_box__1Ork6",
	"buttonsNav": "Editor_buttonsNav__3eXe6",
	"textNav": "Editor_textNav__1HvSg",
	"textField": "Editor_textField__eRsRM",
	"checkBox": "Editor_checkBox__393lg",
	"titleLabel": "Editor_titleLabel__1_FQ8",
	"descriptionLabel": "Editor_descriptionLabel__2npG6",
	"separator": "Editor_separator__1xfw5"
};
export default ___CSS_LOADER_EXPORT___;
