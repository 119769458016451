import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ARTICLES } from '../../routes/routes-constants'
import { Article } from '../../modules/content/models/Article'
import { emptyLikesDTO } from '../../modules/content/models/LikesDTO'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { Query, QueryParam } from '../../common/api/Query'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY, LIKES_SERVICE_KEY } from '../../modules/content'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Card, Typography, Table, TableFooter, TableRow, Modal } from '@mui/material'
import { ArticleStatus } from 'modules/content/enums/ArticleStatus'
import style from './Search.module.css'
import { RichText } from 'components/text-editor/RichText'
import { getFileContainer } from 'container/file-module'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { viewImageFile } from 'common/utils/file'
import profile from '../../assets/esfera/nav-profile-icons/config-profile-icon.svg'
import commentIcon from '../../assets/superior_menu/chat.svg'
import esferaLogo from '../../assets/brand_logo/smsBlue.svg'
import like from '../../assets/buttons/Like.svg'
import { Pagination } from '../../components/table_AP/Pagination'
import { Pager } from '../../components/table_type/types'
import { LikesService } from '../../modules/content/services/LikesService'
import { CommentsService } from '../../modules/content/services/CommentsService'
import { COMMENTS_SERVICE_KEY, VISUALIZATIONS_SERVICE_KEY } from '../../modules/content/container'
import { CommentsModal } from './CommentsModal'
import { VisualizationService } from '../../modules/content/services/VisualizationService'

const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const visualizationsService = contentContainer.get<VisualizationService>(VISUALIZATIONS_SERVICE_KEY)
const likesService = contentContainer.get<LikesService>(LIKES_SERVICE_KEY)
const commentsService = contentContainer.get<CommentsService>(COMMENTS_SERVICE_KEY)
const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

type ArticleProps = {
  tagIDList: string[]
  circles: number[]
}

const imageList = new Map()
const authorList = new Map()
const likesList = new Map()
const commentsList = new Map()

export function TableGrid(props: ArticleProps) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [articles, setArticles] = useState<ItemList<Article>>(emptyList<Article>())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [articlesInit, setInitArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [articleSelected, setArticle] = useState<Article>()
  const [openModalComments, setOpenModalComments] = useState<boolean>(false)
  const [articlesPerPage, setArticlesPerPage] = useState<number>(50)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
  }, [props.tagIDList])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: articlesPerPage,
      handleChangeRowsPerPage,
    })
  }, [isLoading, page, count, articlesPerPage])

  useEffect(() => {
    if (props.circles.length === 0) {
      return
    }
    const articleList = emptyList<Article>()
    const articlesQuery = [
      new QueryParam<Article>('status', [ArticleStatus[ArticleStatus.publicado]]),
      new QueryParam<Article>('type', 'article'),
      new QueryParam<Article>('categories', props.circles),
    ]

    if (props.tagIDList.length > 0) {
      articlesQuery.push(new QueryParam<Article>('tagIDs', props.tagIDList))
    }
    new Promise((resolve) => {
      articlesService
        .getFilteredList(
          new Query({
            query: articlesQuery,
            pager: { offset: page * articlesPerPage, limit: articlesPerPage },
          })
        )
        .subscribe((res) => {
          setIsLoading(false)
          res.items = res.items.sort((a, b) => {
            return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          })
          articleList.count = 0
          for (let i = res.items.length - 1; i >= 0; i--) {
            const articleItem = res.items[i]

            articleList.items.push(articleItem)
            articleList.count++

            likesService.getLikesByArticleID(articleItem.id).subscribe((response) => {
              likesList.set(articleItem.id, response.count)
            })

            commentsService.getCommentsByArticleID(articleItem.id).subscribe((response) => {
              commentsList.set(articleItem.id, response)
            })

            // visualizationsService.getVisualizationsByArticleID(articleItem.id).subscribe((response) => {
            // })

            if (articleItem.slug !== 'slug') {
              filesService.downloadByID(articleItem.slug).subscribe(async (res) => {
                if (res) {
                  const image = await viewImageFile(res)
                  imageList.set(articleItem.slug, image)
                }
                if (i === 0) {
                  resolve(true)
                }
              })
            } else {
              if (i === 0) {
                resolve(true)
              }
            }
          }
          if (res.items.length === 0) {
            resolve(true)
          }
        })
    }).then(() => {
      setArticles(articleList)
      setInitArticles(articleList)
      setCount(articleList.count)
    })
  }, [props.circles, props.tagIDList, isLoading])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setArticlesPerPage(10)
      return
    }
    setArticlesPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(true)
  }, [page, count, articlesPerPage])

  const handleSee = (event: any, article: Article) => {
    if (
      !event.target.className.includes('like_button') &&
      !event.target.className.includes('comment_button')
    ) {
      navigate(`${ROUTE_ARTICLES}/${article.id}`)
    }
  }

  const handleLike = (article: Article) => {
    const request = emptyLikesDTO()
    setIsLoading(false)
    const logged = JSON.parse(localStorage.getItem('logged user') ?? 'null')
    request.userID = logged.id
    request.articleID = article.id as string
    likesService.getCheckLikeExist(request.articleID, request.userID).subscribe((response) => {
      if (response.likeExists) {
        likesService.delete(response.likeID).subscribe((response) => {
          setIsLoading(true)
        })
      } else {
        likesService.add(request).subscribe((response) => {
          setIsLoading(true)
        })
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleComments = (article: Article) => {
    const request = emptyLikesDTO()
    request.userID = article.userId
    request.articleID = article.id as string
    likesService.getCheckLikeExist(request.articleID, request.userID).subscribe((response) => {
      if (response.likeExists) {
        likesService.delete(response.likeID)
      } else {
        likesService.add(request).subscribe((response) => {
          console.log(response)
        })
      }
    })
    // likesService.add(request).subscribe((response) => {
    //   console.log(response)
    // })
  }

  const handleCloseCommentsModal = () => {
    setOpenModalComments(false)
  }
  const handleOpenCommentsModal = (article: Article) => {
    setArticle(article)
    setOpenModalComments(true)
  }

  return (
    <>
      <Modal open={openModalComments} onClose={handleCloseCommentsModal}>
        <CommentsModal
          handleClose={handleCloseCommentsModal}
          // handleSave={uploadComment}
          comments={
            commentsList.get(articleSelected?.id) && commentsList.get(articleSelected?.id).items
          }
          articleTitle={articleSelected?.title}
          title={t('comments')}
          // author={loggedUser?.name}
        />
      </Modal>
      <Box>
        <Grid container spacing={2}>
          {articles.items.map((item) => {
            let content = <div></div>
            if (item && item.content && item.content.charAt(0) === '[') {
              content = <RichText content={item.content} edit={true} view={true} />
            }

            let articleDate = null
            if (typeof item.createdAt === 'string') {
              articleDate = new Date(item.createdAt)
            } else {
              articleDate = item.createdAt
            }
            const articleDateString = articleDate.toLocaleDateString()

            let articleImage = null

            if (item.slug !== 'slug') {
              articleImage = imageList.get(item.slug) ?? null
            }

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const articleAuthor = authorList.get(item.id)
            const articleLikes = likesList.get(item.id)
            const articleComments = commentsList.get(item.id)

            return (
              <Grid item xs={12} sm={6} md={3} key={item.id}>
                <Card
                  className={style.card}
                  sx={{ borderRadius: 5, borderColor: '#68b3e0' }}
                  variant="outlined"
                  onClick={(event) => handleSee(event, item)}
                >
                  <div>
                    <div className={style.imageContainer}>
                      <img
                        className={style.thumbnail}
                        src={articleImage === null ? esferaLogo : articleImage}
                      />

                      <div className={style.dateComments}>
                        {articleDateString}
                        <div
                          className={'comment_button ' + style.comments}
                          onClick={() => handleOpenCommentsModal(item)}
                        >
                          <img className={'comment_button'} src={commentIcon} />
                          {articleComments && articleComments.count}
                        </div>
                      </div>
                      <div
                        className={'like_button ' + style.likes}
                        onClick={() => handleLike(item)}
                      >
                        <img className={style.like} src={like} />
                        &nbsp;
                        {articleLikes}
                      </div>
                    </div>

                    <Typography variant="h6" color="primary" className={style.title}>
                      {item.title}
                    </Typography>

                    {content}
                    <div className={style.creator}>
                      <img src={profile} />
                      {item.authorName}
                    </div>
                  </div>
                </Card>
              </Grid>
            )
          })}
        </Grid>
        <Table>
          <TableFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TableRow sx={{ borderBottom: 0 }}>{pager && <Pagination {...pager} />}</TableRow>
          </TableFooter>
        </Table>
      </Box>
    </>
  )
}
