// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Table_headerBtns__19AzQ {\n  justify-content: space-between;\n}\n\n.Table_actionBtns__3cYkV {\n  justify-content: flex-start;\n}\n\n.Table_selectTypeBox__OwV8D,\n.Table_headerBtns__19AzQ,\n.Table_actionBtns__3cYkV {\n  display: flex;\n  grid-gap: 1rem;\n  gap: 1rem;\n}\n\n.Table_radioGroup__1fZct {\n  flex-flow: row wrap !important;\n}\n", "",{"version":3,"sources":["webpack://src/features/patient-data/dentists/Table.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;;;EAGE,aAAa;EACb,cAAS;EAAT,SAAS;AACX;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".headerBtns {\n  justify-content: space-between;\n}\n\n.actionBtns {\n  justify-content: flex-start;\n}\n\n.selectTypeBox,\n.headerBtns,\n.actionBtns {\n  display: flex;\n  gap: 1rem;\n}\n\n.radioGroup {\n  flex-flow: row wrap !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerBtns": "Table_headerBtns__19AzQ",
	"actionBtns": "Table_actionBtns__3cYkV",
	"selectTypeBox": "Table_selectTypeBox__OwV8D",
	"radioGroup": "Table_radioGroup__1fZct"
};
export default ___CSS_LOADER_EXPORT___;
