import { Box } from '@mui/material'
import { Header } from '../../components/header/Header'
import close from '../../assets/table_icons/ico-eliminar.svg'
import style from './YearView.module.css'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import vaccineIcon from '../../assets/calendar/vaccine.svg'

export interface YearMobileViewProps {
  id: string
  title: string
  description: string
  year: number
}

type YearMobileModalProps = {
  handlerClose: () => void
  events?: YearMobileViewProps[]
}

export const YearMobileModal = (props: YearMobileModalProps) => {
  const { t } = useTranslation()

  const currentYear = new Date().getFullYear()

  if (!props.events || props.events.length === 0) return null
  const firstEventYear = props.events[0].year
  const eventClass =
    firstEventYear > currentYear ? style.listContainerRed : style.listContainerGreen

  return (
    <>
      <Box className={style.mobileModalContainer}>
        <Box className={style.header}>
          <Header label={t('vaccines')} icon={close} onClick={props.handlerClose} />
        </Box>
        <Box className={style.contentContainer}>
          <Box className={style.mobileScrollContainer}>
            <img src={vaccineIcon} alt={t('vaccine')} />

            <p>
              <strong>
                {t('vaccine')} {firstEventYear}
              </strong>
            </p>

            <Box className={eventClass}>
              {props.events?.map((ev, index) => (
                <Box key={index}>
                  <p>{ev.title.toUpperCase()}</p>
                </Box>
              ))}
            </Box>

            <AppButton
              theme={ButtonTheme.NewPrimaryResponsive}
              type={'button'}
              label={t('cancel')}
              handler={props.handlerClose}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
