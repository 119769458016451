import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { SportCenter, SportCenterQuery } from '../models/SportCenter'
import { SportCenterDTO } from '../models/SportCenterDTO'
import { ISportCenterApi } from '../api/SportCenterApi'

type Props = {
  apiKey: symbol
}

export interface ISportCenterService extends IInit {
  getByID(id: string): Observable<SportCenter | undefined>

  getFilteredList(q: Query<SportCenterQuery>, userId: string): Observable<ItemList<SportCenter>>

  add(e: SportCenter): Observable<SportCenter | undefined>

  update(e: SportCenterDTO): Observable<SportCenter | undefined>

  delete(id: string): Observable<boolean>

  getByUserCircleID(q: Query<SportCenterQuery>, userId: string): Observable<ItemList<SportCenter>>
}

export class SportCenterService implements ISportCenterService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ISportCenterApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<ISportCenterApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: SportCenterDTO): Observable<SportCenter | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<SportCenter | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<SportCenterQuery>): Observable<ItemList<SportCenter>> {
    return this._api.getFilteredList(q)
  }

  update(e: SportCenterDTO): Observable<SportCenter | undefined> {
    return this._api.update(e)
  }

  getByUserCircleID(q: Query<SportCenterQuery>): Observable<ItemList<SportCenter>> {
    return this._api.getByUserCircleID(q)
  }
}
