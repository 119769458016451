import React, { useEffect, useState } from 'react'
import { Searcher } from '../../../../components/table/Searcher'
import { useTranslation } from 'react-i18next'
import { Box, Divider } from '@mui/material'
import genericStyle from '../../../../common/utils/generic.module.css'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import style from '../AddNewCircle.module.css'
import { Header } from '../../../../components/header/Header'
import addButton from '../../../../assets/esfera/buttons/add-icon.svg'
import { steps } from '../Steps'
import CustomStepper from '../CustomStepper'
import ModalAddNewResource from './modalAddResource/ModalAddNewResource'
import ModalResourceOtherCircles from './modalAddResourceFromOtherCircles/ModalResourceOtherCircles'
import {
  ROUTE_ADD_NEW_CIRCLE_CIRCLE_CONFIGURATOR,
  ROUTE_ADD_NEW_CIRCLE_FORM_CONFIGURATOR,
  ROUTE_CIRCLE_CONFIGURATOR,
} from '../../../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import ModalAddDirectory from './modalAddDirectory/ModalAddDirectory'
import { getFileContainer } from '../../../../container/file-module'
import { FILE_SERVICE_KEY, IFileService } from '../../../../modules/files'
import { Query, QueryParam } from '../../../../common/api/Query'
import { File, FileQuery } from '../../../../modules/files/models/File'
import { Directory, DirectoryResource } from './Directory'
import { NewResource, Resource } from './Resource'
import { CheckboxAssignType } from '../../../../modules/forms/enums/CheckboxAssignType'
import { UserQuery } from '../../../../modules/users/models/User'
import { SearchValue, Search } from '../../../../components/table_type/types'
import { DeletingModal } from '../../../../components/modal/DeletingModal'
import { CircleDTO, emptyCircleDTO } from '../../../../modules/circle/models/CircleDTO'
import { getCircleContainer } from '../../../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../../../modules/circle'

const resourcesService = getFileContainer().get<IFileService>(FILE_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

export function AddNewCircleResourcesConfigurator() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isModalAddNewResourceOpen, setModalAddNewResourceOpen] = useState<{
    open: boolean
    resourceID: string | undefined
  }>({ open: false, resourceID: undefined })
  const [isModalAddDirectoryOpen, setModalAddDirectoryOpen] = useState<{
    open: boolean
    directoryID: string | undefined
  }>({ open: false, directoryID: undefined })
  const [isModalResourceOtherCirclesOpen, setModalResourceOtherCirclesOpen] =
    useState<boolean>(false)
  // get circleDraftId from local storage
  const circleDraftId = localStorage.getItem('circleDraftID')
  const [draftDirectories, setDraftDirectories] = useState<Directory[]>()
  const [draftResources, setDraftResources] = useState<Resource[]>()
  const [allFiles, setAllFiles] = useState<File[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filteredFiles, setFilteredFiles] = useState<File[]>()
  const [circleDraft, setCircleDraft] = useState<CircleDTO>(emptyCircleDTO())
  const [directoriesAux, setDirectoriesAux] = useState<Map<string, Directory>>(
    new Map<string, Directory>()
  )
  const [modalDeleting, setModalDeleting] = useState<{
    opened: boolean
    type: string
    id: string
    isDirectory: boolean
    index: number
    directoryParentID?: string
  }>({ opened: false, type: '', id: '', isDirectory: false, index: 0 })

  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'allFields',
      label: t(''),
      value: '',
    },
  ])

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => {
      setSearcher(svs) /*
      setIsLoading(true) */
    },
  }

  useEffect(() => {
    if (!circleDraftId || circleDraft.name) return

    circleService.getByCircleDraftID(circleDraftId).subscribe((circle) => {
      if (!circle) {
        return
      }
      setCircleDraft(circle)
    })
  }, [circleDraftId])

  useEffect(() => {
    if (!circleDraft || !circleDraft.name) return
    resourcesService
      .getFilteredItems(
        new Query<FileQuery>({
          query: [
            new QueryParam<FileQuery>('circles', circleDraft.name),
            new QueryParam<FileQuery>('fillWithCreatorName', 'true'),
          ],
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((files) => {
        setAllFiles(files.items)
        setIsLoading(true)
        setFilteredFiles(files.items)
      })
  }, [
    isModalAddDirectoryOpen.open,
    isModalAddNewResourceOpen.open,
    isModalResourceOtherCirclesOpen,
    isLoading,
    circleDraft.name,
  ])

  useEffect(() => {
    const directories: Map<string, Directory> = new Map<string, Directory>()
    const resources: Resource[] = []
    if (!filteredFiles) return

    filteredFiles.forEach((file) => {
      if (file.isDir) {
        // get assignations names
        const checkBoxesSelected = file.checkBoxesSelected?.split(',')

        directories.set(file.id ?? '', {
          dataDirectory: {
            id: file.id ?? '',
            title: file.name,
            assigned:
              checkBoxesSelected
                ?.map((checkBox) => t(CheckboxAssignType[parseInt(checkBox)]))
                .join(', ') ?? '',
            author: file.creator,
          },
          resources: [],
        })
      }
    })
    // associate resources to directories
    filteredFiles.forEach((file) => {
      if (file.isDir) return
      if (!file.parent) {
        // case file without directory (root)
        resources.push({
          id: file.id ?? '',
          title: file.name,
          assigned: file.checkBoxesSelected ?? '',
          author: file.creator,
        })
      }
      directories.get(file.parent ?? '')?.resources.push({
        id: file.id ?? '',
        title: file.name,
        assigned: file.checkBoxesSelected ?? '',
        author: file.creator,
      })
    })

    setDirectoriesAux(directories)

    const sortedDirectories = Array.from(directories.values()).sort((a, b) => {
      return a.dataDirectory.title.localeCompare(b.dataDirectory.title, undefined, {
        sensitivity: 'base',
      })
    })

    const sortedResources = resources.sort((a, b) => {
      return a.title.localeCompare(b.title, undefined, { sensitivity: 'base' })
    })

    setDraftDirectories(sortedDirectories)
    setDraftResources(sortedResources)
    setIsLoading(true)
  }, [isLoading, allFiles, searcher, filteredFiles])

  useEffect(() => {
    if (!allFiles || !draftDirectories) return
    if (searcher && !searcher[0].value) {
      setFilteredFiles(allFiles)
    }
    const filterFiles = allFiles.filter((file) => {
      return (
        file.name.toLowerCase().includes((searcher[0]?.value ?? '').toLowerCase()) ||
        directoriesAux
          .get(file.id as string)
          ?.resources.some((resource) =>
            resource.title.toLowerCase().includes((searcher[0]?.value ?? '').toLowerCase())
          )
      )
    })
    setFilteredFiles(filterFiles)
    setIsLoading(true)
  }, [searcher, isLoading])

  const handleDeleteResource = (
    resourceID: string,
    index?: number | undefined,
    idDirectory?: string | undefined
  ) => {
    // find resource-patientView

    const file: File | undefined = allFiles?.find((item) => item.id === resourceID)
    if (!file) return

    if (!idDirectory) {
      // delete resource-patientView
      resourcesService.delete(file).subscribe(() => {
        // update state
        setDraftResources((oldDraftResources) => {
          if (!oldDraftResources) return
          const newDraftResources = [...oldDraftResources]

          if (index !== undefined) {
            newDraftResources.splice(index, 1)
            setIsLoading(true)
            return newDraftResources
          }
        })
      })
      setIsLoading(true)
      setModalDeleting({ opened: false, type: '', id: '', isDirectory: false, index: 0 })
      return
    }

    const newDirectories = draftDirectories?.map((directory) => {
      if (index === undefined) return directory

      if (directory.dataDirectory.id === idDirectory) {
        directory.resources.splice(index, 1)
      }
      return directory
    })

    setDraftDirectories(newDirectories)
    setIsLoading(true)
    resourcesService.delete(file).subscribe(() => {
      setModalDeleting({ opened: false, type: '', id: '', isDirectory: false, index: 0 })
    })
  }

  const handleDeleteDirectory = (directoryID: string, index: number) => {
    const file: File | undefined = allFiles?.find((item) => item.id === directoryID)
    if (!file) return

    const newDirectories = draftDirectories?.filter(
      (directory) => directory.dataDirectory.id !== directoryID
    )
    setDraftDirectories(newDirectories)
    setIsLoading(true)
    resourcesService.delete(file)
    setModalDeleting({ opened: false, type: '', id: '', isDirectory: false, index: 0 })
  }

  const handleEditResource = (id: string) => {
    setModalAddNewResourceOpen({ open: true, resourceID: id })
    setIsLoading(true)
  }

  const goPreviousStep = () => navigate(ROUTE_ADD_NEW_CIRCLE_CIRCLE_CONFIGURATOR)

  const goNextStep = () => navigate(ROUTE_ADD_NEW_CIRCLE_FORM_CONFIGURATOR)

  const saveDraft = () => navigate(ROUTE_CIRCLE_CONFIGURATOR)

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Header label={t('newCircle')} />
        <Box>
          <CustomStepper steps={steps} activeStep={2} />
        </Box>

        <Box>
          <Divider light style={{ backgroundColor: '#68b3e0', marginTop: 8, height: 4 }} />
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box display={'flex'} alignItems={'center'} gap={'0.5rem'}>
            <label> {t('search')}</label>
            <Searcher search={search} isPreventDefault={true} />
          </Box>

          <AppButton
            theme={ButtonTheme.newEvent}
            type={'button'}
            label={t('addNewDirectory')}
            marginStartIcon={{ marginRight: '10px' }}
            startIcon={addButton}
            handler={() => {
              setIsLoading(!isLoading)
              setModalAddDirectoryOpen({ open: true, directoryID: undefined })
            }}
            vertical={true}
          />
          <ModalAddDirectory
            open={isModalAddDirectoryOpen.open}
            directoryID={isModalAddDirectoryOpen.directoryID}
            onClose={() => {
              setIsLoading(!isLoading)
              setModalAddDirectoryOpen({
                open: !isModalAddDirectoryOpen.open,
                directoryID: undefined,
              })
            }}
          />

          <AppButton
            theme={ButtonTheme.newEvent}
            type={'button'}
            label={t('addNewResource')}
            marginStartIcon={{ marginRight: '10px' }}
            startIcon={addButton}
            handler={() => {
              setIsLoading(!isLoading)
              setModalAddNewResourceOpen({ open: true, resourceID: undefined })
            }}
            vertical={true}
          />
          <ModalAddNewResource
            open={isModalAddNewResourceOpen.open}
            resourceID={isModalAddNewResourceOpen.resourceID}
            onClose={() => {
              setIsLoading(!isLoading)
              setModalAddNewResourceOpen({ open: false, resourceID: undefined })
            }}
          />

          <AppButton
            theme={ButtonTheme.newEvent}
            type={'button'}
            label={t('resourceOtherCircles')}
            marginStartIcon={{ marginRight: '10px' }}
            startIcon={addButton}
            handler={() => {
              setIsLoading(!isLoading)
              setModalResourceOtherCirclesOpen(true)
            }}
            vertical={true}
          />
          <ModalResourceOtherCircles
            open={isModalResourceOtherCirclesOpen}
            onClose={() => {
              setIsLoading(!isLoading)
              setModalResourceOtherCirclesOpen(false)
            }}
          />
        </Box>

        <Box>
          <Box display="flex" justifyContent="space-between" padding="0 5%" marginBottom="1rem">
            <h4 style={{ flex: 1 }}>{t('title')}</h4>
            <h4 style={{ flex: 1 }}>{t('createdBy')}</h4>
            <h4 style={{ flex: 1 }}>{t('assigned')}</h4>
            <h4 className={style.titleLabel}>{t('Actions')}</h4>
          </Box>
          {draftDirectories?.map((directory, index) => (
            <DirectoryResource
              key={directory.dataDirectory.title}
              directory={directory}
              handleDeleteResource={(resourceID, directoryID, index) => {
                setModalDeleting({
                  opened: true,
                  type: t('resource'),
                  isDirectory: false,
                  id: resourceID,
                  index: index ?? 10000,
                  directoryParentID: directoryID,
                })
              }}
              handleEditResource={handleEditResource}
              handleDeleteDirectory={() =>
                setModalDeleting({
                  opened: true,
                  type: t('directory'),
                  isDirectory: true,
                  id: directory.dataDirectory.id,
                  index,
                })
              }
              handleEditDirectory={() =>
                setModalAddDirectoryOpen({ open: true, directoryID: directory.dataDirectory.id })
              }
            />
          ))}

          {draftResources?.map((resource, index) => (
            <NewResource
              key={resource.title}
              resource={resource}
              handleDeleteResource={() =>
                setModalDeleting({
                  opened: true,
                  type: t('resource'),
                  isDirectory: false,
                  id: resource.id,
                  index,
                })
              }
              handleEditResource={() =>
                setModalAddNewResourceOpen({ open: true, resourceID: resource.id })
              }
            />
          ))}
        </Box>

        <DeletingModal
          type={modalDeleting.type}
          open={modalDeleting.opened}
          handleClose={() =>
            setModalDeleting({ opened: false, type: '', id: '', isDirectory: false, index: 0 })
          }
          handleDelete={(id) => {
            if (modalDeleting.isDirectory) {
              handleDeleteDirectory(modalDeleting.id, modalDeleting.index)
            } else {
              if (modalDeleting.directoryParentID) {
                handleDeleteResource(
                  modalDeleting.id,
                  modalDeleting.index,
                  modalDeleting.directoryParentID
                )
              } else handleDeleteResource(modalDeleting.id, modalDeleting.index)
            }
          }}
        />

        <Box display="flex" justifyContent="space-between">
          <AppButton
            theme={ButtonTheme.whiteAndBlue}
            type={'button'}
            label={t('saveDraft')}
            marginStartIcon={{ marginRight: '10px' }}
            handler={saveDraft}
            vertical={true}
          />
          <Box display="flex">
            <Box style={{ marginRight: '20px' }}>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('backOneToResources')}
                marginStartIcon={{ marginRight: '10px' }}
                handler={goPreviousStep}
                vertical={true}
              />
            </Box>
            <Box>
              <AppButton
                theme={ButtonTheme.newEvent}
                type={'button'}
                label={t('saveAndNext')}
                marginStartIcon={{ marginRight: '10px' }}
                handler={goNextStep}
                vertical={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
