import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { ActivePlan, ActivePlanQuery } from '../models/ActivePlan'
import {
  ActivePlanCustomDTO,
  ActivePlanDTO,
  toCustomModel,
  toModelArray,
} from '../models/ActivePlanDTO'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { ActivePlanContainerConfig } from '../container'
import { toModel } from '../models/ActivePlanDTO'
import { toModel as toModelWithUser } from '../models/ActivePlanWithUserDTO'
import { NumberPatientsByBuilding } from '../models/NumberPatientsByBuilding'
import { DashBoard } from '../models/DashBoard'
import { DashBoardDTO, toDashBoardModel } from '../models/DashBoardDTO'
import { ActivePlanWithUser } from '../models/ActivePlanWithUser'
import { ActivePlanWithUserDTO } from '../models/ActivePlanWithUserDTO'
import { ActivePlanCustom } from '../models/ActivePlanCustom'
import { File } from '../../files/models/File'
import { FileDTO, toModel as toFileModel } from '../../files/models/FileDTO'

export interface IActivePlanApi extends IInit {
  getByID(id: string): Observable<ActivePlan | undefined>

  getFilteredList(q: Query<ActivePlanQuery>): Observable<ItemList<ActivePlan | ActivePlanWithUser>>

  add(e: ActivePlanDTO): Observable<ActivePlan | undefined>

  update(e: ActivePlanDTO): Observable<ActivePlan | undefined>

  delete(id: string): Observable<boolean>

  getByUserCircleID(userCircleID: string): Observable<ActivePlan[] | undefined>

  getNumberOfPatientsByBuilding(): Observable<NumberPatientsByBuilding[] | undefined>

  patientNumberList(q: Query<ActivePlanQuery>): Observable<DashBoard | undefined>

  getUserNumberByActivePlan(): Observable<number[]>

  getDataForManagerTable(q: Query<ActivePlanQuery>): Observable<ItemList<ActivePlanCustom>>

  getExcelTable(): Observable<File | undefined>
}

export class ActivePlanApi implements IActivePlanApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ActivePlanContainerConfig).moduleFullUrl + '/activePlan'
  }

  add(e: ActivePlanDTO): Observable<ActivePlan | undefined> {
    return this._httpClient.post<ActivePlan>({ url: this._url, body: e }).pipe(
      map<ActivePlanDTO, ActivePlan>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<ActivePlan | undefined> {
    return this._httpClient.get<ActivePlan>({ url: `${this._url}/${id}` }).pipe(
      map<ActivePlanDTO, ActivePlan>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(
    q: Query<ActivePlanQuery>
  ): Observable<ItemList<ActivePlan | ActivePlanWithUser>> {
    return this._httpClient
      .get<ItemList<ActivePlan | ActivePlanWithUser>>({ url: prepareURL(`${this._url}`, q) })
      .pipe(
        map<
          ItemList<ActivePlanDTO | ActivePlanWithUserDTO>,
          ItemList<ActivePlan | ActivePlanWithUser>
        >((dto) => {
          const itemList = emptyList<ActivePlan | ActivePlanWithUser>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => {
            if ('user' in d && d.user) {
              return toModelWithUser(d)
            } else {
              return toModel(d)
            }
          })
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<ActivePlan>())
        })
      )
  }

  update(e: ActivePlanDTO): Observable<ActivePlan | undefined> {
    return this._httpClient.put<ActivePlan>({ url: this._url, body: e }).pipe(
      map<ActivePlanDTO, ActivePlan>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByUserCircleID(userCircleID: string): Observable<ActivePlan[] | undefined> {
    return this._httpClient
      .get<ActivePlan[]>({ url: `${this._url}/byUserCircleID/${userCircleID}` })
      .pipe(
        map<ActivePlanDTO[], ActivePlan[]>((d) => toModelArray(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getNumberOfPatientsByBuilding(): Observable<NumberPatientsByBuilding[] | undefined> {
    return this._httpClient
      .get<NumberPatientsByBuilding[]>({ url: `${this._url}/numberOfPatientsByBuildingID` })
      .pipe(
        map<NumberPatientsByBuilding[], NumberPatientsByBuilding[]>((d) => d),
        catchError((err) => {
          console.log('error', err)
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  patientNumberList(q: Query<ActivePlanQuery>): Observable<DashBoard | undefined> {
    return this._httpClient
      .get<DashBoard>({ url: prepareURL(`${this._url}/patientNumberList`, q) })
      .pipe(
        map<DashBoardDTO, DashBoard>((d) => toDashBoardModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getUserNumberByActivePlan(): Observable<number[]> {
    return this._httpClient.get<number[]>({ url: `${this._url}/getUserNumberByActivePlan` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  getDataForManagerTable(q: Query<ActivePlanQuery>): Observable<ItemList<ActivePlanCustom>> {
    return this._httpClient
      .get<
        ItemList<ActivePlanCustom>
      >({ url: prepareURL(`${this._url}/getDataForManagerTable`, q) })
      .pipe(
        map<ItemList<ActivePlanCustomDTO>, ItemList<ActivePlanCustom>>((dto) => {
          const itemList = emptyList<ActivePlanCustom>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toCustomModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<ActivePlanCustom>())
        })
      )
  }

  getExcelTable(): Observable<File | undefined> {
    return this._httpClient.get<File>({ url: `${this._url}/getExcelTable` }).pipe(
      map<FileDTO, File>((d) => toFileModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
