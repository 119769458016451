// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableCircles_backButton__9hB5p {\n  margin-bottom: 10px;\n  display: inline-block;\n}\n\n.TableCircles_selectButton__3Buhr {\n  margin-bottom: 10px;\n  margin-left: 10px;\n  display: inline-block;\n}\n\n.TableCircles_selectBox__SlW6C {\n  border: 1px solid rgba(224, 224, 224, 1);\n}\n\n.TableCircles_collapse__3cy_s {\n  cursor: pointer;\n  vertical-align: middle;\n}\n\n.TableCircles_nameLabel__3J8yh {\n  margin-bottom: 4px;\n  padding-left: 6px;\n  display: inline;\n}\n\n.TableCircles_subRow__3S6vc {\n  background-color: #f4f6f7;\n}\n", "",{"version":3,"sources":["webpack://src/features/premsProms/TableCircles.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".backButton {\n  margin-bottom: 10px;\n  display: inline-block;\n}\n\n.selectButton {\n  margin-bottom: 10px;\n  margin-left: 10px;\n  display: inline-block;\n}\n\n.selectBox {\n  border: 1px solid rgba(224, 224, 224, 1);\n}\n\n.collapse {\n  cursor: pointer;\n  vertical-align: middle;\n}\n\n.nameLabel {\n  margin-bottom: 4px;\n  padding-left: 6px;\n  display: inline;\n}\n\n.subRow {\n  background-color: #f4f6f7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": "TableCircles_backButton__9hB5p",
	"selectButton": "TableCircles_selectButton__3Buhr",
	"selectBox": "TableCircles_selectBox__SlW6C",
	"collapse": "TableCircles_collapse__3cy_s",
	"nameLabel": "TableCircles_nameLabel__3J8yh",
	"subRow": "TableCircles_subRow__3S6vc"
};
export default ___CSS_LOADER_EXPORT___;
