import { Box, MenuItem, Modal, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import style from './StatusCircleModal.module.scss'
import iconClose from '../../../../assets/dashboard/closeIcon.svg'
import filterIcon from '../../../../assets/dashboard/filterIcon.svg'
import { Header } from '../../../../components/header/Header'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam, QueryParamN } from '../../../../common/api/Query'
import { getActivePlanContainer } from '../../../../container/active-plan-module'
import { ActivePlanService } from '../../../../modules/active-plan/services/ActivePlanService'
import { ACTIVE_PLAN_SERVICE_KEY } from '../../../../modules/active-plan'
import { AppTable } from '../../../../components/table'
import { Field, Pager, Search, SearchValue } from '../../../../components/table_type/types'
import { ActivePlanQuery } from '../../../../modules/active-plan/models/ActivePlan'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import tick from '../../../../assets/calendar/tick.svg'
import { ActivePlanStatusType } from '../../../../common/enums/activePlan'
import { ActivePlanCustom } from '../../../../modules/active-plan/models/ActivePlanCustom'
import downloadFileIcon from '../../../../assets/table_icons/ico-download.svg'
import { downloadFile } from '../../../../common/utils/file'

type StatusCircleModalProps = {
  open: boolean
  onClose: () => void
}

type statusAPID = {
  id: string
  status: string
}

const activePlanService = getActivePlanContainer().get<ActivePlanService>(ACTIVE_PLAN_SERVICE_KEY)

export const StatusCircleModal: React.FC<StatusCircleModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState<ActivePlanStatusType[]>([
    ActivePlanStatusType.Active,
    ActivePlanStatusType.Pending,
    ActivePlanStatusType.Refused,
    ActivePlanStatusType.Finished,
  ])
  const status: ActivePlanStatusType[] = [
    ActivePlanStatusType.Active,
    ActivePlanStatusType.Pending,
    ActivePlanStatusType.Refused,
    ActivePlanStatusType.Finished,
  ]
  const [searcher, setSearcher] = useState<SearchValue<ActivePlanQuery>[]>([
    {
      name: 'customSearcher',
      label: t('search') + '...',
      value: '',
    },
  ])
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [apCustom, setAPCustom] = useState<ActivePlanCustom[]>([])
  const [currenStatus, setCurrentStatus] = useState<statusAPID[]>([])
  const optionStatus = [
    ActivePlanStatusType.Pending,
    ActivePlanStatusType.Active,
    ActivePlanStatusType.Refused,
    ActivePlanStatusType.Finished,
  ]

  useEffect(() => {
    if (filters.length === 0) {
      setAPCustom([])
      setCount(0)
      return
    }
    const subscription = activePlanService
      .getDataForManagerTable(
        new Query({
          query: [
            new QueryParam<ActivePlanQuery>('statusArray', filters),
            ...searcherQuery(searcher),
          ],
          sort: [{ field: 'id', desc: true }],
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
        })
      )
      .subscribe((res) => {
        if (!res) {
          setAPCustom([])
          setCount(0)
          return
        }
        setCurrentStatus(getCurrentStatus(res.items))
        setCount(res.count)
        setAPCustom(res.items)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [count, page, searcher, itemsPerPage, filters.length])

  const handlePaginationChange = (event: unknown, value: number) => {
    setPage(value)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
    setPage(0)
  }

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage, searcher, filters.length])

  const search: Search<ActivePlanQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<ActivePlanQuery>[]) => {
      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
    },
  }

  const fields: Field<ActivePlanCustom>[] = [
    {
      name: 'completeName',
      label: t('name'),
    },
    {
      name: 'cip',
      label: t('cip'),
    },
    {
      name: 'dni',
      label: t('dni'),
    },
    {
      name: 'protocol',
      label: t('protocol'),
    },
    {
      name: 'status',
      label: t('status'),
      renderFunc: (f, i) => t(i.status),
    },
    {
      name: 'centerName',
      label: t('sportCenter'),
    },
    {
      name: 'id',
      label: t('action'),
      renderFunc: (f, i) => {
        return (
          <>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Select
                labelId="demo-select-small-label"
                style={{ width: '160px' }}
                id="demo-select-small"
                value={currenStatus.find((value) => value.id === i.id)?.status}
                onChange={(event) => handlerChangeStatus(i.id, event.target.value)}
              >
                {optionStatus.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option === ActivePlanStatusType.Finished ? t('noActive') : t(option)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </>
        )
      },
    },
  ]
  console.log(currenStatus)

  const getFilter = (value: ActivePlanStatusType) => {
    return filters.includes(value)
      ? () => {
          setPage(0)
          setFilters(filters.filter((f) => f !== value))
        }
      : () => {
          setPage(0)
          setFilters([...filters, value])
        }
  }

  const filterComponent = () => {
    return (
      <>
        {status.map((value, index) => (
          <label
            key={index}
            className={
              value === ActivePlanStatusType.Active
                ? style.labelCheck1
                : value === ActivePlanStatusType.Pending
                  ? style.labelCheck2
                  : value === ActivePlanStatusType.Refused
                    ? style.labelCheck3
                    : style.labelCheck4
            }
          >
            <input
              type="checkbox"
              name="tratamientos"
              className={style.checkboxInput}
              onChange={getFilter(value)}
              checked={filters.includes(value)}
            />
            {value === ActivePlanStatusType.Finished ? t('noActive') : t(value)}
            {filters.includes(value) && (
              <img src={tick} alt="Icono de tick" className={style.checkboxImage} />
            )}
          </label>
        ))}
      </>
    )
  }

  const getExcelFile = () => {
    activePlanService.getExcelTable().subscribe((res) => {
      if (!res) return
      downloadFile(res.name, res.mimeType, res.data)
    })
  }

  const getCurrentStatus = (apArr: ActivePlanCustom[]): statusAPID[] => {
    const auxArr: statusAPID[] = []
    apArr.forEach((value) => {
      auxArr.push({ id: value.id, status: value.status })
    })
    return auxArr
  }

  const handlerChangeStatus = (id: string, status: string) => {
    const auxValueArr = [...currenStatus]
    let num = -1
    const auxValue = auxValueArr.find((value, index) => {
      num = index
      return value.id === id
    })
    if (auxValue && auxValue.status && num !== -1) {
      auxValueArr[num].status = status
      setCurrentStatus(auxValueArr)
    }
  }

  return (
    <Modal className={style.modalContainer} open={open} onClose={onClose}>
      <Box className={style.content}>
        <Box className={style.iconContainer}>
          <img className={style.icon} src={iconClose} alt={'icon close'} onClick={onClose} />
        </Box>
        <Header label={'circleStatus'} />
        <Box>
          <Box mt={4} className={style.filterContainer}>
            <Box display={'flex'}>
              <img className={style.iconFilter} src={filterIcon} alt={'filter icon'} />
              <Typography className={style.filterText}>{t('filters')}</Typography>
            </Box>
          </Box>
          <Box className={style.titleRowContainer}>
            <Box className={style.titleCheckContainer}>
              <Typography className={style.filterByCategoryText}>
                {t('filterByCategory')}:{' '}
              </Typography>
              {filterComponent()}
            </Box>
            <Box>
              <img
                className={style.iconDownload}
                src={downloadFileIcon}
                alt={'filter icon'}
                onClick={getExcelFile}
              />
            </Box>
          </Box>
          <Box>
            <AppTable
              fields={fields}
              search={search}
              pager={pager}
              isPreventDefault={true}
              items={apCustom}
              rowKeyField={'cip'}
              rowStyleFunc={(item) => {
                switch (item.status) {
                  case ActivePlanStatusType.Active:
                    return '1px solid #6CE32B'
                  case ActivePlanStatusType.Pending:
                    return '1px solid #F18B24'
                  case ActivePlanStatusType.Refused:
                    return '1px solid #CF3031'
                  case ActivePlanStatusType.Finished:
                    return '1px solid #B6B5B5'
                  default:
                    return '1px solid #DBDADA' // Estilo por defecto
                }
              }}
            />{' '}
            <Box className={style.buttonContainer}>
              <AppButton
                label={t('save')}
                theme={ButtonTheme.NewPrimary}
                handler={() => {}}
                type={'button'}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

const searcherQuery = (
  svs: SearchValue<ActivePlanQuery>[]
): QueryParam<ActivePlanQuery>[] | QueryParamN<ActivePlanQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
