// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Table_headerBtns__28jXY {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    grid-gap: 1rem;\n    gap: 1rem;\n}\n\n.Table_container__2Avys {\n    display: flex;\n    flex-direction: column;\n    grid-gap: 1rem;\n    gap: 1rem;\n}\n\n.Table_actionBtns__3Q6Sv {\n    display:none;\n}\n\n@media (max-width: 599px) {\n    .Table_actionBtns__3Q6Sv {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n        grid-gap: 1rem;\n        gap: 1rem;\n    }\n}", "",{"version":3,"sources":["webpack://src/features/patient-data/treatments/Table.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,cAAS;IAAT,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAS;IAAT,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;QACb,mBAAmB;QACnB,8BAA8B;QAC9B,cAAS;QAAT,SAAS;IACb;AACJ","sourcesContent":[".headerBtns {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    gap: 1rem;\n}\n\n.container {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n}\n\n.actionBtns {\n    display:none;\n}\n\n@media (max-width: 599px) {\n    .actionBtns {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n        gap: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerBtns": "Table_headerBtns__28jXY",
	"container": "Table_container__2Avys",
	"actionBtns": "Table_actionBtns__3Q6Sv"
};
export default ___CSS_LOADER_EXPORT___;
