import { UserForm, UserFormQuery } from '../models/UserForm'
import { Observable } from 'rxjs'
import { IUserFormApi } from '../api/UserFormApi'
import { Container, IInit } from '../../../common/container/Container'
import { ItemList } from '../../../common/models/ItemList'
import { IStatusService } from '../../../common/status/StatusService'
import { Query } from '../../../common/api/Query'
import { UserFormValue } from '../models/UserFormValue'
import { UserFormDTO, UserFormValueDTO } from '../models/UserFormDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UserFormWithCheckbox } from '../models/UserFormWithCheckbox'
import { UserFormWithCheckboxDTO } from '../models/UserFormWithCheckboxDTO'
import { PROMS_beckItems } from '../models/PROMS_beckItemsProps'

type Props = {
  apiKey: symbol
}

export interface IUserFormService extends IInit {
  getByID(id: string): Observable<UserForm | undefined>

  getFilteredList(q: Query<UserFormQuery>): Observable<ItemList<UserForm>>
  getFilteredListWithCustomOrder(q: Query<UserFormQuery>): Observable<ItemList<UserForm>>
  getByFormID(id: string): Observable<UserForm[] | undefined>

  add(e: UserForm): Observable<UserForm | undefined>
  addWithCheckBoxes(e: UserFormWithCheckboxDTO): Observable<UserFormWithCheckbox | undefined>
  addWithSubscribers(e: UserFormWithCheckboxDTO): Observable<UserFormWithCheckbox | undefined>

  update(e: UserFormDTO): Observable<UserForm | undefined>

  updateAssigned(e: UserFormDTO[]): Observable<UserForm[] | undefined>

  updateValue(e: UserFormValueDTO): Observable<UserFormValue | undefined>

  updateValuePerm(e: UserFormDTO): Observable<UserForm | undefined>

  delete(id: string): Observable<boolean>

  getPromsBeckItems(q: Query<UserFormQuery>): Observable<PROMS_beckItems[] | undefined>
}

export class UserFormService implements IUserFormService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserFormApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserFormApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: UserForm): Observable<UserForm | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<UserForm | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<UserFormQuery>): Observable<ItemList<UserForm>> {
    return this._api.getFilteredList(q).pipe()
  }

  getFilteredListWithCustomOrder(q: Query<UserFormQuery>): Observable<ItemList<UserForm>> {
    return this._api.getFilteredListWithCustomOrder(q).pipe()
  }

  update(e: UserFormDTO): Observable<UserForm | undefined> {
    return this._api.update(e)
  }

  updateAssigned(e: UserFormDTO[]): Observable<UserForm[] | undefined> {
    return this._api.updateAssigned(e)
  }

  updateValue(e: UserFormValueDTO): Observable<UserFormValue | undefined> {
    return this._api.updateValue(e)
  }

  updateValuePerm(e: UserFormDTO): Observable<UserForm | undefined> {
    return this._api.updateValuePerm(e)
  }

  getByFormID(id: string): Observable<UserForm[] | undefined> {
    return this._api.getByFormID(id)
  }

  addWithCheckBoxes(e: UserFormWithCheckboxDTO): Observable<UserFormWithCheckbox | undefined> {
    return this._api.addWithCheckBoxes(e)
  }

  addWithSubscribers(e: UserFormWithCheckboxDTO): Observable<UserFormWithCheckbox | undefined> {
    return this._api.addWithSubscribers(e)
  }

  getPromsBeckItems(q: Query<UserFormQuery>): Observable<PROMS_beckItems[] | undefined> {
    return this._api.getPromsBeckItems(q)
  }
}
