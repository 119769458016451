import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  TELECONSULTA_API_KEY,
  TELECONSULTA_MODULE,
  TELECONSULTA_SERVICE_KEY,
  TeleConsultaProps,
} from '../modules/teleconsultation/container'
import { TeleConsultaApi } from '../modules/teleconsultation/api/TeleConsultaApi'
import { TeleConsultaService } from '../modules/teleconsultation/services/TeleConsultaService'

let container: Container

function init(p: TeleConsultaProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(TELECONSULTA_API_KEY, new ContainerItem({ instance: new TeleConsultaApi() }))
  items.set(
    TELECONSULTA_SERVICE_KEY,
    new ContainerItem({ instance: new TeleConsultaService({ apiKey: TELECONSULTA_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getTeleConsultaContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[TELECONSULTA_MODULE],
      },
    })
  }

  return container
}
