import i18n from '../../../i18n'

export enum FormType {
  Template = 0,
  Form = 1,
  PROMS = 2,
  PREMS = 3,
}

export enum PROMS_PREMS {
  QOLIE10 = 'b3b3b3b3-b3b3-b3b3-b3b3-b3b3b3b3b3b3',
  BECKII = 'd1d1d1d1-d1d1-d1d1-d1d1-d1d1d1d1d1d1',
  PITTSBURGH = 'g4g4g4g4-g4g4-g4g4-g4g4-g4g4g4g4g4',
  LSS20 = 'f3f3f3f3-f3f3-f3f3-f3f3-f3f3f3f3f3f3',
  ADVERSE_EFFECTS = 'h5h5h5h5-h5h5-h5h5-h5h5-h5h5h5h5h5',
  APP_USER_SATISFACTION = 'i6i6i6i6-i6i6-i6i6-i6i6-i6i6i6i6i6',
  USER_SATISFACTION = 'j7j7j7j7-j7j7-j7j7-j7j7-j7j7j7j7j7',
  SEAL = 'e2e2e2e2-e2e2-e2e2-e2e2-e2e2e2e2e2e2',
}

export enum BECKII_ITEMS {}

export const formTypes = (): Record<FormType, string> => ({
  [FormType.Template]: i18n.t('template'),
  [FormType.Form]: i18n.t('form'),
  [FormType.PROMS]: i18n.t('proms'),
  [FormType.PREMS]: i18n.t('prems'),
})
