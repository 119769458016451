// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationCard_containerList__2Zfcr {\n  min-height: 400px;\n  cursor: pointer;\n}\n\n.NotificationCard_divider__17hqK {\n  margin-top: 10px !important;\n  background-color: #AFD7EF !important;\n  width: 100% !important;\n}\n\n.NotificationCard_listItemText__1Pa2L {\n  font-family: \"Poppins\", sans-serif !important;\n  text-transform: capitalize;\n}", "",{"version":3,"sources":["webpack://src/features/dashboard/profesional/notification-card/NotificationCard.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;AACF;;AAMA;EACI,2BAAA;EACF,oCAAA;EACA,sBAAA;AAHF;;AAMA;EACE,6CAAA;EACA,0BAAA;AAHF","sourcesContent":[".containerList{\n  min-height: 400px;\n  cursor: pointer;\n}\n\n.icon{\n\n}\n\n.divider{\n    margin-top: 10px !important;\n  background-color: #AFD7EF !important;\n  width: 100% !important\n}\n\n.listItemText{\n  font-family: 'Poppins', sans-serif !important;\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerList": "NotificationCard_containerList__2Zfcr",
	"divider": "NotificationCard_divider__17hqK",
	"listItemText": "NotificationCard_listItemText__1Pa2L"
};
export default ___CSS_LOADER_EXPORT___;
