// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Participant_icon__GPBA8 {\n    width: 15px;\n    height: 15px;\n    margin: 4px;\n}", "",{"version":3,"sources":["webpack://src/features/participant/Participant.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;AACf","sourcesContent":[".icon {\n    width: 15px;\n    height: 15px;\n    margin: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "Participant_icon__GPBA8"
};
export default ___CSS_LOADER_EXPORT___;
