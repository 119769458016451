import { useTranslation } from 'react-i18next'
import React from 'react'
import { ActivePlan } from '../../../modules/active-plan/models/ActivePlan'
import { TrainingGroup } from '../../../modules/training-group/models/TrainingGroup'
import { SportCenter } from '../../../modules/sport-center/models/SportCenter'
import { Box } from '@mui/material'
import style from './ActivePlan.module.css'
import Typography from '@mui/material/Typography'
import { fullWeekdayTypes } from '../../../common/enums/Weekday'
import { addCero } from '../../../common/utils/strings'
import { ROUTE_ARTICLES } from '../../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { ActivePlanStatusType } from '../../../common/enums/activePlan'

type GeneralDataProps = {
  activePlan: ActivePlan
  group: TrainingGroup
  sportCenter: SportCenter
  isUnsubscribe: boolean
  openGroupModal: () => void
}

export function GeneralData(props: GeneralDataProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const getGroupDays = () => {
    if (!props.group) return ''
    let value = ''
    props.group.timeTable.forEach((tt) => {
      if (value === '') {
        value = t(fullWeekdayTypes()[tt.weekDay])
      } else {
        value += ', ' + t(fullWeekdayTypes()[tt.weekDay])
      }
    })
    return value
  }

  const getTimeTable = () => {
    if (!props.group || props.group.timeTable.length === 0) return ''
    const startTime = new Date(props.group.timeTable[0].startTime)
    const finishTime = new Date(props.group.timeTable[0].finishTime)
    const value =
      'de ' +
      addCero(startTime.getHours()) +
      ':' +
      addCero(startTime.getMinutes()) +
      'h a ' +
      addCero(finishTime.getHours()) +
      ':' +
      addCero(finishTime.getMinutes()) +
      'h'
    return value
  }

  const getPeriod = () => {
    if (
      !props.activePlan ||
      (!props.isUnsubscribe && props.activePlan.status !== ActivePlanStatusType.Pending)
    )
      return ''
    const value =
      'del ' +
      addCero(props.activePlan.startDate.getDate()) +
      '/' +
      addCero(props.activePlan.startDate.getMonth()) +
      '/' +
      props.activePlan.startDate.getFullYear() +
      ' al ' +
      addCero(props.activePlan.finishDate.getDate()) +
      '/' +
      addCero(props.activePlan.finishDate.getMonth()) +
      '/' +
      props.activePlan.finishDate.getFullYear()
    return value
  }

  const getSportCenterName = () => {
    if (props.isUnsubscribe) {
      return props.activePlan.status === ActivePlanStatusType.Refused && props.sportCenter.name
    }
    return props.activePlan.status === ActivePlanStatusType.Pending && props.sportCenter.name
  }

  const getSportCenterAddress = () => {
    if (props.isUnsubscribe) {
      return props.activePlan.status === ActivePlanStatusType.Refused && props.sportCenter.location
    }
    return props.activePlan.status === ActivePlanStatusType.Pending && props.sportCenter.location
  }

  return (
    <Box className={style.containerMain}>
      <Box className={style.flexGrowThree}>
        <Box className={style.cardContainerBlue}>
          <Typography className={style.cardTitleBig}>{t('programData')}</Typography>
          <Box className={style.containerRow}>
            <Box className={style.blackBold}>{t('sportCenter')}: </Box>
            <Box className={style.black}>{getSportCenterName()}</Box>
          </Box>
          <Box className={style.containerRow}>
            <Box className={style.blackBold}>{t('address')}: </Box>
            <Box className={style.black}>{getSportCenterAddress()}</Box>
          </Box>
          <Box className={style.containerRow}>
            <Box className={style.blackBold}>{t('days')}: </Box>
            <Box className={style.black}>{getGroupDays()}</Box>
          </Box>
          <Box className={style.containerRow}>
            <Box className={style.blackBold}>{t('timeTable')}: </Box>
            <Box className={style.black}>{getTimeTable()}</Box>
          </Box>
          <Box className={style.containerRow}>
            <Box className={style.blackBold}>{t('period')}: </Box>
            <Box className={style.black}>{getPeriod()}</Box>
          </Box>
          <Box className={style.containerRowFinalDG}>
            <Box className={style.blackBold}>{t('fisicTrainer')}: </Box>
            <Box className={style.black}>{props.group.trainerName}</Box>
          </Box>
        </Box>
      </Box>
      <Box className={style.flexGrowTwo}>
        <Box className={style.containerRow}>
          <Box className={style.flexGrowTwoSub}>
            <Box className={style.containerColumn}>
              <Box className={style.containerRow}>
                {innerWidth > 599 ? (
                  <Box className={style.cardContainerBlue}>
                    <Typography
                      className={style.cardTitleBig}
                      onClick={() => {
                        navigate(ROUTE_ARTICLES + '/547a1966-a2d9-44be-9f65-60ecf8714690')
                      }}
                    >
                      {t('advices')}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    className={style.cardContainer}
                    onClick={() => {
                      navigate(ROUTE_ARTICLES + '/547a1966-a2d9-44be-9f65-60ecf8714690')
                    }}
                  >
                    {t('advices')}
                  </Box>
                )}
              </Box>
              <Box className={style.containerRowSub}>
                <Box
                  className={style.cardContainer}
                  onClick={() => {
                    navigate(ROUTE_ARTICLES + '/547a1966-a2d9-44be-9f65-60ecf8714691')
                  }}
                >
                  {t('userIndication')}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={style.flexGrowOne}>
            <Box className={style.containerColumnSub}>
              <Box className={style.containerRow}>
                <Box
                  className={style.cardContainer}
                  onClick={() => {
                    props.openGroupModal()
                  }}
                >
                  {t('timeTableSportCentre')}
                </Box>
              </Box>
              <Box className={style.containerRowSub}>
                <Box
                  className={style.cardContainer}
                  onClick={() => {
                    navigate(ROUTE_ARTICLES + '/547a1966-a2d9-44be-9f65-60ecf8714692')
                  }}
                >
                  {t('programCondition')}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
