import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { FormEvent } from 'react'
import style from './TeleconsultationForm.module.css'
import { Conversation } from '../../modules/messenger/models/Conversation'
import close from '../../assets/table_icons/ico-eliminar.svg'
import { Header } from '../../components/header/Header'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import styles from '../patient-data/symptoms/epilepticalCrisis/ModalNewCrisis.module.css'
import TextField from '@mui/material/TextField'

type ModalEpilepticCrisisProps = {
  conversation?: Conversation
  handleCloseEpilepticCrisisModal: () => void
  saveConversation: (e: FormEvent<HTMLFormElement>) => void
  message: string
  handleMessage: (me: string) => void
  handleObject: (obj: ObjectForm) => void
  object: ObjectForm
  errorMessage: string
}

interface ObjectForm {
  whatHappen: string[]
  currentTreatment: string
  timeWithoutCrisis: string
  symptom: string[]
  typeCrisis: string
  durationCrisis: string
  healthcare: string
  medicationAdministered: string
  timeWithoutAwareness: string
  nextAppointment: Date | null
  dontKnowAppointment: string
}

export const ModalTeleconsultationForm = (props: ModalEpilepticCrisisProps): JSX.Element => {
  const { t } = useTranslation()
  const [firstRadioButton, setFirstRadioButton] = React.useState<string>('Sí')
  const [secondRadioButton, setSecondRadioButton] = React.useState<string>('Sí')
  const [thirdQuestionText, setThirdQuestionText] = React.useState<string>('')
  const [thirdRadioButton, setThirdRadioButton] = React.useState<string>('Sí')
  const [fourthRadioButton, setFourthRadioButton] = React.useState<string>('Sí')
  const [textFourthQuestion, setTextFourthQuestion] = React.useState<string>('')
  const [textFifthQuestion, setTextFifthQuestion] = React.useState<string>('')

  const useStyles = () => ({
    label: {
      fontFamily: 'Poppins, sans-serif !important',
    },
  })

  const classes = useStyles()

  const handleSaveMessage = () => {
    let messageString = ''

    // Primera pregunta
    messageString += '<b>' + t('anyCrisesSinceYourLastVisit?') + ': </b>'
    messageString += firstRadioButton + '\n'

    // Segunda pregunta
    if (firstRadioButton === 'Sí') {
      messageString += '<b>' + t('haveYouCollectedInApplicationCalendar?') + ': </b>'
      messageString += secondRadioButton + '\n'
    }

    // Tercera pregunta
    messageString += '<b>' + t('haveYouHadAnyProblemsWithMedication?') + ': </b>'
    messageString += thirdRadioButton + '\n'

    // Cuarta pregunta
    messageString += '<b>' + t('haveYouHadBloodTest?') + ': </b>'
    if (fourthRadioButton === 'Sí') {
      messageString += 'Si, ' + textFourthQuestion + '\n'
    } else {
      messageString += fourthRadioButton + '\n'
    }

    // Quinta pregunta
    messageString +=
      '<b>' + t('doYouHaveQuestionsOrNeedToDiscussSomethingWithYourDoctor?') + ': </b>'
    messageString += textFifthQuestion + '\n'

    props.handleMessage(messageString)
  }

  return (
    <Box className={style.newConversationContainer}>
      <Box style={{ margin: window.innerWidth > 598 ? '8px' : '15px' }}>
        <Header
          label={t(props.conversation ? 'editConversation' : 'newQuery')}
          icon={close}
          onClick={props.handleCloseEpilepticCrisisModal}
        />
      </Box>
      <form
        onSubmit={props.saveConversation}
        style={{ width: window.innerWidth < 598 ? '400px ' : '' }}
      >
        <Box style={{ margin: window.innerWidth > 598 ? '18px' : '27px' }}>
          <h3 style={{ fontSize: '1.0rem', fontFamily: 'Poppins, sans-serif' }}>
            {t('anyCrisesSinceYourLastVisit?')}
          </h3>
          <RadioGroup
            style={{
              marginLeft: window.innerWidth > 598 ? '15px' : '4px',
              display: 'flex',
              flexDirection: 'column',
            }}
            className={styles.radioGroup}
            aria-label="gender"
            name="gender1"
            value={firstRadioButton}
            onChange={(e) => {
              setFirstRadioButton(e.target.value)
            }}
          >
            <FormControlLabel
              sx={{
                label: classes.label,
              }}
              value={t('Sí')}
              control={<Radio />}
              label={t('Sí')}
            />
            <FormControlLabel
              sx={{
                label: classes.label,
              }}
              value={t('No')}
              control={<Radio />}
              label={t('No')}
            />
          </RadioGroup>
        </Box>

        {firstRadioButton === 'Sí' && (
          <Box style={{ margin: window.innerWidth > 598 ? '18px' : '27px' }}>
            <h3 style={{ fontSize: '1.0rem', fontFamily: 'Poppins, sans-serif' }}>
              {t('haveYouCollectedInApplicationCalendar?')}
            </h3>
            <RadioGroup
              style={{
                marginLeft: window.innerWidth > 598 ? '15px' : '4px',
                display: 'flex',
                flexDirection: 'column',
              }}
              className={styles.radioGroup}
              aria-label="gender"
              name="gender1"
              value={secondRadioButton}
              onChange={(e) => {
                setSecondRadioButton(e.target.value)
              }}
            >
              <FormControlLabel
                sx={{
                  label: classes.label,
                }}
                value={t('Sí')}
                control={<Radio />}
                label={t('Sí')}
              />
              <FormControlLabel
                sx={{
                  label: classes.label,
                }}
                value={t('No')}
                control={<Radio />}
                label={t('No')}
              />
            </RadioGroup>
          </Box>
        )}

        <Box style={{ margin: window.innerWidth > 598 ? '18px' : '27px' }}>
          <h3 style={{ fontSize: '1.0rem', fontFamily: 'Poppins, sans-serif' }}>
            {t('whatMedicationAreYoucurrentlyOn?')}
          </h3>
          <TextField
            style={{ width: window.innerWidth < 598 ? '350px' : '500px' }}
            defaultValue={undefined}
            type="text"
            value={thirdQuestionText}
            InputProps={{
              inputProps: { min: 1 },
              sx: {
                input: classes.label,
              },
            }}
            onChange={(e) => {
              setThirdQuestionText(e.target.value)
            }}
            id="outlined-basic"
            variant="outlined"
            size={'medium'}
          />
        </Box>

        <Box style={{ margin: window.innerWidth > 598 ? '18px' : '27px' }}>
          <h3 style={{ fontSize: '1.0rem', fontFamily: 'Poppins, sans-serif' }}>
            {t('haveYouHadAnyProblemsWithMedication?')}
          </h3>
          <RadioGroup
            style={{
              marginLeft: window.innerWidth > 598 ? '15px' : '4px',
              display: 'flex',
              flexDirection: 'column',
            }}
            className={styles.radioGroup}
            aria-label="gender"
            name="gender1"
            value={thirdRadioButton}
            onChange={(e) => {
              setThirdRadioButton(e.target.value)
            }}
          >
            <FormControlLabel
              sx={{
                label: classes.label,
              }}
              value={t('Sí')}
              control={<Radio />}
              label={t('Sí')}
            />
            <FormControlLabel
              sx={{
                label: classes.label,
              }}
              value={t('No')}
              control={<Radio />}
              label={t('No')}
            />
          </RadioGroup>
        </Box>

        {thirdRadioButton === 'Sí' && (
          <Box style={{ margin: window.innerWidth > 598 ? '18px' : '27px' }}>
            <TextField
              style={{ width: window.innerWidth < 598 ? '350px' : '500px' }}
              defaultValue={undefined}
              type="text"
              value={textFourthQuestion}
              InputProps={{
                inputProps: { min: 1 },
                sx: {
                  input: classes.label,
                },
              }}
              onChange={(e) => {
                setTextFourthQuestion(e.target.value)
              }}
              id="outlined-basic"
              variant="outlined"
              size={'medium'}
            />
          </Box>
        )}

        <Box style={{ margin: window.innerWidth > 598 ? '18px' : '27px' }}>
          <h3 style={{ fontSize: '1.0rem', fontFamily: 'Poppins, sans-serif' }}>
            {t('haveYouHadBloodTest?')}
          </h3>
          <RadioGroup
            style={{
              marginLeft: window.innerWidth > 598 ? '15px' : '4px',
              display: 'flex',
              flexDirection: 'column',
            }}
            className={styles.radioGroup}
            aria-label="gender"
            name="gender1"
            value={fourthRadioButton}
            onChange={(e) => {
              setFourthRadioButton(e.target.value)
            }}
          >
            <FormControlLabel
              sx={{
                label: classes.label,
              }}
              value={t('Sí')}
              control={<Radio />}
              label={t('Sí')}
            />
            <FormControlLabel
              sx={{
                label: classes.label,
              }}
              value={t('No')}
              control={<Radio />}
              label={t('No')}
            />
          </RadioGroup>
        </Box>

        <Box style={{ margin: window.innerWidth > 598 ? '18px' : '27px' }}>
          <h3 style={{ fontSize: '1.0rem', fontFamily: 'Poppins, sans-serif' }}>
            {t('doYouHaveQuestionsOrNeedToDiscussSomethingWithYourDoctor?')}
          </h3>
          <TextField
            style={{ width: window.innerWidth < 598 ? '350px' : '500px' }}
            defaultValue={undefined}
            type="text"
            value={textFifthQuestion}
            InputProps={{
              inputProps: { min: 1 },
              sx: {
                input: classes.label,
              },
            }}
            onChange={(e) => {
              setTextFifthQuestion(e.target.value)
            }}
            id="outlined-basic"
            variant="outlined"
            size={'medium'}
          />
        </Box>

        <Box display={'flex'} justifyContent={'center'}>
          <AppButton
            label={t('save')}
            type={'submit'}
            theme={ButtonTheme.newEvent}
            handler={(e) => {
              e.preventDefault()
              props.handleCloseEpilepticCrisisModal()
              handleSaveMessage()
            }}
          />
        </Box>
      </form>
    </Box>
  )
}
