// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 599px) {\n  .DashboardSectionCard_box__10F6w {\n    padding: 0 !important;\n    margin: 0 !important;\n    box-shadow: none;\n    border-radius: none;\n  }\n  .DashboardSectionCard_card__11n2b {\n    box-shadow: none !important;\n    border-radius: 0 !important;\n    padding-left: 0 !important;\n    padding-right: 0 !important;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/section-card/DashboardSectionCard.module.scss"],"names":[],"mappings":"AAAA;EACI;IACI,qBAAA;IACA,oBAAA;IACA,gBAAA;IACA,mBAAA;EACN;EACE;IACI,2BAAA;IACA,2BAAA;IACA,0BAAA;IACA,2BAAA;EACN;AACF","sourcesContent":["@media (max-width: 599px) {\n    .box {\n        padding: 0!important;\n        margin: 0!important;\n        box-shadow: none;\n        border-radius: none;\n    }\n    .card {\n        box-shadow: none!important;\n        border-radius: 0!important;\n        padding-left: 0!important;\n        padding-right: 0!important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "DashboardSectionCard_box__10F6w",
	"card": "DashboardSectionCard_card__11n2b"
};
export default ___CSS_LOADER_EXPORT___;
