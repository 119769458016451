/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { SourceType } from '../../modules/notifications/enums/SourceType'
import { Notification } from '../../modules/notifications/models/Notification'
import { DeliveryStatus } from '../../modules/notifications/enums/DeliveryStatus'
import { PriorityLevel } from '../../modules/notifications/enums/PriorityLevel'
import { Message } from 'm../../modules/notifications/models/Message'
import { TransportType } from '../../common/enums/TransportType'
import { Box, Modal, TextField } from '@mui/material'
import { CustomModal } from '../../components/modal/CustomModal'
import style from './CustomModal.module.css'
import { Header } from '../../components/header/Header'
import close from '../../assets/table_icons/ico-eliminar.svg'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { getNotificationContainer } from '../../container/notification-module'
import { NotificationService } from '../../modules/notifications/services/NotificationService'
import {
  MESSAGES_SERVICE_KEY,
  NOTIFICATION_SERVICE_KEY,
  SEGMENTED_NOTIFICATION_API_KEY,
} from '../../modules/notifications'
import { MessageService } from '../../modules/notifications/services/MessageService'
import {
  EmptySegmentedMessageDTO,
  toModel,
  toModelMessage,
} from '../../modules/notifications/models/SegmentedNotificationDTO'
import { SegmentedNotificationService } from '../../modules/notifications/services/SegmentedNotificationService'
import { RichText } from '../../components/text-editor/RichText'
const notificationContainer = getNotificationContainer()
const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)
const segmentedNotificationService = notificationContainer.get<SegmentedNotificationService>(
  SEGMENTED_NOTIFICATION_API_KEY
)
const messagesService = notificationContainer.get<MessageService>(MESSAGES_SERVICE_KEY)

export function NotificationModal({
  data,
  segmentedId,
  userId,
  userCircleId,
  handleClose,
  closeModal,
  newNotification = false,
  updateMessages,
  setUpdateMessages,
  segmentedGroup,
  notiTitle,
}: any) {
  const { t } = useTranslation()
  const [description, setDescription] = useState(data.description)
  const [openMessageCreated, setOpenMessageCreated] = useState<boolean>(false)
  const [lockButton, setLockButton] = useState<boolean>(false)
  const [title, setTitle] = useState(data.title)
  const handleContent = (e: string) => {
    setDescription(e)
  }

  const handleTitle = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  const handleSave = (event: any) => {
    setLockButton(true)
    const message = EmptySegmentedMessageDTO()
    message.title = title
    message.description = description
    message.segmentedId = segmentedId
    segmentedNotificationService.addNotification(toModelMessage(message)).subscribe((resp) => {
      // setOpenMessageCreated(true)
      if (setUpdateMessages) {
        setTimeout(() => {
          setUpdateMessages(!updateMessages)
        }, 2000)
      } else {
        setOpenMessageCreated(true)
      }
    })
  }

  const handleCloseCreatedModal = () => {
    setLockButton(false)
    closeModal()
    setOpenMessageCreated(false)
  }

  const closeCreated = () => {
    setLockButton(false)
    closeModal()
    setOpenMessageCreated(false)
  }

  return (
    <>
      <Modal open={openMessageCreated} onClose={handleCloseCreatedModal}>
        <div className={style.agoraContainer}>
          <Box className={style.containerInside} style={{ width: '500px' }}>
            <Box
              display="flex"
              justifyContent="center"
              textAlign={'center'}
              className={'titleSend'}
            >
              <h3>{segmentedGroup.name}</h3>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              textAlign={'center'}
              className={'titleSend'}
            >
              <h2 style={{ color: '#f29940' }}>{t('successfullySended').toUpperCase()}</h2>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box style={{ padding: '10px' }}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('close')}
                  handler={closeCreated}
                />
              </Box>
            </Box>
          </Box>
        </div>
      </Modal>
      <div className={style.agoraContainer}>
        <Box className={style.containerInside}>
          <Header
            label={newNotification ? notiTitle : t('editNotification')}
            icon={close}
            onClick={handleClose}
          />
          <Box mt={3} mb={3} display={'flex'} justifyContent={'space-between'}>
            <p className={style.label}>{t('title')}*</p>
            <TextField
              key={'name'}
              id={'name'}
              name={'name'}
              // disabled={!newNotification}
              value={title}
              onChange={(e) => handleTitle(e)}
              style={{ padding: '10px' }}
              size={'small'}
              variant={'outlined'}
              fullWidth={true}
            />
          </Box>

          <p>{t('description') + ': '}</p>
          <Box mb={3}>
            <RichText
              content={description || '[{"type":"paragraph","children":[{"text":""}]}]'}
              edit={true}
              handleChange={(e) => handleContent(e)}
            />
            {/* <TextField */}
            {/*  id="outlined-multiline-flexible" */}
            {/*  value={description} */}
            {/*  onChange={(e) => handleContent(e)} */}
            {/*  multiline */}
            {/*  style={{ padding: '10px' }} */}
            {/*  fullWidth={true} */}
            {/*  minRows={12} */}
            {/* /> */}
          </Box>
          <Box display="flex" justifyContent="end">
            <Box style={{ padding: '10px' }}>
              <AppButton
                disabled={lockButton}
                theme={ButtonTheme.NewPrimary}
                type={'submit'}
                label={t('send')}
                handler={handleSave}
              />
            </Box>
          </Box>
        </Box>
      </div>
    </>
  )
}
