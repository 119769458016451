import React, { ReactNode, useEffect, useState } from 'react'
import { AllCalendarEventDTO } from '../../../modules/calendar/models/AllCalendarEventDTO'
import { getCalendarContainer } from '../../../container/calendar-module'
import { EventService } from '../../../modules/calendar/services/EventService'
import { EVENT_SERVICE_KEY } from '../../../modules/calendar'
import { Query, QueryParam } from '../../api/Query'
import { Event } from '../../../modules/calendar/models/Event'
import dayjs from 'dayjs'
import { useGetActiveUser } from '../../../hooks/get-active-user/useGetActiveUser'
import { fromArrayModel } from '../../../modules/calendar/models/AllCalendarEventDTO'
import { EventCategory, EventCategoryArray } from '../../../modules/calendar/enums/EventCategory'

const calendarContainer = getCalendarContainer()
const eventService = calendarContainer.get<EventService>(EVENT_SERVICE_KEY)

type CalendarEventsContextProviderProps = {
  children: ReactNode
}
export type CalendarEventsContextType = {
  calendarEvents: AllCalendarEventDTO[]
  filterEvents: EventCategory[]
  setfilterEvents: (calendarEvents: EventCategory[]) => void
  refreshCalendarEvents: boolean
  setRefreshCalendarEvents: (refresh: boolean) => void
  eventsMonthYear: {
    mes: number
    ano: number
  }
  setEventsMonthYear: (monthYear: { mes: number; ano: number }) => void
  isLoading: boolean
}

export const CalendarEventsContext = React.createContext<CalendarEventsContextType>({
  calendarEvents: [],
  filterEvents: [],
  setfilterEvents: () => {},
  refreshCalendarEvents: false,
  setRefreshCalendarEvents: () => {},
  eventsMonthYear: {
    mes: 0,
    ano: 0,
  },
  setEventsMonthYear: (monthYear: { mes: number; ano: number }) => {},
  isLoading: true,
})
export const CalendarEventsContextProvider: React.FC<CalendarEventsContextProviderProps> = ({
  children,
}) => {
  const [allCalendarEvents, setAllCalendarEvents] = useState<AllCalendarEventDTO[]>([])
  const [filterEvents, setfilterEvents] = useState<EventCategory[]>(EventCategoryArray)
  const [refreshCalendarEvents, setRefreshCalendarEvents] = useState(false)
  const { activeUser } = useGetActiveUser()
  const today = dayjs()
  const [eventsMonthYear, setEventsMonthYear] = useState({
    mes: today.month() + 1, // dayjs month is 0 based
    ano: today.year(),
  })
  const [isLoading, setIsLoading] = useState(true)

  // estados previos para controlar renderizados, unicamente se piden de nuevo los datos si alguno de los estados ha cambiado
  const [prevEventsMonthYear, setPrevEventsMonthYear] = useState(eventsMonthYear)
  const [prevRefreshCalendarEvents, setPrevRefreshCalendarEvents] = useState(refreshCalendarEvents)
  const [prevFilterEvents, setPrevFilterEvents] = useState<EventCategory[]>()

  useEffect(() => {
    // comprobamos si alguno de los estados ha cambiado
    if (
      prevEventsMonthYear.mes !== eventsMonthYear.mes ||
      prevEventsMonthYear.ano !== eventsMonthYear.ano ||
      prevRefreshCalendarEvents !== refreshCalendarEvents ||
      JSON.stringify(prevFilterEvents) !== JSON.stringify(filterEvents)
    ) {
      // console.log('actualizo los eventos')

      setIsLoading(true)

      const today = new Date()
      setEventsMonthYear({
        mes: today.getMonth() + 1, // Adjusting to 1-based month
        ano: today.getFullYear(),
      })

      const startDate = dayjs(`${today.getFullYear() - 1}-${today.getMonth() + 1}-01`).startOf(
        'month'
      )
      const endDate = startDate.clone().add(100, 'month').endOf('month') // aquí contemplamos cambio de año de tarea

      const lastPreviousMonth = startDate
        .clone()
        .subtract(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

      const firstNextMonth = endDate
        .clone()
        .add(1, 'month')
        .startOf('month')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

      eventService
        .getAllCalendarEvents(
          new Query<Event>({
            query: [
              new QueryParam<Event>('startDate', lastPreviousMonth),
              new QueryParam<Event>('finishDate', firstNextMonth),
            ],
          }),
          activeUser?.user.id || ''
        )
        .subscribe((res) => {
          // filtramos los eventos por las categorias seleccionadas
          setIsLoading(false)
          if (filterEvents.length > 0) {
            res = res.filter((event) => filterEvents.includes(event.eventCategory))
            setAllCalendarEvents(fromArrayModel(res))
          }
        })

      // actualizamos los estados previos
      setPrevEventsMonthYear(eventsMonthYear)
      setPrevRefreshCalendarEvents(refreshCalendarEvents)
      setPrevFilterEvents([...filterEvents])
    }
  }, [eventsMonthYear, refreshCalendarEvents, filterEvents])

  return (
    <CalendarEventsContext.Provider
      value={{
        calendarEvents: allCalendarEvents,
        filterEvents,
        setfilterEvents,
        refreshCalendarEvents,
        setRefreshCalendarEvents,
        eventsMonthYear,
        setEventsMonthYear,
        isLoading,
      }}
    >
      {children}
    </CalendarEventsContext.Provider>
  )
}
