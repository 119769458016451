import { Box } from '@mui/material'
import genericStyle from '../../common/utils/generic.module.css'
import React, { useEffect, useState } from 'react'
import { Login } from './Login'
import { getUserContainer } from '../../container/user-module'
import { ICircleService } from '../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../modules/users/container'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ROUTE_WEARABLES } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Checkbox } from '@mui/material'
import { Header } from '../../components/header/Header'
import { getPatientDataContainer } from '../../container/patient-data-module'
import { WearableService } from '../../modules/patient-data/services/WearableService'
import { WEARABLE_SERVICE_KEY } from '../../modules/patient-data/container'

const patientDataContainer = getPatientDataContainer()
const wearableService = patientDataContainer.get<WearableService>(WEARABLE_SERVICE_KEY)

export function View() {
  const { t } = useTranslation()

  const [legal, setLegal] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>("")
  // const circle = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY).getActiveUser()
  const [hasLogin, setHasLogin] = useState<boolean>(false)
  const navigate = useNavigate()
  const goBack = () => navigate(ROUTE_WEARABLES)
  const handleChange = () => setLegal(!legal)

  useEffect(() => {
    if(localStorage.getItem("selected user")) {
      let obj = localStorage.getItem("selected user");
      let userName: any
      let lastName: any
      // @ts-ignore
      userName = JSON.parse(localStorage.getItem('selected user')).name
      // @ts-ignore
      lastName = JSON.parse(localStorage.getItem('selected user')).lastname
      setUserName(userName+" "+lastName)
      }

    if (localStorage.getItem('logged user')) {
      let user = JSON.parse(localStorage.getItem('selected user') || 'null')
      let userId: any
      // @ts-ignore
      userId = user.id
      wearableService.getLoginType(userId).subscribe((res) => {
        setHasLogin(res > 0)
      })
    }
    }, [])


  const handleLegalSMS = () => {
    window.open('https://sede.carm.es/sms/portaldelpaciente/view/avisoLegal.xhtml', '_Blank')
  }

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Box>
          <Box mb={3} display="flex" justifyContent="space-between">
            <Header label={'Vincular un dispositivo'} />
            <Box borderBottom={"3px solid #f18b24"}>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('back')}
                handler={goBack}
              />
            </Box>
          </Box>
        </Box>
        <Box mb={3} display={"flex"} flexDirection={"row"}>
          <b>
            {t('user')}:
          </b>
          <div>
            {userName}
          </div>
        </Box>
        {
          !hasLogin &&
          <div>
            <Box mb={3}>
              <b>
                <Checkbox key={'legal'} name={'legal'} checked={legal} onClick={handleChange} />
                <b>He leído y acepto los términos y condiciones de uso</b>
              </b>
            </Box>
            <Box mb={3} className={genericStyle.legalContainer}>
              <div>
                <p style={{ fontWeight: 'bold' }}>
                  En cumplimiento de la normativa de Protección de Datos vigente se le informa de que
                  está accediendo a Círculo de Salud del Paciente, por lo que, si vincula su dispositivo
                  externo wearables (como una pulsera o una báscula inteligente), está aceptando nuestra
                  política de privacidad y condiciones de uso, lo que implica que sus datos serán
                  almacenados por el Servicio Murciano de Salud, pasando a formar parte de su historia
                  clínica y conservados por el tiempo legamente establecido. No serán cedidos o vendidos
                  a terceros en los mismos términos que la Política de privacidad principal que puede
                  ver a través del enlace incluido al final del texto. Asimismo, y con carácter previo
                  usted habrá aceptado la Política de privacidad de Google Fit o Fitbit siendo sus datos
                  almacenados fuera de la UE y cuya aceptación es totalmente ajena a nosotros.
                </p>
                <p style={{ fontWeight: 'bold' }}>
                  {'\n'}El dispositivo externo recopila datos para calcular una variedad de métricas,
                  como el número de pasos, la distancia recorrida, consumo calórico, peso, frecuencia
                  cardíaca, períodos de sueño, minutos de actividad, entre otros. Los datos recopilados
                  variarán dependiendo del dispositivo que utilice. La finalidad de la recogida de sus
                  datos es un correcto seguimiento de su salud para la prestación de asistencia
                  sanitaria a través de un panel de seguimiento de las referidas métricas que puedan ser
                  de interés clínico, legitimado por el artículo 6.1.c) del Reglamento General de
                  Protección de Datos.
                </p>
                <p style={{ fontWeight: 'bold' }}>
                  {'\n'}Para obtener más información, acceda a:
                </p>
                <p onClick={handleLegalSMS} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                  {'\n'}https://sede.carm.es/sms/portaldelpaciente/view/avisoLegal.xhtml
                </p>
              </div>
            </Box>
          </div>
          }
        <Login legal={legal} checkLogin={setHasLogin} toWearables={goBack}/>
      </Box>
    </>
  )
}
