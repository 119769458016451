import { RouteProps } from '../../routes/AppRouter'
import { AddNewCircle as ANC } from '../../features/circle-configurator/add-new-circle/view 1 Definition/AddNewCircle'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function AddNewCircle(props: RouteProps) {
  return (
    <>
      <CurrentNavHeaderEsfera title={props.title ?? ''} />
      <ANC />
    </>
  )
}
