export enum ActivePlanStatusType {
  Created = 'created',
  Pending = 'pending',
  Refused = 'refused',
  Active = 'active',
  Finished = 'finished',
}

export enum ActivePlanForms {
  FisicalConditionFormIDInicio = '9ec24ff8-be61-4090-a98d-67616afeda01',
  FisicalConditionFormIDFinal = '9ec24ff8-be61-4090-a98d-67616afeda02',
}

export enum ActivePlanFromFields {
  FCFormFieldAerobicConditionInicio = '9ec24ff8-be61-4090-a98d-67616afeda01',
  FCFormFieldForceInicio = '9ec24ff8-be61-4090-a98d-67616afeda02',
  FCFormFieldFlexibilityInicio = '9ec24ff8-be61-4090-a98d-67616afeda03',
  FCFormFieldBalanceInicio = '9ec24ff8-be61-4090-a98d-67616afeda04',

  FCFormFieldAerobicConditionFinal = '9ec24ff8-be61-4090-a98d-67616afeda05',
  FCFormFieldForceFinal = '9ec24ff8-be61-4090-a98d-67616afeda06',
  FCFormFieldFlexibilityFinal = '9ec24ff8-be61-4090-a98d-67616afeda07',
  FCFormFieldBalanceFinal = '9ec24ff8-be61-4090-a98d-67616afeda08',
}
