// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableResults_headerCell__3vVCa {\n  background-color: #ffffff;\n  font-size: 14px;\n  border-bottom: 3px solid #68b3e0;\n}\n\n.TableResults_cell__2cks7 {\n  font-weight: bold;\n  color: #515151;\n}\n", "",{"version":3,"sources":["webpack://src/features/premsProms/TableResults.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".headerCell {\n  background-color: #ffffff;\n  font-size: 14px;\n  border-bottom: 3px solid #68b3e0;\n}\n\n.cell {\n  font-weight: bold;\n  color: #515151;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerCell": "TableResults_headerCell__3vVCa",
	"cell": "TableResults_cell__2cks7"
};
export default ___CSS_LOADER_EXPORT___;
