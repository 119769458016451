/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
import { Box } from '@mui/material'
import styles from '../user-profile/Editor.module.css'
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  GoogleLogout,
} from 'react-google-login'
import { LoginType } from '../../modules/patient-data/enums/LoginType'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { WearableService } from '../../modules/patient-data/services/WearableService'
import { WEARABLE_SERVICE_KEY } from '../../modules/patient-data/container'
import { getPatientDataContainer } from '../../container/patient-data-module'
import { getUserContainer } from '../../container/user-module'
import { ICircleService } from '../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../modules/users/container'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const wearableService = getPatientDataContainer().get<WearableService>(WEARABLE_SERVICE_KEY)

const googleLoginScope = [
  'https://www.googleapis.com/auth/fitness.activity.read',
  'https://www.googleapis.com/auth/fitness.blood_pressure.read',
  'https://www.googleapis.com/auth/fitness.body.read',
  'https://www.googleapis.com/auth/fitness.body_temperature.read',
  'https://www.googleapis.com/auth/fitness.heart_rate.read',
  'https://www.googleapis.com/auth/fitness.location.read',
  'https://www.googleapis.com/auth/fitness.oxygen_saturation.read',
  'https://www.googleapis.com/auth/fitness.sleep.read',
]

interface loginProps {
  legal: boolean
  checkLogin: Dispatch<SetStateAction<boolean>>
  toWearables: any
}

export function Login(props: loginProps) {
  const fitbitLoginURL = process.env.REACT_APP_FITBIT_REDIRECT_URL
  const fitbitVerifier = process.env.REACT_APP_FITBIT_VERIFIER
  const fitbitState = process.env.REACT_APP_FITBIT_STATE

  // console.log(`From env files: RedirectURL: ${fitbitLoginURL} | Code Verifier: ${fitbitVerifier} | Fitbit State: ${fitbitState}`)
  // alert(`From env files: RedirectURL: ${fitbitLoginURL} | Code Verifier: ${fitbitVerifier} | Fitbit State: ${fitbitState}`)

  const { t } = useTranslation()

  const circle = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY).getActiveUser()
  const location = useLocation()
  const [googleClientId] = useState<string>(
    '42021262300-v75eogvpen7vvqdcutnpi9odb2ctop4j.apps.googleusercontent.com'
  )

  const [loginType, setLoginType] = useState<number>(0)
  const [code, setCode] = useState<string>('')

  useEffect(() => {
    const c = location.search.split('=')[1]
    if (c) {
      const code = c.split('&state')[0]
      if (code) {
        setCode(code)
        setLoginType(LoginType.Fitbit)
      } else {
        wearableService.getLoginType(circle?.id ?? '').subscribe((res) => {
          setLoginType(res)
        })
      }
    } else {
      const user = JSON.parse(localStorage.getItem('selected user') ?? 'null')
      let userId: any
      // @ts-ignore
      // eslint-disable-next-line prefer-const
      userId = user.id
      wearableService.getLoginType(userId).subscribe((res) => {
        setLoginType(res)
      })
    }
  }, [])

  useEffect(() => {
    // if (!code || !circle?.id || loginType === 0) {
    //   return
    // }
    if (
      code &&
      localStorage.getItem('logged user') &&
      loginType !== 0 &&
      fitbitVerifier &&
      fitbitState
    ) {
      const user = JSON.parse(localStorage.getItem('selected user') ?? 'null')
      let userId: any
      // @ts-ignore
      // eslint-disable-next-line prefer-const
      userId = user.id

      // alert(`Code: ${code} | UserID: ${userId} | Code Verifier: ${fitbitVerifier} | State: ${fitbitState} | LoginType: ${loginType}`)
      // console.log(`Code: ${code} | UserID: ${userId} | Code Verifier: ${fitbitVerifier} | State: ${fitbitState} | LoginType: ${loginType}`)

      wearableService
        .createToken({
          code,
          userID: userId,
          codeVerifier: fitbitVerifier,
          state: fitbitState,
          loginType,
        })
        .subscribe((res) => {
          props.checkLogin(true)
          props.toWearables()
        })
    }
  }, [code, loginType])

  const googleLogin = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if (res.code) {
      setCode(res.code)
    }
    setLoginType(LoginType.Google)
  }

  const closeSession = () => {
    const user = JSON.parse(localStorage.getItem('selected user') ?? 'null')
    let userId: any
    // @ts-ignore
    // eslint-disable-next-line prefer-const
    userId = user.id
    wearableService.deleteToken(userId).subscribe(() => {
      setLoginType(0)
      props.checkLogin(false)
    })
  }

  const googleLogout = () => closeSession()

  const authWithFitbit = () => {
    if (!fitbitLoginURL) {
      return
    }

    window.location.assign(fitbitLoginURL)
    setLoginType(LoginType.Fitbit)
  }

  return (
    <>
      <Box mb={3} className={styles.textFieldBox} display={'flex'} justifyContent={'center'}>
        {!loginType && (
          <>
            <GoogleLogin
              responseType={'code'}
              prompt={'consent'}
              accessType={'offline'}
              className={styles.textFieldGoogle}
              clientId={googleClientId}
              scope={googleLoginScope.join(' ')}
              cookiePolicy={'single_host_origin'}
              isSignedIn={loginType === LoginType.Google}
              buttonText={t('loginWithGoogle')}
              onSuccess={googleLogin}
              disabled={false}
            />
          </>
        )}
        {loginType === LoginType.Google && (
          <>
            <GoogleLogout
              className={styles.textFieldGoogle}
              buttonText={t('logoutWithGoogle')}
              clientId={googleClientId}
              onLogoutSuccess={googleLogout}
            />
          </>
        )}
        {!loginType && (
          <>
            <AppButton
              theme={props.legal ? ButtonTheme.NewSecondary : ButtonTheme.BasicSecondary}
              type={'button'}
              label={t('loginWithFitbit')}
              handler={() => authWithFitbit()}
              disabled={!props.legal}
            />
          </>
        )}
        {loginType === LoginType.Fitbit && (
          <>
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('logoutWithFitbit')}
              handler={() => closeSession()}
            />
          </>
        )}
      </Box>
      <Box
        mb={3}
        className={styles.textFieldBox}
        display={'flex'}
        justifyContent={'space-between'}
      ></Box>
    </>
  )
}
