// import { useTranslation } from 'react-i18next'
import React from 'react'
import { Box } from '@mui/material'
import { ActivePlanDTO } from '../../../modules/active-plan/models/ActivePlanDTO'

type InclutionDataProps = {
  activePlan: ActivePlanDTO
}

export function InclutionData(props: InclutionDataProps) {
  // const { t } = useTranslation()

  return <Box>{props?.activePlan?.professionalMessage}</Box>
}
