import {
  Box,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useEffect, useState } from 'react'
import style from './AppParticipantTable.module.css'
import { Searcher } from '../../../components/table/Searcher'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import patient from '../../../assets/role_icons/ico-rol-paciente.svg'
import professionalSMS from '../../../assets/role_icons/ico-rol-profesional.svg'
import family from '../../../assets/role_icons/ico-rol-familiar.svg'
import externProfessional from '../../../assets/role_icons/ico-rol-externo.svg'
import career from '../../../assets/role_icons/ico-rol-cuidador.svg'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { UserPending } from '../../../modules/users/models/UserPending'
import { Participant } from '../Table'
import { Action, Actions, Field, Search, Sort } from '../../../components/table_type/types'
import { getUserContainer } from '../../../container/user-module'
import { RolesService } from '../../../modules/users/services/RolesServices'
import { IUserService, ROLES_SERVICE_KEY, USER_SERVICE_KEY } from '../../../modules/users'
import { enqueueSnackbar } from 'notistack'
import { Roles } from '../../../modules/users/enums/Roles'
import { UserPendingDTO } from '../../../modules/users/models/UserPendingDTO'
import { getUserCircleContainer } from '../../../container/user-circle-module'
import { IUserCircleService, USER_CIRCLE_SERVICE_KEY } from '../../../modules/user-circle'

export type UsersTableProps<T, Q> = {
  readonly titleActions: string
  readonly titleNewItems: string
  readonly addButtonLabel: string
  readonly removeButtonLabel: string
  readonly reInviteButtonLabel: string
  readonly titleCaption: string
  readonly items: Participant[]
  readonly newItems?: UserPending[]
  readonly fields: Field<T>[]
  readonly sort?: Sort<T>
  readonly actions?: Actions<T>
  readonly search?: Search<Q>
  readonly autocompleteItems?: string[]
  readonly autocompleteLabel?: string
  readonly autocompleteAction?: Action
  readonly isPreventDefault?: boolean
  handlerAdd: (e?: any) => void
  hasLinkPermission: boolean
}

const roleService = getUserContainer().get<RolesService>(ROLES_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)

export function AppUsersTable<T extends Record<string, any>, Q extends Record<string, any>>(
  props: UsersTableProps<T, Q>
) {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pending, setPending] = useState<Boolean>()

  const [user, setUser] = useState<Participant>()
  const [selectedPendingUser, setSelectedPendingUser] = useState<UserPending>()
  const [pendingUsers, setPendingUsers] = useState<UserPending[]>(props.newItems ?? [])
  const [participants, setParticipants] = useState<Participant[]>(props.items ?? [])
  const [pendingIcons, setPendingIcons] = useState<(string | undefined)[]>([])
  const [rowsPending, setRowsPending] = useState<JSX.Element[]>([])
  const changeUser = (u: Participant) => {
    setPending(false)
    setSelectedPendingUser(undefined)
    setUser(u)
  }

  // useEffecçt added in order to listen to changes in searchbar and display result accondently
  useEffect(() => {
    setParticipants(props.items)
  }, [props.items])

  //
  const useStyles = () => ({
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0 1rem',
    },
    row: {
      boxShadow: '4px 4px 10px rgba(0.4, 0, 0, 0.1)',
    },
    headCell: {
      marginLeft: '10px !important',
      backgroundColor: '#FFFFFF',
      color: '#515151',
      border: 'none !important',
      fontFamily: 'Montserrat-SemiBold, sans-serif !important',
      fontStyle: 'normal !important',
      fontSize: '16px',
      lineHeight: '24px !important',
      padding: 0,
      textAlign: 'center',
    },

    firtsCell: {
      border: 'none',
    },

    firtsBodyCell: {
      maginTop: '1rem',
      marginLeft: '10px !important',
      backgroundColor: '#FFFFFF',
      color: '#515151',
      borderLeft: '1px solid',
      borderBottom: '1px solid',
      borderTop: '1px solid',
      fontFamily: 'Montserrat-SemiBold, sans-serif !important',
      fontStyle: 'normal !important',
      fontSize: '16px',
      lineHeight: '24px !important',
      padding: '1.5rem 0',
      textAlign: 'center',
      borderTopLeftRadius: '20px',
      borderBottomLeftRadius: '20px',
      borderColor: '#68B3E0',
    },
    middleBodyCell: {
      maginTop: '1rem',
      marginLeft: '10px !important',
      backgroundColor: '#FFFFFF',
      color: '#515151',
      borderBottom: '1px solid',
      borderTop: '1px solid',
      fontFamily: 'Montserrat-SemiBold, sans-serif !important',
      fontStyle: 'normal !important',
      fontSize: '16px',
      lineHeight: '24px !important',
      padding: '1.5rem 0',
      textAlign: 'center',
      borderColor: '#68B3E0',
    },
    statusBodyCellActive: {
      maginTop: '1rem',
      marginLeft: '10px !important',
      backgroundColor: '#FFFFFF',
      color: 'green',
      borderBottom: '1px solid',
      borderTop: '1px solid',
      fontFamily: 'Montserrat-SemiBold, sans-serif !important',
      fontStyle: 'normal !important',
      fontSize: '16px',
      lineHeight: '24px !important',
      padding: '1.5rem 0',
      textAlign: 'center',
      borderColor: '#68B3E0',
    },
    statusBodyCellActiveExpired: {
      maginTop: '1rem',
      marginLeft: '10px !important',
      backgroundColor: '#FFFFFF',
      color: 'red',
      borderBottom: '1px solid',
      borderTop: '1px solid',
      fontFamily: 'Montserrat-SemiBold, sans-serif !important',
      fontStyle: 'normal !important',
      fontSize: '16px',
      lineHeight: '24px !important',
      padding: '1.5rem 0',
      textAlign: 'center',
      borderColor: '#68B3E0',
    },

    lastBodyCell: {
      maginTop: '1rem',
      marginLeft: '10px !important',
      backgroundColor: '#FFFFFF',
      color: '#515151',
      borderRight: '1px solid',
      borderBottom: '1px solid',
      borderTop: '1px solid',
      fontFamily: 'Montserrat-SemiBold, sans-serif !important',
      fontStyle: 'normal !important',
      fontSize: '16px',
      lineHeight: '24px !important',
      padding: '1.5rem 0',
      textAlign: 'center',
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
      borderColor: '#68B3E0',
    },

    displayNone: {
      display: 'none',
    },
  })

  const classes = useStyles()

  const setImgRole = (role: string | undefined) => {
    if (role === undefined) {
      return
    }

    let imgRole: string = 'patient'

    switch (role) {
      case (role = 'patient'):
        imgRole = patient
        break
      case (role = 'family/Tutor'):
        imgRole = family
        break

      case (role = 'professionalSMS'):
        imgRole = professionalSMS
        break

      case (role = 'carer/Teacher'):
        imgRole = career
        break

      case (role = 'externProfessional'):
        imgRole = externProfessional
        break
    }

    return imgRole
  }

  const getNameAndRole = (i: Participant): JSX.Element => {
    const result = getRoleColor(i)
    return (
      <Box>
        <Box>{i.name + ' ' + i.lastname}</Box>
        <Box className={style.chipRoleRow}>
          <Box
            style={{
              color: result.color,
              backgroundColor: result.background,
              padding: '8px',
              borderRadius: '20px',
            }}
          >
            {i._role ? t(i._role) : ''}
          </Box>
        </Box>
      </Box>
    )
  }

  const getRoleColor = (i: Participant): { color: string; background: string } => {
    let color = ''
    let background = ''
    switch (i._role) {
      case Roles.Patient:
        color = '#ffffff'
        background = '#1462a2'
        break
      case Roles.FamilyOrTutor:
        color = '#1462a2'
        background = '#b1d7ed'
        break
      case Roles.Professional:
        color = '#ffffff'
        background = '#ee8c38'
        break
      case Roles.ProfessionalExtern:
        color = '#ffffff'
        background = '#6db3dd'
        break
      case Roles.CarerOrTeacher:
        color = '#ffffff'
        background = '#6db3dd'
        break
      case Roles.ContentManager:
        color = '#ffffff'
        background = '#6db3dd'
        break
    }
    return { color, background }
  }

  const headCells = Object.values(props.fields).map((f) => (
    <TableCell sx={classes.headCell} key={(f.name as string) + uuidv4()}>
      {f.label}
    </TableCell>
  ))

  const headCells2 =
    innerWidth > 599 ? (
      <>
        <TableCell sx={classes.headCell}></TableCell>
        <TableCell sx={classes.headCell} key={'email'}>
          {t('email')}
        </TableCell>
        <TableCell sx={classes.headCell} key={'email'}>
          {t('status')}
        </TableCell>
        <TableCell sx={classes.headCell} key={'role'}>
          {t('role')}
        </TableCell>
      </>
    ) : (
      <>
        <TableCell sx={classes.headCell}></TableCell>
        <TableCell sx={classes.headCell} key={'role'}></TableCell>
        <TableCell sx={classes.headCell} key={'email'} />
      </>
    )

  const rows = participants.map((item, index) => {
    const imgrole = setImgRole(item._role)

    const hasLinkPermission = props.hasLinkPermission
    return (
      <TableRow sx={classes.row} key={index}>
        <TableCell sx={classes.firtsBodyCell}>
          {hasLinkPermission && (
            <Radio
              checked={user === item}
              onClick={() => {
                changeUser(item)
              }}
            />
          )}
        </TableCell>
        <TableCell sx={global.innerWidth < 599 ? classes.middleBodyCell : classes.displayNone}>
          <img src={imgrole} alt={item._role} />
        </TableCell>
        <TableCell sx={global.innerWidth > 599 ? classes.middleBodyCell : classes.displayNone}>
          {item.name} {item.lastname}
        </TableCell>
        <TableCell sx={global.innerWidth > 599 ? classes.displayNone : classes.lastBodyCell}>
          {getNameAndRole(item)}
        </TableCell>
        <TableCell sx={global.innerWidth < 599 ? classes.displayNone : classes.middleBodyCell}>
          <img src={imgrole} alt={item._role} />
        </TableCell>
        <TableCell sx={global.innerWidth > 599 ? classes.lastBodyCell : classes.displayNone}>
          {item._professionalType}
        </TableCell>
      </TableRow>
    )
  })

  useEffect(() => {
    setPendingIcons([])
    if (props.newItems) {
      // Mapear los nuevos elementos y suscribirse a las promesas
      const promises = pendingUsers.map(async (item, index) => {
        return await roleService
          .getByID(item.roleId)
          .toPromise()
          .then((role) => {
            return setImgRole(role?.name)
          })
      })

      // Esperar a que todas las promesas se completen
      Promise.all(promises).then((newRows) => {
        // Actualizar el estado con todas las filas completadas
        setPendingIcons(newRows ?? [])
      })
    }
  }, [pendingUsers])

  useEffect(() => {
    const pending = pendingUsers.map((item, index) => {
      if (pendingIcons?.length !== pendingUsers?.length) {
        return <></>
      }
      return (
        <TableRow sx={classes.row} key={index}>
          <TableCell sx={classes.firtsBodyCell}>
            <Radio
              onClick={() => {
                setSelectedPendingUser(item)
                setUser(undefined)
              }}
              checked={item.email === selectedPendingUser?.email}
            />
          </TableCell>
          <TableCell sx={global.innerWidth < 599 ? classes.middleBodyCell : classes.displayNone}>
            <img src={pendingIcons[index]} alt={'familiarIcon'} />
          </TableCell>
          <TableCell sx={classes.middleBodyCell}>
            {global.innerWidth < 599 ? item.email.substring(0, 10) + '...' : item.email}
          </TableCell>
          <TableCell
            sx={
              global.innerWidth < 599
                ? [
                    classes.lastBodyCell,
                    item.token === 'expired'
                      ? classes.statusBodyCellActiveExpired
                      : classes.statusBodyCellActive,
                  ]
                : item.token === 'expired'
                  ? classes.statusBodyCellActiveExpired
                  : classes.statusBodyCellActive
            }
          >
            {item.token === 'expired' ? t('expired') : t('active')}
          </TableCell>

          <TableCell sx={global.innerWidth < 599 ? classes.displayNone : classes.lastBodyCell}>
            <img src={pendingIcons[index]} alt={'familiarIcon'} />
          </TableCell>
        </TableRow>
      )
    })
    setRowsPending(pending ?? [])
  }, [pendingIcons, selectedPendingUser])

  const handlerDelete = () => {
    if (selectedPendingUser) {
      setPendingUsers((prev) => prev.filter((item) => item.email !== selectedPendingUser.email))
      userService.deleteUserPending(selectedPendingUser.email)
    } else if (user) {
      const loggedUser = localStorage.getItem('logged user')
      if (loggedUser) {
        const loggedUserJSON = JSON.parse(loggedUser)
        if (loggedUserJSON.id === user.id) {
          enqueueSnackbar(t('cantDeleteExistingParticipants'), { variant: 'error' })
          return
        }
      }

      const selectedUserCircle = localStorage.getItem('selected user circle')
      if (selectedUserCircle) {
        const selectedUserCircleJSON = JSON.parse(selectedUserCircle)
        userCircleService.removeParticipant(selectedUserCircleJSON.id, user.id).subscribe((res) => {
          if (res) {
            enqueueSnackbar(t('deleteUserSuccess'), { variant: 'success' })
            setParticipants((prev) => prev.filter((item) => item.id !== user.id))
          } else {
            enqueueSnackbar(t('cantDeleteExistingParticipants'), { variant: 'error' })
          }
        })
      }
    }
  }

  const handlerReInvite = () => {
    if (selectedPendingUser) {
      const userAux: UserPendingDTO = {
        email: selectedPendingUser.email,
        token: '',
        circleId: selectedPendingUser.circleId,
        roleId: selectedPendingUser.roleId,
        date: new Date(),
      }
      userService.deleteUserPending(selectedPendingUser.email).subscribe(() => {
        userService
          .invite(
            selectedPendingUser.email,
            selectedPendingUser.circleId,
            selectedPendingUser.roleId
          )
          .subscribe(() => {
            setPendingUsers((prev) => [
              ...prev.filter((item) => item.email !== selectedPendingUser.email),
              new UserPending(userAux),
            ])
            enqueueSnackbar(t('reinviteSuccess'), { variant: 'success' })
          })
      })
      setSelectedPendingUser(undefined)
    }
  }

  const isMobile = window.innerWidth < 599

  return (
    <>
      <Box className={style.containerTableUsers}>
        <Box className={style.containerActions}>
          <p className={style.title}>{props.titleActions}</p>
          <Box className={style.containerSearch}>
            {props.search && (
              <>
                <Box className={style.searchText}>{t('search')}</Box>
                <Searcher
                  search={props.search}
                  autocompleteAction={props.autocompleteAction}
                  autocompleteItems={props.autocompleteItems}
                  autocompleteLabel={props.autocompleteLabel}
                  isPreventDefault={props.isPreventDefault}
                />
              </>
            )}
          </Box>

          {props.hasLinkPermission && !isMobile && (
            <Box className={style.containerAction}>
              <AppButton
                theme={ButtonTheme.addParticipant}
                type={'button'}
                label={props.addButtonLabel}
                handler={props.handlerAdd}
              />

              <AppButton
                theme={ButtonTheme.RemoveGroup}
                type={'button'}
                label={props.removeButtonLabel}
                handler={handlerDelete}
              />
              <AppButton
                theme={
                  selectedPendingUser?.token !== 'expired'
                    ? ButtonTheme.reinvitePartipantDisabled
                    : ButtonTheme.reinviteParticipant
                }
                type={'button'}
                disabled={selectedPendingUser?.token !== 'expired'}
                label={props.reInviteButtonLabel}
                handler={handlerReInvite}
              />
            </Box>
          )}
        </Box>

        <Box className={style.containerParticipants}>
          <TableContainer>
            <Table sx={classes.table}>
              <TableHead>
                <TableRow>{headCells}</TableRow>
              </TableHead>

              <TableBody>{rows}</TableBody>
            </Table>
          </TableContainer>
          <Box className={style.containerPendings}>
            {props.hasLinkPermission && pendingUsers && pendingUsers.length > 0 && (
              <>
                <p className={style.title}>{props.titleNewItems}</p>
                <TableContainer>
                  <Table sx={classes.table}>
                    <TableHead>
                      <TableRow>{headCells2}</TableRow>
                    </TableHead>

                    <TableBody>{rowsPending}</TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
          {props.hasLinkPermission && isMobile && (
            <Box className={style.containerAction}>
              <Box className={style.containerAction2}>
                <AppButton
                  theme={ButtonTheme.RemoveGroup}
                  type={'button'}
                  label={props.removeButtonLabel}
                  handler={handlerDelete}
                />
                <AppButton
                  theme={
                    selectedPendingUser?.token !== 'expired'
                      ? ButtonTheme.reinvitePartipantDisabled
                      : ButtonTheme.reinviteParticipant
                  }
                  type={'button'}
                  disabled={selectedPendingUser?.token !== 'expired'}
                  label={props.reInviteButtonLabel}
                  handler={handlerReInvite}
                />
              </Box>

              <AppButton
                theme={ButtonTheme.addParticipant}
                type={'button'}
                label={props.addButtonLabel}
                handler={props.handlerAdd}
                fullWidth={true}
              />
            </Box>
          )}
          <Box className={style.roleContainer}>
            <Box>
              <p>{props.titleCaption}:</p>
            </Box>
            <Box className={style.role}>
              <img src={professionalSMS} alt={t('professionalSMS')} />
              {t('professionalSMS')}
            </Box>
            <Box className={style.role}>
              <img src={patient} alt={t('patient')} />
              {t('patient')}
            </Box>
            <Box className={style.role}>
              <img src={career} alt={t('carer/Teacher')} />
              {t('carer/Teacher')}
            </Box>
            <Box className={style.role}>
              <img src={family} alt={t('family/Tutor')} />
              {t('family/Tutor')}
            </Box>
            <Box className={style.role}>
              <img src={externProfessional} alt={t('externProfessional')} />
              {t('externProfessional')}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
