import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'
import stepIcon from '../../../../assets/dashboard/stepsIcon.svg'
import caloriesIcon from '../../../../assets/dashboard/caloriesIcon.svg'
import style from './MonitoredActivityCard.module.scss'
import { useTranslation } from 'react-i18next'
import { useGetActiveUser } from '../../../../hooks/get-active-user/useGetActiveUser'
import {
  avatarStyle,
  dividerStyle,
  TypographyProfileText,
  TypographyProfileTitleInformation,
} from './MonitoredActivityCardStyleMui'
import ProgressCircle from '../../../../components/dashboard/circle-progress/CircleProgress'
import ProgressSemiCircle from '../../../../components/dashboard/circle-progress/SemiCircleProgress'
import { dateToYYYYMMDD } from '../../../../common/utils/date'
import { getPatientDataContainer } from '../../../../container/patient-data-module'
import { WearableService } from '../../../../modules/patient-data/services/WearableService'
import { WEARABLE_SERVICE_KEY } from '../../../../modules/patient-data/container'
import styles from '../../../patient-data/wearables/Wearables.module.css'
import genericStyle from '../../../../common/utils/generic.module.css'
import { Graphs } from '../../../patient-data/wearables/Graphs'

type MonitoredActivityCardProps = {}

const patientDataContainer = getPatientDataContainer()
const wearableService = patientDataContainer.get<WearableService>(WEARABLE_SERVICE_KEY)

export const MonitoredActivityCard: React.FC<MonitoredActivityCardProps> = () => {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { activeUser } = useGetActiveUser()
  const isXS = useMediaQuery('max-width: 599px')
  const [wearablesData, setWearablesData] = useState<any>({})
  const [visibleCard, setVisibleCard] = useState<boolean>(false)
  const [dataType, setType] = useState<string>('')

  useEffect(() => {
    // let date = new Date()
    const newDate = dateToYYYYMMDD(new Date())
    wearableService.getData(newDate).subscribe((res) => {
      // alert(res)
      if (res) {
        setWearablesData(res)
      }
      // console.log(res)
    })
  }, [])

  const getDistances = (): any => {
    if (
      wearablesData.dailyActivitySummaryResponse &&
      wearablesData.dailyActivitySummaryResponse.summary.distances &&
      wearablesData.dailyActivitySummaryResponse.summary.distances.length > 0
    ) {
      let aux = 0
      aux = wearablesData.dailyActivitySummaryResponse.summary.distances
        .find((obj: { activity: string }) => obj.activity === 'total')
        .distance.toFixed(2)
      return aux
    }
    return '0 '
  }

  const getGoalDistance = (): any => {
    if (wearablesData.dailyActivitySummaryResponse) {
      const aux = wearablesData.dailyActivitySummaryResponse.goals.distance
      if (aux === 0) return '0'
      const total = (getDistances() / aux) * 100
      return total === Infinity ? 0 : Math.trunc(total)
    }
    return '0 '
  }

  const getSleepEfficiency = (): any => {
    if (
      wearablesData &&
      wearablesData.sleep &&
      wearablesData.sleep.length > 0
    ) {
      return wearablesData.sleep[wearablesData.sleep.length - 1].efficiency
    }
    return '0 '
  }

  const parseSleepMinutes = (): any => {
    if (wearablesData && wearablesData.sleep && wearablesData.sleep[wearablesData.sleep.length - 1].minutesAsleep) {
      const minutes = wearablesData.sleep[wearablesData.sleep.length - 1].minutesAsleep
      let h: number = Math.floor(minutes / 60)
      let m: number = minutes % 60
      // @ts-ignore
      h = h < 10 ? '0' + h : h
      // @ts-ignore
      m = m < 10 ? '0' + m : m
      return h + ':' + m
    }
    return '0 '
  }

  const changeToGraphic = (type: string): any => {
    setType(type)
    setVisibleCard(true)
  }

  const returnToWearables = (): any => {
    setVisibleCard(false)
  }

  if (visibleCard) {
    return (
      <Box className={genericStyle.pageContainer}>
        <Box
          className={styles.chartContainer}
          flexGrow={'1'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Graphs returnToWearables={returnToWearables} type={dataType} />
        </Box>
      </Box>
    )
  } else
    return (
      <Grid container direction={isXS ? 'column' : 'row'} spacing={2} mt={1}>
        <Grid
          display={'flex'}
          flexWrap={'wrap'}
          flexDirection={'row'}
          mt={4}
          item
          xs={12}
          sm={6}
          md={12}
        >
          <Grid item xs={12} sm={6} md={3} className={styles.marginCard}>
            <Card className={style.cardProfile}>
              <CardContent className={style.containerCard}>
                <Avatar
                  sx={avatarStyle}
                  src={wearablesData.userResponse && wearablesData.userResponse.user.avatar640}
                />
                <Typography variant="h6" gutterBottom>
                  {`${wearablesData.userResponse && wearablesData.userResponse.user.displayName}`}
                </Typography>
                <Box display={'flex'} mt={4}>
                  <Box display={'flex'} alignItems={'center'}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                      <Typography sx={TypographyProfileTitleInformation}>{t('age')}</Typography>
                      <Typography sx={TypographyProfileText}>
                        {wearablesData.userResponse && wearablesData.userResponse.user.age} años
                      </Typography>
                    </Box>
                    <Divider orientation={'vertical'} sx={dividerStyle} />
                  </Box>
                  <Box display={'flex'} alignItems={'center'}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                      <Typography sx={TypographyProfileTitleInformation}>{t('height')}</Typography>
                      <Typography sx={TypographyProfileText}>
                        {wearablesData.userResponse && wearablesData.userResponse.user.height} cm
                      </Typography>
                    </Box>
                    <Divider orientation={'vertical'} sx={dividerStyle} />
                  </Box>
                  <Box display={'flex'} alignItems={'center'}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                      <Typography sx={TypographyProfileTitleInformation}>{t('weight')}</Typography>
                      <Typography sx={TypographyProfileText}>
                        {wearablesData.userResponse && wearablesData.userResponse.user.weight} Kg
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={6}
            sm={2}
            md={2}
            className={styles.marginCard}
            onClick={() => changeToGraphic('steps')}
          >
            <Card className={styles.card}>
              <CardContent className={styles.containerCard}>
                <Avatar src={stepIcon} sx={avatarStyle} />
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Typography variant="h6" gutterBottom>
                    {t('steps')}
                  </Typography>
                  <Typography sx={TypographyProfileText}>
                    {wearablesData.dailyActivitySummaryResponse &&
                      wearablesData.dailyActivitySummaryResponse.summary.steps}{' '}
                    {t('today')}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={6}
            sm={6}
            md={2}
            className={styles.marginCard}
            onClick={() => changeToGraphic('calories')}
          >
            <Card className={styles.card}>
              <CardContent className={styles.containerCard}>
                <Avatar src={caloriesIcon} sx={avatarStyle} />
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Typography variant="h6" gutterBottom>
                    {t('calories')}
                  </Typography>
                  <Typography sx={TypographyProfileText}>
                    {wearablesData.dailyActivitySummaryResponse &&
                      wearablesData.dailyActivitySummaryResponse.summary.caloriesOut}{' '}
                    {t('today')}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            className={styles.marginCard}
            onClick={() => changeToGraphic('distance')}
          >
            <Card className={styles.card}>
              <CardContent className={styles.containerCard}>
                <ProgressSemiCircle percentage={getGoalDistance()} />
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Typography variant="h6" gutterBottom>
                    {t('distance')}
                  </Typography>
                  <Typography sx={TypographyProfileText}>
                    {getDistances() + 'km ' + t('today')}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            className={styles.marginCard}
            onClick={() => changeToGraphic('sleepHours')}
          >
            <Card className={styles.card}>
              <CardContent className={styles.containerCard}>
                <ProgressCircle progress={getSleepEfficiency()} />
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Typography variant="h6" gutterBottom>
                    {t('sleepHours')}
                  </Typography>
                  <Typography sx={TypographyProfileText}>
                    {' '}
                    {parseSleepMinutes() + ' h ' + t('today')}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    )
}
