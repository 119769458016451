// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChatsCard_chatContainer__r1APo{\n  display: flex;\n  align-items: center;\n  border: 2px solid #68B3E0;\n  padding:10px;\n  border-radius:10px;\n  box-shadow: 0px 4px 4px 0px #00000040 !important;\n  cursor: pointer;\n  background: white;\n  margin-bottom: 0.75rem;\n  width: 100%;\n  padding: .65rem 1rem!important;\n  grid-gap: 0.5rem;\n  gap: 0.5rem;\n}\n\n\n.ChatsCard_chatContainer__r1APo:last-child {\n  margin-bottom: 0!important;\n}\n\n\n.ChatsCard_listItemText__qtKzq{\n  font-family: 'Poppins', sans-serif !important;\n  text-transform: capitalize;\n}", "",{"version":3,"sources":["webpack://src/features/dashboard/patient/chats-card/ChatsCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,gDAAgD;EAChD,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,WAAW;EACX,8BAA8B;EAC9B,gBAAW;EAAX,WAAW;AACb;;;AAGA;EACE,0BAA0B;AAC5B;;;AAGA;EACE,6CAA6C;EAC7C,0BAA0B;AAC5B","sourcesContent":[".chatContainer{\n  display: flex;\n  align-items: center;\n  border: 2px solid #68B3E0;\n  padding:10px;\n  border-radius:10px;\n  box-shadow: 0px 4px 4px 0px #00000040 !important;\n  cursor: pointer;\n  background: white;\n  margin-bottom: 0.75rem;\n  width: 100%;\n  padding: .65rem 1rem!important;\n  gap: 0.5rem;\n}\n\n\n.chatContainer:last-child {\n  margin-bottom: 0!important;\n}\n\n\n.listItemText{\n  font-family: 'Poppins', sans-serif !important;\n  text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatContainer": "ChatsCard_chatContainer__r1APo",
	"listItemText": "ChatsCard_listItemText__qtKzq"
};
export default ___CSS_LOADER_EXPORT___;
