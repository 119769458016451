// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Editor_emailBox__2Z3Sn {\n  margin-top: 2.5%;\n  margin-left: 1.5%;\n}\n\n.Editor_participantBox__1Rl2j {\n  margin-left: 1.5%;\n  margin-top: 3%;\n}\n\n@media (max-width: 480px) {\n  .Editor_emailBox__2Z3Sn {\n    width: 100%;\n  }\n}\n\n@media (max-width: 480px) {\n  .Editor_participantBox__1Rl2j {\n    width: 300%;\n  }\n}\n\n@media (max-width: 1024px) {\n  .Editor_emailBox__2Z3Sn {\n    width: 80%;\n  }\n}\n\n@media (max-width: 1024px) {\n  .Editor_participantBox__1Rl2j {\n    width: 260%;\n  }\n}\n\n@media (min-width: 1025px) {\n  .Editor_participantBox__1Rl2j {\n    width: 200%;\n  }\n}\n\n@media (min-width: 1025px) {\n  .Editor_emailBox__2Z3Sn {\n    width: 75%;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/features/participant/Editor.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".emailBox {\n  margin-top: 2.5%;\n  margin-left: 1.5%;\n}\n\n.participantBox {\n  margin-left: 1.5%;\n  margin-top: 3%;\n}\n\n@media (max-width: 480px) {\n  .emailBox {\n    width: 100%;\n  }\n}\n\n@media (max-width: 480px) {\n  .participantBox {\n    width: 300%;\n  }\n}\n\n@media (max-width: 1024px) {\n  .emailBox {\n    width: 80%;\n  }\n}\n\n@media (max-width: 1024px) {\n  .participantBox {\n    width: 260%;\n  }\n}\n\n@media (min-width: 1025px) {\n  .participantBox {\n    width: 200%;\n  }\n}\n\n@media (min-width: 1025px) {\n  .emailBox {\n    width: 75%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emailBox": "Editor_emailBox__2Z3Sn",
	"participantBox": "Editor_participantBox__1Rl2j"
};
export default ___CSS_LOADER_EXPORT___;
