/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Avatar, Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import genericStyle from '../../../common/utils/generic.module.css'
import { ROUTE_GOALS_FORM, ROUTE_LINK_WEARABLE, ROUTE_PATIENT_DATA } from '../../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { WearableType, wereableTypes } from '../../../modules/patient-data/enums/WearableType'
import styles from './Wearables.module.css'
import { getUserContainer } from '../../../container/user-module'
import { WearableService } from '../../../modules/patient-data/services/WearableService'
import { WEARABLE_SERVICE_KEY } from '../../../modules/patient-data/container'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { Query, QueryParam } from '../../../common/api/Query'
import { Wearable, WearableQuery } from '../../../modules/patient-data/models/Wearable'
import { ICircleService } from '../../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY, LOGGED_USER_SERVICE_KEY } from '../../../modules/users/container'
import { LoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { Permission } from '../../../common/permission'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useGetActiveUser } from '../../../hooks/get-active-user/useGetActiveUser'
import {
  avatarStyle,
  dividerStyle,
  TypographyProfileText,
  TypographyProfileTitleInformation,
} from '../../dashboard/patient/monitored-activity-card/MonitoredActivityCardStyleMui'
import stepIcon from '../../../assets/dashboard/stepsIcon.svg'
import peso from '../../../assets/dashboard/peso.svg'
import termometro from '../../../assets/dashboard/termometro.png'
import bascula from '../../../assets/dashboard/bascula.png'
import caloriesIcon from '../../../assets/dashboard/caloriesIcon.svg'
import ProgressSemiCircle from '../../../components/dashboard/circle-progress/SemiCircleProgress'
import ProgressCircle from '../../../components/dashboard/circle-progress/CircleProgress'
import { LineChart } from '@mui/x-charts'

// import wereablesData from "./mock.json"
import { dateToYYYYMMDD } from '../../../common/utils/date'
import { Graphs } from './Graphs'

const wearableTypes = wereableTypes()

const patientDataContainer = getPatientDataContainer()
const wearableService = patientDataContainer.get<WearableService>(WEARABLE_SERVICE_KEY)

const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const xLabels = ['09:00', '12:00']

const heartRate = [67, 67]

export const Wearables = () => {
  const circle = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY).getActiveUser()

  const { t } = useTranslation()

  const [tabValue, setTabValue] = useState<number>(0)
  const [fromDate, setFromDate] = useState<Date>(new Date())
  const [toDate, setToDate] = useState<Date>(new Date())
  const { activeUser } = useGetActiveUser()
  const [wearableResult, setWearableResult] = useState<Wearable[]>([])
  const [wearableCharts, setWearableCharts] = useState<object[]>([])
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const navigate = useNavigate()
  const [hasLogin, setHasLogin] = useState<boolean>(false)
  const [showDashboard, setShowDashBoard] = useState<boolean>(false)
  const [visibleCard, setVisibleCard] = useState<boolean>(false)
  const [dataType, setType] = useState<string>('')
  const [wearablesData, setWearablesData] = useState<any>({})
  const [restingHeartData, setRestingHeartData] = useState<any>(0)

  useEffect(() => {
    if (localStorage.getItem('logged user')) {
      const user = JSON.parse(localStorage.getItem('selected user') ?? 'null')
      let userId: any
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // eslint-disable-next-line prefer-const
      userId = user.id
      wearableService.getLoginType(userId).subscribe((res) => {
        setHasLogin(res > 0)
      })
    }
  }, [])

  useEffect(() => {
    // let date = new Date()
    const newDate = dateToYYYYMMDD(fromDate)
    wearableService.getData(newDate).subscribe((res) => {
      if (res) {
        setWearablesData(res)
      }
    })
    if (localStorage.getItem('logged user')) {
      const user = JSON.parse(localStorage.getItem('selected user') ?? 'null')
      let userId: any
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // eslint-disable-next-line prefer-const
      userId = user.id
      wearableService
        .getHeartRateByDate(
          new Query({
            query: [
              new QueryParam<WearableQuery>('userID', userId),
              new QueryParam<WearableQuery>('startDate', fromDate.toISOString()),
              new QueryParam<WearableQuery>('finishDate', fromDate.toISOString()),
            ],
          })
        )
        .subscribe((res) => {
          if (res['activities-heart']) {
            setRestingHeartData(res['activities-heart'][0].value.restingHeartRate)
          }
        })
    }
  }, [fromDate])

  // useEffect(() => {
  //   let date = fromDate
  //   let newDate = dateToYYYYMMDD(date)
  //   wearableService.getData(newDate).subscribe((res) => {
  //     if(res){
  //       setWearablesData(res)
  //     }
  //     // console.log(res)
  //   })
  // }, [fromDate])

  useEffect(() => {
    if (!circle) {
      return
    }

    wearableService
      .getFilteredItems(
        new Query({
          query: [
            new QueryParam<WearableQuery>('userID', circle.id),
            new QueryParam<WearableQuery>('startDate', fromDate.toISOString()),
            new QueryParam<WearableQuery>('finishDate', toDate.toISOString()),
            new QueryParam<WearableQuery>('type', tabValue + 1),
          ],
        })
      )
      .subscribe((res) => {
        setWearableResult(res)
      })
  }, [tabValue, fromDate, toDate])

  useEffect(() => {
    if (!wearableResult.length) {
      return
    }

    const numberOfValues = wearableResult[0].values.length
    const tmpWearableChart = []

    for (let i = 0; i < numberOfValues; i++) {
      tmpWearableChart.push({
        label: wearableDatasetName(i),
        data: wearableResult.map((wr) => wr.values[i]),
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
      })
    }
    setWearableCharts(tmpWearableChart)
  }, [wearableResult])

  const goBack = () => navigate(ROUTE_PATIENT_DATA)

  const goGoals = () => navigate(ROUTE_GOALS_FORM)

  const linkWearable = () => navigate(ROUTE_LINK_WEARABLE)

  const changeTabValue = (event: React.ChangeEvent<{}>, tabNumber: number) => setTabValue(tabNumber)

  const handleFromDateChange = (date: any) => {
    if (date) {
      setFromDate(date.toDate())
    }
  }

  const measurementUnit = (): string => {
    switch (tabValue + 1) {
      case WearableType.Steps:
        return t('steps')
      case WearableType.Calories:
        return 'kcal'
      case WearableType.Distance:
        return t('meters')
      case WearableType.SleepHours:
        return t('hours')
      case WearableType.RestingPulse:
        return t('pulse')
      case WearableType.OxygenSaturation:
        return '%'
      case WearableType.Weight:
        return 'kg'
      case WearableType.Fat:
        return '%'
      case WearableType.BloodPressure:
        return 'mmHG'
      case WearableType.Temperature:
        return '°C'
      default:
        return ''
    }
  }

  const wearableDatasetName = (value: number): string => {
    switch (tabValue + 1) {
      case WearableType.Steps:
        return t('steps')
      case WearableType.Calories:
        return t('calories')
      case WearableType.Distance:
        return t('distance')
      case WearableType.SleepHours:
        return t('sleepHours')
      case WearableType.RestingPulse:
        return t('restingPulse')
      case WearableType.OxygenSaturation:
        return t('oxygenSaturation')
      case WearableType.Weight:
        return t('weight')
      case WearableType.Fat:
        return t('fat')
      case WearableType.BloodPressure:
        if (value === 0) {
          return t('systolic')
        }
        return t('diastolic')
      case WearableType.Temperature:
        return t('temperature')
      default:
        return ''
    }
  }

  const chartTypeByWearables = (): string => {
    switch (tabValue + 1) {
      case WearableType.BloodPressure:
        return 'line'
      case WearableType.Weight:
        return 'line'
      default:
        return 'bar'
    }
  }

  const getSleepEfficiency = (): any => {
    if (
      wearablesData &&
      wearablesData.sleep &&
      wearablesData.sleep.length > 0
    ) {
      return wearablesData.sleep[wearablesData.sleep.length - 1].efficiency
    }
    return '0 '
  }

  const parseSleepMinutes = (): any => {
    if (wearablesData && wearablesData.sleep && wearablesData.sleep[wearablesData.sleep.length - 1].minutesAsleep) {
      const minutes = wearablesData.sleep[wearablesData.sleep.length - 1].minutesAsleep
      let h: number = Math.floor(minutes / 60)
      let m: number = minutes % 60
      // @ts-ignore
      h = h < 10 ? '0' + h : h
      // @ts-ignore
      m = m < 10 ? '0' + m : m
      return h + ':' + m
    }
    return '0 '
  }

  const getDistances = (): any => {
    if (
      wearablesData.dailyActivitySummaryResponse &&
      wearablesData.dailyActivitySummaryResponse.summary.distances &&
      wearablesData.dailyActivitySummaryResponse.summary.distances.length > 0
    ) {
      let aux = 0
      // @ts-ignore
      aux = wearablesData.dailyActivitySummaryResponse.summary.distances
        .find((obj: { activity: string }) => obj.activity === 'total')
        .distance.toFixed(2)
      return aux
    }
    return '0 '
  }

  const getGoalDistance = (): any => {
    if (wearablesData.dailyActivitySummaryResponse) {
      const aux = wearablesData.dailyActivitySummaryResponse.goals.distance
      if (aux === 0) return '0'
      const total = (getDistances() / aux) * 100
      return total === Infinity ? 0 : Math.trunc(total)
    }
    return '0 '
  }

  const changeToGraphic = (type: string): any => {
    setType(type)
    setVisibleCard(true)
  }

  const checkData = (data: string): string => {
    if (data) {
      return data
    }
    return ''
  }

  const returnToWearables = (): any => {
    setVisibleCard(false)
  }

  if (visibleCard) {
    return (
      <Box className={genericStyle.pageContainer}>
        <Box
          className={styles.chartContainer}
          flexGrow={'1'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Graphs returnToWearables={returnToWearables} type={dataType} />
        </Box>
      </Box>
    )
  } else
    return (
      <Box className={genericStyle.pageContainer}>
        <Box>
          <Box mb={3} display={'flex'} justifyContent={'space-between'}>
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('back')}
              handler={goBack}
            />
            {/* {hasLogin && ( */}
            {/*  <AppButton */}
            {/*    theme={ButtonTheme.NewPrimary} */}
            {/*    type={'button'} */}
            {/*    label={t('setGoals')} */}
            {/*    handler={goGoals} */}
            {/*  /> */}
            {/* )} */}
            {loggedUserService.userCan(Permission.linkWearable) && (
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={hasLogin ? t('unlinkWearable') : t('linkWearable')}
                handler={linkWearable}
              />
            )}
          </Box>
        </Box>
        <Box className={styles.dateContainer} mb={4} display={'flex'} justifyContent={'flex-end'}>
          <Box flexDirection={'row'} display={'flex'} width={500} justifyContent={'space-between'}>
            <p>{t('selectDate')}:</p>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
              <DatePicker
                key={'date'}
                PopperProps={{
                  sx: {
                    '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                      fontFamily: 'Poppins',
                      textTransform: 'capitalize',
                    },
                    '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                      fontFamily: 'Poppins',
                    },
                    '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                      fontFamily: 'Poppins',
                    },
                    '& .css-3eghsz-PrivatePickersYear-button': {
                      fontFamily: 'Poppins',
                    },
                  },
                }}
                onError={(reason, value) => {
                  switch (reason) {
                    case 'invalidDate':
                      setDateTimePickerError(t('invalidDateMessage'))
                      break
                    case 'minDate':
                      setDateTimePickerError(t('minDateMessage'))
                      break
                  }
                }}
                renderInput={(props) => (
                  <TextField
                    sx={{
                      '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                      '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                        fontFamily: 'Poppins',
                      },
                    }}
                    variant={'outlined'}
                    helperText={props.error && DateTimePickerError}
                    {...props}
                  />
                )}
                inputFormat="DD/MM/YYYY"
                value={fromDate}
                onChange={handleFromDateChange}
              />
            </LocalizationProvider>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid
            display={'flex'}
            flexWrap={'wrap'}
            flexDirection={'row'}
            mt={4}
            item
            xs={12}
            sm={6}
            md={12}
          >
            <Grid item xs={12} sm={3} md={3} className={styles.marginCard}>
              <Card className={styles.cardProfile}>
                <CardContent className={styles.containerCard}>
                  <Avatar
                    sx={avatarStyle}
                    src={wearablesData.userResponse && wearablesData.userResponse.user.avatar640}
                  />
                  <Typography variant="h6" gutterBottom>
                    {`${wearablesData.userResponse && wearablesData.userResponse.user.displayName}`}
                  </Typography>
                  <Box display={'flex'} mt={4}>
                    <Box display={'flex'} alignItems={'center'}>
                      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Typography sx={TypographyProfileTitleInformation}>{t('age')}</Typography>
                        <Typography sx={TypographyProfileText}>
                          {wearablesData.userResponse && wearablesData.userResponse.user.age} años
                        </Typography>
                      </Box>
                      <Divider orientation={'vertical'} sx={dividerStyle} />
                    </Box>
                    <Box display={'flex'} alignItems={'center'}>
                      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Typography sx={TypographyProfileTitleInformation}>
                          {t('height')}
                        </Typography>
                        <Typography sx={TypographyProfileText}>
                          {wearablesData.userResponse && wearablesData.userResponse.user.height} cm
                        </Typography>
                      </Box>
                      <Divider orientation={'vertical'} sx={dividerStyle} />
                    </Box>
                    <Box display={'flex'} alignItems={'center'}>
                      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Typography sx={TypographyProfileTitleInformation}>
                          {t('weight')}
                        </Typography>
                        <Typography sx={TypographyProfileText}>
                          {wearablesData.userResponse && wearablesData.userResponse.user.weight} Kg
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xs={6}
              sm={2}
              md={2}
              className={styles.marginCard}
              onClick={() => changeToGraphic('steps')}
            >
              <Card className={styles.card}>
                <CardContent className={styles.containerCard}>
                  <Avatar src={stepIcon} sx={avatarStyle} />
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant="h6" gutterBottom>
                      {t('steps')}
                    </Typography>
                    <Typography
                      sx={TypographyProfileText}>{wearablesData.dailyActivitySummaryResponse && wearablesData.dailyActivitySummaryResponse.summary.steps} {t('steps')+" "+t('today')}</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              className={styles.marginCard}
              onClick={() => changeToGraphic('calories')}
            >
              <Card className={styles.card}>
                <CardContent className={styles.containerCard}>
                  <Avatar src={caloriesIcon} sx={avatarStyle} />
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant="h6" gutterBottom>
                      {t('calories')}
                    </Typography>
                    <Typography
                      sx={TypographyProfileText}>{wearablesData.dailyActivitySummaryResponse && wearablesData.dailyActivitySummaryResponse.summary.caloriesOut} KCal {t('today')}</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              className={styles.marginCard}
              onClick={() => changeToGraphic('distance')}
            >
              <Card className={styles.card}>
                <CardContent className={styles.containerCard}>
                  <ProgressSemiCircle percentage={getGoalDistance()} />
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant="h6" gutterBottom>
                      {t('distance')}
                    </Typography>
                    <Typography sx={TypographyProfileText}>
                      {getDistances() + ' km ' + t('today')}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              className={styles.marginCard}
              onClick={() => changeToGraphic('sleepHours')}
            >
              <Card className={styles.card}>
                <CardContent className={styles.containerCard}>
                  <ProgressCircle progress={getSleepEfficiency()} />
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant="h6" gutterBottom>
                      {t('sleepHours')}
                    </Typography>
                    <Typography sx={TypographyProfileText}>
                      {' '}
                      {parseSleepMinutes() + ' h ' + t('today')}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className={styles.marginCard}
              onClick={() => changeToGraphic('pulse2')}
            >
              <Card className={styles.card}>
                <CardContent className={styles.containerCard}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={'left'}>
                    <Typography variant="h6" gutterBottom>
                      {t('pulse2')}
                    </Typography>
                  </Box>
                  <LineChart
                    xAxis={[{ scaleType: 'band', data: xLabels }]}
                    series={[
                      {
                        curve: 'linear',
                        data: [restingHeartData, restingHeartData],
                      },
                    ]}
                    width={500}
                    height={200}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={6}
              sm={2}
              md={2}
              className={styles.marginCard}
              onClick={() => changeToGraphic('bloodPreasure')}
            >
              <Card className={styles.card}>
                <CardContent className={styles.containerCard}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                    <Typography variant="h6" gutterBottom>
                      {t('bloodPreasure')}
                    </Typography>
                    <Typography sx={TypographyProfileText}>0 mmHg</Typography>
                    {/* <Typography variant="h6" gutterBottom> */}
                    {/*  {t('pulse')} */}
                    {/* </Typography> */}
                    {/* <Typography sx={TypographyProfileText}> */}
                    {/*  {wearablesData.ecgReadingsResponse && */}
                    {/*    wearablesData.ecgReadingsResponse.ecgReadings && */}
                    {/*    wearablesData.ecgReadingsResponse.ecgReadings.length > 0 && */}
                    {/*    wearablesData.ecgReadingsResponse.ecgReadings[wearablesData.ecgReadingsResponse.ecgReadings.length - 1].averageHeartRate} ppm */}
                    {/* </Typography> */}
                    <Typography variant="h6" gutterBottom>
                      {'Oxígeno en sangre'}
                    </Typography>
                    <Typography sx={TypographyProfileText}>
                      {wearablesData.spo2Response && wearablesData.spo2Response.value.avg}%
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              className={styles.marginCard}
              onClick={() => changeToGraphic('fat')}
            >
              <Card className={styles.card}>
                <CardContent className={styles.containerCard}>
                  <Avatar src={peso} sx={avatarStyle} />
                  <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                    <Typography variant="h3" gutterBottom>
                      {wearablesData.fatResponse &&
                      wearablesData.fatResponse.fat &&
                      wearablesData.fatResponse.fat.length
                        ? wearablesData.fatResponse.fat[wearablesData.fatResponse.fat.length - 1]
                            .fat
                        : '0'}{' '}
                      %
                    </Typography>
                    <Typography sx={TypographyProfileText}>{t('fat')}</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={6}
              sm={2}
              md={2}
              className={styles.marginCard}
              onClick={() => changeToGraphic('weight')}
            >
              <Card className={styles.card}>
                <CardContent className={styles.containerCard}>
                  <Avatar src={bascula} sx={avatarStyle} />
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant="h6" gutterBottom>
                      {t('weight')}
                    </Typography>
                    <Typography
                      sx={TypographyProfileText}>{wearablesData.userResponse && wearablesData.userResponse.user.weight} kg</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={6}
              sm={2}
              md={2}
              className={styles.marginCard}
              onClick={() => changeToGraphic('temperature')}
            >
              <Card className={styles.card}>
                <CardContent className={styles.containerCard}>
                  <Avatar src={termometro} sx={avatarStyle} />
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant="h6" gutterBottom>
                      {t('temperature')}
                    </Typography>
                    <Typography sx={TypographyProfileText}>
                      {wearablesData.temperatureResponse &&
                      wearablesData.temperatureResponse.tempCore &&
                      wearablesData.temperatureResponse.tempCore.length > 0 ?
                      wearablesData.temperatureResponse.tempCore[wearablesData.temperatureResponse.tempCore.length - 1].value + ' ºC': "0 ºC"}</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
}
