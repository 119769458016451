// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActiveQueriesCard_chatContainer__2hlGf{\n    display: flex;\n    align-items: center;\n    border: 2px solid #68B3E0;\n    padding:10px;\n    border-radius:10px;\n    box-shadow: 0px 4px 4px 0px #00000040 !important;\n    cursor: pointer;\n    background: white;\n    margin-bottom: 0.75rem;\n    width: 100%;\n    padding: .65rem 1rem!important;\n    grid-gap: 0.5rem;\n    gap: 0.5rem;\n  }\n  \n  \n  .ActiveQueriesCard_chatContainer__2hlGf:last-child {\n    margin-bottom: 0!important;\n  }\n  \n  \n  .ActiveQueriesCard_listItemText__nZ63J{\n    font-family: 'Poppins', sans-serif !important;\n    text-transform: capitalize;\n  }", "",{"version":3,"sources":["webpack://src/features/dashboard/patient/active-query-card/ActiveQueriesCard.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,gDAAgD;IAChD,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,WAAW;IACX,8BAA8B;IAC9B,gBAAW;IAAX,WAAW;EACb;;;EAGA;IACE,0BAA0B;EAC5B;;;EAGA;IACE,6CAA6C;IAC7C,0BAA0B;EAC5B","sourcesContent":[".chatContainer{\n    display: flex;\n    align-items: center;\n    border: 2px solid #68B3E0;\n    padding:10px;\n    border-radius:10px;\n    box-shadow: 0px 4px 4px 0px #00000040 !important;\n    cursor: pointer;\n    background: white;\n    margin-bottom: 0.75rem;\n    width: 100%;\n    padding: .65rem 1rem!important;\n    gap: 0.5rem;\n  }\n  \n  \n  .chatContainer:last-child {\n    margin-bottom: 0!important;\n  }\n  \n  \n  .listItemText{\n    font-family: 'Poppins', sans-serif !important;\n    text-transform: capitalize;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatContainer": "ActiveQueriesCard_chatContainer__2hlGf",
	"listItemText": "ActiveQueriesCard_listItemText__nZ63J"
};
export default ___CSS_LOADER_EXPORT___;
