// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_divHeader__2aNaG {\n  background-color: #ffffff;\n  padding: 7px;\n}\n\n.Header_divHeaderLeft__OtBBI > * {\n  margin-left: 1rem;\n}\n\n.Header_contactFullName__HNDMU {\n  margin-right: 1rem;\n  font-weight: bold;\n  font-size: 16px;\n}\n\n.Header_tooltipConversationUsers__TBCPR {\n  margin-left: 1rem;\n}\n\n.Header_conversationLeft__3lWCy {\n  flex-flow: column;\n  align-items: end;\n}\n\n.Header_conversationLeft__3lWCy p {\n  padding-top: 0;\n  margin-top: 0;\n  margin-bottom: 0;\n}\n\n.Header_conversationOptions__1ZHMh {\n  justify-content: flex-end;\n  padding-bottom: 0;\n  width: 100%;\n}\n\n.Header_conversationOptions__1ZHMh > div {\n  width: 2rem;\n  display: flex;\n  justify-content: center;\n}\n\n.Header_MuiAvatar-fallback__18Jzq {\n  background-color: #68b3e0;\n}\n", "",{"version":3,"sources":["webpack://src/features/teleconsultation/Header.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".divHeader {\n  background-color: #ffffff;\n  padding: 7px;\n}\n\n.divHeaderLeft > * {\n  margin-left: 1rem;\n}\n\n.contactFullName {\n  margin-right: 1rem;\n  font-weight: bold;\n  font-size: 16px;\n}\n\n.tooltipConversationUsers {\n  margin-left: 1rem;\n}\n\n.conversationLeft {\n  flex-flow: column;\n  align-items: end;\n}\n\n.conversationLeft p {\n  padding-top: 0;\n  margin-top: 0;\n  margin-bottom: 0;\n}\n\n.conversationOptions {\n  justify-content: flex-end;\n  padding-bottom: 0;\n  width: 100%;\n}\n\n.conversationOptions > div {\n  width: 2rem;\n  display: flex;\n  justify-content: center;\n}\n\n.MuiAvatar-fallback {\n  background-color: #68b3e0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divHeader": "Header_divHeader__2aNaG",
	"divHeaderLeft": "Header_divHeaderLeft__OtBBI",
	"contactFullName": "Header_contactFullName__HNDMU",
	"tooltipConversationUsers": "Header_tooltipConversationUsers__TBCPR",
	"conversationLeft": "Header_conversationLeft__3lWCy",
	"conversationOptions": "Header_conversationOptions__1ZHMh",
	"MuiAvatar-fallback": "Header_MuiAvatar-fallback__18Jzq"
};
export default ___CSS_LOADER_EXPORT___;
