import React, { useState } from 'react'
import { Action, Search, SearchValue } from '../table_type/types'
import { Box, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import style from './AppTable.module.css'
import { useDebounce } from '../../hooks/debounce/useDebounce'
import { SearcherAutocomplete, SearcherDatePicker, SearcherTextField } from './searcherTypes'

type SearchProps<Q> = {
  readonly isLoading?: boolean
  readonly search: Search<Q>
  readonly autocompleteItems?: string[]
  readonly autocompleteLabel?: string
  readonly autocompleteAction?: Action
  disabled?: boolean
  isPreventDefault?: boolean
}

export function Searcher<Q extends Record<string, any>>(props: SearchProps<Q>) {
  const { t } = useTranslation()

  const [values, setValues] = useState<SearchValue<Q>[]>(props.search.searchValues)
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (props.isPreventDefault) {
      e.preventDefault()
    }
    props.search.handleSearch([...values])
  }

  const handleSearch = useDebounce((term) => {
    props.search.handleSearch([...term])
  }, 1000)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    values.find((sv) => e.target.name === sv.name)!.value = e.target.value
    setValues([...values])
    handleSearch([...values])
  }

  const handleDate = (date: Date | null) => {
    if (date == null) {
      try {
        values.forEach((sv) => {
          if (sv.name === 'birthDate') sv.value = ''
          if (sv.name === 'symptomStartDate') sv.value = ''
        })
        setValues([...values])
        props.search.handleSearch([...values])
      } catch (e) {
        console.error(e)
      }
    } else {
      try {
        const dateValue = new Date(date).toDateString()
        values.forEach((sv) => {
          if (sv.name === 'birthDate') sv.value = dateValue
          if (sv.name === 'symptomStartDate') sv.value = dateValue
        })
        setValues([...values])
        handleSearch([...values])
      } catch (e) {
        console.error(e)
      }
    }
  }

  return (
    <form onSubmit={(event) => handleSubmit(event)}>
      <Box className={style.searcherFieldsContainer}>
        {values.map((v) => {
          if (props.isLoading) {
            return <Skeleton key={v.name as string} style={{ width: '40px', height: '16px' }} />
          }
          if (v.type === 'date') {
            return (
              <SearcherDatePicker
                key={v.name as string}
                name={v.name as string}
                placeholder={t('date')}
                onErrorPicker={(v) => setDateTimePickerError(t(v))}
                handleDate={handleDate}
                type={v.type}
                value={v.value ?? ''}
                DateTimePickerError={Boolean(DateTimePickerError)}
              />
            )
          } else {
            return (
              <SearcherTextField
                key={v.name as string}
                name={v.name as string}
                label={v.label ?? ''}
                disabled={Boolean(props.disabled)}
                handleChange={handleChange}
                type={v.type as string}
                value={v.value ?? ''}
              />
            )
          }
        })}
        {props.autocompleteAction && props.autocompleteItems && props.autocompleteLabel && (
          <SearcherAutocomplete
            isLoading={Boolean(props.isLoading)}
            autocompleteAction={props.autocompleteAction}
            autocompleteLabel={props.autocompleteLabel}
            autocompleteItems={props.autocompleteItems}
          />
        )}
      </Box>
    </form>
  )
}
