export type UserTokenProps = {
  code: string
  userID: string
  loginType: number
  codeVerifier: string
  state: string
}

export class UserToken {
  private readonly _code: string
  private readonly _userID: string
  private readonly _loginType: number
  private readonly _codeVerifier: string
  private readonly _state: string

  constructor(p: UserTokenProps) {
    this._code = p.code
    this._userID = p.userID
    this._loginType = p.loginType
    this._codeVerifier = p.codeVerifier
    this._state = p.state
  }

  get code(): string {
    return this._code
  }

  get userID(): string {
    return this._userID
  }

  get loginType(): number {
    return this._loginType
  }

  get codeVerifier(): string {
    return this._codeVerifier
  }

  get state(): string {
    return this._state
  }
}
