// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalGroupManagement_modalOverFlow__2n-E6 {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .ModalGroupManagement_modalContent__1lsod {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    min-width: 800px;\n    max-width: 1400px;\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n  }", "",{"version":3,"sources":["webpack://src/features/dashboard/profesional/group-management/ModalGroupManagement.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,2CAA2C;EAC7C","sourcesContent":[".modalOverFlow {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .modalContent {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    min-width: 800px;\n    max-width: 1400px;\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverFlow": "ModalGroupManagement_modalOverFlow__2n-E6",
	"modalContent": "ModalGroupManagement_modalContent__1lsod"
};
export default ___CSS_LOADER_EXPORT___;
