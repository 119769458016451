// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form_title__2Hc3U {\n  border-bottom: 2px solid #f18b24;\n  margin: 1.5rem;\n}\n\n.form_pageContainer__1GLUO {\n  background: #ffffff;\n  margin: 25px;\n  box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.1);\n  border-radius: 3px;\n  padding: 15px;\n}\n\n.form_pageContainer__1GLUO .form_box__1CbJY {\n  display: flex;\n  width: 100%;\n}\n\n.form_pageContainer__1GLUO .form_label__LZJiA {\n  font-weight: bold;\n  width: 10%;\n}\n\n.form_pageContainer__1GLUO .form_boxButtons__2dVI0 {\n  display: flex;\n  margin-left: 83%;\n  grid-gap: 15px;\n  gap: 15px;\n  justify-content: flex-end;\n}\n\n.form_row__14-wb {\n  display: flex;\n  align-items: center;\n}\n\ninput {\n  min-height: auto !important;\n}\n", "",{"version":3,"sources":["webpack://src/features/vaccines/form.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,6CAA6C;EAC7C,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,cAAS;EAAT,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".title {\n  border-bottom: 2px solid #f18b24;\n  margin: 1.5rem;\n}\n\n.pageContainer {\n  background: #ffffff;\n  margin: 25px;\n  box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.1);\n  border-radius: 3px;\n  padding: 15px;\n}\n\n.pageContainer .box {\n  display: flex;\n  width: 100%;\n}\n\n.pageContainer .label {\n  font-weight: bold;\n  width: 10%;\n}\n\n.pageContainer .boxButtons {\n  display: flex;\n  margin-left: 83%;\n  gap: 15px;\n  justify-content: flex-end;\n}\n\n.row {\n  display: flex;\n  align-items: center;\n}\n\ninput {\n  min-height: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "form_title__2Hc3U",
	"pageContainer": "form_pageContainer__1GLUO",
	"box": "form_box__1CbJY",
	"label": "form_label__LZJiA",
	"boxButtons": "form_boxButtons__2dVI0",
	"row": "form_row__14-wb"
};
export default ___CSS_LOADER_EXPORT___;
