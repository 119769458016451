import style from './Editor.module.css'
import { Box } from '@mui/material'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import React from 'react'
import { FormDTO } from '../../modules/forms/models/FormDTO'
import { useTranslation } from 'react-i18next'

type NewFormSectionProps = {
  form: FormDTO
  handleInput: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  isCreator: boolean
}
export function NewFormSection({ form, handleInput, isCreator }: NewFormSectionProps) {
  const { t } = useTranslation()

  return (
    <Box style={{ display: 'flex', marginBottom: 3 }} className={style.box}>
      <b className={style.descriptionLabel}>{t('description')}*</b>
      <Box display="flex" className={style.textField}>
        <TextFieldItem
          field="description"
          value={form.description}
          label={''}
          type={'text'}
          handleChange={handleInput}
          rows={4}
          required={true}
          disabled={!isCreator}
          maxLength={1000}
        />
      </Box>
    </Box>
  )
}
