import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'
import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { FormGeneratorTable as F } from '../../features/premsProms'

export function PromsPrems(props: RouteProps) {
  const title = props.title ?? ''
  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <F />
    </>
  )
}
