import GenericChartDashboard from '../../../../components/dashboard/generic-chart-dashboard/GenericChartDashboard'
import { ChartEnum } from '../../../../components/dashboard/generic-chart-dashboard/ChartEnum'
import { useEffect, useState } from 'react'
import { getActivePlanContainer } from '../../../../container/active-plan-module'
import { ActivePlanService } from '../../../../modules/active-plan/services/ActivePlanService'
import { ACTIVE_PLAN_SERVICE_KEY } from '../../../../modules/active-plan'

const activePlanService = getActivePlanContainer().get<ActivePlanService>(ACTIVE_PLAN_SERVICE_KEY)

const SportCenterCard = () => {
  const backgroundColors = ['#0063A6', '#F18B24', '#B6B5B5', '#F1DD25', '#68B3E0', '#4CBF0D']
  const [datasets, setDataSets] = useState<
    { data: number[]; backgroundColor: string; label: string }[]
  >([])
  const [label, setLabel] = useState<string[]>([])

  useEffect(() => {
    activePlanService.getNumberOfPatientsByBuilding().subscribe((data) => {
      if (!data) return

      setLabel(createEmptyLabel(data.length))
      setDataSets(
        data.map((item, index) => ({
          data: createArrByLength(data.length, item.numberOfPatients, index),
          backgroundColor: getColor(index),
          label: item.buildingName,
        }))
      )
    })
  }, [])

  const createArrByLength = (length: number, value: number, valueIndex: number): number[] => {
    const auxArr: number[] = []
    for (let i = 0; i < length; i++) {
      if (i === valueIndex) {
        auxArr.push(value)
      } else {
        auxArr.push(0)
      }
    }
    return auxArr
  }

  const createEmptyLabel = (length: number): string[] => {
    const auxArr: string[] = []
    for (let i = 0; i < length; i++) {
      auxArr.push('')
    }
    return auxArr
  }

  const getColor = (index: number): string => {
    if (index === 0) return backgroundColors[index]

    const auxNum = Math.trunc((index + 1) / backgroundColors.length)
    let num = 0
    if (auxNum > 0) {
      num = index - backgroundColors.length * auxNum
      return backgroundColors[num]
    }
    return backgroundColors[index]
  }

  return (
    <GenericChartDashboard
      datasets={datasets}
      labels={label}
      title={''}
      typeChart={ChartEnum.Bar}
      legendPosition={'bottom'}
    />
  )
}
export default SportCenterCard
