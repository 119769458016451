import React, { useState } from 'react'
import homeIcon from '../../assets/navbarResponsive/homeIcon.svg'
import libraryIcon from '../../assets/navbarResponsive/libraryIcon.svg'
import profileIcon from '../../assets/navbarResponsive/profileIcon.svg'
import circleIconNav from '../../assets/navbarResponsive/circleIcon.svg'
import dashboardIconNav from '../../assets/navbarResponsive/dashboardIcon.svg';
import style from './navbarResponsive.module.css'
import { useNavigate } from 'react-router-dom'
import { Box, Card, CardContent, List, ListItem, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  ROUTE_ARTICLES,
  ROUTE_CALENDAR,
  ROUTE_CIRCLE_CONFIGURATOR,
  ROUTE_CIRCLES,
  ROUTE_MESSENGER,
  ROUTE_DASHBOARD,
  ROUTE_NOTIFICATIONS,
  ROUTE_PATIENT_DATA,
  ROUTE_PATIENT_FORMS,
  ROUTE_TELECONSULTATION,
  ROUTE_SEGMENTED_NOTIFICATIONS,
  ROUTE_QUERY,
  ROUTE_RESOURCES,
  ROUTE_SHARE_RESOURCES,
  ROUTE_TAG_ARTICLES,
  ROUTE_USERS,
} from '../../routes/routes-constants'
import calendarIcon from '../../assets/responsive_icons/calendarIconResponsive.svg'
import chatIcon from '../../assets/responsive_icons/chatIconResponsive.svg'
import circleIcon from '../../assets/responsive_icons/circleIconResponsive.svg'
import formIcon from '../../assets/responsive_icons/formIconResponsive.svg'
import dashboardIcon from '../../assets/responsive_icons/dashboardIconResponsive.svg'
import notificationSegmentIcon from '../../assets/responsive_icons/notificationSegmentIconResponsive.svg'
import participantICon from '../../assets/responsive_icons/participantIconResponsive.svg'
import patientDataIcon from '../../assets/responsive_icons/patientDataIconResponsive.svg'
import queryIcon from '../../assets/responsive_icons/queryIconResponsive.svg'
import resourcesIcon from '../../assets/responsive_icons/resourcesIconResponsive.svg'
import teleconsultationIcon from '../../assets/responsive_icons/teleconsultationIconResponsive.svg'
import { LinkType } from '../../pages/layout/NavigationEsfera'
import { canSeeQuery, useIsRelated } from '../../hooks/relatedUsers/getRelatedUsers'
import { getUserContainer } from '../../container/user-module'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import Typography from '@mui/material/Typography'
import { Permission } from '../../common/permission'
import { MobilPreProfile } from './mobilPreProfile'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import {
  emptyProfessionalQueryDTO,
  ProfessionalQueryDTO,
} from 'modules/messenger_consulta/models/ProfessionalQueryDTO'
const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)

const NavbarResponsive: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [open, setOpen] = useState(false)
  const userCircle = userCircleActiveService.getActiveFullUserCircle()
  const [openPreProfile, setOpenPreProfile] = useState<boolean>(false)
  const [professionalQuery] = useState<ProfessionalQueryDTO>(emptyProfessionalQueryDTO())
  const { userCircleService } = useCircleConfiguration()
  const selectedUserCircleConfig = userCircleService?.getActiveFullUserCircle()
  const menuConfigData = selectedUserCircleConfig?.circle.menuConfig

  const hasRelevantRole = [
    'professionalSMS',
    'managerHealthChild',
    'managerActivePlan',
    'managerEpileptic',
  ].some((role) => loggedUser?.roles?.includes(role))

  const canQuery = () => {
    if (!hasRelevantRole) return true
    return professionalQuery.acceptConsulta === 1
  }

  const links: LinkType[] = [
    {
      route: ROUTE_DASHBOARD,
      title: 'dashboard',
      activeIcon: dashboardIcon,
      icon: dashboardIcon,
      altSrc: 'dashboardIcon',
      hoverIcon: dashboardIcon,
      isVisible:
        (userCircle !== undefined &&
          (!loggedUserService.get()?.roles.some((role) => role.startsWith('manager')) ?? false) &&
          menuConfigData?.wall) ??
        false,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_CIRCLES,
      title: 'circleOfPatient',
      activeIcon: circleIcon,
      icon: circleIcon,
      altSrc: 'circleOfPatientIcon',
      hoverIcon: circleIcon,
      isVisible: loggedUserService.userCan(Permission.seeCircles),
      additionalRoutes: [
        ROUTE_NOTIFICATIONS,
        ROUTE_USERS,
        ROUTE_PATIENT_DATA,
        ROUTE_SHARE_RESOURCES,
        ROUTE_PATIENT_FORMS,
        ROUTE_DASHBOARD,
        ROUTE_RESOURCES,
        ROUTE_CALENDAR,
        ROUTE_NOTIFICATIONS,
        ROUTE_QUERY,
        ROUTE_MESSENGER,
        ROUTE_CALENDAR,
      ],
      subMenu: false,
    },
    {
      route: ROUTE_USERS,
      title: 'participants',
      activeIcon: participantICon,
      icon: participantICon,
      altSrc: 'manageCircleIcon',
      hoverIcon: participantICon,
      isVisible: (userCircle !== undefined && menuConfigData?.participant) ?? false,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_PATIENT_DATA,
      title: 'patientData',
      activeIcon: patientDataIcon,
      icon: patientDataIcon,
      altSrc: 'patientDataIcon',
      hoverIcon: patientDataIcon,
      isVisible: (userCircle !== undefined && menuConfigData?.patientData) ?? false,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_MESSENGER,
      title: 'chat',
      activeIcon: chatIcon,
      icon: chatIcon,
      altSrc: 'chatIcon',
      hoverIcon: chatIcon,
      isVisible:
        ((useIsRelated() ? userCircle !== undefined : false) &&
          (!loggedUserService.get()?.roles.some((role) => role.startsWith('manager')) ?? false) &&
          menuConfigData?.chats) ??
        false,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_QUERY,
      title: 'query',
      activeIcon: queryIcon,
      icon: queryIcon,
      altSrc: 'queryIcon',
      hoverIcon: queryIcon,
      isVisible: ((canQuery() ? canSeeQuery() : false) && menuConfigData?.querys) ?? false,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_CALENDAR,
      title: 'calendar',
      activeIcon: calendarIcon,
      icon: calendarIcon,
      altSrc: 'calendarIcon',
      hoverIcon: calendarIcon,
      isVisible: (userCircle !== undefined && menuConfigData?.calendar) ?? false,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_RESOURCES,
      title: 'resources',
      activeIcon: resourcesIcon,
      icon: resourcesIcon,
      altSrc: 'resourcesIcon',
      hoverIcon: resourcesIcon,
      isVisible: userCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_PATIENT_FORMS,
      title: 'patientsForms',
      activeIcon: formIcon,
      icon: formIcon,
      altSrc: 'patientsFormsIcon',
      hoverIcon: formIcon,
      isVisible: userCircle !== undefined && loggedUserService.userCan(Permission.userForms),
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_SEGMENTED_NOTIFICATIONS,
      title: 'segmentedNotifications',
      activeIcon: notificationSegmentIcon,
      icon: notificationSegmentIcon,
      altSrc: 'notificationSegmentIcon',
      hoverIcon: notificationSegmentIcon,
      isVisible: userCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
    {
      route: ROUTE_TELECONSULTATION,
      title: 'Teleconsult',
      activeIcon: teleconsultationIcon,
      icon: teleconsultationIcon,
      altSrc: 'teleconsultationIcon',
      hoverIcon: teleconsultationIcon,
      isVisible: userCircle !== undefined,
      additionalRoutes: [],
      subMenu: true,
    },
  ]
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpenPreProfile = () => {
    setOpenPreProfile(true)
  }

  const handleClosePreProfile = () => {
    setOpenPreProfile(false)
  }

  return (
    <>
      <Box component="nav" className={style.navbar}>
        <List className={style.navbarMenu}>
        <ListItem className={style.navbarItem} >
            <Box
              className={style.navbarLink}
              onClick={() => {
                if (!userCircle) {
                  navigate(ROUTE_CIRCLES)
                } else {
                  navigate(ROUTE_DASHBOARD)
                }
              }}
              role="button"
              tabIndex={0}
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <img src={homeIcon} alt="Home" />
              <span className={style.navbarText}>{t('goToHome')}</span>
            </Box>
          </ListItem>
          <ListItem className={style.navbarItem} >
            <Box
              className={style.navbarLink}
              onClick={handleOpen}
              role="button"
              tabIndex={0}
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <img src={circleIconNav} alt="Círculo" />
              <span className={style.navbarText}>{t('circle')}</span>
            </Box>
          </ListItem>
          <ListItem className={style.navbarItem} >
            <Box
              className={style.navbarLink}
              onClick={() => navigate(ROUTE_DASHBOARD)}
              role="button"
              tabIndex={0}
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <img src={dashboardIconNav} alt="Dashboard" />
              <span className={style.navbarText}>{t('dashboard')}</span>
            </Box>
          </ListItem>
          <ListItem className={style.navbarItem} >
            <Box
              className={style.navbarLink}
              onClick={() => navigate(ROUTE_ARTICLES)}
              role="button"
              tabIndex={0}
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <img src={libraryIcon} alt="Biblioteca" />
              <span className={style.navbarText}>{t('library')}</span>
            </Box>
          </ListItem>
          <ListItem className={style.navbarItem} >
            <Box
              className={style.navbarLink}
              onClick={() => handleOpenPreProfile()}
              role="button"
              tabIndex={0}
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <img src={profileIcon} alt="Mi Perfil" />
              <span className={style.navbarText}>{t('profile')}</span>
            </Box>
          </ListItem>
        </List>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="menu-modal-title"
        aria-describedby="menu-modal-description"
        sx={{ overflow: 'scroll' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            backgroundColor: 'white',
            height: '100%',
          }}
        >
          {links
            .filter((link) => link.isVisible)
            .map((link) => (
              <Card
                key={link.route}
                onClick={() => {
                  navigate(link.route)
                  handleClose()
                }}
                role="button"
                tabIndex={0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginBottom: '10px',
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                  borderRadius: '20px',
                  border: '1px solid #68B3E0',
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    padding: '10px',
                    '&:last-child': {
                      paddingBottom: '10px',
                    },
                  }}
                >
                  <img src={link.icon} alt={link.altSrc} style={{ marginRight: '12px' }} />
                  <Typography sx={{ fontWeight: 'bold', color: '#515151' }} variant="body1">
                    {t(link.title)}
                  </Typography>
                </CardContent>
              </Card>
            ))}
        </Box>
      </Modal>
      <Modal open={openPreProfile} onClose={handleClosePreProfile}>
        <MobilPreProfile handleClosePreProfile={handleClosePreProfile} />
      </Modal>
    </>
  )
}

export default NavbarResponsive
