import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import React, { useEffect, useState } from 'react'
import { File as F } from '../../modules/files/models/File'
import {
  ROUTE_CREATE,
  ROUTE_RESOURCES,
  ROUTE_RESOURCES_FORM,
  ROUTE_RESOURCES_SHARED_WITH_ME,
} from '../../routes/routes-constants'
import { emptyFileDTO, FileDTO, fromModel, toModel } from '../../modules/files/models/FileDTO'
import style from '../generic/GenericTable.module.css'
import { cloneDeep } from 'lodash'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import createFile from '../../assets/resource_icons/ico-file-crear.svg'
import createDirectory from '../../assets/resource_icons/ico-folder.svg'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users/container'
import { getUserContainer } from '../../container/user-module'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import { downloadFile, viewFile } from '../../common/utils/file'
import { AppTable, Field } from '../../components/table'
import { Actions, Pager, Search, SearchValue } from '../../components/table_type/types'
import { forkJoin, Observable } from 'rxjs'
import { User } from '../../modules/users/models/User'
import { reduceString } from '../../common/utils/strings'
import { UserService } from '../../modules/users/services/UserService'
import {
  ILoggedUserService,
  LoggedUserService,
} from '../../modules/users/services/LoggedUserService'
import tableStyles from './Table.module.css'
import folder from '../../assets/resource_icons/folferIconResponsive.svg'
import file from '../../assets/resource_icons/resourceIconResponsive.svg'
import fileResponsive from '../../assets/resource_icons/resourceIconResponsive.svg'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import seeIconResponsive from '../../assets/resource_icons/ico-eye.svg'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import editIconResponsive from '../../assets/resource_icons/ico-editar.svg'
import downloadIcon from '../../assets/table_icons/ico-download.svg'
import downloadIconResponsive from '../../assets/resource_icons/ico-download.svg'
import deleteIcon from '../../assets/table_icons/ico-eliminar.svg'
import deleteIconResponsive from '../../assets/resource_icons/ico-eliminar.svg'
import toAgoraIcon from '../../assets/table_icons/ico-subir.svg'
import ShareIcon from '../../assets/resource_icons/ico-share-azul.svg'
import { CustomModal } from '../../components/modal/CustomModal'
import { Permission } from '../../common/permission'
import { Query, QueryParam, QueryParamN } from '../../common/api/Query'
import { SharedWith, SharedWithQuery } from '../../modules/sharedWith/models/SharedWith'
import { ShareModal } from '../../components/share-modal/ShareModal'
import { useIsRelated } from 'hooks/relatedUsers/getRelatedUsers'
import { Box, Breadcrumbs, Modal, Stack, Tooltip } from '@mui/material'
import styles from '../share-resources/ShareResources.module.css'
import { RolesType } from '../../common/enums/RolesType'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleActiveService, USER_CIRCLE_ACTIVE_SERVICE_KEY } from '../../modules/user-circle'
import { TableSkeleton } from '../../components/table-skeleton/TableSkeleton'

const userContainer = getUserContainer()
const loggedUserService2 = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = userContainer.get<UserService>(USER_SERVICE_KEY)
const userCircleActiveService = getUserCircleContainer().get<IUserCircleActiveService>(
  USER_CIRCLE_ACTIVE_SERVICE_KEY
)
const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

type ResourcesProps = {
  resource?: string
  breadcrumbUrl?: Map<string, string>
}

export function Table(props: ResourcesProps): JSX.Element {
  const { t } = useTranslation('translation')
  const location = useLocation()
  const navigate = useNavigate()
  const userCircle = userCircleActiveService.getActiveFullUserCircle()
  const loggedUser = loggedUserService.get()
  const rel = useIsRelated()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [resources, setResources] = useState<F[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [breadcrumbUrl, setBreadcrumbUrl] = useState<Map<string, string>>(
    props.breadcrumbUrl && props.breadcrumbUrl.size !== 0
      ? props.breadcrumbUrl
      : new Map<string, string>([['-1', 'Inicio']])
  )
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [filesPerPage, setFilesPerPage] = useState<number>(10)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [currentFile, setCurrentFile] = useState<F>()
  const [userNames, setUserNames] = useState<Map<string, string>>(new Map())
  const [fileDTO] = useState<FileDTO>(
    currentFile ? fromModel(currentFile) : emptyFileDTO('', '', false)
  )
  const [isSystem, setIsSystem] = useState<string>('')
  const isSharedFolder = location.pathname.includes(ROUTE_RESOURCES_SHARED_WITH_ME)
  const [mapSharedUsers, setMapSharedUsers] = useState<Map<string, string[]>>(
    new Map<string, string[]>()
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sharedFiles, setSharedFiles] = useState<SharedWith[]>([])
  const [openModalShare, setOpenModalShare] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shareWithArray, setShareWithArray] = useState<string[] | string>(t('anyone'))

  const { innerWidth } = window
  const [isLoadResource, setIsLoadResource] = useState<boolean>(false)
  const [currentDirectory, setCurrentDirectory] = useState<F | null>(null)
  const [isCreatedByDoctor, setIsCreatedByDoctor] = useState<boolean>(false)
  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString) // Parsea la fecha
    const day = date.getDate().toString().padStart(2, '0') // Obtiene el día y lo ajusta al formato DD
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Obtiene el mes y lo ajusta al formato MM (recuerda que los meses comienzan desde 0)
    const year = date.getFullYear() // Obtiene el año
    return `${day}-${month}-${year}` // Retorna la fecha formateada
  }
  const [haveCircle, setHaveCircle] = useState<string>('')
  const [backButton, setBackButton] = useState<boolean>(false)
  const [badResoursesOpen, setBadResoursesOpen] = useState<boolean>(false)
  const [badResoursesMessage, setBadResoursesMessage] = useState<string>('')
  const [badResoursesTitle, setBadResoursesTitle] = useState<string>('')
  const [userID] = useState<string>(loggedUser && loggedUser.id ? loggedUser.id : '')

  const [searcher, setSearcher] = useState<SearchValue<SharedWithQuery>[]>([
    {
      name: 'searchName',
      label: t('search') + '...',
      value: '',
    },
  ])

  const search: Search<SharedWithQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<SharedWithQuery>[]) => {
      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)

        return Object.assign({ ...s }, { value: date.toJSON() })
      })
      setSearcher(result)
    },
  }

  useEffect(() => {
    if (isLoading) {
      if (!loggedUser) return
      if (!props.resource) {
        filesService
          .sharedFileIn(
            new Query({
              query: [
                new QueryParam<SharedWithQuery>(
                  'userId',
                  !loggedUser.roles.includes('professionalSMS') ? loggedUser.id : ''
                ),
                new QueryParam<SharedWithQuery>('fileId', props.resource ?? ''),
                new QueryParam<SharedWithQuery>('circleId', userCircle?.id ?? ''),
                ...searcherQuery(searcher),
              ],
              pager: { offset: page * filesPerPage, limit: filesPerPage },
            })
          )
          .subscribe((res) => {
            if (!res.items) return
            setResources(res.items)
            setCount(res.count)
            res.items.forEach((f) => {
              setIsSystem(f?.isSystem ? f?.isSystem : '')
              handleSubscribeFileService(f)
            })
            timer(1500)
          })
      } else {
        filesService
          .sharedFileIn(
            new Query({
              query: [
                new QueryParam<SharedWithQuery>(
                  'userId',
                  !loggedUser.roles.includes('professionalSMS') ? loggedUser.id : ''
                ),
                new QueryParam<SharedWithQuery>('fileId', props.resource ?? ''),
                new QueryParam<SharedWithQuery>('circleId', userCircle?.id ?? ''),
                ...searcherQuery(searcher),
              ],
              pager: { offset: page * filesPerPage, limit: filesPerPage },
            })
          )
          .subscribe((res) => {
            setResources(res.items)
            setCount(res.count)
            res.items.forEach((f) => {
              filesService
                .getFilteredItemsSharedWithMe(
                  new Query({
                    query: [
                      new QueryParam<SharedWithQuery>('fileId', f.id ?? ''),
                      ...searcherQuery(searcher),
                    ],
                    pager: { offset: page * filesPerPage, limit: filesPerPage },
                  })
                )
                .subscribe((res) => {
                  setSharedFiles(res)
                  timer(1500)
                })
            })
          })
      }
    }

    return () => {
      setIsLoading(false)
      handleSubscribeFileService(undefined)
    }
  }, [isLoading, page, filesPerPage])

  const timer = (t: number) => {
    const interval = setInterval(() => {
      setIsLoading(false)
    }, t)

    return () => {
      clearInterval(interval)
    }
  }

  useEffect(() => {
    setIsLoading(true)
  }, [props.resource])

  useEffect(() => {
    const mapAux: Map<string, string[]> = new Map<string, string[]>()
    resources.forEach((r) => {
      filesService.getUsersSharedByFileID(r.id ?? '', userCircle?.id ?? '').subscribe((res) => {
        mapAux.set(r.id ?? '', res)
      })
    })
    if (mapAux !== undefined) {
      setMapSharedUsers(mapAux)
    }
  }, [resources])

  useEffect(() => {
    if (props.breadcrumbUrl && props.breadcrumbUrl.size !== 0) {
      setBreadcrumbUrl(props.breadcrumbUrl)
    }
  }, [props.breadcrumbUrl])

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  useEffect(() => {
    let users: string[] = []
    resources.forEach((r) => {
      users = users.concat(r.sharedWith, [r.creator])
    })
    getUsers(users).subscribe((res) => {
      const newMap = new Map()
      res.forEach((user) => {
        if (user) {
          newMap.set(user.id, user.name + ' ' + user.lastname)
        }
      })
      setUserNames(newMap)
    })
  }, [resources])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: filesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, filesPerPage, searcher])

  useEffect(() => {
    setPage(0)
  }, [filesPerPage, searcher[0].value])

  useEffect(() => {
    setIsLoading(true)
  }, [page, filesPerPage, searcher[0].value])

  useEffect(() => {
    if (!props.resource) return
    if (isLoading) {
      filesService
        .getByID(props.resource || null)
        .toPromise()
        .then((res) => {
          if (!res) return
          setIsSystem(res?.isSystem ? res?.isSystem : '')
          setIsCreatedByDoctor(res.createdByDoctor)
          setHaveCircle(res.circles)
          handleSubscribeFileService(res)
        })
    }
  }, [isLoading, props.resource])

  useEffect(() => {
    if (currentDirectory === null) return
    handleSubscribeFileService(currentDirectory)
  }, [props.resource])

  const handleSubscribeFileService = (res: F | undefined) => {
    if (props.resource) {
      if (breadcrumbUrl.get(props.resource ?? '')) {
        let breadcrumbUrlCopy = Array.from(breadcrumbUrl, ([key, value]) => ({
          key,
          value,
        }))
        breadcrumbUrlCopy = breadcrumbUrlCopy.slice(
          0,
          breadcrumbUrlCopy.findIndex(({ key }) => key === props.resource) + 1
        )
        setBreadcrumbUrl(new Map(breadcrumbUrlCopy.map((i) => [i.key, i.value])))
      } else {
        const breadcrumbCopy = cloneDeep<Map<string, string>>(breadcrumbUrl)
        breadcrumbCopy.set(props.resource, res?.name ?? '-')
        setBreadcrumbUrl(breadcrumbCopy)
      }
    } else {
      setBreadcrumbUrl(new Map<string, string>([['-1', 'Inicio']]))
    }
  }

  const createResource = () =>
    navigate(`${ROUTE_RESOURCES_FORM}/${ROUTE_CREATE}`, {
      state: { parentID: props.resource ?? null, breadcrumbUrl },
    })

  const createDir = () =>
    navigate(`${ROUTE_RESOURCES_FORM}/${ROUTE_CREATE}`, {
      state: { parentID: props.resource ?? null, breadcrumbUrl, isDir: true },
    })

  const editResource = (resource: F) =>
    navigate(`${ROUTE_RESOURCES_FORM}/${resource.id}`, {
      state: {
        parentID: props.resource ?? null,
        breadcrumbUrl,
        isDir: resource.isDir,
      },
    })

  const downloadResource = (resource: F) => {
    setIsLoadResource(true)
    filesService.download(resource).subscribe((res) => {
      if (res) {
        downloadFile(res.name, res.mimeType, res.data)
        setIsLoadResource(false)
      } else {
        setBadResoursesTitle(t('seeResourses'))
        setBadResoursesMessage(t('modalRosoursesBroken'))
        setBadResoursesOpen(true)
        setIsLoadResource(false)
      }
    })
  }

  const viewResource = (resource: F) => {
    setIsLoadResource(true)
    filesService.download(resource).subscribe((res) => {
      if (res) {
        viewFile(res)
        setIsLoadResource(false)
      } else {
        setBadResoursesTitle(t('seeResourses'))
        setBadResoursesMessage(t('modalRosoursesBroken'))
        setBadResoursesOpen(true)
        setIsLoadResource(false)
      }
    })
  }

  const handleCloseShareModal = () => {
    setOpenModalShare(false)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleSave = () => {
    const newFile = Object.assign({ ...fileDTO }, { isUploaded: true })
    filesService.update(toModel(newFile)).subscribe()
    setCurrentFile(undefined)
    setOpenModal(false)
    setIsLoading(true)
  }

  const handleClick = (resource: F) => {
    if (resource.isDir) {
      setCurrentDirectory(resource)
      handleSubscribeFileService(resource)
      navigateToResources(resource.id ?? null, isSharedFolder, resource)
    }
  }

  const removeResource = (resource: F) => {
    setCurrentFile(resource)
    setOpenDeleteModal(true)
    setIsLoading(true)
  }

  const navigateToResources = (
    id: string | null,
    goToSharedFolder: boolean = false,
    resource: F | null
  ) => {
    if (id) {
      setCurrentDirectory(resource)
      navigate(`${goToSharedFolder ? ROUTE_RESOURCES_SHARED_WITH_ME : ROUTE_RESOURCES}/${id}`, {
        state: {
          parentID: props.resource ?? null,
          breadcrumbUrl,
          isDir: true,
        },
      })
      return
    }
    setCurrentDirectory(null)
    navigate(`${goToSharedFolder ? ROUTE_RESOURCES_SHARED_WITH_ME : ROUTE_RESOURCES}`, {
      state: {
        parentID: props.resource ?? null,
        breadcrumbUrl: new Map(),
        isDir: false,
      },
    })
  }

  async function handleBreadcrumbClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setSearcher([
      {
        name: 'searchName',
        label: t('search') + '...',
        value: '',
      },
    ])
    const resourceID = event.currentTarget.id
    if (resourceID === props.resource) return

    if (Number(resourceID) === -1) {
      setCurrentDirectory(null)
      navigateToResources(null, isSharedFolder, null)
    } else {
      filesService.getByID(resourceID).subscribe((res) => {
        if (!res) return
        setCurrentDirectory(res)
      })
      navigateToResources(resourceID, isSharedFolder, null)
    }
  }

  async function handleBreadcrumbBackClick(_: React.MouseEvent<HTMLElement, MouseEvent>) {
    setSearcher([
      {
        name: 'searchName',
        label: t('search') + '...',
        value: '',
      },
    ])
    setBackButton(true)
    if (currentDirectory) {
      const parentID = currentDirectory.parent ?? null
      if (parentID) {
        filesService.getByID(parentID).subscribe((res) => {
          if (!res) return
          setCurrentDirectory(res)
        })
      } else {
        breadcrumbUrl?.delete(currentDirectory.id ? currentDirectory.id : '')
        setCurrentDirectory(null)
      }
      navigateToResources(parentID, isSharedFolder, null)
    } else {
      setBreadcrumbUrl(new Map())
      setCurrentDirectory(null)
      navigateToResources(null, isSharedFolder, null)
    }
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setFilesPerPage(10)
      return
    }
    setFilesPerPage(Number.parseInt(event.target.value))
  }

  const handleToAgora = (item: F) => {
    if (item) setCurrentFile(item)
    setOpenModal(true)
  }

  const hasSpeciality = (r: F) => {
    if (r.circles !== '' && r.specialities !== '') {
      return false
    }
    return true
  }

  const isCreator = (r: F) => r.creator === userID && hasSpeciality(r)
  const fields: Field<F>[] =
    innerWidth > 598
      ? [
          {
            name: 'name',
            label: t('title'),
            renderFunc: (f, i) => (
              <div className={i.isDir ? tableStyles.dir : ''} onClick={() => handleClick(i)}>
                <img src={i.isDir ? folder : file} className={tableStyles.icon} />
                <span style={{ color: i.creator === loggedUser?.id ? '' : '' }}>{i.name}</span>
              </div>
            ),
          },
          {
            name: 'creator',
            label: t('creator'),
            renderFunc: (f, i) =>
              i.isSystem !== '' ? 'Sistema' : (userNames.get(i.creator) ?? ''),
          },
          {
            name: 'creationDate',
            label: t('creationDate'),
            renderFunc: (f, i) => (
              <div className={i.isDir ? tableStyles.dir : ''} onClick={() => handleClick(i)}>
                <span>{formatDate(i.creationDate ? i.creationDate.toString() : '')}</span>
              </div>
            ),
          },
          {
            name: 'sharedWith',
            label: innerWidth > 598 ? t('sharedWith') : '',
            renderFunc: (f, i) => {
              if (innerWidth < 598) {
                return ''
              }
              const mshared = mapSharedUsers.get(i.id ?? '')
              if (mshared && mshared?.length > 0) {
                const joinedNames = mshared?.join(', ')
                return (
                  <div>
                    <Tooltip title={joinedNames || ''}>
                      <div>{reduceString(joinedNames || '', 30)}</div>
                    </Tooltip>
                  </div>
                )
              }
              // if (i.isPublic) return t('every')
              return t('anyone')
            },
          },
        ]
      : [
          {
            name: 'name',
            label: '',
            renderFunc: (f, i) => (
              <div className={i.isDir ? tableStyles.dir : ''} onClick={() => handleClick(i)}>
                <div style={{ marginBottom: '12px' }}>
                  <img src={i.isDir ? folder : fileResponsive} className={tableStyles.icon} />
                  <span style={{ fontWeight: 'bold' }}>{i.name}</span>
                </div>
                <span style={{ fontWeight: 'bold' }}>
                  Creado por: {i.isSystem !== '' ? 'Sistema' : (userNames.get(i.creator) ?? '')}
                </span>
              </div>
            ),
          },
          {
            name: 'creationDate',
            label: '',
            renderFunc: (f, i) => (
              <div className={i.isDir ? tableStyles.dir : ''} onClick={() => handleClick(i)}>
                <span>{formatDate(i.creationDate ? i.creationDate.toString() : '')}</span>
              </div>
            ),
          },
        ]

  const actions: Actions<F> =
    innerWidth > 598
      ? {
          actionsColumn: t('Actions'),
          items: [
            {
              handler: viewResource,
              icon: innerWidth > 598 ? seeIcon : seeIconResponsive,
              label: 'see',
              hidden: (r) =>
                rel
                  ? (!isCreator(r) && !rel) || r.isSystem !== '' || r.isDir
                  : (!rel && r.isSystem !== '') || r.isDir,
            },
            {
              handler: editResource,
              icon: innerWidth > 598 ? editIcon : editIconResponsive,
              label: 'edit',
              hidden: rel ? (r) => !isCreator(r) || r.isSystem !== '' : () => true,
            },
            {
              handler: downloadResource,
              icon: innerWidth > 598 ? downloadIcon : downloadIconResponsive,
              label: 'download',
              hidden: (r) => r.isDir,
            },
            {
              handler: handleToAgora,
              icon: toAgoraIcon,
              label: 'cloudToAgora',
              hidden: rel
                ? () =>
                    !loggedUserService2.userCan(Permission.uploadToAgora) ||
                    !(typeof props.resource === 'undefined')
                : () => true,
            },
            {
              handler: removeResource,
              icon: innerWidth > 598 ? deleteIcon : deleteIconResponsive,
              label: 'delete',
              hidden: rel ? (r) => !isCreator(r) || r.isSystem !== '' : (r) => true,
            },
          ],
        }
      : {
          items: [
            {
              handler: downloadResource,
              icon: downloadIconResponsive,
              label: 'download',
              hidden: (r) => r.isDir,
            },
          ],
        }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteResource = () => {
    if (currentFile) {
      filesService.delete(currentFile).subscribe(() => {
        setIsLoading(true)
      })
    }
    setOpenModal(false)
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  const breadcrumbItems = Array.from(breadcrumbUrl.entries()).map(([key, value]) => (
    <span className={styles.breadcrumbItem} key={key}>
      <a style={{ fontFamily: 'Poppins' }} id={key} onClick={handleBreadcrumbClick}>
        {value}
      </a>
    </span>
  ))

  // resolve el bug cuando pinchas muchas veces seguido boton "volver". Antes volvia a principio y se rompia breadcrumblURL
  useEffect(() => {
    if (backButton) {
      setTimeout(() => setBackButton(false), 500)
    }
  }, [backButton])

  const handleCloseModalBadResources = () => {
    setBadResoursesOpen(false)
  }

  const buttonCreateResourses = (): boolean => {
    if (!props.resource) {
      return true
    }

    if (!((!isSharedFolder && isSystem === '') || !props.resource)) {
      return false
    }

    if (
      loggedUser?.roles.includes(RolesType.ProfessionalSMS) &&
      haveCircle === '' &&
      currentDirectory?.creator === userID
    ) {
      return true
    }

    if (!isCreatedByDoctor && currentDirectory?.creator === userID) {
      return true
    }

    return false
  }

  useEffect(() => {
    if (!props.resource) return
    searcher[0].value = ''
    setIsLoading(true)
    filesService.getByID(props.resource).subscribe((res) => {
      if (!res) return
      setCurrentDirectory(res)
    })
  }, [props.resource])

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Stack display={'flex'} mb={2} spacing={2}>
          <Breadcrumbs separator=">" aria-label="breadcrumb" color={'#68b3e0'}>
            {breadcrumbItems}
          </Breadcrumbs>
        </Stack>
        <Box className={style.buttonContainer}>
          {buttonCreateResourses() && (
            <>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('createResource')}
                startIcon={createFile}
                handler={createResource}
              />
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('createDir')}
                startIcon={createDirectory}
                handler={createDir}
              />
            </>
          )}
          {props.resource && (
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('backOneToResources')}
              handler={handleBreadcrumbBackClick}
              disabled={backButton}
            />
          )}
        </Box>
        <Box>
          {!isLoading ? (
            <>
              <AppTable
                items={resources}
                rowKeyField="id"
                fields={fields}
                actions={actions}
                pager={pager}
                search={search}
                isPreventDefault={true}
              />
              {resources.length === 0 && (
                <h1 key="no-resources" className={style.noElements}>
                  {t('noResources')}
                </h1>
              )}
            </>
          ) : (
            <TableSkeleton searcher={true} />
          )}
          {!currentFile?.isDir && (
            <Modal
              open={openDeleteModal}
              className={style.eventModal}
              onClose={handleCloseDeleteModal}
            >
              <>
                <CustomModal
                  handleClose={handleCloseDeleteModal}
                  handleSave={handleDeleteResource}
                  title={t('deleteResource')}
                  warningText={t('irreversibleResourceAction')}
                />
              </>
            </Modal>
          )}
          {currentFile?.isDir && (
            <Modal
              open={openDeleteModal}
              className={style.eventModal}
              onClose={handleCloseDeleteModal}
            >
              <>
                <CustomModal
                  handleClose={handleCloseDeleteModal}
                  handleSave={handleDeleteResource}
                  title={t('deleteDirectory')}
                  warningText={t('irreversibleDirectoryAction')}
                />
              </>
            </Modal>
          )}
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <>
            <CustomModal
              handleClose={handleCloseModal}
              handleSave={handleSave}
              title={t('cloudToAgora')}
              warningText={t('irreversibleAction')}
            />
          </>
        </Modal>
        <Modal open={openModalShare} onClose={handleCloseShareModal} className={style.sharedModal}>
          <>
            <ShareModal
              title={'Compartido con'}
              icon={ShareIcon}
              handleContent={shareWithArray}
              handleClose={handleCloseShareModal}
            />
          </>
        </Modal>
      </Box>
      {isLoadResource ? (
        <Box
          style={{
            backgroundColor: '#0a09098f',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '130px',
          }}
        >
          <LoadingSpinner className={loaderStyles.loadingResourceSpinner} />
        </Box>
      ) : null}
      <Box>
        <Modal
          open={badResoursesOpen}
          onClose={handleCloseModalBadResources}
          className={style.eventModal}
        >
          <CustomModal
            title={badResoursesTitle}
            handleClose={handleCloseModalBadResources}
            warningText={badResoursesMessage}
            handleSave={() => {}}
            noButton={true}
          />
        </Modal>
      </Box>
    </>
  )
}

const searcherQuery = (
  svs: SearchValue<SharedWithQuery>[]
): QueryParam<SharedWithQuery>[] | QueryParamN<SharedWithQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
