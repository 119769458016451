// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GenericChart_canvas__1newX {\n  max-height: 1920px;\n}\n@media (max-width: 1920px){\n  .GenericChart_canvas__1newX {\n    width: auto!important;\n    max-height: 450px!important;\n  }  \n}", "",{"version":3,"sources":["webpack://src/components/generic-chart/GenericChart.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE;IACE,qBAAqB;IACrB,2BAA2B;EAC7B;AACF","sourcesContent":[".canvas {\n  max-height: 1920px;\n}\n@media (max-width: 1920px){\n  .canvas {\n    width: auto!important;\n    max-height: 450px!important;\n  }  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"canvas": "GenericChart_canvas__1newX"
};
export default ___CSS_LOADER_EXPORT___;
